import TYPES from '../../action-types';
import convertAssetToMatchData from '../../helpers/assets/convertAssetToMatchData';

const selectAllByMatch = () => (dispatch, getAll) => {
  const { items } = getAll().assets;

  dispatch({
    type: TYPES.ASSETS.SELECT_ALL_BY_MATCH,
    payload: {
      selectedAssetsData: items.map((item) => convertAssetToMatchData(item)),
    },
  });
  window.dispatchEvent(new Event('selection:switched:on'));
};

export default selectAllByMatch;
