import React, {
  memo, useMemo, useCallback, useState,
  useEffect,
} from 'react';
import { TextField } from '@picsio/ui';
import { useDispatch } from 'react-redux';
import { changeHandle } from '../../store/products/actions';

import DetailsBlock from '../DetailsBlock';

import s from './styles.module.scss';

const DEFAULT_META = [];

const SearchListing = ({
  productId,
  handle,
  meta = DEFAULT_META,
  detailsPanelVisibility,
  toggleVisibility,
  blockName,
  blockTitle,
}) => {
  const dispatch = useDispatch();
  const title = useMemo(() => meta.find((item) => item.key === 'title_tag')?.value || '', [meta]);
  const description = useMemo(() => meta.find((item) => item.key === 'description_tag')?.value || '', [meta]);

  const [editedHandle, setEditedHandle] = useState(handle);

  useEffect(() => {
    setEditedHandle(handle);
  }, [handle]);

  const handleChangeHandle = useCallback((e) => {
    setEditedHandle(e.target.value);
  }, [setEditedHandle]);

  const saveHandle = useCallback(() => {
    if (editedHandle === handle || !editedHandle) return;
    dispatch(changeHandle({ productId, handle: editedHandle }));
  }, [dispatch, editedHandle, handle, productId]);

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') e.target.blur();
  }, []);

  return (
    <DetailsBlock
      dataQa="productSearchListing"
      detailsPanelVisibility={detailsPanelVisibility}
      toggleVisibility={toggleVisibility}
      blockName={blockName}
      blockTitle={blockTitle}
    >
      <div className={s.row}>
        <TextField
          value={editedHandle}
          onChange={handleChangeHandle}
          onBlur={saveHandle}
          onKeyPress={handleKeyPress}
          error={!editedHandle}
          label="URL handle"
        />
      </div>
      <div className={s.row}>
        <TextField
          value={title}
          label="Page title"
          inputProps={{ maxLength: 70 }}
          disabled
        />
      </div>
      <div className={s.row}>
        <TextField
          InputProps={{
            component: 'textarea',
            multiline: true,
            maxLength: 320,
          }}
          value={description}
          label="Meta description"
          disabled
        />
      </div>
    </DetailsBlock>
  );
};

export default memo(SearchListing);
