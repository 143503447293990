import React from 'react';
import { createRoot } from 'react-dom/client';
import Logger from '../../services/Logger';
import UnsupportedMediaDialog from './UnsupportedMediaDialog';

export default async function renderUnsupportedMediaDialog({ codes, allowedAssetCount, allAssetCount }) {
  Logger.log('UI', 'ShowUnsupportedMediaDialog', { codes });

  const dialogRoot = document.getElementById('dialogRoot');
  const wrapperDialog = document.createElement('div');
  wrapperDialog.id = 'wrapperUnsupportedMediaDialog';
  dialogRoot.appendChild(wrapperDialog);

  const parentEl = document.getElementById('wrapperUnsupportedMediaDialog');

  const root = createRoot(parentEl);

  const handleCloseDialog = () => {
    Logger.log('User', 'UnsupportedMediaDialogCancel');
    if (parentEl) root.unmount();
    dialogRoot.removeChild(wrapperDialog);
  };

  root.render(
    <UnsupportedMediaDialog
      onClose={handleCloseDialog}
      codes={codes}
      allowedAssetCount={allowedAssetCount}
      allAssetCount={allAssetCount}
    />,
  );
}
