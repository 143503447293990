import React from 'react';
import {
  object, oneOfType, string, bool, func,
} from 'prop-types';
import cn from 'classnames';
import { Icon, IconButton } from '@picsio/ui';
import {
  Close, Checkbox, Hash, Date, Title,
} from '@picsio/icons';
import localization from '../../shared/strings';
import ua from '../../ua';
import Logger from '../../services/Logger';
import picsioConfig from '../../../../../config';

const isMobile = ua.browser.isNotDesktop();

class SortItem extends React.Component {
  handleClick = (event, order) => {
    if (!isMobile) event.stopPropagation(); // desktop sort dropdown doesn't hiding by item clicking
    const {
      name, sort, changeSort, sortConfig, onNewClicked, isCustomField,
    } = this.props;

    const defaultOrder = 'desc';
    const currentSortType = sort.type;

    function setSortOrder() {
      if (name === 'custom' || name === 'score') return;
      if (order) {
        return order;
      } if (currentSortType === name) {
        return sort.order === 'asc' ? 'desc' : 'asc';
      }
      return defaultOrder;
    }

    Logger.log('User', 'ChangeSortMode', isCustomField ? { name: `meta.${name}`, sortOrder: sort.order } : { name, sortOrder: sort.order });

    if (changeSort) {
      if (sortConfig[name]?.isNewFeature && name === 'score' && picsioConfig.isMainApp) {
        onNewClicked({
          settings: {
            isBestMatchVisited: true,
          },
        }, false);
      }
      changeSort(isCustomField ? `meta.${name}` : name, setSortOrder());
    } else {
      throw new Error('changeSortHandler is undefined');
    }
  };

  handleDeselect = (event) => {
    event.stopPropagation();
    this.props.removeCustomField(this.props.selectedCustomField);
  }

  render() {
    const {
      children,
      name,
      sortConfig,
      sort,
      additionalClass,
      disabled,
      isBestMatchVisited,
      isCustomField,
      selectedCustomField,
    } = this.props;

    let ascText = '';
    let descText = '';
    let sortingIcon = '';
    if (isCustomField) {
      if (selectedCustomField.type === 'enum') {
        ascText = 'A-z';
        descText = 'Z-a';
        sortingIcon = sortConfig.meta.icon();
      } else if (selectedCustomField.type === 'boolean') {
        ascText = 'Unset first';
        descText = 'Set first';
        sortingIcon = <Checkbox />;
      } else if (selectedCustomField.type === 'int') {
        ascText = 'Min first';
        descText = 'Max first';
        sortingIcon = <Hash />;
      } else if (selectedCustomField.type === 'date') {
        ascText = 'Old first';
        descText = 'New first';
        sortingIcon = <Date />;
      } else {
        ascText = 'A-z';
        descText = 'Z-a';
        sortingIcon = <Title />;
      }
    } else {
      ascText = sortConfig[name].asc;
      descText = sortConfig[name].desc;
      sortingIcon = sortConfig[name].icon();
    }

    return (
      <div
        className={cn('toolbarDropdownItem toolbarDropdownSortItem', {
          active: isCustomField ? sort.type === `meta.${name}` : sort.type === name,
          [additionalClass]: additionalClass,
          disabled,
        })}
        onClick={(event) => this.handleClick(event)}
      >
        <i className="toolbarDropdownItemIcon">
          <Icon size="lg" color="inherit">
            {sortingIcon}
          </Icon>
        </i>

        <Choose>
          <When condition={isCustomField}>
            <span className="toolbarDropdownItemText">{name.replace('meta.', '')}</span>
          </When>
          <Otherwise>
            <span className="toolbarDropdownItemText">{localization.SORT[name]}</span>
          </Otherwise>
        </Choose>

        <If condition={sortConfig[name]?.isNewFeature && picsioConfig.isMainApp && !isBestMatchVisited}>
          <span className="newFeatures">{localization.DETAILS.new}</span>
        </If>

        <If condition={name !== 'custom' && name !== 'score'}>
          <div className="sortDirections">
            <span
              onClick={(event) => this.handleClick(event, 'asc')}
              className={cn('sortDirection-asc', {
                'sortDirection-disabled': sort.order !== 'asc',
              })}
              data-testid={`sortingField-${isCustomField ? name.replace('meta.', '') : localization.SORT[name]}-${ascText}`}
            >
              {ascText}
            </span>
            <span
              onClick={(event) => this.handleClick(event, 'desc')}
              className={cn('sortDirection-desc', {
                'sortDirection-disabled': sort.order !== 'desc',
              })}
              data-testid={`sortingField-${isCustomField ? name.replace('meta.', '') : localization.SORT[name]}-${descText}`}
            >
              {descText}
            </span>
          </div>
          <If condition={isCustomField && !picsioConfig.isProofing}>
            <IconButton componentProps={{ 'data-testid': `removeCustomFieldSort-${name.replace('meta.', '')}` }} id="removeCustomFieldSort" buttonSize="lg" size="xl" onClick={this.handleDeselect}>
              <Close />
            </IconButton>
          </If>
        </If>
        {children}
      </div>
    );
  }
}

SortItem.defaultProps = {
  isCustomField: false,
};

SortItem.propTypes = {
  name: string,
  sortConfig: object,
  sort: oneOfType([string, object]),
  additionalClass: string,
  disabled: bool,
  changeSort: func,
  onNewClicked: func,
  isBestMatchVisited: bool,
  isCustomField: bool,
};

export default SortItem;
