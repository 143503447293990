import TYPES from '../../action-types';

const deselectAllByMatch = () => (dispatch) => {
  dispatch({
    type: TYPES.ASSETS.DESELECT_ALL_BY_MATCH,
    payload: {},
  });
  window.dispatchEvent(new Event('selection:switched:off'));
};

export default deselectAllByMatch;
