import events from '@picsio/events';
import CONSTANTS from '@picsio/db/src/constants';
import _uniq from 'lodash/uniq';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _replace from 'lodash/replace';
import qlimit from 'qlimit';
import Q from 'q';

import { bindActionCreators } from 'redux';
import picsioConfig from '../../../../config';
import l18n from '../shared/strings';
import Logger from './Logger';

import sdk from '../sdk';

import store from '../store';
import * as actions from '../store/actions/notifications';
import * as mainActions from '../store/actions/main';
import * as user from '../store/actions/user';
import * as _collectionsActions from '../store/actions/collections';
import * as _assetsActions from '../store/actions/assets';
import * as _keywordsActions from '../store/keywords/actions';
import * as _facesActions from '../store/faces/actions';
import * as _customFieldsActions from '../store/actions/customFields';
import * as _inboxesActions from '../store/inboxes/actions';
import * as _websiteSettingsActions from '../store/websiteSettings/actions';
import * as _downloadListActions from '../store/actions/downloadList';
import * as _productsActions from '../store/products/actions';
import { isHaveTeammatePermission } from '../store/helpers/user';
import { getTeam, setTeammateStatus } from '../store/reducers/teammates';
import { showDestructiveSyncErrorDialog } from '../store/helpers/notifications';
import { LocalStorage } from '../shared/utils';
import * as pathHelper from '../helpers/paths';
import getAggregatedData from '../helpers/assets/getAggregatedData';
import sendEventToIntercom from './IntercomEventService';

import {
  findCollection,
  findCollections,
  getParent,
  getAllowedCollections,
  getAllowedCollectionsWithPermissions,
} from '../store/helpers/collections';
import getSessionId from '../helpers/getSessionId';
import checkUserEventSubscription from '../helpers/checkUserEventSubscription';

import * as _archiveActions from '../store/actions/archive';
import { findCollectionById } from '../store/actions/helpers/archive';
import Toast from '../components/Toast';
import { showDialog } from '../components/dialog';
import {
  back, getPreviewId, navigateToRoot, setSearchRoute, getSearchProps, navigate,
} from '../helpers/history';
import { saveFile } from '../store/actions/downloadList';
import TYPES from '../store/action-types';

const eventItems = [
  'colletcion.copied',
  'collection.created',
  'collection.deleted',
  'collection.renamed',
  'collection.moved',
  'collection.color.changed',
  'collection.description.changed',
  'collection.archived',
  'collection.archived.deleted',
  'collection.unarchived',
  'website.created',
  'website.deleted',
  'invitation.sent',
  'invitation.accepted',
  'invitation.rejected',
  'invitation.pending',
  'asset.transcribing.complete',
  'asset.transcribing.failed',
  'asset.transcribing.rejected',
  'asset.transcribing.chunk_is_ready',
  'assets.trashing.waiting',
  'assets.trashing.complete',
  'assets.moving.waiting',
  'assets.moving.complete',
  'assets.deleting.waiting',
  'assets.deleting.complete',
  'assets.untrashing.waiting',
  'assets.untrashing.complete',
  'assets.untrashing.failed',
  'assets.assigned',
  'assets.unassigned',
  'assets.archived',
  'assets.unarchived',
  'assets.archived.deleted',
  'asset.revision.approved',
  'asset.revision.disapproved',
  'asset.created',
  'asset.renamed',
  'asset.comment.added',
  'asset.comment.deleted',
  'asset.comment.reaction.changed',
  'asset.copied',
  'asset.revision.created',
  'asset.revision.reverted',
  'assets.color.changed',
  'assets.flag.changed',
  'assets.rating.changed',
  'assets.attached_to_collection',
  'assets.detached_from_collection',
  'assets.keyword_attached',
  'assets.keyword_detached',
  'assets.keywords_detached',
  'asset.metadating.complete',
  'assets.crawling.complete',
  'assets.crawling.failed',
  'asset.replicating.complete',
  'asset.contenting.complete',
  'asset.converting.complete',
  'asset.thumbnailing.complete',
  'assets.metadating.failed',
  'assets.replicating.failed',
  'assets.keywording.complete',
  'asset.faceRecognizing.complete',
  'asset.manual_faceRecognizing.complete',
  'assets.keywording.failed',
  'assets.contenting.failed',
  'assets.converting.failed',
  'assets.thumbnailing.failed',
  'assets.removed_modified_meta_field',
  'keywords.created',
  'keyword.renamed',
  'keywords.deleted',
  'keywords.dictionary_applied',
  'sync.started.FullSync',
  'sync.failed.FullSync',
  'sync.succeed.FullSync',
  'sync.failed.PartialSync',
  'sync.succeed.IncrementalSync',
  'user.free_keywords_run_out',
  'user.working_folder.changed',
  'system.message',
  'customfield.created',
  'customfield.deleted',
  'command.reload_app',
  'user.jobs_statuses',
  'inbox.created',
  'inbox.renamed',
  'inbox.deleted',
  'inbox.changed',
  'inbox.asset.created',
  'inbox.assetsLimitExceeded',
  'asset.adobe_uploaded',
  'faces.created',
  'face.avatarCropping.complete',
  'user.api_calls_counter.updated',
  'assets.moved_to_collection',
  'request.created',
  'websiteSettingsPreset.created',
  'websiteSettingsPreset.deleted',
  'websiteSettingsPreset.renamed',
  'assets.download.status_changed',
  'user.dropbox.connected',
  'user.dropbox.connection_error',
  'asset.describing.complete',
  'asset.describing.failed',
  'assets.importing.complete',
  'assets.importing.failed',
  'collection.importing.complete',
  'collections.importing.failed',
  'assets.describing.complete',
  'user.aikit.tier_upgraded',
  'user.productsPulling.complete',
  'assets.importingMedia.complete',
  'assets.importingMedia.failed',
  'assets.productAttaching.complete',
  'assets.productAttaching.failed',
];

const notificationsActions = bindActionCreators(actions, store.dispatch);
const userActions = bindActionCreators(user, store.dispatch);
const mainAction = bindActionCreators(mainActions, store.dispatch);
const collectionsActions = bindActionCreators(_collectionsActions, store.dispatch);
const assetsActions = bindActionCreators(_assetsActions, store.dispatch);
const keywordsActions = bindActionCreators(_keywordsActions, store.dispatch);
const facesActions = bindActionCreators(_facesActions, store.dispatch);
const customFieldsActions = bindActionCreators(_customFieldsActions, store.dispatch);
const inboxesActions = bindActionCreators(_inboxesActions, store.dispatch);
const archiveActions = bindActionCreators(_archiveActions, store.dispatch);
const getTeamAction = bindActionCreators(getTeam, store.dispatch);
const websiteSettingsActions = bindActionCreators(_websiteSettingsActions, store.dispatch);
const downloadListActions = bindActionCreators(_downloadListActions, store.dispatch);
const setTeammateStatusAction = bindActionCreators(setTeammateStatus, store.dispatch);
const productsActions = bindActionCreators(_productsActions, store.dispatch);

const notificationCenterTypes = events.getNotificationCenterTypes(picsioConfig.eventsAppName);
const getIds = (event) => event.data.assets.map((item) => item._id);

const addChangedTagsIdsAfterSync = (data) => {
  const { sync } = data;
  if (sync && sync.stats && sync.stats.assetsCreatedIn) {
    const ids = sync.stats.assetsCreatedIn.length
      ? sync.stats.assetsCreatedIn
      : [];
    if (ids.length) notificationsActions.addChangedTagsIds(ids);
  }
};

const addChangedTagsIds = (data, otherIds = []) => {
  const collectionIds = data.assets.reduce((ids, asset) => {
    if (asset.tags && asset.tags.length) {
      ids.push(...asset.tags.map((t) => t._id));
    }
    return ids;
  }, []);
  const ids = _uniq(collectionIds);

  notificationsActions.addChangedTagsIds([...ids, ...otherIds]);
};

const addActiveChangedIds = (collections, activeCollection, normalized = true) => {
  if (activeCollection) {
    const regexp = new RegExp(`^${activeCollection.path}${activeCollection.name}/`);
    const update = collections.find((i) => {
      if (!normalized) {
        const withoutRoot = _replace(i.path, '/root', '');
        const lastSlashIndex = withoutRoot.lastIndexOf('/');
        const path = withoutRoot.slice(0, lastSlashIndex + 1);

        return path.match(regexp);
      }
      return i.path.match(regexp);
    });

    if (update) {
      notificationsActions.addChangedTagsIds([activeCollection._id]);
    }
  }
};

export default async function PubSubRouter(socket) {
  const { user } = store.getState();
  const userId = user._id;

  if (picsioConfig.isMainApp) {
    // permissions
    const { integrations } = user;

    const manageTeamEvents = ['invitation.rejected', 'invitation.pending'];
    const manageBillingEvents = ['user.free_keywords_run_out'];
    const manageStorageEvents = ['user.low_disk_space'];
    // TODO: needs to test it
    const isHasAccessManageTeamEvents = !isHaveTeammatePermission('manageTeam', user);
    const isHasAccessManageBillingEvents = !isHaveTeammatePermission(
      'manageBilling',
      user,
    );

    // Events for notification center
    notificationCenterTypes.forEach((item) => {
      socket.on(item, async (event) => {
        const isUserUnsubscribed = checkUserEventSubscription(
          userId,
          event,
          integrations,
          'notificationCenter',
        );

        if (event.type === 'system.message') {
          if (event.data.reloadApp) {
            Logger.log('UI', 'AppReloadAlert');
            Toast(l18n.TOAST.SYSTEM_MESSAGE, {
              autoClose: false,
              closeButton: false,
              btnOkValue: 'Refresh',
              onOk: () => {
                Logger.log('User', 'AppReloadAlertRefresh');
                window.location.reload();
              },
            });
          }
          notificationsActions.add(event);
          return;
        }

        if (isUserUnsubscribed) return;

        if (
          manageTeamEvents.includes(event.type)
          && isHasAccessManageTeamEvents
        ) {
          notificationsActions.add(event);
          return;
        }
        if (
          manageTeamEvents.includes(event.type)
          && !isHasAccessManageTeamEvents
        ) {
          return;
        }
        if (
          manageBillingEvents.includes(event.type)
          && isHasAccessManageBillingEvents
        ) {
          notificationsActions.add(event);
          return;
        }
        if (manageStorageEvents.includes(event.type)) {
          notificationsActions.add(event);
          return;
        }
        if (
          manageBillingEvents.includes(event.type)
          && !isHasAccessManageBillingEvents
        ) {
          return;
        }

        const isInitiator = userId === (event.initiator && event.initiator._id);
        const handleAnyway = [
          'asset.adobe_uploaded',
          'inbox.asset.created',
          'inbox.assetsLimitExceeded',
          'sync.succeed.FullSync',
          'asset.transcribing.complete',
          'request.created',
        ].includes(event.type);
        const isBackgroundActionFinished = event.type === 'asset.copied' && event.data.assets && event.data.assets.length > 30;

        if ((handleAnyway || !isInitiator || isBackgroundActionFinished) && !isUserUnsubscribed) {
          notificationsActions.add(event);
        }
      });
    });
  }

  // Events for real time update
  eventItems.forEach((item) => {
    socket.on(item, async (event) => {
      const { collectionIds } = store.getState().router.location.query;
      const handleAnyway = [
        'assets.attached_to_collection',
        'collection.created',
        'collection.copied',
        'asset.created',
        'asset.copied',
        'assets.trashing.complete',
        'assets.moving.complete',
        'assets.untrashing.complete',
        'assets.deleting.complete',
        'keywords.created',
        'assets.crawling.complete', /** @TODO need to create separate USER ? */
        'assets.crawling.failed', /** @TODO need to create separate USER ? */
        'inbox.asset.created',
        'assets.keywording.complete',
        'sync.succeed.FullSync',
        'sync.failed.FullSync',
        'sync.failed.PartialSync',
        'assets.archived.deleted',
        'asset.faceRecognizing.complete',
        'asset.manual_faceRecognizing.complete',
        'faces.created',
        'face.avatarCropping.complete',
        'user.api_calls_counter.updated',
        'asset.transcribing.complete',
        'asset.transcribing.failed',
        'asset.transcribing.rejected',
        'asset.transcribing.chunk_is_ready',
        'assets.moved_to_collection',
        'request.created',
        'invitation.accepted',
        'assets.download.status_changed',
        'assets.thumbnailing.failed',
        'assets.describing.complete',
        'asset.describing.failed',
        'assets.importing.complete',
        'assets.importing.failed',
        'collection.importing.complete',
        'collections.importing.failed',
        'user.aikit.tier_upgraded',
        'user.productsPulling.complete',
        'assets.importingMedia.complete',
        'assets.importingMedia.failed',
        'assets.productAttaching.complete',
        'assets.productAttaching.failed',
      ].includes(event.type);
      const isInitiator = userId === (event.initiator && event.initiator._id);
      const initiatorId = (event.initiator && event.initiator._id) || 'unknown';

      try {
        if (handleAnyway || !isInitiator) {
          switch (item) {
          case 'request.created': {
            getTeamAction();
            break;
          }

          case 'invitation.accepted': {
            const { user: { subscriptionFeatures } } = store.getState();
            await getTeamAction();
            userActions.updateUser({
              subscriptionFeatures: {
                ...subscriptionFeatures,
                teammatesCount: subscriptionFeatures.teammatesCount + 1,
                teammatesCountIncludingPending: subscriptionFeatures.teammatesCountIncludingPending + 1,
              },
            });
            break;
          }

          case 'collection.created': {
            const collectionsStore = store.getState().collections;
            // Checks if parrent collection is opened and only then push new collection
            const newCollection = { ...event.data.collection };
            const pathArr = newCollection.path.split('/');
            if (pathArr[1] === 'root') pathArr.splice(1, 1);

              const newCollectionName = pathArr.pop(); // eslint-disable-line
            const parentName = pathArr.pop();
            const parentPath = `${pathArr.join('/')}/`;

            let parentCollection = null;
            /** if parent is root collection */
            if (parentPath === '/' && !parentName) {
              parentCollection = collectionsStore.collections.my;
            } else {
              /** @type {Object[]} */
              const parentLvl = findCollections(
                collectionsStore.collections,
                null,
                { path: parentPath },
              );
              if (parentLvl && parentLvl.length > 0) {
                parentCollection = parentLvl.find(
                  (c) => c.path === parentPath && c.name === parentName,
                );
              }
            }

            if (
              (parentCollection && parentCollection.isOpen)
                /** children already fetched */
                || (parentCollection && parentCollection.isFetching === false)
                /** new collection is first child */
                || (parentCollection && parentCollection.hasChild === false)
            ) {
              const { data: collection } = await sdk.collections.getCollection(
                newCollection._id,
              );
              collection.path += collection.name;
              collection.addedByTeammate = true; // needs to highlight new collection
              delete collection.name;
              collection.permissions = { ...parentCollection.permissions };

              collectionsActions.pushCollections([collection]);
              collectionsActions.getChildren(collection._id);
            }
            break;
          }

          case 'collection.deleted': {
            const collectionsStore = store.getState().collections;
            const collectionId = event.data.collection._id;
            const collection = findCollection(
              collectionsStore.collections,
              'my',
              {
                _id: collectionId,
              },
            );
            if (collection) {
              collectionsActions.collectionRemoving(collectionId);
              if (collectionIds === collectionId) {
                Logger.log(
                  'UI',
                  'CollectionAlreadyDeletedDialog',
                  collectionId,
                );
                showDialog({
                  title: l18n.DIALOGS.COLLECTION_DELETE_ERROR.TITLE,
                  text: l18n.DIALOGS.COLLECTION_DELETE_ERROR.TEXT(event.initiator.displayName, pathHelper.getCollectionName(event.data.collection.path)),
                  textBtnOk: l18n.DIALOGS.COLLECTION_DELETE_ERROR.BTN_OK,
                  textBtnCancel: null,
                  onOk: () => navigateToRoot(),
                  onCancel: () => navigateToRoot(),
                });
              }
            }
            break;
          }

          case 'asset.faceRecognizing.complete': {
            assetsActions.facesGenerated(event.data.assets);
            break;
          }

          case 'asset.manual_faceRecognizing.complete': {
            const treeOpened = LocalStorage.get('picsio.treeOpened');
            const { collections } = store.getState();
            const { _id: rootCollectionId } = collections.collections.my;
            const makeSearchRoute = () => {
              const { trashed, archived } = getSearchProps();
              const result = { trashed, archived };
              result.collectionIds = rootCollectionId;
              if (event.data.assets.length > 0) {
                const faces = event.data.assets.map((asset) => asset.faces);
                result.faces = faces.reduce((ids, i) => {
                  const faces = [...ids];
                  i.forEach((face) => faces.push(face._id));
                  return faces;
                }, []);
              }
              return result;
            };
            const countAssets = event.data.assetsTotalCount;
            const countFaces = event.data.facesTotalCount;
            Toast(l18n.TOAST.FACE_RECOGNIZING_COMPLETE(countAssets, countFaces), {
              autoClose: false,
              closeButton: false,
              btnOkValue: 'See faces',
              onOk: () => { setSearchRoute(makeSearchRoute()), treeOpened !== 'face' ? mainAction.changeTree('face') : null; },
            });
            break;
          }

          case 'asset.faceRecognizing.rejected': {
            Toast(l18n.TOAST.FACE_RECOGNIZING_REJECTED, {
              autoClose: false,
              closeButton: false,
            });
            break;
          }

          case 'asset.transcribing.chunk_is_ready': {
            assetsActions.transcribingEvent(event);
            break;
          }

          case 'asset.transcribing.complete': {
            assetsActions.transcribingEvent(event);
            break;
          }

          case 'asset.transcribing.failed': {
            assetsActions.transcribingEvent(event);
            break;
          }

          case 'asset.transcribing.rejected': {
            assetsActions.transcribingEvent(event);
            break;
          }

          case 'collection.renamed': {
            collectionsActions.renamedCollection(event.data.collection);
            break;
          }

          case 'collection.color.changed': {
            collectionsActions.changedCollectionColor(
              event.data.collection._id,
              event.data.collection.color,
            );
            break;
          }

          case 'collection.description.changed': {
            collectionsActions.changedCollectionDescription(
              event.data.collection._id,
              event.data.collection.description,
            );
            break;
          }

          case 'collection.archived': {
            const { data, initiator } = event;

            const { collections, archive } = store.getState();
            const { archived } = store.getState().router.location.query;
            const collectionsWithPermissions = await getAllowedCollectionsWithPermissions(data.collections);
            const archivedCollections = _filter(collectionsWithPermissions, { archived: true });

            const add = (markAsChanged = false) => {
              if (markAsChanged) {
                const activeCollection = !archived
                  ? findCollection(collections.collections, 'my', { _id: collections.activeCollections[0]?._id })
                  : findCollectionById(archive.collections, archive.activeCollectionId);

                addActiveChangedIds(collectionsWithPermissions, activeCollection);
              }
              collectionsActions.decrementCount(data.assetsTotalCount);
              archiveActions.addCollections({
                ...data, collections: collectionsWithPermissions, user, isTeammate: true,
              });
            };

            if (!archived) {
              // for main tree
              const { _id: rootCollectionId } = collections.collections.my;

              // let currentCollection = _find(archivedCollections, { _id: collections.activeCollection?._id });
              const currentCollections = archivedCollections.filter((archivedCollection) => collections.activeCollections.includes(archivedCollection._id));

              if (currentCollections.length > 0) {
                const { _id: collectionId, name } = currentCollections[0];
                const navigate = () => {
                  add();
                  setSearchRoute({ collectionIds: rootCollectionId });
                };

                Logger.log('UI', 'ShowDialogTeammateArchivedCollection', collectionId);
                collectionsActions.collectionRemoving(collectionId);

                showDialog({
                  title: l18n.DIALOGS.TEAMMATE_ARCHIVED_COLLECTION.TITLE,
                  text: l18n.DIALOGS.TEAMMATE_ARCHIVED_COLLECTION.TEXT(initiator.displayName, name),
                  onOk: navigate,
                  onCancel: navigate,
                  onClose: navigate,
                });
              } else {
                add(true);
              }
            } else {
              // for archive tree
              add(true);
            }
            break;
          }

          case 'collection.copied': {
            const { data, initiator } = event;
            const { destinationCollection } = data;
            notificationsActions.addChangedTagsIds([destinationCollection._id]);
            break;
          }

          case 'collection.unarchived': {
            const { initiator, data } = event;
            const { collections, archive } = store.getState();
            const { archived } = store.getState().router.location.query;
            const collectionsWithPermissions = await getAllowedCollectionsWithPermissions(data.collections);
            const parentsWithPermissions = await getAllowedCollectionsWithPermissions(data.parents);
            const collectionIds = _map(collectionsWithPermissions, '_id');

            const remove = (markAsChanged = false) => {
              if (markAsChanged) {
                const activeCollection = !archived
                  ? findCollection(collections.collections, 'my', { _id: collections.activeCollections[0]?._id })
                  : findCollectionById(archive.collections, archive.activeCollectionId);

                addActiveChangedIds(collectionsWithPermissions, activeCollection);
              }
              collectionsActions.incrementCount(data.assetsTotalCount);
              archiveActions.deleteCollections({
                ...data, collections: collectionsWithPermissions, parents: parentsWithPermissions, user, isTeammate: true,
              });
            };

            if (archived) {
              // for archive tree
              const rootCollectionId = archive.collections[0]._id;
              const currentCollection = _find(data.collections, { _id: archive.activeCollectionId });

              if (currentCollection) {
                const { _id: collectionId, name } = currentCollection;
                const navigate = () => {
                  remove();
                  archiveActions.setActiveCollectionId(rootCollectionId);
                  setSearchRoute({ collectionIds: rootCollectionId, archived: true });
                };

                Logger.log('UI', 'ShowDialogTeammateUnarchivedCollection', collectionId);
                archiveActions.addToDeleted(collectionId);

                showDialog({
                  title: l18n.DIALOGS.TEAMMATE_UNARCHIVED_COLLECTION.TITLE,
                  text: l18n.DIALOGS.TEAMMATE_UNARCHIVED_COLLECTION.TEXT(initiator.displayName, name),
                  onOk: navigate,
                  onCancel: navigate,
                  onClose: navigate,
                });
              } else {
                remove(true);
              }
            } else {
              // for main tree
              remove(true);
            }
            notificationsActions.addChangedTagsIds(collectionIds);
            break;
          }

          case 'collection.archived.deleted': {
            const { data, initiator } = event;
            const { archive } = store.getState();
            const allowedCollections = getAllowedCollections(data.collections, user);
            const currentCollection = _find(allowedCollections, { _id: archive.activeCollectionId });
            const ids = _map(allowedCollections, '_id');

            const remove = (markAsChanged = false) => {
              if (markAsChanged) {
                const activeCollection = findCollectionById(archive.collections, archive.activeCollectionId);
                addActiveChangedIds(allowedCollections, activeCollection, false);
              }
              archiveActions.deleteCollectionsById({ ids, isTeammate: true });
            };

            if (currentCollection) {
              const { _id } = currentCollection;
              const collectionName = pathHelper.getCollectionName(currentCollection.path);
              const rootCollectionId = archive.collections[0]._id;

              const navigate = () => {
                remove();
                archiveActions.setActiveCollectionId(rootCollectionId);
                setSearchRoute({ collectionIds: rootCollectionId, archived: true });
              };

              Logger.log('UI', 'ShowDialogTeammateDeletedArchivedCollection', _id);
              showDialog({
                title: l18n.DIALOGS.TEAMMATE_DELETED_ARCHIVED_COLLECTION.TITLE,
                text: l18n.DIALOGS.TEAMMATE_DELETED_ARCHIVED_COLLECTION.TEXT(initiator.displayName, collectionName),
                onOk: navigate,
                onCancel: navigate,
                onClose: navigate,
              });
            } else {
              remove(true);
            }
            break;
          }

          case 'assets.archived': {
            const { initiator, data } = event;
            const collectionsWithPermissions = await getAllowedCollectionsWithPermissions(data.collections);
            const { archived } = store.getState().router.location.query;
            const previewAssetId = getPreviewId();

            if (!archived || previewAssetId) {
              const ids = getIds(event);
              const activeAsset = event.data.assets.find((({ _id }) => _id === previewAssetId));

              if (activeAsset) {
                const navigate = () => {
                  back();
                  assetsActions.deletedAssets(ids);
                };

                Logger.log('UI', 'ShowDialogTeammateArchivedAsset', previewAssetId);
                showDialog({
                  title: l18n.DIALOGS.TEAMMATE_ARCHIVED_ASSET.TITLE,
                  text: l18n.DIALOGS.TEAMMATE_ARCHIVED_ASSET.TEXT(initiator.displayName, activeAsset.name),
                  textBtnOk: 'Ok',
                  onOk: navigate,
                  onCancel: navigate,
                  onClose: navigate,
                });
              } else {
                assetsActions.deletedAssets(ids);
              }
            }
            addChangedTagsIds(data);
            collectionsActions.decrementCount(data.assetsTotalCount);
            archiveActions.addCollections({
              ...data, collections: collectionsWithPermissions, user, isAssets: true, isTeammate: true,
            });
            break;
          }

          case 'assets.archived.deleted': {
            const { data } = event;
            const allowedCollections = getAllowedCollections(data.collections, user);
            const ids = _map(allowedCollections, '_id');

            notificationsActions.addChangedTagsIds(ids);
            break;
          }

          case 'assets.unarchived': {
            const { initiator, data } = event;
            const { archived } = store.getState().router.location.query;
            const previewAssetId = getPreviewId();
            const otherIds = [data.unarchivedTo._id];

            if (archived || previewAssetId) {
              const ids = getIds(event);

              if (previewAssetId && ids.includes(previewAssetId)) {
                const currentAsset = _find(data.assets, { _id: previewAssetId });
                const navigate = () => {
                  assetsActions.deletedAssets(ids);
                  back();
                };

                Logger.log(
                  'UI',
                  'ShowDialogTeammateUnarchivedAsset',
                  previewAssetId,
                );
                showDialog({
                  title: l18n.DIALOGS.TEAMMATE_UNARCHIVED_ASSET.TITLE,
                  text: l18n.DIALOGS.TEAMMATE_UNARCHIVED_ASSET.TEXT(initiator.displayName, currentAsset.name),
                  textBtnOk: 'Ok',
                  onOk: navigate,
                  onCancel: navigate,
                  onClose: navigate,
                });
              } else {
                assetsActions.deletedAssets(ids);
              }
            }
            addChangedTagsIds(data, otherIds);
            collectionsActions.incrementCount(data.assetsTotalCount);
            break;
          }

          case 'inbox.created': {
            inboxesActions.createdEvent(event.data.inbox._id);
            break;
          }

          case 'inbox.renamed': {
            inboxesActions.renamedEvent({ ...event.data.inbox, addedByTeammate: true });
            break;
          }

          case 'inbox.deleted': {
            inboxesActions.deletedEvent({ _id: event.data.inbox._id, deletedByTeammate: true });
            break;
          }

          case 'inbox.changed': {
            inboxesActions.changedEvent({ ...event.data.inbox });
            break;
          }

          case 'inbox.asset.created': {
            const { inbox } = (event.data.assets && event.data.assets[0]) || event.data.asset;
            if (inbox) {
              notificationsActions.addChangedTagsIds([inbox._id]);
            }
            break;
          }

          case 'asset.created': {
            const collectionsStore = store.getState().collections;
            const isRecursiveSearchActive = collectionsStore.notRecursiveSearch;
            const rootID = collectionsStore.collections.my._id;
            const eventTags = [];

            if (!event.data.assets || !event.data.assets.length) {
              throw new Error('asset.created: assets are undefined');
            }
            event.data.assets.forEach((asset) => {
              if (asset.tags && asset.tags.length) {
                asset.tags.forEach((tag) => {
                  if (!eventTags.length) {
                    eventTags.push(tag);
                  } else if (
                    eventTags.some((eventTag) => eventTag._id !== tag._id)
                  ) {
                    eventTags.push(tag);
                  }
                });
              }
            });
            const changedCollections = [];

            if (isRecursiveSearchActive) {
              if (eventTags.length > 0 && collectionIds === eventTags[0]._id) {
                changedCollections.push(eventTags[0]._id);
              } else if (eventTags.length === 0 && collectionIds === rootID) {
                changedCollections.push(rootID);
              }
              // uploaded asset from 'my collection'
            } else if (eventTags.length === 0) {
              changedCollections.push(rootID);
              // uploaded asset from one of a sub-collection
            } else if (eventTags.length > 0) {
              let collectionPath;
              changedCollections.push(rootID);
              changedCollections.push(eventTags[0]._id);

              // needs to find all IDs from the received 'path'
              collectionPath = `${eventTags[0].path}/`;
              collectionPath = collectionPath.replace('/root', '');
              let collectionsList = collectionPath.split('/');
              collectionsList = collectionsList.slice(
                1,
                collectionsList.length - 1,
              );

              while (collectionsList.length > 0) {
                let collection;
                collectionsList = collectionsList.slice(
                  0,
                  collectionsList.length - 1,
                );

                collectionPath = `/${collectionsList.join('/')}/`;
                collection = getParent(collectionsStore.collections, 'my', {
                  path: collectionPath,
                });

                if (collectionsList.length === 1) {
                  if (!collection) {
                    collection = getParent(collectionsStore.collections, 'my', {
                      name: collectionsList[collectionsList.length - 1],
                    });
                  }
                }

                if (collection) {
                  changedCollections.push(collection._id);
                }
              }
            }
            if (changedCollections.length) {
              notificationsActions.addChangedTagsIds(changedCollections);
            }
            break;
          }

          case 'asset.renamed': {
            const { _id, name } = event.data.asset;
            assetsActions.renamed(_id, name);
            break;
          }

          case 'assets.trashing.waiting': {
            assetsActions.setTrashing(
              getIds(event),
              CONSTANTS.TRASHING_STATUS_FIELD_NAME,
            );
            break;
          }

          case 'assets.trashing.complete': {
            assetsActions.deletedAssets(getIds(event));
            /** Trash updated */
            const { trashed } = store.getState().router.location.query;
            if (trashed) notificationsActions.addChangedTagsIds([collectionIds]);

            /** only for teammates show collection updated */
            if (!isInitiator) {
              const collectionIds = event.data.assets.reduce((ids, asset) => {
                if (asset.tags && asset.tags.length) {
                  ids.push(...asset.tags.map((t) => t._id));
                }
                return ids;
              }, []);
              const ids = _uniq(collectionIds);
              notificationsActions.addChangedTagsIds(ids);
            }
            break;
          }

          case 'assets.moving.waiting': {
            assetsActions.setMoving(
              getIds(event),
              CONSTANTS.ASYNC_JOB_STATUS_WAITING,
            );
            break;
          }

          case 'assets.moving.complete': {
            assetsActions.setMoving(
              getIds(event),
              CONSTANTS.ASYNC_JOB_STATUS_COMPLETE,
            );
            break;
          }

          case 'assets.deleting.waiting': {
            assetsActions.setTrashing(
              getIds(event),
              CONSTANTS.DELETING_STATUS_FIELD_NAME,
            );
            break;
          }

          case 'assets.deleting.complete': {
            assetsActions.deletedAssets(getIds(event));

            const { trashed } = store.getState().router.location.query;
            if (!isInitiator && trashed) notificationsActions.addChangedTagsIds([collectionIds]);

            break;
          }

          case 'assets.untrashing.waiting': {
            assetsActions.setUntrashing(
              getIds(event),
              CONSTANTS.ASYNC_JOB_STATUS_WAITING,
            );
            break;
          }

          case 'assets.untrashing.failed': {
            assetsActions.setUntrashing(
              getIds(event),
              CONSTANTS.ASYNC_JOB_STATUS_FAILED,
            );
            break;
          }

          case 'assets.untrashing.complete': {
            const collectionToUntrash = event.data.assets[0].untrashTo;
            if (collectionToUntrash) {
              notificationsActions.addChangedTagsIds([collectionToUntrash._id]);
            }
            /** Trash updated */
            const { trashed } = store.getState().router.location.query;
            if (trashed) assetsActions.deletedAssets(getIds(event));
            break;
          }

          case 'assets.color.changed': {
            assetsActions.changedColor(
              getIds(event),
              event.data.value,
              initiatorId,
            );
            break;
          }

          case 'assets.flag.changed': {
            assetsActions.changedFlag(
              getIds(event),
              event.data.value,
              initiatorId,
            );
            break;
          }

          case 'assets.rating.changed': {
            assetsActions.changedRating(
              getIds(event),
              event.data.value,
              initiatorId,
            );
            break;
          }

          case 'assets.assigned': {
            assetsActions.assignedUser(
              getIds(event),
              event.data.assignees[0]._id,
            );
            break;
          }

          case 'assets.unassigned': {
            assetsActions.unAssignedUser(
              getIds(event),
              event.data.assignees[0]._id,
            );
            break;
          }

          case 'assets.removed_modified_meta_field': {
            assetsActions.removedModifiedField(
              getIds(event),
              event.data.fieldName,
            );
            break;
          }

          case 'website.created': {
            collectionsActions.setWebsite(event.data.collection._id, {}, true);
            break;
          }

          case 'website.deleted': {
            collectionsActions.setWebsite(
              event.data.collection._id,
              null,
              true,
            );
            break;
          }

          case 'user.api_calls_counter.updated': {
            userActions.updateTeamAICount(event.data);
            break;
          }

          case 'assets.attached_to_collection': {
            const collectionId = event.data.collection._id;
            notificationsActions.addChangedTagsIds([collectionId]);
            const ids = event.data?.assets?.map((asset) => asset._id);

            const params = {
              _id: collectionId,
              path: event.data.collection.path,
              ids,
            };
            assetsActions.addedToCollection(params);
            break;
          }

          case 'assets.moved_to_collection': {
            const tagID = event.data.collection._id;
            notificationsActions.addChangedTagsIds([tagID]);
            const ids = event.data?.assets?.map((asset) => asset._id);
            const params = {
              _id: tagID,
              path: event.data.collection.path,
              ids,
              showToast: true,
            };
            assetsActions.addedToCollection(params);
            break;
          }

          case 'assets.detached_from_collection': {
            const collectionId = event.data.collection._id;
            notificationsActions.addChangedTagsIds([collectionId]);
            const ids = event.data?.assets?.map((asset) => asset._id);

            const params = {
              _id: collectionId,
              path: event.data.collection.path,
              ids,
            };
            assetsActions.removedFromCollection(params);
            break;
          }

          case 'faces.created': {
            facesActions.createFace({ eventData: event.data.faces });
            break;
          }

          case 'face.avatarCropping.complete': {
            facesActions.setAvatars({ eventData: event.data.faces });
            break;
          }

          case 'keywords.created': {
            keywordsActions.added(event.data?.keywords || []);
            break;
          }

          case 'keyword.renamed': {
            keywordsActions.renamed({
              _id: event.data.keyword._id,
              name: event.data.keyword.name,
            });
            break;
          }

          case 'keywords.deleted': {
            keywordsActions.deleted(event.data.keywords[0]._id);
            break;
          }

          case 'assets.keyword_attached': {
            assetsActions.attachedKeyword(
              getIds(event),
              event.data.keyword,
              initiatorId,
            );
            break;
          }

          case 'assets.keyword_detached': {
            assetsActions.detachedKeyword(
              getIds(event),
              event.data.keyword._id,
              initiatorId,
            );
            break;
          }

          case 'assets.keywords_detached': {
            assetsActions.detachedKeywords(
              getIds(event),
              (event.data.keywords || []).map((k) => k._id),
              initiatorId,
            );
            break;
          }

          case 'keywords.dictionary_applied': {
            keywordsActions.search();
            break;
          }

          case 'asset.metadating.complete': {
            assetsActions.metadatingComplete(event.data.assets);
            break;
          }

          case 'assets.crawling.complete': {
            const { assets } = event.data;
            /** update data */
            assetsActions.crawlingEvent({
              assets,
              allKeywords: store.getState().keywords.all,
            });

            /** fetch thumbnails */
            const assetIdsWithTumbs = assets.map(({ _id, crawledData }) => {
              if (crawledData?.image) return _id;
              return null;
            }).filter(Boolean);
            if (assetIdsWithTumbs.length) assetsActions.getThumbnails(assetIdsWithTumbs);

            break;
          }

          case 'assets.crawling.failed': {
            const { assets } = event.data;
            assetsActions.crawlingEvent({ assets });
            break;
          }

          case 'asset.replicating.complete': {
            assetsActions.updateFields(
              event.data.asset._id,
              ['replicating'],
              ['complete'],
            );
            break;
          }

          case 'assets.keywording.complete': {
            assetsActions.keywordsGenerated(event.data.assets);
            sendEventToIntercom('keywording complete');
            break;
          }

          case 'asset.contenting.complete': {
            assetsActions.updateFields(
              event.data.asset._id,
              ['contenting'],
              ['complete'],
            );
            break;
          }

          case 'assets.importing.complete': {
            event.data.assets.forEach((asset) => {
              assetsActions.updateFields(
                asset._id,
                ['imageMediaMetadata', 'storageId', 'importing', 'thumbnailing'],
                [asset.imageMediaMetadata, asset.storageId, 'complete', asset.thumbnailing || null],
              );
            });
            /** Need to update CatalogView after asset metadata set */
            setTimeout(() => window.dispatchEvent(new CustomEvent('images:imported')), 1000);

            Toast(l18n.TOAST.IMPORTING_ASSETS_COMPLETE(event.data.assets.length), {
              autoClose: false,
              closeButton: false,
              type: 'success',
            });

            break;
          }

          case 'assets.importing.failed': {
            const assets = [];
            event.data.assets.forEach((asset) => {
              assets.push(asset.name);
              assetsActions.updateFields(
                asset._id,
                ['importing'],
                ['failed'],
              );
            });
            Toast(l18n.TOAST.IMPORTING_ASSETS_FAILED(assets), {
              autoClose: false,
              type: 'error',
            });
            break;
          }

          case 'collection.importing.complete': {
            store.dispatch({
              type: TYPES.COLLECTIONS.IMPORTING.COMPLETE,
              payload: {
                collectionID: event.data._id,
              },
            });
            collectionsActions.getChildren(event.data._id);
            const folderName = event.data.path.substring(event.data.path.lastIndexOf('/') + 1);
            Toast(l18n.TOAST.IMPORTING_COLLECTION_COMPLETE(folderName), {
              autoClose: false,
              closeButton: false,
              type: 'success',
            });
            break;
          }

          case 'collections.importing.failed': {
            const collectionPaths = [];
            event.data.collections.forEach((collection) => {
              collectionPaths.push(collection.path);
              store.dispatch({
                type: TYPES.COLLECTIONS.IMPORTING.FAILED,
                payload: {
                  collectionID: collection._id,
                },
              });
            });
            Toast(l18n.TOAST.IMPORTING_COLLECTION_FAILED(collectionPaths), {
              autoClose: false,
              type: 'error',
            });
            break;
          }

          case 'asset.converting.complete': {
            const {
              _id,
              revisionId,
              proxyFileStorageId,
            } = event.data.asset;

            setTimeout(() => {
              assetsActions.setPdfProxies(
                _id,
                revisionId,
                proxyFileStorageId,
              );
            }, 1000);
            break;
          }

          case 'asset.thumbnailing.complete': {
            setTimeout(() => {
              assetsActions.thumbnailingComplete(event.data.assets);
            }, 2000);
            if (picsioConfig.isPim) {
              setTimeout(() => {
                productsActions.setMediaThumbnails(event.data.assets);
              }, 2000);
            }
            break;
          }

          case 'customfield.created': {
            customFieldsActions.add(event.data.customField, true);
            break;
          }

          case 'customfield.deleted': {
            customFieldsActions.remove(event.data.customField.title, true, event.data.customField.required);
            break;
          }

          case 'command.reload_app': {
            if (!event.data.bySessionId) {
              window.location.reload();
              break;
            }

            const sessionId = getSessionId();
            const account = event.data.accounts.find(
              (acc) => acc.sessionId && acc.sessionId === sessionId,
            );

            if (account && event.data.bySessionId) {
              setTimeout(() => window.location.reload(), 2000);
            }
            break;
          }

          case 'user.jobs_statuses': {
            if (!event.data) break;
            notificationsActions.updateJobsStatus(event.data.statuses);
            break;
          }

          case 'sync.succeed.FullSync': {
            if (!event.data) break;
            const { sync } = event.data;
            const { stats } = sync;
            const {
              collectionsRemoved, collectionsCreated, tagsCreated, tagsRemoved,
            } = stats;
            if (collectionsRemoved || collectionsCreated || tagsCreated || tagsRemoved) {
              collectionsActions.getCollections();
            }
            addChangedTagsIdsAfterSync(event.data);
            break;
          }

          case 'sync.failed.FullSync': {
            if (!event.data) break;
            if (userId !== initiatorId) break; // show this event only initiator
            const { code, websites } = event.data;
            // if we show dialog, we mark event as read and don't show it in NotificationCenter
            showDestructiveSyncErrorDialog(code, websites, store.getState);
            notificationsActions.notificationMarkAsRead(event._id, true);
            break;
          }

          case 'sync.failed.PartialSync': {
            if (!event.data) break;
            if (userId !== initiatorId) break; // show this event only initiator
            const { code, websites } = event.data;
            // if we show dialog, we mark event as read and don't show it in NotificationCenter
            showDestructiveSyncErrorDialog(code, websites, store.getState, true);
            notificationsActions.notificationMarkAsRead(event._id, true);
            break;
          }

          case 'sync.succeed.IncrementalSync': {
            if (!event.data) break;
            addChangedTagsIdsAfterSync(event.data);
            break;
          }

          case 'asset.comment.added': {
            const assetId = event.data?.asset?._id;
            if (assetId) {
              notificationsActions.updateUnreadParam('newComments', event._id, assetId);
            }
            break;
          }

          case 'asset.revision.created': {
            const assetId = event.data?.asset?._id;
            if (assetId) {
              notificationsActions.updateUnreadParam('newRevisions', event._id, assetId);
            }
            break;
          }

          case 'websiteSettingsPreset.created': {
            const presetId = event.data?._id;
            websiteSettingsActions.createdPresetEvent(presetId);
            break;
          }

          case 'websiteSettingsPreset.deleted': {
            const presetId = event.data?._id;
            websiteSettingsActions.deletedPresetEvent(presetId);
            break;
          }

          case 'websiteSettingsPreset.renamed': {
            const presetId = event.data?._id;
            const newName = event.data?.name;
            websiteSettingsActions.renamedPresetEvent({ presetId, newName });
            break;
          }

          case 'assets.download.status_changed': {
            /** Limit download to 5 assets maximum */
            const downloadLimit = qlimit(4);

            downloadListActions.updateZipperDownloadListItem(event.data);
            if (event.data.ready) {
              const assets = store.getState().downloadList.items.filter(({ jobHash }) => jobHash === event.data.jobHash);

              assets.forEach(downloadLimit(async (asset, index) => {
                await saveFile(asset.url, asset);
                /** Use a 3-second delay to prevent download overload in the browser */
                if (index % 4 === 0) await Q.delay(8000);
              }));
            }
            break;
          }

          case 'assets.thumbnailing.failed': {
            if (event.data.assets?.length) {
              event.data.assets.forEach((asset) => {
                assetsActions.updateFields(
                  asset._id,
                  ['thumbnailing', 'thumbnailingErrorCode'],
                  ['failed', 'ThumbnailingError'],
                );
              });
            }
            break;
          }

          case 'asset.copied': {
            if (event.data.assets?.length && isInitiator) {
              event.data.assets.forEach((asset) => {
                assetsActions.updateFields(
                  asset._id,
                  ['copying'],
                  ['complete'],
                );
              });
              assetsActions.getAssets(true);
            }
            break;
          }

          case 'assets.describing.complete': {
            assetsActions.descriptionGenerated({ describedAssets: event.data.assets });
            break;
          }

          case 'asset.describing.failed': {
            assetsActions.descriptionGenerationFailed(event.data.assets);
            break;
          }

          case 'user.aikit.tier_upgraded': {
            Toast(l18n.BILLING.aiKitUpgradeToastText, {
              btnOkValue: l18n.BILLING.btnSeeDetails,
              onOk: () => {
                LocalStorage.set('picsio.openTiersListOnBillingPage', true);
                // eslint-disable-next-line no-return-assign
                window.location = '/billing?tab=overview';
              },
              closeButton: false,
            });
            break;
          }

          case 'user.productsPulling.complete': {
            userActions.updateUser({ productsPulling: 'complete' });
            Toast('Sync successful: all products and attributes have been updated from Shopify. Refresh page to see updates', {
              autoClose: false, type: 'success', onOk: () => window.location.reload(), btnOkValue: l18n.HISTORY.textBtnRefresh,
            });

            break;
          }

          case 'user.productsPulling.failed': {
            userActions.updateUser({ productsPulling: 'failed' });
            Toast('Sync failed: unable to update products and attributes from Shopify.', { type: 'error', autoClose: false });
            break;
          }

          case 'assets.importingMedia.complete': {
            event.data.assets.forEach((asset) => {
              assetsActions.updateFields(
                asset._id,
                ['importingMedia', 'storageId', 'thumbnailing', 'metadating'],
                ['complete', asset.storageId, 'waiting', 'waiting'],
              );
              productsActions.updateMediaFields({
                assetID: asset._id,
                fields: ['importingMedia', 'storageId', 'thumbnailing', 'metadating'],
                values: ['complete', asset.storageId, 'waiting', 'waiting'],
              });
            });

            break;
          }

          case 'assets.importingMedia.failed': {
            event.data.assets.forEach((asset) => {
              assetsActions.updateFields(
                asset._id,
                ['importingMedia'],
                ['failed'],
              );
              productsActions.updateMediaFields({
                assetID: asset._id,
                fields: ['importingMedia'],
                values: ['failed'],
              });
            });

            break;
          }

          case 'assets.productAttaching.complete': {
            const { user, assets: { selectedItems } } = store.getState();
            setTimeout(async () => {
              let aggregatedData = {};
              if (selectedItems.length !== 0) {
                aggregatedData = await getAggregatedData(selectedItems, user);
              }

              event.data.assets.forEach((asset) => {
                assetsActions.updateFields(
                  asset._id,
                  ['productAttaching'],
                  ['complete'],
                );
                assetsActions.addProduct({ assetId: asset._id, product: asset.attachedProduct, productAttaching: aggregatedData.productAttaching });
              });
              Toast(l18n.TOAST.ATTACHING_ASSETS_TO_PRODUCT_COMPLETE, {
                autoClose: false,
                closeButton: false,
                type: 'success',
              });
            }, 2000);

            break;
          }

          case 'assets.productAttaching.failed': {
            event.data.assets.forEach((asset) => {
              assetsActions.updateFields(
                asset._id,
                ['productAttaching', 'productAttachingErrorCode', 'productAttachingReason'],
                ['failed', asset.productAttachingErrorCode, asset.productAttachingReason],
              );
            });
            Toast(l18n.TOAST.ATTACHING_ASSETS_TO_PRODUCT_FAILED, { type: 'error', autoClose: false });
            break;
          }

          default:
            break;
          }
        } else if (isInitiator) {
          switch (item) {
          case 'user.dropbox.connected': {
            const { user: { connectedCloud } } = store.getState();
            userActions.updateUser({
              connectedCloud: {
                ...connectedCloud,
                dropbox: event.data.accountInfo,
              },
            });
            break;
          }
          case 'user.dropbox.connection_error': {
            const { user: { connectedCloud } } = store.getState();
            if (event.data.error === 'access_denied') {
              showDialog({
                title: l18n.DIALOGS.DROPBOX_CONNECTION_ERROR.TITLE,
                text: l18n.DIALOGS.DROPBOX_CONNECTION_ERROR.TEXT,
                textBtnOk: l18n.DIALOGS.DROPBOX_CONNECTION_ERROR.BTN_OK,
                textBtnCancel: null,
              });
            } else {
              showDialog({
                title: l18n.DIALOGS.DROPBOX_CONNECTION_ERROR.TITLE,
                text: event.data.message || l18n.DIALOGS.DROPBOX_CONNECTION_ERROR.TEXT,
                textBtnOk: l18n.DIALOGS.DROPBOX_CONNECTION_ERROR.BTN_OK,
                textBtnCancel: null,
              });
            }
            userActions.updateUser({
              connectedCloud: {
                ...connectedCloud,
                dropbox: null,
              },
            });
            break;
          }
          default:
            break;
          }
        }
      } catch (err) {
        Logger.error(
          new Error('PubSubRouter error'),
          { eventType: event.type, eventId: event._id, error: err },
          ['ProcessEventFailed', (err && err.message) || 'NoMessage'],
        );
      }
    });
  });

  // Update teammates status
  socket.on('user.status.changed', async (event) => {
    setTeammateStatusAction(event);
  });
}
