import React, { memo, useCallback, useRef } from 'react';
import JoditEditor from 'jodit-react';
import { useDispatch, useSelector } from 'react-redux';
import { changeDescription } from '../../store/products/actions';

import DetailsBlock from '../DetailsBlock';

/** @link https://xdsoft.net/jodit/docs/options.html */
const EDITOR_CONFIG = {
  placeholder: '',
  showPlaceholder: false,
  width: '100%',
  height: 250,
  toolbarButtonSize: 'small',
  statusbar: false,
  showStatusbar: false,
  showXPathInStatusbar: false,
  hidePoweredByJodit: true,
  useNativeTooltipuseNativeTooltip: true,
  defaultLineHeight: 1,
  buttons: [
    'fullsize',
    'preview',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'eraser',
    'ul',
    'ol',
    'font',
    'fontsize',
    'paragraph',
    'image',
    'video',
    'table',
    'link',
    'indent',
    'outdent',
    'align',
    'brush',
  ],
  toolbarAdaptive: false,
  removeButtons: [
    'lineHeight',
    'file',
    'spellcheck',
    'speechRecognize',
    'superscript',
    'subscript',
    'insertclassname',
    'about',
    'symbols',
    'ai-commands',
    'ai-assistant',
    'cut',
    'selectall',
    'redo',
    'undo',
    'copy',
    'paste',
    'source',
    'print',
    'hr',
    'break',
  ],
};

const Description = ({
  productId,
  currentValue,
  detailsPanelVisibility,
  toggleVisibility,
  blockName,
  blockTitle,
}) => {
  const dispatch = useDispatch();
  const $editor = useRef(null);
  const picsioTheme = useSelector((state) => state.user.settings.picsioTheme);

  const saveDescription = useCallback(
    (value) => {
      if (currentValue !== value) {
        dispatch(changeDescription({ productId, description: value }));
      }
    },
    [currentValue, dispatch, productId]
  );

  return (
    <DetailsBlock
      dataQa="productKeywords"
      detailsPanelVisibility={detailsPanelVisibility}
      toggleVisibility={toggleVisibility}
      blockName={blockName}
      blockTitle={blockTitle}
    >
      <JoditEditor
        ref={$editor}
        value={currentValue}
        config={{
          ...EDITOR_CONFIG,
          theme: picsioTheme === 'dark' || !picsioTheme ? 'dark' : 'default',
        }}
        onBlur={saveDescription}
      />
    </DetailsBlock>
  );
};

export default memo(Description);
