import React from 'react';
import s from './PlaceholderMediaMatch.module.scss';
import l18n from '../../../shared/strings';

export default function PlaceholderMediaMatch() {
  return (
    <div className={s.PlaceholderMediaMatch}>
      <div className={s.title}>{l18n.BULK_MEDIA_MATCH.TITLE_PLACEHOLDER}</div>
      <div className={s.text}>{l18n.BULK_MEDIA_MATCH.TEXT_PLACEHOLDER}</div>
    </div>
  );
}
