function convertAssetToMatchData(asset) {
  return asset?.product?.type === 'variant' ? {
    _id: asset._id,
    productId: asset?.product?.baseProduct?._id,
    variantId: asset?.product?._id,
  } : {
    _id: asset._id,
    productId: asset?.product?._id,
  };
}

export default convertAssetToMatchData;
