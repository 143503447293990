import React from 'react';
import { Icon } from '@picsio/ui';
import { Info } from '@picsio/icons';
import cn from 'classnames';
import s from './MediaMatchInfoBlock.module.scss';
import l18n from '../../../shared/strings';

export default function MediaMatchInfoBlock() {
  return (
    <div className={s.MediaMatchInfoBlock}>
      <Icon className={s.icon} size="xl" color="secondary">
        <Info />
      </Icon>
      <div className={s.text}>
        {l18n.BULK_MEDIA_MATCH.TEXT_INFO_BLOCK()}
      </div>
      <a
        className={cn('picsioLink', s.link)}
        target="_blank"
        rel="noreferrer"
        href="https://help.toriut.com/en/articles/10289173-using-bulk-media-match-in-toriut-com"
      >
        {l18n.BULK_MEDIA_MATCH.LINK_INFO_BLOCK}
      </a>
    </div>
  );
}
