import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { TextField, InputAdornment, InputControlLabel } from '@picsio/ui';
import { useDispatch } from 'react-redux';
import {
  changePrice, changeCompareAtPrice, changeTaxable, changeUnitCost,
} from '../../store/products/actions';
import DetailsBlock from '../DetailsBlock';

import s from './styles.module.scss';

const Pricing = ({
  productId,
  variantId,
  price,
  compareAtPrice,
  taxable,
  unitCost,
  detailsPanelVisibility,
  toggleVisibility,
  blockName,
  blockTitle,
}) => {
  const dispatch = useDispatch();
  const [priceValue, setPriceValue] = useState(price);
  const [caPrice, setCaPrice] = useState(compareAtPrice);
  const [uCost, setUCost] = useState(unitCost?.amount || '');

  const handleChangePrice = useCallback((e) => {
    setPriceValue(e.target.value);
  }, [setPriceValue]);

  const savePrice = useCallback(() => {
    if (priceValue === price) return;
    dispatch(changePrice({ productId, variantId, price: Number(priceValue) }));
  }, [dispatch, price, priceValue, productId, variantId]);

  const handleChangeCaPrice = useCallback((e) => {
    setCaPrice(e.target.value);
  }, [setCaPrice]);

  const saveCaPrice = useCallback(() => {
    if (caPrice === compareAtPrice) return;
    dispatch(changeCompareAtPrice({ productId, variantId, compareAtPrice: caPrice }));
  }, [caPrice, compareAtPrice, dispatch, productId, variantId]);

  const handleChangeUnitCost = useCallback((e) => {
    setUCost(e.target.value);
  }, [setUCost]);

  const saveUnitCost = useCallback(() => {
    if (uCost === (unitCost?.amount || '')) return;
    dispatch(changeUnitCost({ productId, variantId, unitCost: Number(uCost) }));
  }, [dispatch, productId, uCost, unitCost?.amount, variantId]);

  const saveTaxable = useCallback(() => {
    dispatch(changeTaxable({ productId, variantId, taxable: !taxable }));
  }, [dispatch, productId, taxable, variantId]);

  useEffect(() => {
    // Set price value if it was changed in the props
    setPriceValue(price);
  }, [price]);

  useEffect(() => {
    // Set ca price value if it was changed in the props
    setCaPrice(compareAtPrice);
  }, [compareAtPrice]);

  useEffect(() => {
    // Set ca price value if it was changed in the props
    setUCost(unitCost?.amount || '');
  }, [unitCost]);

  const dollarSign = useMemo(() => <InputAdornment>$</InputAdornment>, []);

  return (
    <DetailsBlock
      dataQa="productPricing"
      detailsPanelVisibility={detailsPanelVisibility}
      toggleVisibility={toggleVisibility}
      blockName={blockName}
      blockTitle={blockTitle}
    >
      <div className={s.row}>
        <TextField
          InputProps={{
            startAdornment: dollarSign,
            placeholder: '0.00',
          }}
          value={priceValue}
          onChange={handleChangePrice}
          onBlur={savePrice}
          label="Price"
        />
        <TextField
          InputProps={{
            startAdornment: dollarSign,
            placeholder: '0.00',
          }}
          value={caPrice}
          onChange={handleChangeCaPrice}
          onBlur={saveCaPrice}
          label="Compare-at price"
        />
      </div>
      <div className={s.row}>
        <InputControlLabel
          control="checkbox"
          label="Charge tax on this product"
          value={taxable}
          onChange={saveTaxable}
        />
      </div>
      <hr className={s.hr} />
      <div className={s.row}>
        <TextField
          InputProps={{
            startAdornment: dollarSign,
            placeholder: '0.00',
          }}
          value={uCost}
          onChange={handleChangeUnitCost}
          onBlur={saveUnitCost}
          label="Cost per item"
        />
      </div>
    </DetailsBlock>
  );
};

export default memo(Pricing);
