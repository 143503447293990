/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { navigate } from '../../helpers/history';

export const remove = createAsyncThunk(
  'products/remove',
  async (id, {
    extra: {
      sdk, Logger, utils, showErrorDialog, showDialogAsync,
    },
    getState,
  }) => {
    try {
      try {
        await showDialogAsync({
          title: 'Remove product',
          text: 'Are you sure you want to remove the product?',
          textBtnOk: 'Remove',
        }).promise;
      } catch (error) {
        Logger.log('User', 'Remove product canceled');
        return null;
      }

      await sdk.products.remove(id);

      if (getState().router?.location?.pathname?.includes(id)) {
        /** Navigate to products search if we are currently on the product view of the removed product */
        navigate('/products');
      }

      return id;
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not remove product';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error product removing'),
        { error },
        ['RemoveProductFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      remove.pending,
      (state, { meta }) => {
        state.items.forEach((product) => {
          if (product._id === meta.arg) {
            product.isBusy = true;
          }
        });
      },
    )
    .addCase(
      remove.fulfilled,
      (state, { payload, meta }) => {
        if (payload) {
          state.items = state.items.filter(({ _id }) => _id !== payload);
          state.search.items = state.search.items.filter(({ _id, baseProductId }) => _id !== payload && baseProductId !== payload);
          state.error = null;
        } else {
          state.items.forEach((product) => {
            if (product._id === meta.arg) delete product.isBusy;
          });
        }
      },
    )
    .addCase(
      remove.rejected,
      (state, { meta, error }) => {
        state.items.forEach((product) => {
          if (product._id === meta.arg) delete product.isBusy;
        });
        state.error = error;
      },
    );
};
