import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
  DownloadList,
  Delete,
  RemoveFolder,
  Download,
  Web,
  Edit,
  AddRevision,
  UploadCustomThumbnailIcon,
  Link,
  CheckIcon,
  CreateVideoThumb,
} from '@picsio/icons';
import * as utils from '../../shared/utils';
import uploadThumbail from '../../helpers/assets/uploadThumbail';
import copyTextToClipboard from '../../helpers/copyTextToClipboard';

import { toggleDownloadList } from '../../store/actions/main';
import Logger from '../../services/Logger';
import localization from '../../shared/strings';
import { checkUserAccess } from '../../store/helpers/user';
import Group from './Group';
import UploadButton from './UploadButton';
import HelpButton from './HelpButton';
import Button from './Button';
import ToolbarVideoControls from './ToolbarVideControls';
import { assetSelector } from '../../store/selectors/assets';
import { navigate } from '../../helpers/history';
import sendEventToIntercom from '../../services/IntercomEventService';
import Dialog, { showErrorDialog } from '../dialog';
import uiBlocker from '../../services/UiBlocker';
import setCustomThumbnail from '../../store/actions/assets/setCustomThumbnail';
import LiveSupportButton from './LiveSupportButton';
import showRemoveAssetFromCollectionDialog from '../../helpers/showRemoveAssetFromCollectionDialog';
import picsioConfig from '../../../../../config';

const FileInput = React.forwardRef((props, ref) => (
  <input
    ref={ref}
    style={{
      display: 'none',
    }}
    type="file"
    accept="image/jpeg"
    onChange={props.handleUploadCustomThumbnail}
  />
));

const ToolbarPreviewLeft = (props) => {
  const {
    assetId,
    asset,
    getSnapshot,
    createCustomThumbnail,
    cropVideo,
    isVideoPlayer,
    isArchived,
    isExternal,
    isImage,
    isPlaceholder,
    rolePermissions,
    hasWatermark,
    subscriptionFeatures,
    isThumbnailingRejected,
    isImportingInProcess,
    isImportingFailed,
    onCreateCustomThumbnail,
  } = props;
  const user = useSelector((state) => state.user);
  const isArchiveAllowed = checkUserAccess('subscriptions', 'archive', null, user);
  const isDeleteArchiveAllowed = (isArchived ? isArchiveAllowed && checkUserAccess('permissions', 'deleteArchive', null, user) : true);
  const isDownloadArchiveAllowed = (isArchived ? isArchiveAllowed && checkUserAccess('permissions', 'downloadArchive', null, user) : true);
  const uploadSizeLimit = 5 * 1024000;

  const [isCopied, setCopied] = useState(false);

  const { allowAssetSharing, is3DModel } = asset;
  const fileInput = React.createRef();
  const dialogWrapper = React.createRef();

  const handleShare = () => {
    Logger.log('User', 'PreviewShare');
    sendEventToIntercom('share from preview');
    if (allowAssetSharing) {
      navigate(`/singlesharing/${assetId}`);
    } else {
      navigate('/billing?tab=overview');
    }
  };

  const handleClickUploadCustomThumbnail = (e) => {
    fileInput.current.click();
  };

  const uploadCustomThumbnail = async (thumbnail) => {
    uiBlocker.block('Uploading...');
    await uploadThumbail(assetId, thumbnail, props.actions.setCustomThumbnail);
    uiBlocker.unblock();
  };

  const openCustomThumbnailDialog = (thumbnail) => {
    const img = (
      <img
        alt="New custom thumbnail"
        src={window.URL.createObjectURL(thumbnail)}
        style={{ width: '100%', height: 'auto' }}
      />
    );

    new Dialog({
      parentEl: dialogWrapper.current,
      data: {
        title: 'New custom thumbnail',
        children: img,
        textBtnOk: 'Create',
        textBtnCancel: 'Cancel',
        onOk: () => uploadCustomThumbnail(thumbnail),
      },
    });
  };

  const handleUploadCustomThumbnail = async (e) => {
    Logger.log('User', 'UploadCustomThumbnail');

    const file = e.target.files[0];
    const { size } = file;

    if (size > uploadSizeLimit) {
      const { FILE_TO_LARGE } = localization.DIALOGS;
      const parsedLimit = utils.bytesToSize(uploadSizeLimit);
      const parsedSize = utils.bytesToSize(size);

      showErrorDialog(FILE_TO_LARGE.TEXT(parsedLimit, parsedSize), FILE_TO_LARGE.TITLE);
    } else {
      openCustomThumbnailDialog(file);
    }
  };

  const handleAssetLinkCopy = () => {
    setCopied(true);
    const link = `${window.location.href}`;
    copyTextToClipboard(link);
  };

  const handleLiveSupport = () => {
    if (subscriptionFeatures.chatSupport) {
      window.dispatchEvent(new Event('toolbar:ui:liveSupport'));
    }
  };

  return (
    <>
      <div className="toolbar toolbarVertical toolbarPreviewLeft">
        <Group>
          <If condition={props.isMultipage}>
            <Button
              id="button-multipage"
              icon="pages"
              isActive={props.isActive}
              onClick={() => {
                Logger.log('User', 'PreviewMultipagePanel');
                props.handleOnClick();
              }}
              tooltip={
                props.isActive
                  ? localization.TOOLBARS.titleHideMultipagePanel
                  : localization.TOOLBARS.titleShowMultipagePanel
              }
            />
          </If>
          <If condition={allowAssetSharing && !isImportingInProcess && !isImportingFailed && !picsioConfig.isPim}>
            <Button
              id="button-share"
              icon={() => <Web />}
              onClick={handleShare}
              tooltip={localization.TOOLBARS.titleShare}
            />
          </If>
          <If condition={!asset.trashed && !isImportingInProcess && !isImportingFailed}>
            <Button
              id="button-link"
              icon={() => (isCopied ? <CheckIcon className="checkIcon" /> : <Link />)}
              onClick={handleAssetLinkCopy}
              tooltip={isCopied ? localization.DETAILS.textLinkCopied : localization.CATALOG_ITEM.tooltipCopyAsset}
            />
          </If>
          <If condition={isThumbnailingRejected || (!isImage && !isPlaceholder)}>
            <FileInput
              handleUploadCustomThumbnail={handleUploadCustomThumbnail}
              ref={fileInput}
            />
            <Button
              dataTestId="ToolbarPreviewLeft-UploadCustomThumbnailButton"
              icon={() => <UploadCustomThumbnailIcon />}
              tooltip="Upload Custom Thumbnail"
              onClick={handleClickUploadCustomThumbnail}
            />
          </If>
          <If condition={props.onCreateCustomThumbnail && is3DModel && !isImportingInProcess && !isImportingFailed}>
            <Button
              dataTestId="ToolbarPreviewLeft-CreateCustomThumbnailButton"
              icon={() => <CreateVideoThumb />}
              iconSize="xxl"
              tooltip="Snapshot & Custom thumbnail"
              onClick={props.onCreateCustomThumbnail}
            />
          </If>
          <If condition={props.addRevision && !isArchived && !isImportingInProcess && !isImportingFailed}>
            <UploadButton
              addRevision={props.addRevision}
              isDisabled={!props.subscriptionFeatures.revisions}
              icon={AddRevision}
              iconSize="xl"
            />
          </If>
          <If condition={props.permissions.allowEditor && !isImportingInProcess && !isImportingFailed && !picsioConfig.isPim}>
            <>
              {props.openEditor && !isArchived && !hasWatermark && !isExternal && (
                <Button
                  id="button-editor"
                  icon={() => <Edit />}
                  additionalClass="tabletHidden"
                  onClick={() => {
                    props.openEditor();
                  }}
                  tooltip={props.subscriptionFeatures.revisions
                    ? localization.TOOLBARS.titleEdit : localization.UPGRADE_PLAN.tooltipForButtons}
                  tooltipDelayHide={500}
                  tooltipInteractive
                  isDisabled={!props.subscriptionFeatures.revisions}
                />
              )}
              {props.rotateantickw && (
                <Button
                  id="button-rotateantickw"
                  icon="rotateantickw"
                  onClick={() => {
                    Logger.log('User', 'PreviewRotate', 'antickw');
                    props.rotateantickw();
                  }}
                  tooltip={localization.TOOLBARS.titleRotateCCW}
                />
              )}
              {props.flipy && (
                <Button
                  id="button-flipy"
                  icon="flipy"
                  onClick={() => {
                    Logger.log('User', 'PreviewFlip', 'FlipY');
                    props.flipy();
                  }}
                  tooltip={localization.TOOLBARS.titleVerticalFlip}
                />
              )}
              {props.flipx && (
                <Button
                  id="button-flipx"
                  icon="flipx"
                  onClick={() => {
                    Logger.log('User', 'PreviewFlip', 'FlipX');
                    props.flipx();
                  }}
                  tooltip={localization.TOOLBARS.titleHorizontalFlip}
                />
              )}
            </>
          </If>
          <If condition={props.download && (!props.isRestricted
          || (props.isRestricted && props.rolePermissions.restrictedDownload))
          && isDownloadArchiveAllowed && !isImportingInProcess && !isImportingFailed}
          >
            <Button
              id="button-previewDownload"
              icon={() => <Download />}
              onClick={() => {
                Logger.log('User', 'PreviewDownload', { assetId });
                props.download();
              }}
              tooltip={localization.TOOLBARS.titleDownload}
            />
          </If>
          <If condition={props.permissions.downloadFiles && (isArchived ? rolePermissions.downloadArchive : true)
          && (props.downloadListItems.length > 0 || props.mainStore.downloadListOpened) && !isImportingInProcess && !isImportingFailed}
          >
            <Button
              id="button-previewDownloadList"
              icon={() => <DownloadList />}
              tooltip={localization.TOOLBARS.titleDownloadDialog}
              onClick={() => {
                Logger.log('User', 'DonwloadPanelShowClicked');
                props.actions.toggleDownloadList();
              }}
              additionalClass={
                props.mainStore.importOpened ? 'disabled' : props.mainStore.downloadListOpened ? 'active' : null
              }
              counter={props.downloadListItems.length}
            />
          </If>
          <If condition={(!asset.trashed && asset.tags?.length) && asset.permissions.editAssetCollections && !isImportingInProcess && !isImportingFailed}>
            <Button
              id="button-previewRemoveFromCollections"
              icon={() => <RemoveFolder />}
              onClick={() => {
                Logger.log('User', 'PreveiwRemoveFromCollections', { assetId });
                showRemoveAssetFromCollectionDialog({ assetId });
              }}
              tooltip={localization.TOOLBARS.titleRemoveFromCollections}
            />
          </If>
          <If condition={props.moveToTrash && isDeleteArchiveAllowed
          && (!props.isRestricted || (props.isRestricted && props.rolePermissions.restrictedMoveOrDelete)) && !isImportingInProcess && !isImportingFailed}
          >
            <Button
              id="button-previewTrash"
              icon={() => <Delete />}
              onClick={() => {
                Logger.log('User', 'PreviewTrash', { assetId });
                props.moveToTrash();
              }}
              tooltip={
                props.isRemoveForever ? localization.TOOLBARS.titleRemoveForever : localization.TOOLBARS.titleRemove
              }
            />
          </If>
          <If condition={isVideoPlayer}>
            <ToolbarVideoControls
              getSnapshot={getSnapshot}
              createCustomThumbnail={createCustomThumbnail}
              cropVideo={cropVideo}
            />
          </If>
          <If condition={props.originalSizeImg}>
            <Button
              id="button-fitsize"
              icon={props.originalSizeImg.fit ? 'originalSizeImg' : 'fitSizeImg'}
              onClick={() => {
                Logger.log('User', 'PreviewChangeZoom');
                props.originalSizeImg.handler();
              }}
              tooltip={props.originalSizeImg.fit ? localization.TOOLBARS.titleZoom : localization.TOOLBARS.titleFit}
            />
          </If>
        </Group>
        <Group>
          <HelpButton tooltipPosition="left" component="previewView" />
          <LiveSupportButton handleLiveSupport={handleLiveSupport} chatSupport={subscriptionFeatures.chatSupport} />
        </Group>
      </div>
      <div ref={dialogWrapper} />
    </>
  );
};

ToolbarPreviewLeft.propTypes = {
  assetId: PropTypes.string.isRequired,
  getSnapshot: PropTypes.func,
  createCustomThumbnail: PropTypes.func,
  cropVideo: PropTypes.func,
  hasWatermark: PropTypes.bool,
  isVideoPlayer: PropTypes.bool,
  isArchived: PropTypes.bool,
  isPlaceholder: PropTypes.bool,
  isThumbnailingRejected: PropTypes.bool,
  asset: PropTypes.shape({
    _id: PropTypes.string,
    allowAssetSharing: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string,
      path: PropTypes.string,
    })),
    permissions: PropTypes.shape({
      editCollections: PropTypes.bool,
    }),
    is3DModel: PropTypes.bool,
  }),
  isImportingInProcess: PropTypes.bool,
  isImportingFailed: PropTypes.bool,
  onCreateCustomThumbnail: PropTypes.func,
};

const ConnectedToolbarPreviewLeft = connect(
  (store, props) => ({
    mainStore: store.main,
    downloadListItems: store.downloadList.items,
    rolePermissions: store.user.role.permissions || {},
    subscriptionFeatures: store.user.subscriptionFeatures || {},
    asset: assetSelector(store, props),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ toggleDownloadList, setCustomThumbnail }, dispatch),
  }),
)(ToolbarPreviewLeft);

export default (props) => (<ConnectedToolbarPreviewLeft {...props} />);
