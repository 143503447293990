import React from 'react';
import { findAll } from 'highlight-words-core';
import { Icon } from '@picsio/ui';
import { Folder, Label as Keyword, SavedSearch } from '@picsio/icons';

const Suggestion = (suggestion, { query }, savedValue) => {
  const { type, result } = suggestion;
  const suggestionText = suggestion.name || suggestion.path || suggestion.value;
  const actualQuery = savedValue ? query?.replace(savedValue, '')?.trim() : query;

  const chunks = findAll({
    autoEscape: true,
    searchWords: [actualQuery],
    textToHighlight: suggestionText,
  });

  return (
    <>
      <If condition={type}>
        <Icon size="lg">
          <Choose>
            <When condition={type === 'collection'}>
              <Folder />
            </When>
            <When condition={type === 'keyword'}>
              <Keyword />
            </When>
            <When condition={type === 'savedSearch'}>
              <SavedSearch />
            </When>
            <Otherwise>
              {null}
            </Otherwise>
          </Choose>
        </Icon>
      </If>
      <div className="react-autosuggest__suggestion-text" data-testid={suggestion.dataTestId}>
        {chunks.map((chunk, index) => {
          const { end, highlight, start } = chunk;
          const text = suggestionText.substr(start, end - start);
          const className = highlight ? 'react-autosuggest__suggestion-match' : null;
          return (
            <span className={className} key={index}>
              {text}
            </span>
          );
        })}
      </div>
      <If condition={result}>
        <span className="react-autosuggest__suggestion-result">{result}</span>
      </If>
    </>
  );
};

export default Suggestion;
