/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const changeUnitCost = createAsyncThunk(
  'products/changeUnitCost',
  async ({ productId, variantId = 'default', unitCost }, {
    extra: {
      sdk, Logger, showErrorDialog, utils,
    },
  }) => {
    try {
      const { data } = await sdk.products.changeUnitCost(productId, variantId, unitCost);
      return data;
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not change product unit cost';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error product change unit cost'),
        { error },
        ['ChangeUnitCostProductFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      changeUnitCost.pending,
      (state, { meta }) => {
        const { productId } = meta.arg;
        state.items.forEach((product) => {
          if (product._id === productId) {
            product.isBusy = true;
          }
        });
      },
    )
    .addCase(
      changeUnitCost.fulfilled,
      (state, { meta, payload }) => {
        const { productId } = meta.arg;
        state.items.forEach((product) => {
          if (product._id === productId) {
            product.isBusy = false;
            product.variants = payload.variants;
            product.defaultVariant = payload.defaultVariant;
          }
        });
      },
    )
    .addCase(
      changeUnitCost.rejected,
      (state, { meta, error }) => {
        state.items.forEach((product) => {
          if (product._id === meta.arg.productId) {
            product.isBusy = false;
          }
        });
        state.error = error;
      },
    );
};
