import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon as IconUI } from '@picsio/ui';
import { PlayVideo } from '@picsio/icons';
import Icon from '../Icon';

const MediaPlaceholder = (props) => {
  const { thumbnailPlaceholder, onClickImage, isVideo } = props;
  return (
    <div
      className={cn('placeholderMediaFile', {
        placeholderMediaFileFullWidth: thumbnailPlaceholder?.fullWidth,
        failed: thumbnailPlaceholder.isFailed,
      })}
      onClick={
        thumbnailPlaceholder?.onClick && !thumbnailPlaceholder?.selectable ? null : onClickImage
      }
      role="presentation"
    >
      <div className="innerPlaceholderMediaFile">
        <If condition={isVideo}>
          <div
            className="catalogItem__playIcon"
            onClick={onClickImage}
            role="presentation"
          >
            <PlayVideo />
          </div>
        </If>
        <Choose>
          <When condition={typeof thumbnailPlaceholder?.icon === 'function'}>
            <IconUI size="xxxl" color="inherit" className="icon" style={{ color: thumbnailPlaceholder?.iconColor, fill: thumbnailPlaceholder?.iconColor }}>
              {thumbnailPlaceholder?.icon()}
            </IconUI>
          </When>
          <Otherwise>
            <Icon
              name={thumbnailPlaceholder?.icon}
              style={{ color: thumbnailPlaceholder?.iconColor }}
            />
          </Otherwise>
        </Choose>
        <div className="text">
          {thumbnailPlaceholder?.text}
        </div>
        <div className="fileName">{thumbnailPlaceholder?.fileName}</div>
        {thumbnailPlaceholder?.btn && (
          <div className="btns">
            <span className="picsioDefBtn" onClick={thumbnailPlaceholder?.onClick} role="presentation">
              {thumbnailPlaceholder?.btn}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

MediaPlaceholder.propTypes = {
  onClickImage: PropTypes.func.isRequired,
  isVideo: PropTypes.bool,
  thumbnailPlaceholder: PropTypes.shape({
    fullWidth: PropTypes.number,
    onClick: PropTypes.func,
    selectable: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    iconColor: PropTypes.string,
    fileName: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    btn: PropTypes.string,
    isFailed: PropTypes.bool,
  }).isRequired,
};

export default memo(MediaPlaceholder);
