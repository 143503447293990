import React, { useRef, useEffect } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import PropTypes from 'prop-types';
import AdjustCamera from '../AdjustCamera';

const Scene = ({
  url, asset, position, showAxisLines,
}) => {
  const lightRef = useRef();
  const { camera } = useThree();

  useFrame(() => {
    if (lightRef.current) {
      lightRef.current.position.copy(camera.position);
    }
  });

  useEffect(() => () => {
    if (lightRef.current) {
      lightRef.current.dispose();
    }
  }, []);

  return (
    <>
      <ambientLight color={0xcccccc} intensity={0.5} />
      <hemisphereLight skyColor="#ffffff" groundColor="#444444" intensity={1.0} />
      <directionalLight
        ref={lightRef}
        position={[10, 10, 10]}
        intensity={1.5}
        castShadow
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        shadow-camera-near={0.1}
        shadow-camera-far={50}
        color={0xffffff}
      />
      <spotLight
        position={[-10, 15, 10]}
        angle={0.3}
        penumbra={0.2}
        intensity={2.0}
        castShadow
      />
      <pointLight position={[0, 5, 5]} intensity={1.5} color={0xffffff} />
      <AdjustCamera url={url} asset={asset} position={position} />
      {showAxisLines && (
        <>
          <axesHelper args={[5]} />
        </>
      )}
      <OrbitControls enableDamping />
    </>
  );
};

Scene.propTypes = {
  url: PropTypes.string,
  asset: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
  showAxisLines: PropTypes.bool.isRequired,
};

Scene.defaultProps = {
  url: null,
};

export default Scene;
