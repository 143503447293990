import sdk from '../../sdk';
import prepareSearchQuery from '../assets/prepareSearchQuery';

export const PAGE_SIZE = 50;

export default function searchMain(page, sort) {
  const data = {
    ...prepareSearchQuery(),
    size: PAGE_SIZE,
    from: (page - 1) * PAGE_SIZE,
    sort,
  };

  return sdk.products.search(data);
}
