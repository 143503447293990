const DEFAULT_OPERATORS_NAMES = {
  isSet: 'is set',
  notSet: 'is not set',
  oneOf: 'is any of',
  noneOf: 'is none of',
  contains: 'contains',
  endsWith: 'ends with',
  startsWith: 'starts with',
  allOf: 'is all of',
  between: 'range',
  equals: 'is',
  gt: 'more than',
  lt: 'less than',
  notEquals: 'is not',
  last90days: 'last 90 days',
  lastmonth: 'last 30 days',
  lastweek: 'last 7 days',
  today: 'today',
  yesterday: 'yesterday',
};

export const FILTER_NAMES_ASSETS = {
  collectionIds: {
    title: 'Collection',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  lightboardId: {
    title: 'Lightboard',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  comment: {
    title: 'Comment text',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  commentedBy: {
    title: 'Commented By',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  external: {
    title: 'External links',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  fileSize: {
    title: 'Size',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
      equals: 'equals',
      notEquals: 'does not equal',
    },
  },
  fileExtension: {
    title: 'File Extension',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  mimeType: {
    title: 'Mime Type',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  name: {
    title: 'Filename',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  inboxId: {
    title: 'Inbox',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  isShared: {
    title: 'Share Status',
    booleanOptions: [
      { label: 'Shared', value: 'true' },
      { label: 'Not Shared', value: 'false' },
    ],
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  products: {
    title: 'Products',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  showArchived: {
    title: 'Archived Assets',
    booleanOptions: [
      { label: 'Show', value: 'true' },
      { label: 'Hide', value: 'false' },
    ],
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  trashed: {
    title: 'Trashed status',
    booleanOptions: [
      { label: 'Trashed', value: 'true' },
      { label: 'Not Trashed', value: 'false' },
    ],
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  type: {
    title: 'Type',
    options: {
      any: 'Any',
      images: 'Images',
      video: 'Video',
      audio: 'Audio',
      audioAndVideo: 'Audio and video',
      text: 'Text documents',
      pdf: 'PDF documents',
      office: 'Office documents',
      sketch: 'Sketch files',
      raw: 'DSLR RAW photos',
      photoshop: 'Photoshop documents',
      '3d': '3D models',
      externalLink: 'External links',
    },
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  createdAt: {
    title: 'Created At',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
      equals: 'on',
      gt: 'after',
      lt: 'before',
      between: 'range (start date & end date)',
      notEquals: 'not on',
    },
  },
  updatedAt: {
    title: 'Updated At',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
      equals: 'on',
      gt: 'after',
      lt: 'before',
      between: 'range (start date & end date)',
      notEquals: 'not on',
    },
  },
  keywords: {
    title: 'Keyword',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  faces: {
    title: 'Person',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  flag: {
    title: 'Flag Status',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  color: {
    title: 'Color',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  rating: {
    title: 'Rating',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
      equals: 'equals',
      notEquals: 'does not equal',
    },
  },
  assignees: {
    title: 'Assignee',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  title: {
    title: 'Title',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  description: {
    title: 'Description',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  watermarkId: {
    title: 'Watermark',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  createdBy: {
    title: 'Created By',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  transcript: {
    title: 'Transcript',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  isRestricted: {
    title: 'Restriction Status',
    booleanOptions: [
      { label: 'Restricted', value: 'true' },
      { label: 'Unrestricted', value: 'false' },
    ],
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  restrictionReason: {
    title: 'Restriction reason',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  metadating: {
    title: 'Metadating',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  replicating: {
    title: 'Replicating',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  contenting: {
    title: 'Contenting',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  transcoding: {
    title: 'Transcoding',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  thumbnailing: {
    title: 'Thumbnailing',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  trashing: {
    title: 'Trashing',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  untrashing: {
    title: 'Untrashing',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  unsharing: {
    title: 'Unsharing',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  deleting: {
    title: 'Deleting',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  moving: {
    title: 'Moving',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  copying: {
    title: 'Copying',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  videoPreviewing: {
    title: 'Video Previewing',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  crawling: {
    title: 'Crawling',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  converting: {
    title: 'Converting',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,

    },
  },
  faceRecognizing: {
    title: 'Face Recognizing',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,

    },
  },
  faceIndexing: {
    title: 'Face Indexing',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,

    },
  },
  transcribing: {
    title: 'Transcribing',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,

    },
  },
  websiteThumbnailing: {
    title: 'Website Thumbnailing',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,

    },
  },
  describing: {
    title: 'Describing',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,

    },
  },
  keywording: {
    title: 'Keywording',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  embedding: {
    title: 'Embedding',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  CLIPEmbedding: {
    title: 'CLIP Embedding',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  justCreated: {
    title: 'Just Created',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  meta: {
    operatorNamesString: {
      ...DEFAULT_OPERATORS_NAMES,
    },
    operatorNamesNumber: {
      ...DEFAULT_OPERATORS_NAMES,
      equals: 'equals',
      notEquals: 'does not equal',
    },
    operatorNamesDate: {
      ...DEFAULT_OPERATORS_NAMES,
      equals: 'on',
      gt: 'after',
      lt: 'before',
      between: 'range (start date & end date)',
      notEquals: 'not on',
    },
  },
};

export const FILTER_NAMES_PRODUCTS = {
  availableForSale: {
    title: 'Available for sale',
    booleanOptions: [
      { label: 'Available', value: 'true' },
      { label: 'Not available', value: 'false' },
    ],
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  barcode: {
    title: 'Barcode',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  compareAtPrice: {
    title: 'Compare at price',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  createdBy: {
    title: 'Created by',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  createdInStoreAt: {
    title: 'Created in store at',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  description: {
    title: 'Description',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  displayName: {
    title: 'Display name',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  inventoryPolicy: {
    title: 'Inventory policy',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  inventoryQuantity: {
    title: 'Quantity',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
      equals: 'equals',
      notEquals: 'does not equal',
    },
  },
  keywords: {
    title: 'Keyword',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  price: {
    title: 'Price',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
      equals: 'equals',
      notEquals: 'does not equal',
    },
  },
  publishedAt: {
    title: 'Published at',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
      equals: 'on',
      gt: 'after',
      lt: 'before',
      between: 'range (start date & end date)',
      notEquals: 'not on',
    },
  },
  createdAt: {
    title: 'Created at',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
      equals: 'on',
      gt: 'after',
      lt: 'before',
      between: 'range (start date & end date)',
      notEquals: 'not on',
    },
  },
  updatedAt: {
    title: 'Updated at',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
      equals: 'on',
      gt: 'after',
      lt: 'before',
      between: 'range (start date & end date)',
      notEquals: 'not on',
    },
  },
  requiresComponents: {
    title: 'Requires components',
    booleanOptions: [
      { label: 'Requires', value: 'true' },
      { label: 'Not requires', value: 'false' },
    ],
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  sku: {
    title: 'SKU',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  status: {
    title: 'Product status',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  taxable: {
    title: 'Taxable',
    booleanOptions: [
      { label: 'Taxable', value: 'true' },
      { label: 'Not taxable', value: 'false' },
    ],
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  taxcode: {
    title: 'Taxcode',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  title: {
    title: 'Title',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  type: {
    title: 'Type',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  unitCost: {
    title: 'Unit cost',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
      equals: 'equals',
      notEquals: 'does not equal',
    },
  },
  vendor: {
    title: 'Vendor',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  productType: {
    title: 'Product type',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  trashed: {
    title: 'Trashed',
    operatorNames: {
      ...DEFAULT_OPERATORS_NAMES,
    },
  },
  meta: {
    operatorNamesString: {
      ...DEFAULT_OPERATORS_NAMES,
    },
    operatorNamesNumber: {
      ...DEFAULT_OPERATORS_NAMES,
      equals: 'equals',
      notEquals: 'does not equal',
    },
    operatorNamesDate: {
      ...DEFAULT_OPERATORS_NAMES,
      equals: 'on',
      gt: 'after',
      lt: 'before',
      between: 'range (start date & end date)',
      notEquals: 'not on',
    },
  },
};

export const BOOL_OPTIONS = [
  { label: 'Checked', value: 'true' },
  { label: 'Unchecked', value: 'false' },
];

export const SINGLE_OPERATORS = [
  'hasAnyValue',
  'hasNoValue',
  'unnamed',
  'notProcessedByFr',
  'processedByFr',
  'isSet',
  'notSet',
  'isTranscribed',
  'isNotTranscribed',
  'today',
  'last90days',
  'lastmonth',
  'lastweek',
  'yesterday',
];

export const NUMBER_OPERATORS = [
  'fileSize',
  'rating',
];

export const PROOFING_FILTERS = {
  collectionIds: {
    type: 'collectionIds',
    operators: [
      'oneOf',
    ],
  },
  color: {
    type: 'color',
    operators: [
      'isSet',
      'noneOf',
      'notSet',
      'oneOf',
    ],
  },
  description: {
    type: 'string',
    subtype: 'text',
    operators: [
      'contains',
      'endsWith',
      'equals',
      'isSet',
      'notEquals',
      'notSet',
      'startsWith',
    ],
  },
  flag: {
    type: 'flag',
    operators: [
      'equals',
      'isSet',
      'notEquals',
      'notSet',
    ],
  },
  name: {
    type: 'string',
    operators: [
      'contains',
      'endsWith',
      'equals',
      'notEquals',
      'startsWith',
    ],
  },
  rating: {
    type: 'rating',
    operators: [
      'between',
      'equals',
      'gt',
      'isSet',
      'lt',
      'notEquals',
      'notSet',
    ],
  },
  title: {
    type: 'string',
    operators: [
      'contains',
      'endsWith',
      'equals',
      'isSet',
      'notEquals',
      'notSet',
      'startsWith',
    ],
  },
  type: {
    type: 'text',
    operators: [
      'equals',
    ],
    options: [
      'images',
      'video',
      'audio',
      'audioAndVideo',
      'text',
      'pdf',
      'sketch',
      'raw',
      'photoshop',
      'office',
      '3d',
      'externalLink',
    ],
  },
};

export const PROOFING_FILTERS_ORDER = {
  colorShow: 'color',
  flagShow: 'flag',
  ratingShow: 'rating',
  titleShow: 'title',
  fileNameShow: 'name',
  tagsTreeShow: 'collectionIds',
  descriptionShow: 'description',
};

export const OPERATORS_ORDER = {
  date: ['equals', 'notEquals', 'lt', 'gt', 'between', 'isSet', 'notSet', 'today', 'yesterday', 'lastweek', 'lastmonth', 'last90days'],
  text: ['equals', 'notEquals', 'contains', 'startsWith', 'endsWith', 'isSet', 'notSet'],
  comment: ['contains', 'startsWith', 'endsWith', 'isSet', 'notSet'],
  enumMultiple: [
    'oneOf',
    'allOf',
    'noneOf',
    'isSet',
    'notSet',
  ],
  enumSingle: ['oneOf', 'noneOf', 'isSet', 'notSet'],
  number: ['equals', 'notEquals', 'lt', 'gt', 'between', 'isSet', 'notSet'],
  assignees: [
    'oneOf',
    'allOf',
    'noneOf',
    'isSet',
    'notSet',
  ],
  color: [
    'oneOf',
    'noneOf',
    'isSet',
    'notSet',
  ],
  createdBy: [
    'oneOf',
    'noneOf',
    'isSet',
    'notSet',
  ],
  faces: [
    'oneOf',
    'allOf',
    'noneOf',
    'isSet',
    'notSet',
  ],
  fileSize: [
    'equals',
    'notEquals',
    'lt',
    'gt',
    'between',
  ],
  flag: [
    'equals',
    'notEquals',
    'isSet',
    'notSet',
  ],
  keywords: [
    'oneOf',
    'allOf',
    'noneOf',
    'isSet',
    'notSet',
  ],
  fileExtension: ['equals',
    'notEquals'],
  rating: [
    'equals', 'notEquals', 'lt', 'gt', 'between', 'isSet', 'notSet',
  ],
  mimeType: [
    'equals', 'notEquals', 'contains', 'startsWith', 'endsWith',
  ],
  type: [
    'equals',
  ],
  name: [
    'equals', 'notEquals', 'contains', 'startsWith', 'endsWith',
  ],
  products: [
    'oneOf',
    'noneOf',
    'isSet',
    'notSet',
  ],
  restrictionReason: [
    'equals', 'notEquals', 'contains', 'startsWith', 'endsWith',
  ],
  title: [
    'equals', 'notEquals', 'contains', 'startsWith', 'endsWith', 'isSet', 'notSet',
  ],
  commentedBy: [
    'oneOf',
    'allOf',
    'noneOf',
    'isSet',
    'notSet',
  ],
  watermarkId: ['oneOf', 'noneOf', 'isSet', 'notSet'],
  transcript: ['contains', 'startsWith', 'endsWith', 'isSet', 'notSet'],
};

// temporarily removed, saved for future, it is hard to match the country names.
// countryCodeOfOrigin: {
//   title: 'Country code of origin',
//   options: {
//     AC: 'Ascension Island',
//     AD: 'Andorra',
//     AE: 'United Arab Emirates',
//     AF: 'Afghanistan',
//     AG: 'Antigua & Barbuda',
//     AI: 'Anguilla',
//     AL: 'Albania',
//     AM: 'Armenia',
//     AN: 'Netherlands Antilles',
//     AO: 'Angola',
//     AR: 'Argentina',
//     AT: 'Austria',
//     AU: 'Australia',
//     AW: 'Aruba',
//     AX: 'Åland Islands',
//     AZ: 'Azerbaijan',
//     BA: 'Bosnia & Herzegovina',
//     BB: 'Barbados',
//     BD: 'Bangladesh',
//     BE: 'Belgium',
//     BF: 'Burkina Faso',
//     BG: 'Bulgaria',
//     BH: 'Bahrain',
//     BI: 'Burundi',
//     BJ: 'Benin',
//     BL: 'St. Barthélemy',
//     BM: 'Bermuda',
//     BN: 'Brunei',
//     BO: 'Bolivia',
//     BQ: 'Caribbean Netherlands',
//     BR: 'Brazil',
//     BS: 'Bahamas',
//     BT: 'Bhutan',
//     BV: 'Bouvet Island',
//     BW: 'Botswana',
//     BY: 'Belarus',
//     BZ: 'Belize',
//     CA: 'Canada',
//     CC: 'Cocos (Keeling) Islands',
//     CD: 'Congo - Kinshasa',
//     CF: 'Central African Republic',
//     CG: 'Congo - Brazzaville',
//     CH: 'Switzerland',
//     CI: 'Côte d’Ivoire',
//     CK: 'Cook Islands',
//     CL: 'Chile',
//     CM: 'Cameroon',
//     CN: 'China',
//     CO: 'Colombia',
//     CR: 'Costa Rica',
//     CU: 'Cuba',
//     CV: 'Cape Verde',
//     CW: 'Curaçao',
//     CX: 'Christmas Island',
//     CY: 'Cyprus',
//     CZ: 'Czechia',
//     DE: 'Germany',
//     DJ: 'Djibouti',
//     DK: 'Denmark',
//     DM: 'Dominica',
//     DO: 'Dominican Republic',
//     DZ: 'Algeria',
//     EC: 'Ecuador',
//     EE: 'Estonia',
//     EG: 'Egypt',
//     EH: 'Western Sahara',
//     ER: 'Eritrea',
//     ES: 'Spain',
//     ET: 'Ethiopia',
//     FI: 'Finland',
//     FJ: 'Fiji',
//     FK: 'Falkland Islands',
//     FO: 'Faroe Islands',
//     FR: 'France',
//     GA: 'Gabon',
//     GB: 'United Kingdom',
//     GD: 'Grenada',
//     GE: 'Georgia',
//     GF: 'French Guiana',
//     GG: 'Guernsey',
//     GH: 'Ghana',
//     GI: 'Gibraltar',
//     GL: 'Greenland',
//     GM: 'Gambia',
//     GN: 'Guinea',
//     GP: 'Guadeloupe',
//     GQ: 'Equatorial Guinea',
//     GR: 'Greece',
//     GS: 'South Georgia & South Sandwich Islands',
//     GT: 'Guatemala',
//     GW: 'Guinea-Bissau',
//     GY: 'Guyana',
//     HK: 'Hong Kong SAR',
//     HM: 'Heard & McDonald Islands',
//     HN: 'Honduras',
//     HR: 'Croatia',
//     HT: 'Haiti',
//     HU: 'Hungary',
//     ID: 'Indonesia',
//     IE: 'Ireland',
//     IL: 'Israel',
//     IM: 'Isle of Man',
//     IN: 'India',
//     IO: 'British Indian Ocean Territory',
//     IQ: 'Iraq',
//     IR: 'Iran',
//     IS: 'Iceland',
//     IT: 'Italy',
//     JE: 'Jersey',
//     JM: 'Jamaica',
//     JO: 'Jordan',
//     JP: 'Japan',
//     KE: 'Kenya',
//     KG: 'Kyrgyzstan',
//     KH: 'Cambodia',
//     KI: 'Kiribati',
//     KM: 'Comoros',
//     KN: 'St. Kitts & Nevis',
//     KP: 'North Korea',
//     KR: 'South Korea',
//     KW: 'Kuwait',
//     KY: 'Cayman Islands',
//     KZ: 'Kazakhstan',
//     LA: 'Laos',
//     LB: 'Lebanon',
//     LC: 'St. Lucia',
//     LI: 'Liechtenstein',
//     LK: 'Sri Lanka',
//     LR: 'Liberia',
//     LS: 'Lesotho',
//     LT: 'Lithuania',
//     LU: 'Luxembourg',
//     LV: 'Latvia',
//     LY: 'Libya',
//     MA: 'Morocco',
//     MC: 'Monaco',
//     MD: 'Moldova',
//     ME: 'Montenegro',
//     MF: 'St. Martin',
//     MG: 'Madagascar',
//     MK: 'North Macedonia',
//     ML: 'Mali',
//     MM: 'Myanmar (Burma)',
//     MN: 'Mongolia',
//     MO: 'Macao SAR',
//     MQ: 'Martinique',
//     MR: 'Mauritania',
//     MS: 'Montserrat',
//     MT: 'Malta',
//     MU: 'Mauritius',
//     MV: 'Maldives',
//     MW: 'Malawi',
//     MX: 'Mexico',
//     MY: 'Malaysia',
//     MZ: 'Mozambique',
//     NA: 'Namibia',
//     NC: 'New Caledonia',
//     NE: 'Niger',
//     NF: 'Norfolk Island',
//     NG: 'Nigeria',
//     NI: 'Nicaragua',
//     NL: 'Netherlands',
//     NO: 'Norway',
//     NP: 'Nepal',
//     NR: 'Nauru',
//     NU: 'Niue',
//     NZ: 'New Zealand',
//     OM: 'Oman',
//     PA: 'Panama',
//     PE: 'Peru',
//     PF: 'French Polynesia',
//     PG: 'Papua New Guinea',
//     PH: 'Philippines',
//     PK: 'Pakistan',
//     PL: 'Poland',
//     PM: 'St. Pierre & Miquelon',
//     PN: 'Pitcairn Islands',
//     PS: 'Palestinian Territories',
//     PT: 'Portugal',
//     PY: 'Paraguay',
//     QA: 'Qatar',
//     RE: 'Réunion',
//     RO: 'Romania',
//     RS: 'Serbia',
//     RU: 'Russia',
//     RW: 'Rwanda',
//     SA: 'Saudi Arabia',
//     SB: 'Solomon Islands',
//     SC: 'Seychelles',
//     SD: 'Sudan',
//     SE: 'Sweden',
//     SG: 'Singapore',
//     SH: 'St. Helena',
//     SI: 'Slovenia',
//     SJ: 'Svalbard & Jan Mayen',
//     SK: 'Slovakia',
//     SL: 'Sierra Leone',
//     SM: 'San Marino',
//     SN: 'Senegal',
//     SO: 'Somalia',
//     SR: 'Suriname',
//     SS: 'South Sudan',
//     ST: 'São Tomé & Príncipe',
//     SV: 'El Salvador',
//     SX: 'Sint Maarten',
//     SY: 'Syria',
//     SZ: 'Eswatini',
//     TA: 'Tristan da Cunha',
//     TC: 'Turks & Caicos Islands',
//     TD: 'Chad',
//     TF: 'French Southern Territories',
//     TG: 'Togo',
//     TH: 'Thailand',
//     TJ: 'Tajikistan',
//     TK: 'Tokelau',
//     TL: 'Timor-Leste',
//     TM: 'Turkmenistan',
//     TN: 'Tunisia',
//     TO: 'Tonga',
//     TR: 'Türkiye',
//     TT: 'Trinidad & Tobago',
//     TV: 'Tuvalu',
//     TW: 'Taiwan',
//     TZ: 'Tanzania',
//     UA: 'Ukraine',
//     UG: 'Uganda',
//     UM: 'U.S. Outlying Islands',
//     US: 'United States',
//     UY: 'Uruguay',
//     UZ: 'Uzbekistan',
//     VA: 'Vatican City',
//     VC: 'St. Vincent & Grenadines',
//     VE: 'Venezuela',
//     VG: 'British Virgin Islands',
//     VN: 'Vietnam',
//     VU: 'Vanuatu',
//     WF: 'Wallis & Futuna',
//   },
// },
