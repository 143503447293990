import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import l18n from '../../../../shared/strings';

import { showDialog } from '../../../../components/dialog';
import { navigate } from '../../../history';
import UiBlocker from '../../../../services/UiBlocker';
import Logger from '../../../../services/Logger';
import * as utils from '../../../../shared/utils';
import { create } from '../../../../store/products/actions';
import * as mainActions from '../../../../store/actions/main';

export default function useCreateProduct() {
  const [isValidNewName, setValidNewName] = useState(true);
  const [addInProgress, setAddInProgress] = useState(false);
  const [newProductName, setNewProductName] = useState('');
  const openedTree = useSelector((state) => state.main.openedTree);
  const products = useSelector((state) => state.products.items);
  const dispatch = useDispatch();

  const makeNewProductName = useCallback(() => {
    let newName = 'New product';

    if (products.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < 10000; i++) {
        const testName = i !== 1 ? `${newName} ${i}` : newName;
        if (products.every((item) => utils.decodeSlash(item.name) !== utils.decodeSlash(testName))) {
          newName = testName;
          break;
        }
      }
    }

    /* if new name is in progress */
    if (newName === newProductName) {
      const nameArr = newName.split(' ');
      const lastNumber = Number(nameArr[nameArr.length - 1]);
      if (Number.isNaN(lastNumber)) {
        newName += ' 1';
      } else {
        nameArr[nameArr.length - 1] += 1;
        newName = nameArr.join(' ');
      }
    }

    return newName;
  }, [products, newProductName]);

  const doCreateProduct = useCallback(async (_value) => {
    const value = _value.trim();
    if (!value) {
      setValidNewName(true);
      return;
    }

    Logger.log('User', 'ProductsPanelAddProductConfirm');

    if (value.length >= 499) {
      showDialog({
        title: l18n.DIALOGS.WARNING_COLLECTION_NAME_LENGTH.TITLE,
        text: l18n.DIALOGS.WARNING_COLLECTION_NAME_LENGTH.TEXT,
        textBtnOk: l18n.DIALOGS.WARNING_COLLECTION_NAME_LENGTH.OK_TEXT,
        textBtnCancel: null,
      });
      setAddInProgress(false);
      return;
    }

    setNewProductName(value);
    UiBlocker.block(`Creating product ${value}`);
    const { payload } = await dispatch(create({ title: value }));
    UiBlocker.unblock();
    navigate(`/product/${payload._id}`);
    setNewProductName('');

    setAddInProgress(false);
  }, [dispatch, setValidNewName]);

  const handleCreateCancel = useCallback(() => {
    setAddInProgress(false);
  }, []);

  const createProduct = useCallback((e) => {
    e.stopPropagation();
    setAddInProgress(true);
    showDialog({
      title: 'Create product',
      text: 'Product title',
      input: {
        placeholder: 'Enter product name',
        value: makeNewProductName(),
      },
      textBtnOk: 'Create',
      textBtnCancel: 'Cancel',
      onOk: async ({ input }) => {
        await doCreateProduct(input);
        if (openedTree !== 'products') {
          dispatch(mainActions.changeTree('products'));
        }
      },
      onCancel: handleCreateCancel,
    });

    Logger.log('User', 'ProductsPanelAddProduct');
  }, [dispatch, openedTree, setAddInProgress, makeNewProductName, doCreateProduct, handleCreateCancel]);

  return {
    isValidNewName, addInProgress, createProduct, createProductCancel: handleCreateCancel,
  };
}
