/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { func, string, array } from 'prop-types';
import cn from 'classnames';
import {
  Button, InputAdornment, Input, Icon as IconNew,
} from '@picsio/ui';
import { Search as SearchIcon, Close } from '@picsio/icons';
import l18n from '../../shared/strings';
import Icon from '../Icon';
import Field from './Field';

import './styles.scss';

const { SEARCH_FILTERS_DIALOG } = l18n;
const KEY_ENTER = 13;
const KEY_ESC = 27;

const SearchFiltersDialog = ({
  destroy,
  title,
  textBtnCancel,
  textBtnOk,
  filters,
  onOk,
}) => {
  const [fields, setFields] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const submit = useCallback(async () => {
    onOk(fields);
    destroy();
  }, [destroy, fields, onOk]);

  const keyListener = useCallback((event) => {
    if (event.keyCode === KEY_ENTER) submit();
    if (event.keyCode === KEY_ESC) destroy();
  }, [destroy, submit]);

  useEffect(() => {
    window.addEventListener('keydown', keyListener);
    return () => window.removeEventListener('keydown', keyListener);
  }, [keyListener]);

  const handleCheckboxChange = useCallback((field, value) => {
    setFields((prevFields) => (value ? prevFields.concat(field) : prevFields.filter(({ title }) => title !== field.title)));
  }, []);

  const handleChangeSearchValue = useCallback((e) => {
    setSearchValue(e.target.value);
  }, [setSearchValue]);

  const getValue = useCallback((title) => fields.find((field) => field.title === title), [fields]);
  const getText = useCallback(
    () => (fields.length ? fields.map(({ title: fieldTitle }) => (fieldTitle.startsWith('meta.') ? fieldTitle.replace('meta.', '') : fieldTitle)).join(', ') : SEARCH_FILTERS_DIALOG.textNone),
    [fields],
  );
  const renderFilters = useMemo(() => {
    if (searchValue) {
      return filters.filter(({ title }) => title.toLowerCase().includes(searchValue.toLowerCase()));
    }
    return filters;
  }, [filters, searchValue]);

  const handleResetText = useCallback(() => {
    setSearchValue('');
  }, []);

  return (
    <div className="simpleDialog exportDialog">
      <div className="simpleDialogUnderlayer" />
      <div className="simpleDialogBox">
        <div className="simpleDialogHeader">
          <span className="simpleDialogTitle">{title}</span>
          <span className="simpleDialogBtnCross" onClick={destroy} role="button" tabIndex={0}>
            <Icon name="close" />
          </span>
        </div>
        <div className="simpleDialogContent">
          <div className="simpleDialogContentInner noOverflow">
            <div className="simpleDialogDescription">
              <p><span className="subtext">{SEARCH_FILTERS_DIALOG.text}</span>{getText()}</p>
              <div className="exportDialog-radios">
                <div className="exportDialog-row">
                  <Input
                    placeholder={SEARCH_FILTERS_DIALOG.placeholder}
                    value={searchValue}
                    onChange={handleChangeSearchValue}
                    startAdornment={<SearchIcon className="searchIcon" />}
                    endAdornment={searchValue.length
                      && (
                        <InputAdornment className="addFilters__icon-close" position="end" onClick={handleResetText}>
                          <IconNew color="inherit" size="lg">
                            <Close />
                          </IconNew>
                        </InputAdornment>
                      )}
                  />
                </div>
                <div className={cn('exportDialog-row-expanded')}>
                  <ul className="exportDialog-checkboxes">
                    {renderFilters.map((field) => (
                      <Field field={field} key={field.name} value={getValue(field.title)} onChange={handleCheckboxChange} />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="simpleDialogFooter">
          <Button
            color="secondary"
            size="md"
            variant="contained"
            tabIndex={0}
            onClick={destroy}
          >
            {textBtnCancel}
          </Button>
          <Button
            color="primary"
            size="md"
            variant="contained"
            tabIndex={0}
            onClick={submit}
          >
            {textBtnOk}
          </Button>
        </div>
      </div>
    </div>
  );
};

SearchFiltersDialog.propTypes = {
  destroy: func.isRequired,
  title: string,
  textBtnCancel: string,
  textBtnOk: string,
  filters: array,
  onOk: func,
};

SearchFiltersDialog.defaultProps = {
  title: SEARCH_FILTERS_DIALOG.title,
  textBtnCancel: SEARCH_FILTERS_DIALOG.textBtnCancel,
  textBtnOk: SEARCH_FILTERS_DIALOG.textBtnOk,
  onOk: Function.prototype,
};

export default SearchFiltersDialog;
