import events from '@picsio/events';
import picsioConfig from '../../../../config';

export default function (userId, event, integrations = [], transportType) {
  if (!event) return false;

  const collectionEventTypes = events.getCollectionTypes(picsioConfig.eventsAppName);
  const subscribeAnyway = ['request.created'].includes(event.type);

  if (collectionEventTypes.includes(event.type)) {
    // List of users that decided to disable {transportType} transport (collection transports)
    const transportTypeDisabledFor = event[`${transportType}DisabledFor`] || [];
    if (transportTypeDisabledFor.includes(userId) && event.type && !subscribeAnyway) return true;
  } else {
    // Settings for global transport
    const transportSettings = integrations.find((item) => item.type === transportType);

    // Check global transports
    if (transportSettings && !transportSettings.active && !subscribeAnyway) return true;

    if (transportSettings
      && transportSettings.active
      && transportSettings.eventTypes
      && transportSettings.eventTypes.length
      && !transportSettings.eventTypes.includes(event.type)
      && !subscribeAnyway
    ) {
      return true;
    }
  }

  return false;
}
