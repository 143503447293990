/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const addAssetToVariant = createAsyncThunk(
  'products/addAssetToVariant',
  async ({ assetId, productId, variantId }, {
    extra: {
      sdk, Logger, utils, showErrorDialog,
    },
  }) => {
    try {
      await sdk.products.addAssetToVariant(productId, variantId, assetId);
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not add asset to product variant';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error add asset to product variant'),
        { error },
        ['AddAssetToProductVariantFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      addAssetToVariant.pending,
      () => {
        /** Do nothing */
      },
    )
    .addCase(
      addAssetToVariant.fulfilled,
      (state, { meta }) => {
        state.items.forEach((product) => {
          if (product._id === meta.arg.productId) {
            (product.variants || []).forEach((variant) => {
              if (variant._id === meta.arg.variantId) {
                variant.assetId = meta.arg.assetId;
              }
            });
          }
        });
      },
    )
    .addCase(
      addAssetToVariant.rejected,
      (state, { error }) => {
        state.error = error;
      },
    );
};
