import React, { memo, useCallback } from 'react';
import {
  func, bool, string, arrayOf, shape,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@picsio/ui';
import { Product as ProductIcon } from '@picsio/icons';
import {
  ASYNC_JOB_STATUS_WAITING,
  ASYNC_JOB_STATUS_RUNNING,
} from '@picsio/db/src/constants';
import Logger from '../../../services/Logger';
import { removeAssets, addAssets } from '../../../store/products/actions';
import { navigate } from '../../../helpers/history';
import ProductsDropdown from '../../ProductsDropdown/ProductsDropdown';

const Products = ({
  products, selectedAssets, changeTree, openedTree,
}) => {
  const dispatch = useDispatch();
  const aggregatedData = useSelector((state) => state.assets.selectedAssetsAggregatedData);
  const isDisabled = [ASYNC_JOB_STATUS_RUNNING, ASYNC_JOB_STATUS_WAITING].includes(aggregatedData.productAttaching) || aggregatedData.productDetaching;

  const handleClick = useCallback((productId) => {
    navigate(`/product/${productId}`);
    if (changeTree && openedTree !== 'products') {
      changeTree('products', true);
    }
  }, [changeTree, openedTree]);

  const addToProduct = useCallback((product) => {
    Logger.log('User', 'InfoPanelAddToProduct', { productId: product._id });
    dispatch(addAssets({ assetIds: selectedAssets, product }));
  }, [dispatch, selectedAssets]);

  const removeFromProduct = useCallback((product) => {
    Logger.log('User', 'InfoPanelRemoveFromProduct', { productId: product._id });
    dispatch(removeAssets({ assetIds: selectedAssets, product }));
  }, [dispatch, selectedAssets]);

  const icon = useCallback(() => (
    <Icon>
      <ProductIcon />
    </Icon>
  ), []);

  return (
    <ProductsDropdown
      placeholder="@TODO: Placeholder"
      placeholderIcon="emptyKeywords"
      icon={icon}
      filterText="Choose Products"
      checkedItems={products}
      onCheckedHandler={addToProduct}
      onUncheckedHandler={removeFromProduct}
      onItemClickHandler={handleClick}
      canCreate={false}
      createHandler={null}
      readOnly={false}
      disabled={isDisabled}
      inProgress={isDisabled}
    />
  );
};

Products.propTypes = {
  products: arrayOf(shape({
    _id: string,
    title: string,
  })),
  lightboardsEditable: bool,
  selectedAssets: arrayOf(string),
  changeTree: func,
  remove: func,
  openedTree: string,
  disabled: bool,
};

export default memo(Products);
