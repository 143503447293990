import React, {
  forwardRef, useCallback, useMemo, useRef,
} from 'react';
import cn from 'classnames';
import {
  CloseIcon, Dragndrop, TriangleArrowDown,
} from '@picsio/icons';
import { IconButton, Popper } from '@picsio/ui';
import SearchFilterDropOpener from './SearchFilterDropOpener';
import { SINGLE_OPERATORS } from './config';

const SearchFilterItem = forwardRef(({
  id = '',
  disabled,
  data,
  draggable = true,
  onDragStart,
  onDragEnter,
  onDragEnd,
  customizingMode,
  isSelected,
  isOpen,
  onOpen,
  onClose,
  onSelectFilter,
  onResetFilter,
  onRemove,
  filterData,
  onConfirmFilter,
  confirmedData,
  filters,
  rootCollectionId,
}) => {
  const {
    title, type, subtype, operators, options,
  } = data;
  const ref = useRef();
  const operatorName = useMemo(() => {
    if (id.startsWith('meta.')) {
      if (type === 'number') {
        return filters?.meta?.operatorNamesNumber[confirmedData?.operator];
      }
      if (type === 'date') {
        return filters?.meta?.operatorNamesDate[confirmedData?.operator];
      }
      return filters?.meta?.operatorNamesString[confirmedData?.operator];
    }
    if (filters[id]?.operatorNames) {
      return filters[id]?.operatorNames[confirmedData?.operator];
    } return confirmedData?.operator;
  }, [confirmedData?.operator, id, type]);

  const formattedSelectedFilterTitle = useMemo(() => {
    const titleToRender = title.startsWith('meta.') ? title.replace('meta.', '') : title;
    if (isSelected) {
      if (Array.isArray(confirmedData.value) && !SINGLE_OPERATORS.includes(confirmedData.operator)) {
        const selectedValueTitle = confirmedData.value[0]?.name || confirmedData.value[0]?.title || confirmedData.value[0];
        return `${titleToRender} (${operatorName}) ${selectedValueTitle}`;
      }
      if (type === 'date') {
        let date = new Date();

        if (confirmedData.operator === 'between') {
          date = new Date(Number(confirmedData.value.split('_')[0]));
        } else {
          date = new Date(Number(confirmedData.value));
        }
        const selectedValueTitle = date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        });
        return `${titleToRender} (${operatorName}) ${selectedValueTitle}`;
      }
      return `${titleToRender} (${operatorName}) ${SINGLE_OPERATORS.includes(confirmedData.operator) ? '' : confirmedData.value}`;
    }
    return titleToRender;
  }, [confirmedData?.operator, confirmedData?.value, isSelected, operatorName, title, type]);

  const handleOpen = useCallback(() => (customizingMode ? Function.prototype : onOpen(id)), [customizingMode, id, onOpen]);

  const handleRemove = useCallback(() => onRemove(id), [id, onRemove]);

  const active = useMemo(() => {
    if (isSelected && !customizingMode) {
      if (id === 'collectionIds' && confirmedData?.value?.length === 1 && confirmedData?.value[0]._id === rootCollectionId) {
        return false;
      }
      return true;
    }
    if (isOpen && !customizingMode) {
      return true;
    }
    return false;
  }, [confirmedData?.value, customizingMode, id, isOpen, isSelected, rootCollectionId]);

  return (
    <div
      key={id}
      data-id={id}
      draggable={!disabled && draggable && customizingMode}
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
      style={{
        position: 'relative', display: 'inline-block', marginBottom: '4px', marginTop: '4px',
      }}
      ref={ref}
    >
      <div
        className={cn('searchFilterItem', {
          settingMode: customizingMode,
          active,
        })}
        onClick={isOpen ? onClose : handleOpen}
      >
        <If condition={customizingMode && draggable}>
          <div className="searchFilterItem__section">
            <Dragndrop className="searchFilterItem__dnd" />
          </div>
        </If>
        <div
          className={cn('searchFilterItem__title')}
        >
          { formattedSelectedFilterTitle }
        </div>
        <div className="searchFilterItem__section">
          <Choose>
            <When condition={customizingMode}>
              <IconButton size="sm" disabled={disabled} className="searchFilterItem__iconBtn" onClick={handleRemove}>
                <CloseIcon className="searchFilterItem__closeIcon" />
              </IconButton>
            </When>
            <Otherwise>
              <IconButton size="sm" disabled={disabled} className="searchFilterItem__iconBtn">
                <TriangleArrowDown />
              </IconButton>
            </Otherwise>
          </Choose>
        </div>
      </div>
      <Popper
        target={ref}
        className="dropdownElementPopper"
        isOpen={isOpen}
        onClose={() => {}}
        offset={[0, 10]}
        hide={false}
        arrow={false}
        autoWidth
        outsideClickListener
      >
        <SearchFilterDropOpener
          btnRef={ref}
          id={id}
          type={type}
          subtype={subtype}
          filterData={filterData}
          confirmedData={confirmedData}
          isSelected={isSelected}
          isDropdownOpened={isOpen}
          operators={operators}
          options={options}
          onSelectFilter={onSelectFilter}
          onConfirmFilter={onConfirmFilter}
          onResetFilter={onResetFilter}
          onCloseDropdown={onClose}
          data={data}
          filters={filters}
          title={title}
        />
      </Popper>
    </div>
  );
});

export default SearchFilterItem;
