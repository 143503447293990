/* eslint-disable react/require-default-props */
import React, {
  useState, useRef, useEffect, useMemo, Suspense,
} from 'react';
import { Html, useProgress } from '@react-three/drei';
import PropTypes from 'prop-types';
import { Canvas } from '@react-three/fiber';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ToolbarPreviewLeft from '../../../toolbars/ToolbarPreviewLeft';
import Icon from '../../../Icon';
import Logger from '../../../../services/Logger';
import * as utils from '../../../../shared/utils';
import localization from '../../../../shared/strings';
import getDownloadUrl from '../../../../helpers/getDownloadUrl';
import { showDialog } from '../../../dialog';
import uploadThumbnail from '../../../../helpers/assets/uploadThumbail';
import { setCustomThumbnail } from '../../../../store/actions/assets';
import uiBlocker from '../../../../services/UiBlocker';
import Scene from './Scene';
import ScreenshotHandler from './ScreenshotHandler';
import ThreeContext from './ThreeContext';
import ProgressBar from '../../../ProgressBar';
// import Controls from './Controls';

const THREE_CHUNK = () => import(/* webpackChunkName: "three" */ 'three');

function Loader() {
  const { progress } = useProgress();
  return (
    <Html center>
      <div style={{ minWidth: '400px' }}>
        <ProgressBar percent={progress} text="Loading model..." />
      </div>
    </Html>
  );
}

const TDModel = ({
  asset,
  isMainApp = false,
  addRevision = null,
  handleDownload = null,
  moveToTrash = null,
  isRemoveForever = false,
}) => {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [url, setUrl] = useState(null);
  const [position, setPosition] = useState([0, 0, 0]);
  const [showAxisLines, setShowAxisLines] = useState(false);
  const dialogWrapper = useRef(null);
  const takeScreenshot = useRef(null);
  const [threeInstance, setThreeInstance] = useState(null);
  const [isDialogShown, setIsDialogShown] = useState(true);

  const initThree = async () => {
    try {
      const THREE = await THREE_CHUNK();
      setThreeInstance(THREE);
    } catch (err) {
      Logger.error(new Error('Can not init 3D viewer'), { error: err, showDialog: true });
    }
  };

  useEffect(() => {
    initThree();
  }, []);

  useEffect(() => {
    setIsLoaded(false);
    setIsError(false);

    const loadFile = async () => {
      try {
        const downloadUrl = await getDownloadUrl({
          assetId: asset._id,
          allowDownloadByGS: false,
          forPreviewDownload: true,
        });
        setUrl(downloadUrl);
        setIsLoaded(true);
      } catch (error) {
        Logger.error(new Error('Failed to get file URL', { error }));
        setIsError(true);
      }
    };

    loadFile();
  }, [asset._id]);

  useEffect(() => {
    if (isLoaded && isDialogShown) {
      const dontShowAgain = utils.LocalStorage.get('tdmodelDialogDontShow');

      if (!dontShowAgain) {
        showDialog({
          title: 'Viewing 3D models',
          text: (
            <div>
              To get the most out of your 3D models, follow {isMainApp ? 'these tips' : 'this tip'}:
              <br />
              <br />
              Explore from any angle: Use your mouse to rotate, zoom in, or zoom out. This is especially helpful if the file initially appears empty or out of frame.
              { isMainApp && (
                <>
                  <br />
                  <br />
                  Create a perfect preview: Use "Upload Custom Thumbnail" or "Snapshot & Custom Thumbnail" to capture and set the best angle for your thumbnail.
                </>
              )}
            </div>
          ),
          checkbox: {
            label: "Don't show it again",
          },
          textBtnCancel: null,
          textBtnOk: 'Okay',
          onOk: ({ checkbox }) => {
            if (checkbox) {
              utils.LocalStorage.set('tdmodelDialogDontShow', true);
            }
          },
        });
        setIsDialogShown(false);
      }
    }
  }, [isLoaded, isDialogShown, isMainApp]);

  const uploadCustomThumbnail = async (thumbnail) => {
    const { _id } = asset;
    uiBlocker.block('Uploading...');
    await uploadThumbnail(_id, thumbnail, bindActionCreators(setCustomThumbnail, dispatch));
    uiBlocker.unblock();
  };

  const handleTakeScreenshot = async () => {
    if (takeScreenshot.current) {
      const screenshot = await takeScreenshot.current();
      return screenshot;
    }
    return null;
  };

  const handleCreateCustomThumbnail = async () => {
    Logger.log('User', '3DModelCreateCustomThumbnail');

    const screenshot = await handleTakeScreenshot();
    showDialog({
      title: 'New custom thumbnail',
      text: (
        <img
          alt="New custom thumbnail"
          src={window.URL.createObjectURL(screenshot)}
          style={{ width: '100%', height: 'auto' }}
        />
      ),
      textBtnOk: 'Create',
      textBtnCancel: 'Cancel',
      onOk: () => uploadCustomThumbnail(screenshot),
    });
  };

  const isRestricted = utils.isAssetRestricted(asset?.restrictSettings);

  const MemoizedScene = useMemo(() => (
    isLoaded && url && (
      <Scene url={url} asset={asset} position={position} showAxisLines={showAxisLines} />
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [url, position, showAxisLines, isLoaded]);

  return (
    <div className="innerContainerMediaFile">
      <ThreeContext.Provider value={threeInstance}>
        <If condition={isMainApp}>
          <ToolbarPreviewLeft
            assetId={asset._id}
            addRevision={addRevision}
            download={handleDownload}
            moveToTrash={moveToTrash}
            permissions={asset.permissions}
            isRestricted={isRestricted}
            isRemoveForever={isRemoveForever}
            onCreateCustomThumbnail={handleCreateCustomThumbnail}
          />
        </If>
        <div className="theMediaFile">
          <Choose>
            <When condition={!isError}>
              <Canvas
                gl={{
                  outputEncoding: threeInstance?.sRGBEncoding,
                }}
                camera={{ position: [0, 0, 200], fov: 60 }}
                onCreated={({ gl }) => {
                  gl.setPixelRatio(window.devicePixelRatio);
                  gl.setSize(window.innerWidth, window.innerHeight);
                }}
              >
                <Suspense fallback={<Loader />}>
                  {MemoizedScene}
                  <If condition={isMainApp}>
                    <ScreenshotHandler takeScreenshot={takeScreenshot} />
                  </If>
                </Suspense>
              </Canvas>
              {/* <Controls
                position={position}
                setPosition={setPosition}
                showAxisLines={showAxisLines}
                setShowAxisLines={setShowAxisLines}
              /> */}
            </When>
            <When condition={isError}>
              <div className="placeholderMediaFile">
                <div className="innerPlaceholderMediaFile">
                  <div className="icon" style={{ color: '#474747' }}>
                    <Icon name="error" />
                  </div>
                  <div className="text">{localization.PREVIEW_VIEW.errorLoadFile}</div>
                  <div className="fileName">{asset?.name}</div>
                </div>
              </div>
            </When>
          </Choose>
          <div className="wrapperVideoDialog" ref={dialogWrapper} />
        </div>
      </ThreeContext.Provider>
    </div>
  );
};

TDModel.propTypes = {
  asset: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    permissions: PropTypes.shape({}).isRequired,
    restrictSettings: PropTypes.shape({}),
  }).isRequired,
  isMainApp: PropTypes.bool,
  addRevision: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  handleDownload: PropTypes.func,
  moveToTrash: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isRemoveForever: PropTypes.bool,
};

export default TDModel;
