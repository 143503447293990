/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const create = createAsyncThunk(
  'products/create',
  async ({ title }, {
    extra: {
      sdk, utils, Logger, showErrorDialog,
    },
    getState,
  }) => {
    try {
      const { data } = await sdk.products.create({ title });
      Logger.log('UI', 'product_created', { product_title: title, created_by: getState().user._id });
      return data;
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not create product';
      showErrorDialog(errorMessage);
      Logger.error(new Error('Error product creating'), { error }, [
        'CreateProductFailed',
        errorMessage || 'NoMessage',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      create.pending,
      (state, { meta }) => {
        state.nameCreatingProduct = meta.arg.title;
      },
    )
    .addCase(
      create.fulfilled,
      (state, { payload }) => {
        state.items.unshift(payload);
        state.nameCreatingProduct = null;
        state.error = null;
      },
    )
    .addCase(
      create.rejected,
      (state, { error }) => {
        state.nameCreatingProduct = null;
        state.error = error;
      },
    );
};
