/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import renderUnsupportedMediaDialog from '../../../components/UnsupportedMediaDialog/renderUnsupportedMediaDialog';
import UiBlocker from '../../../services/UiBlocker';
import { navigate } from '../../../helpers/history';
import picsioConfig from '../../../../../../config';

const attachToProducts = createAsyncThunk(
  'assets/attachToProducts',
  async (_, {
    extra: {
      sdk, Logger, utils, showErrorDialog, showDialog, localization: l10n, Toast,
    },
    getState,
  }) => {
    const { assets: { selectedItemsToMatch } } = getState();

    try {
      UiBlocker.block('Apply matching...');
      const { data } = await sdk.assets.attachToProducts(selectedItemsToMatch);

      let assetsWithReason = { failedIds: [], codes: {} };
      if (data.assetsWithReason) {
        assetsWithReason = data.assetsWithReason.reduce((acc, { _id, reason, name }) => {
          acc.failedIds.push(_id);
          acc.codes[reason] = acc.codes[reason] ? [...acc.codes[reason], name] : [name];
          return acc;
        }, { failedIds: [], codes: {} });
      }

      if (Object.keys(assetsWithReason.codes).length !== 0) {
        renderUnsupportedMediaDialog({ codes: assetsWithReason.codes, allowedAssetCount: data.assetsToProcessCount, allAssetCount: selectedItemsToMatch.length });
      }

      if (data.assetsToProcessCount > 0) {
        Logger.log('User', 'ApplyMatchingBulkMediaMatch', { assetsToProcessCount: data.assetsToProcessCount });
        Toast(l10n.BULK_MEDIA_MATCH.TOAST_MATCHING_IN_PROCESS);
      }
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not add assets to products';
      const errorSubCode = utils.getDataFromResponceError(error, 'subcode');

      if (errorSubCode === 'MatchAssetsOnTrialLimitExceededError') {
        showDialog({
          title: l10n.BULK_MEDIA_MATCH.MATCHING_LIMIT_REACHED.TITLE,
          text: l10n.BULK_MEDIA_MATCH.MATCHING_LIMIT_REACHED.TEXT(picsioConfig.toriutMatchedAssetsOnTrialLimit),
          textBtnCancel: l10n.BULK_MEDIA_MATCH.MATCHING_LIMIT_REACHED.BTN_CANCEL,
          textBtnOk: l10n.BULK_MEDIA_MATCH.MATCHING_LIMIT_REACHED.BTN_OK,
          async onOk() {
            Logger.log('User', 'ClickViewPlans');
            navigate('/billing?tab=overview');
          },
        });
      } else {
        showErrorDialog(errorMessage);
      }

      Logger.error(
        new Error('Error add assets to product'),
        { error },
        ['AttachMediaToProductsFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    } finally {
      UiBlocker.unblock();
    }
  },
);

export default attachToProducts;
