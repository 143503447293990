import React from 'react';
import { Button, Icon as IconUI } from '@picsio/ui';
import PropTypes from 'prop-types';
import { Info } from '@picsio/icons';
import Icon from '../Icon';
import withErrorBoundary from '../ErrorBoundary/withErrorBoundary';
import l18n from '../../shared/strings';
import ErrorsList from './ErrorsList';

import './styles.scss';

const UnsupportedMediaDialog = ({
  onClose, codes, allowedAssetCount, allAssetCount,
}) => (
  <div className="simpleDialog  UnsupportedMediaDialog">
    <div className="simpleDialogUnderlayer" />
    <div className="simpleDialogBox">
      <div className="simpleDialogHeader">
        <h2 className="simpleDialogTitle">{l18n.DIALOGS.UNSUPPORTED_MEDIA_DIALOG.TITLE}</h2>
        <button
          className="simpleDialogBtnCross"
          onClick={onClose}
          type="button"
        >
          <Icon name="close" />
        </button>
      </div>
      <div className="simpleDialogSubHeader">
        <p>{l18n.DIALOGS.UNSUPPORTED_MEDIA_DIALOG.TEXT(Number(allAssetCount) - Number(allowedAssetCount), allAssetCount)}</p>
      </div>
      <div className="simpleDialogContent">
        <div className="simpleDialogContentInner">
          <ErrorsList codes={codes} />
        </div>
      </div>
      <div className="simpleDialogSubContent">
        <span className="simpleDialogSubContent__icon"><IconUI size="md"><Info /></IconUI></span>
        <span>{l18n.DIALOGS.UNSUPPORTED_MEDIA_DIALOG.SUB_TEXT}</span>
      </div>
      <div className="simpleDialogFooter">
        <Button
          color="primary"
          size="md"
          variant="contained"
          onClick={onClose}
          componentProps={{
            'data-testid': 'btnUnsupportedMediaCancel',
          }}
        >
          {l18n.DIALOGS.UNSUPPORTED_MEDIA_DIALOG.BTN_OK}
        </Button>
      </div>
    </div>
  </div>
);

UnsupportedMediaDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  codes: PropTypes.objectOf(PropTypes.any).isRequired,
  allowedAssetCount: PropTypes.number.isRequired,
  allAssetCount: PropTypes.number.isRequired,
};

export default withErrorBoundary(UnsupportedMediaDialog, { className: 'errorBoundaryOverlay' });
