/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getProducts = createAsyncThunk(
  'products/getProducts',
  async (_, {
    extra: { Logger, Toast, sdk },
  }) => {
    try {
      const { data } = await sdk.products.getAll();
      return data;
    } catch (error) {
      Logger.log('UI', 'ToastProductsNotLoaded');
      Toast(
        'Products not loaded. Refresh and try again',
        { autoClose: false, type: 'error' },
      );
      Logger.error(new Error('Error products fetching'), { error }, [
        'FetchProductsFailed',
        'Products can not be fetched from backend.',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder.addCase(
    getProducts.pending,
    (state) => {
      state.isLoaded = false;
      state.isLoading = true;
      state.error = null;
    },
  ).addCase(
    getProducts.fulfilled,
    (state, { payload }) => {
      state.isLoaded = true;
      state.isLoading = false;
      state.error = null;
      state.items = payload;
    },
  ).addCase(
    getProducts.rejected,
    (state, { error }) => {
      state.items = [];
      state.isLoaded = false;
      state.isLoading = false;
      state.error = error;
    },
  );
};
