import CONSTANTS from '@picsio/db/src/constants';
import _isEqual from 'lodash/isEqual';

import TYPES from '../action-types';
import * as helpers from '../helpers/assets';
import { merge as mergeFaces } from '../faces/merge';
import removeFromAllCollections from '../actions/assets/removeFromAllCollections';
import descriptionGenerated from '../actions/assets/descriptionGenerated';
import descriptionGenerationFailed from '../actions/assets/descriptionGenerationFailed';
import descriptionGenerationStarted from '../actions/assets/descriptionGenerationStarted';
import addProduct from '../actions/assets/addProduct';
import retryImporting from '../actions/assets/retryImporting';
import { addAssets } from '../products/addAssets';
import { removeAssets } from '../products/removeAssets';
import attachToProducts from '../actions/assets/attachToProducts';

const defaultState = {
  isLoaded: true,
  uiBlocked: true,
  updateInProgress: false,
  inProgress: {
    title: false,
    description: false,
    keywords: false,
    faceRecognition: false,
    assignees: false,
    collections: false,
    lightboards: false,
    marks: false,
    customField: false,
    customFields: [],
    share: false,
    modifiedFields: [],
    processing: false,
    watermarking: false,
    assetFaceApplying: false,
    transcribing: false,
  },
  items: [],
  watermarks: [],
  geo: [],
  tmpItemIDs: [], // needed when we choose any linked asset, which isn't in current collection.
  // we can't use "assets", because it will be rendered to catalogView.
  selectedItems: [],
  selectedItemsToMatch: [],
  selectedAssetsAggregatedData: {},
  allowedActions: {},
  lastClicked: null,
  total: 0,
  full: false,
  error: null,
};

export default function AssetsReducer(state = defaultState, action, ...args) {
  const {
    type, payload, error, meta,
  } = action;

  switch (type) {
  /** Fetch  */
  case TYPES.ASSETS.FETCH.START: {
    return {
      ...state,
      isLoaded: false,
      error: null,
      uiBlocked: payload.blockUI,
      items: payload.blockUI ? [] : state.items,
      // selectedItems: payload.blockUI ? [] : state.selectedItems,
      lastClicked: payload.blockUI ? null : state.lastClicked,
      geo: [...state.geo],
      suggestedSort: {},
    };
  }
  case TYPES.ASSETS.FETCH.COMPLETE: {
    return {
      ...state,
      isLoaded: true,
      items: payload.isNewRoute
        ? [...helpers.extendAssets(payload.items)]
        : [...state.items, ...helpers.extendAssets(payload.items)],
      suggestedSort: payload.suggestedSort,
      full: payload.full,
      total: payload.total,
      uiBlocked: false,
      geo: payload.geo ? [...payload.geo] : [],
    };
  }
  case TYPES.ASSETS.FETCH.FAILED: {
    return {
      ...state,
      isLoaded: true,
      error,
    };
  }

  /** Get tmp assets */
  case TYPES.ASSETS.FETCH_TMP.START: {
    return {
      ...state,
      isLoaded: false,
      tmpItemIDs: [],
      error: null,
    };
  }
  case TYPES.ASSETS.FETCH_TMP.COMPLETE: {
    const { ids, rolePermissions = null } = payload;
    let allowedActions = {};
    const items = [...state.items, ...helpers.extendAssets(payload.items)];
    // We don't need to check assets permissions on Proofing.
    if (rolePermissions) {
      allowedActions = helpers.getPermissions(
        items,
        ids,
        rolePermissions,
      );
    }

    return {
      ...state,
      isLoaded: true,
      items: [...items],
      allowedActions,
      tmpItemIDs: payload.ids,
    };
  }
  case TYPES.ASSETS.FETCH_TMP.FAILED: {
    return {
      ...state,
      isLoaded: true,
      error,
    };
  }

  /** Set tmp item */
  case TYPES.ASSETS.SET_TMP_ITEM: {
    return {
      ...state,
      tmpItemIDs: [...state.tmpItemIDs, payload.id],
    };
  }

  /** Get assets by ids */
  case TYPES.ASSETS.GET_BY_IDS.START: {
    return {
      ...state,
      error: null,
    };
  }

  case TYPES.ASSETS.GET_BY_IDS.COMPLETE: {
    return {
      ...state,
      items: [...state.items, ...helpers.extendAssets(payload.items)],
    };
  }

  case TYPES.ASSETS.GET_SELECTED_ASSETS.COMPLETE: {
    const {
      items, selectedItemsIds, rolePermissions, permissionsOnDeselect,
    } = payload;
    let allowedActions = {};
    // We don't need to check assets permissions on Proofing.
    if (rolePermissions) {
      allowedActions = helpers.getPermissions(
        [...helpers.extendAssets(items)],
        selectedItemsIds,
        rolePermissions,
        permissionsOnDeselect,
      );
    }

    return {
      ...state,
      items: [...helpers.extendAssets(items)],
      total: items.length,
      full: true,
      allowedActions,
    };
  }

  case TYPES.ASSETS.GET_SELECTED_ASSETS.FAILED: {
    return {
      ...state,
      error,
    };
  }

  case TYPES.ASSETS.GET_BY_IDS.FAILED: {
    return {
      ...state,
      error,
    };
  }

  /** Set thumbnails for assets */
  case TYPES.ASSETS.SET_THUMBNAILS: {
    const { thumbnails, isUsedS3Storage } = payload;
    return {
      ...state,
      items: helpers.setThumbnailUrls(
        state.items,
        thumbnails,
        isUsedS3Storage,
      ),
    };
  }

  /** Set custom thumbnail */
  case TYPES.ASSETS.SET_CUSTOM_THUMBNAIL: {
    const {
      id, revisionId, url, pages, imageSizes, thumbnail,
    } = payload;
    return {
      ...state,
      items: helpers.setCustomThumbnail(
        state.items,
        id,
        revisionId,
        url,
        pages,
        imageSizes,
        thumbnail,
      ),
    };
  }

  /** Set multiple custom thumbnails */
  case TYPES.ASSETS.SET_CUSTOM_THUMBNAILS: {
    return {
      ...state,
      items: helpers.setCustomThumbnails(state.items, payload.assets),
    };
  }

  /** Set pdf proxies */
  case TYPES.ASSETS.SET_PDF_PROXIES: {
    const {
      id, revisionId, proxyFileStorageId,
    } = payload;

    const updatedAssets = helpers.setPdfProxies(
      state.items,
      id,
      revisionId,
      proxyFileStorageId,
    );

    return {
      ...state,
      items: updatedAssets,
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        converting: 'complete',
      },
    };
  }

  /** Add highlight to asset */
  case TYPES.ASSETS.ADD_HIGHLIGHT: {
    return {
      ...state,
      items: state.items.map((item) => {
        if (payload.ids.includes(item._id)) {
          const paramsForHighlight = [
            ...item.paramsForHighlight,
            payload.type,
          ];
          return {
            ...item,
            paramsForHighlight,
          };
        }
        return item;
      }),
    };
  }

  /** Remove highlight to asset */
  case TYPES.ASSETS.REMOVE_HIGHLIGHT: {
    return {
      ...state,
      items: state.items.map((item) => {
        if (payload.ids.includes(item._id)) {
          const paramsForHighlight = item.paramsForHighlight.filter(
            (param) => param !== payload.type,
          );
          return {
            ...item,
            paramsForHighlight,
          };
        }
        return item;
      }),
    };
  }

  /** Remove tmp item */
  case TYPES.ASSETS.REMOVE_TMP_ITEMS: {
    return {
      ...state,
      items: state.items.filter(
        (item) => !state.tmpItemIDs.includes(item._id),
      ),
      tmpItemIDs: [],
    };
  }

  /** Select */
  case TYPES.ASSETS.SELECT: {
    const {
      value,
      id,
      isRange,
      rolePermissions = null,
      aggregatedData,
      newItems,
      permissionsOnDeselect,
    } = payload;

    const selectedItemsIds = helpers.select(
      value,
      id,
      isRange,
      state.lastClicked,
      state.items,
      state.selectedItems,
    );

    let allowedActions = {};
    // We don't need to check assets permissions on Proofing.
    if (rolePermissions) {
      allowedActions = helpers.getPermissions(
        state.items,
        selectedItemsIds,
        rolePermissions,
        permissionsOnDeselect,
      );
    }

    return {
      ...state,
      items: newItems?.length < state.items.length ? newItems : state.items,
      selectedItems: selectedItemsIds,
      selectedAssetsAggregatedData: { ...aggregatedData },
      allowedActions,
      total: newItems?.length <= state.items.length ? newItems.length : state.total,
      lastClicked: value ? id : null,
    };
  }

  /** Select by match */
  case TYPES.ASSETS.SELECT_BY_MATCH: {
    const {
      selectedAssetsData,
      value,
      matchData,
    } = payload;

    return {
      ...state,
      selectedItemsToMatch: selectedAssetsData,
      lastClicked: value ? matchData : null,
    };
  }

  case TYPES.ASSETS.SELECT_ALL_BY_MATCH: {
    const {
      selectedAssetsData,
    } = payload;

    return {
      ...state,
      selectedItemsToMatch: selectedAssetsData,
    };
  }

  case TYPES.ASSETS.DESELECT_ALL_BY_MATCH: {
    return {
      ...state,
      selectedItemsToMatch: [],
      selectedAssetsAggregatedData: {},
      allowedActions: {},
      lastClicked: null,
    };
  }

  case TYPES.ASSETS.SELECT_MANY: {
    const { value, ids } = payload;
    return {
      ...state,
      selectedItems: helpers.selectMany(value, ids, state.selectedItems),
    };
  }

  /** Select all */
  case TYPES.ASSETS.SELECT_ALL.START: {
    return {
      ...state,
    };
  }
  case TYPES.ASSETS.SELECT_ALL.COMPLETE: {
    const {
      aggregatedData,
      selectedAssetsIds,
      rolePermissions,
      assetsBatchPermissions,
    } = payload;
    const allowedActions = helpers.getPermissions(
      state.items,
      selectedAssetsIds,
      rolePermissions,
      assetsBatchPermissions,
    );
    return {
      ...state,
      selectedItems: [...state.selectedItems, ...selectedAssetsIds],
      allowedActions,
      selectedAssetsAggregatedData: aggregatedData,
    };
  }
  case TYPES.ASSETS.SELECT_ALL.FAILED: {
    return {
      ...state,
      error,
    };
  }

  case TYPES.ASSETS.CREATE_COLLECTION.COMPLETE: {
    return {
      ...state,
      selectedAssetsAggregatedData: payload.aggregatedData,
    };
  }

  /** Deselect all */
  case TYPES.ASSETS.DESELECT_ALL: {
    const {
      routeIsSelectedAssets,
      selectedItems,
      aggregatedData,
      assetsBatchPermissions,
      rolePermissions,
    } = payload;

    if (selectedItems?.length) {
      const allowedActions = helpers.getPermissions(
        state.items,
        selectedItems,
        rolePermissions,
        assetsBatchPermissions,
      );
      return {
        ...state,
        items: routeIsSelectedAssets ? [] : state.items,
        selectedItems,
        selectedAssetsAggregatedData: aggregatedData,
        allowedActions,
        lastClicked: null,
      };
    }
    return {
      ...state,
      items: routeIsSelectedAssets ? [] : state.items,
      selectedItems: state.selectedItems.length > 0 ? [] : state.selectedItems, // don't update field if not changed
      selectedAssetsAggregatedData: {},
      allowedActions: {},
      lastClicked: null,
      total: routeIsSelectedAssets ? 0 : state.total,
    };
  }

  /** Reorder */
  case TYPES.ASSETS.REORDER: {
    return {
      ...state,
      items: payload.items,
    };
  }

  /** Add to collection */
  case TYPES.ASSETS.ADD_COLLECTION.START: {
    const { ids, isMove } = payload;

    return {
      ...state,
      items: isMove ? helpers.setField(
        state.items,
        ids,
        ['moving'],
        [CONSTANTS.ASYNC_JOB_STATUS_WAITING],
      ) : state.items,
      selectedItems: isMove
        ? []
        : state.selectedItems,
      selectedAssetsAggregatedData: isMove ? {} : state.selectedAssetsAggregatedData,
      inProgress: {
        ...state.inProgress,
        collections: true,
      },
    };
  }
  case TYPES.ASSETS.ADD_COLLECTION.COMPLETE: {
    const {
      ids,
      collectionID,
      collectionPath,
      isMove,
      isTeamDrive,
      isMoveIntoNestedCollection,
      activeCollectionID,
      notRecursiveSearch,
    } = payload;
    const data = {
      items: state.items,
      assetIDs: ids,
      collectionID,
      collectionPath,
      isMove,
      isTeamDrive,
      isMoveIntoNestedCollection,
      activeCollectionID,
      notRecursiveSearch,
    };

    let newTags = [];

    if (!helpers.tagExists(collectionPath, state.selectedAssetsAggregatedData.tags || []) && !isMove) { // if it is add and new tag
      newTags = state.selectedAssetsAggregatedData?.tags?.length
        ? [...state.selectedAssetsAggregatedData.tags, { _id: collectionID, path: collectionPath }]
        : [{ _id: collectionID, path: collectionPath }];
    } else if (isMove) {
      newTags = [{ _id: collectionID, path: collectionPath }];
    } else { // if its add and not a new tag
      newTags = state.selectedAssetsAggregatedData.tags;
    }

    const itemsAdded = helpers.addToCollection(data);
    const itemsMoved = helpers.setField(
      state.items,
      ids,
      ['moving', 'lightboards'],
      [CONSTANTS.ASYNC_JOB_STATUS_COMPLETE, []],
    );

    let { total } = state;
    if (((isTeamDrive || isMove) && !isMoveIntoNestedCollection) || (isMove && isMoveIntoNestedCollection && notRecursiveSearch)) {
      total = state.total - ids.length;
    }

    return {
      ...state,
      selectedItems:
          payload.isMove
            ? []
            : state.selectedItems,
      items: itemsAdded,
      total,
      full: total <= itemsAdded.length,
      inProgress: {
        ...state.inProgress,
        collections: false,
      },
      selectedAssetsAggregatedData:
        payload.isMove
          ? {} : {
            ...state.selectedAssetsAggregatedData,
            tags: newTags,
          }
      ,
    };
  }
  case TYPES.ASSETS.ADD_COLLECTION.FAILED: {
    const { ids, isMove } = payload;
    return {
      ...state,
      error,
      items: isMove ? helpers.setField(
        state.items,
        ids,
        ['moving'],
        [CONSTANTS.ASYNC_JOB_STATUS_FAILED],
      ) : state.items,
      inProgress: {
        ...state.inProgress,
        collections: false,
      },
    };
  }

  /** Remove from collection */
  case TYPES.ASSETS.REMOVE_COLLECTION.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        collections: true,
      },
    };
  }
  case TYPES.ASSETS.REMOVE_COLLECTION.COMPLETE: {
    const result = helpers.removeFromCollection(
      state.items,
      payload.collectionID,
      payload.selectedItems,
    );
    return {
      ...state,
      items: result.items,
      selectedItems: result.itemsRemoved > 0 ? [] : state.selectedItems,
      total: state.total - result.itemsRemoved,
      full: state.total - result.itemsRemoved !== 0 ? state.full : true,
      inProgress: {
        ...state.inProgress,
        collections: false,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        tags: state.selectedAssetsAggregatedData?.tags?.filter((tag) => tag._id !== payload.collectionID),
      },
    };
  }
  case TYPES.ASSETS.REMOVE_COLLECTION.FAILED: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        collections: false,
      },
      error,
    };
  }
  case TYPES.ASSETS.REMOVED_COLLECTION: {
    const result = helpers.removeFromCollection(
      state.items,
      payload.collectionID,
      payload.selectedIds,
      true,
    );
    return {
      ...state,
      items: result.items,
    };
  }

  /** Delete collection */
  case TYPES.ASSETS.DELETE_COLLECTION: {
    return {
      ...state,
      items: helpers.removeFromCollection(state.items, payload.id).items,
    };
  }

  /** Add to lightboard */
  case TYPES.ASSETS.ADD_LIGHTBOARD.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        lightboards: true,
      },
    };
  }
  case TYPES.ASSETS.ADD_LIGHTBOARD.COMPLETE: {
    const {
      assetIDs,
      lightboardID,
      lightboardPath,
      isMove,
      isTeamDrive,
      userId,
    } = payload;
    const data = {
      items: state.items,
      assetIDs,
      lightboardID,
      lightboardPath,
      isMove,
      isTeamDrive,
      userId,
    };
    const items = helpers.addToLightboard(data);
    const total = isMove ? state.total - assetIDs.length : state.total;
    return {
      ...state,
      items,
      selectedItems: payload.isMove ? [] : state.selectedItems,
      total,
      full: total <= items.length,
      inProgress: {
        ...state.inProgress,
        lightboards: false,
      },
    };
  }
  case TYPES.ASSETS.ADD_LIGHTBOARD.FAILED: {
    return {
      ...state,
      error,
      inProgress: {
        ...state.inProgress,
        lightboards: false,
      },
    };
  }

  /** Remove from lightboard */
  case TYPES.ASSETS.REMOVE_LIGHTBOARD.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        lightboards: true,
      },
    };
  }
  case TYPES.ASSETS.REMOVE_LIGHTBOARD.COMPLETE: {
    const { items, itemsRemoved } = helpers.removeFromLightboard(
      state.items,
      payload.ids,
      payload.lightboardId,
    );
    const total = state.total - itemsRemoved;
    return {
      ...state,
      items,
      selectedItems: state.selectedItems.filter((id) => items.find((asset) => asset._id === id)),
      total,
      full: total <= items.length,
      inProgress: {
        ...state.inProgress,
        lightboards: false,
      },
    };
  }
  case TYPES.ASSETS.REMOVE_LIGHTBOARD.FAILED: {
    return {
      ...state,
      error,
      inProgress: {
        ...state.inProgress,
        lightboards: false,
      },
    };
  }

  /** Add to product */
  case addAssets.fulfilled.type: {
    const { assetIds } = payload;

    return {
      ...state,
      items: state.items.map((asset) => {
        if (assetIds.includes(asset._id)) {
          return { ...asset, productAttaching: 'waiting' };
        }
        return asset;
      }),
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        productAttaching: assetIds.some((id) => state.selectedItems.includes(id)) ? 'waiting' : null,
      },
    };
  }

  case addProduct.type: {
    const { assetId, product, productAttaching } = payload;
    const data = {
      ...state,
      items: state.items.map((asset) => {
        if (assetId === asset._id) {
          const products = asset.products || [];
          if (!products.some((p) => p._id === product._id)) products.push(product);
          return { ...asset, products: [...products] };
        }
        return asset;
      }),

    };

    if (state.selectedItems.length !== 0) {
      data.selectedAssetsAggregatedData = {
        ...state.selectedAssetsAggregatedData,
        products: state.selectedItems.includes(assetId)
          ? [...(state.selectedAssetsAggregatedData.products || []).filter(({ _id }) => _id !== product._id), product]
          : state.selectedAssetsAggregatedData.products,
        productAttaching,
      };
    }
    return data;
  }

  case attachToProducts.fulfilled.type: {
    return {
      ...state,
      items: [],
      selectedItems: [],
      selectedItemsToMatch: [],
      selectedAssetsAggregatedData: {},
      total: 0,
      full: true,
    };
  }

  /** Remove from product */
  case removeAssets.pending.type: {
    return {
      ...state,
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        productDetaching: true,
      },
    };
  }

  case removeAssets.fulfilled.type: {
    const { product } = action.meta.arg;
    const ids = payload;
    if (payload.length) {
      return {
        ...state,
        items: state.items.map((asset) => {
          if (ids.includes(asset._id)) {
            const products = (asset.products || []).filter(({ _id }) => _id !== product._id);
            return { ...asset, products };
          }
          return asset;
        }),
        selectedAssetsAggregatedData: {
          ...state.selectedAssetsAggregatedData,
          products: ids.some((id) => state.selectedItems.includes(id))
            ? (state.selectedAssetsAggregatedData.products || []).filter(({ _id }) => _id !== product._id)
            : state.selectedAssetsAggregatedData.products,
          productDetaching: false,
        },
      };
    }
    return {
      ...state,
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        productDetaching: false,
      },
    };
  }

  case removeAssets.rejected.type: {
    const { product, ids } = action.meta.arg;
    return {
      ...state,
      items: state.items.map((asset) => {
        if (ids.includes(asset._id)) {
          /** @type {Array} */
          const products = asset.products || [];
          if (!products.some((p) => p._id === product._id)) products.push(product);
          return { ...asset, products };
        }
        return asset;
      }),
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        products: ids.some((id) => state.selectedItems.includes(id))
          ? [...(state.selectedAssetsAggregatedData.products || []).filter(({ _id }) => _id !== product._id), product]
          : state.selectedAssetsAggregatedData.products,
      },
    };
  }

  /** Add keyword */
  case TYPES.ASSETS.ATTACH_KEYWORD.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        keywords: true,
      },
    };
  }
  case TYPES.ASSETS.ATTACH_KEYWORD.COMPLETE: {
    if (state.selectedAssetsAggregatedData.keywords) {
      return {
        ...state,
        items: helpers.attachKeyword(
          state.items,
          payload.keyword,
          payload.ids,
          payload.notify,
          payload.userId,
        ),
        inProgress: {
          ...state.inProgress,
          keywords: false,
        },
        selectedAssetsAggregatedData: {
          ...state.selectedAssetsAggregatedData,
          keywords: helpers.updateKeywordsAggregatedData(
            state.selectedAssetsAggregatedData.keywords,
            [payload.keyword],
            payload.ids.length,
          ),
          modifiedMetaFields: {
            ...state.selectedAssetsAggregatedData.modifiedMetaFields,
            Keywords: helpers.setModifiedMetaField('Keywords', payload.userId, null),
          },
        },
      };
    }
    return {
      ...state,
      items: helpers.attachKeyword(
        state.items,
        payload.keyword,
        payload.ids,
        payload.notify,
        payload.userId,
      ),
      inProgress: {
        ...state.inProgress,
        keywords: false,
      },
    };
  }
  case TYPES.ASSETS.ATTACH_KEYWORD.FAILED: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        keywords: false,
      },
      error,
    };
  }

  /** Remove keyword */
  case TYPES.ASSETS.DETACH_KEYWORD.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        keywords: true,
      },
    };
  }
  case TYPES.ASSETS.DETACH_KEYWORD.COMPLETE: {
    if (state.selectedAssetsAggregatedData.keywords) {
      return {
        ...state,
        items: helpers.detachKeywords(
          state.items,
          payload.keywordsIds,
          payload.ids,
          payload.notify,
          payload.userId,
        ),
        inProgress: {
          ...state.inProgress,
          keywords: false,
        },
        selectedAssetsAggregatedData: {
          ...state.selectedAssetsAggregatedData,
          keywords: state.selectedAssetsAggregatedData.keywords.filter(
            ({ _id }) => !payload.keywordsIds.includes(_id),
          ),
          modifiedMetaFields: {
            ...state.selectedAssetsAggregatedData.modifiedMetaFields,
            Keywords: helpers.setModifiedMetaField('Keywords', payload.userId, null),
          },
        },
      };
    }
    return {
      ...state,
      items: helpers.detachKeywords(
        state.items,
        payload.keywordsIds,
        payload.ids,
        payload.notify,
        payload.userId,
      ),
      inProgress: {
        ...state.inProgress,
        keywords: false,
      },
    };
  }
  case TYPES.ASSETS.DETACH_KEYWORD.FAILED: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        keywords: false,
      },
      error,
    };
  }

  /** Rename keyword */
  case TYPES.ASSETS.RENAME_KEYWORD: {
    return {
      ...state,
      items: helpers.renameKeyword(state.items, payload.id, payload.newName),
    };
  }

  /** Merge keywords */
  case TYPES.ASSETS.MERGE_KEYWORDS: {
    return {
      ...state,
      items: helpers.mergeKeywords(
        state.items,
        payload.keywordsIds,
        payload.targetKeyword,
        payload.ids,
        payload.notify,
        payload.userId,
      ),
      inProgress: {
        ...state.inProgress,
        keywords: false,
      },
    };
  }

  /** Update lightboard */
  case TYPES.ASSETS.UPDATE_LIGHTBOARD: {
    return {
      ...state,
      items: helpers.updateLightboard(state.items, payload.id, payload.data),
    };
  }

  /** Delete lightboard */
  case TYPES.ASSETS.DELETE_LIGHTBOARD: {
    return {
      ...state,
      items: helpers.deleteLightboard(state.items, payload.id),
    };
  }

  /** Rename collection */
  case TYPES.ASSETS.RENAME_COLLECTION: {
    return {
      ...state,
      items: helpers.renameCollection(
        state.items,
        payload.id,
        payload.newName,
      ),
    };
  }

  /** Change collection path */
  case TYPES.ASSETS.CHANGE_PATH: {
    return {
      ...state,
      items: helpers.changePath(
        state.items,
        payload.oldPath,
        payload.newPath,
        payload.collectionName,
      ),
    };
  }

  /** Remove all keywords */
  case TYPES.ASSETS.REMOVE_ALL_KEYWORDS: {
    return {
      ...state,
      items: helpers.removeAllKeywords(state.items),
    };
  }

  /** Generate Faces */
  case TYPES.ASSETS.GENERATE_FACES.COMPLETE: {
    return {
      ...state,
      items: helpers.setFaces(state.items, payload.facedAssets),
      inProgress: {
        ...state.inProgress,
        keywords: false,
      },
    };
  }

  /** Generate keywords */
  case TYPES.ASSETS.GENERATE_KEYWORDS.START: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        payload.assetsIds,
        ['keywording'],
        ['running'],
      ),
      inProgress: {
        ...state.inProgress,
        keywords: true,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        keywording: 'running',
      },
    };
  }
  case TYPES.ASSETS.GENERATE_KEYWORDS.COMPLETE: {
    return {
      ...state,
      items: helpers.setKeywords(state.items, payload.keywordedAssets),
      inProgress: {
        ...state.inProgress,
        keywords: false,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        keywording: 'complete',
        keywords: helpers.setKeywordsToAggData(state.selectedAssetsAggregatedData.keywords, payload.keywordedAssets, state.selectedItems),
      },
    };
  }
  case TYPES.ASSETS.GENERATE_KEYWORDS.FAILED: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        payload.assetsIds,
        ['keywording'],
        ['failed'],
      ),
      inProgress: {
        ...state.inProgress,
        keywords: false,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        keywording: 'failed',
      },
      error,
    };
  }

  /** Assign user */
  case TYPES.ASSETS.ASSIGN_USER.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        assignees: true,
      },
    };
  }
  case TYPES.ASSETS.ASSIGN_USER.COMPLETE: {
    return {
      ...state,
      items: helpers.assignUser(
        state.items,
        payload.assigneeId,
        payload.ids,
        payload.notify,
      ),
      inProgress: {
        ...state.inProgress,
        assignees: false,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        assignees: state.selectedAssetsAggregatedData?.assignees?.length
          ? [...state.selectedAssetsAggregatedData.assignees, { _id: payload.assigneeId }]
          : [{ _id: payload.assigneeId }],
      },
    };
  }
  case TYPES.ASSETS.ASSIGN_USER.FAILED: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        assignees: false,
      },
      error,
    };
  }

  /** Unassign user */
  case TYPES.ASSETS.UNASSIGN_USER.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        assignees: true,
      },
    };
  }
  case TYPES.ASSETS.UNASSIGN_USER.COMPLETE: {
    return {
      ...state,
      items: helpers.unAssignUser(
        state.items,
        payload.assigneeId,
        payload.ids,
        payload.notify,
      ),
      inProgress: {
        ...state.inProgress,
        assignees: false,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        assignees: state.selectedAssetsAggregatedData.assignees.filter((assignee) => assignee._id !== payload.assigneeId),
      },
    };
  }
  case TYPES.ASSETS.UNASSIGN_USER.FAILED: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        assignees: false,
      },
      error,
    };
  }

  /** Change flag */
  case TYPES.ASSETS.CHANGE_FLAG.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        marks: true,
      },
    };
  }
  case TYPES.ASSETS.CHANGE_FLAG.COMPLETE: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        payload.ids,
        payload.keys,
        payload.values,
        payload.eventType,
        payload.userId,
      ),
      inProgress: {
        ...state.inProgress,
        marks: false,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        flag: payload.values[0],
        modifiedMetaFields: {
          ...state.selectedAssetsAggregatedData.modifiedMetaFields,
          Flag: helpers.setModifiedMetaField('Flag', payload.userId, payload.values[0]),
        },
      },
    };
  }
  case TYPES.ASSETS.CHANGE_FLAG.FAILED: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        marks: false,
      },
      error,
    };
  }

  /** Change stars */
  case TYPES.ASSETS.CHANGE_STARS.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        marks: true,
      },
    };
  }
  case TYPES.ASSETS.CHANGE_STARS.COMPLETE: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        payload.ids,
        payload.keys,
        payload.values,
        payload.eventType,
        payload.userId,
      ),
      inProgress: {
        ...state.inProgress,
        marks: false,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        rating: payload.values[0],
        modifiedMetaFields: {
          ...state.selectedAssetsAggregatedData.modifiedMetaFields,
          Rating: helpers.setModifiedMetaField('Rating', payload.userId, payload.values[0]),
        },
      },
    };
  }
  case TYPES.ASSETS.CHANGE_STARS.FAILED: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        marks: false,
      },
      error,
    };
  }

  /** Change color */
  case TYPES.ASSETS.CHANGE_COLOR.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        marks: true,
      },
    };
  }
  case TYPES.ASSETS.CHANGE_COLOR.COMPLETE: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        payload.ids,
        payload.keys,
        payload.values,
        payload.eventType,
        payload.userId,
      ),
      inProgress: {
        ...state.inProgress,
        marks: false,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        color: payload.values[0],
        modifiedMetaFields: {
          ...state.selectedAssetsAggregatedData.modifiedMetaFields,
          Color: helpers.setModifiedMetaField('Color', payload.userId, payload.values[0]),
        },
      },
    };
  }
  case TYPES.ASSETS.CHANGE_COLOR.FAILED: {
    return {
      ...state,
      error,
      inProgress: {
        ...state.inProgress,
        marks: false,
      },
    };
  }

  /** Reset highlight */
  case TYPES.ASSETS.RESET_HIGHLIGHT: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        payload.ids,
        payload.keys,
        payload.values,
      ),
    };
  }

  /** Rename */
  case TYPES.ASSETS.RENAME.START: {
    return { ...state, inProgress: { ...state.inProgress, title: true } };
  }
  case TYPES.ASSETS.RENAME.COMPLETE: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        [payload.id],
        ['name'],
        [payload.name],
      ),
      inProgress: {
        ...state.inProgress,
        title: false,
      },
    };
  }
  case TYPES.ASSETS.RENAME.FAILED: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        title: false,
      },
      error,
    };
  }

  /** Change title */
  case TYPES.ASSETS.CHANGE_TITLE.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        title: true,
      },
    };
  }
  case TYPES.ASSETS.CHANGE_TITLE.COMPLETE: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        payload.ids,
        ['title'],
        [payload.title],
        null,
        payload.userId,
      ),
      inProgress: {
        ...state.inProgress,
        title: false,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        title: payload.title,
        modifiedMetaFields: {
          ...state.selectedAssetsAggregatedData.modifiedMetaFields,
          Title: helpers.setModifiedMetaField('Title', payload.userId, payload.title),
        },
      },
    };
  }
  case TYPES.ASSETS.CHANGE_TITLE.FAILED: {
    return {
      ...state,
      error,
      inProgress: {
        ...state.inProgress,
        title: false,
      },
    };
  }

  /** Change description */
  case TYPES.ASSETS.CHANGE_DESCRIPTION.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        description: true,
      },
    };
  }
  case TYPES.ASSETS.CHANGE_DESCRIPTION.COMPLETE: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        payload.ids,
        ['description'],
        [payload.description],
        null,
        payload.userId,
      ),
      inProgress: {
        ...state.inProgress,
        description: false,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        description: payload.description,
        modifiedMetaFields: {
          ...state.selectedAssetsAggregatedData.modifiedMetaFields,
          Description: helpers.setModifiedMetaField('Description', payload.userId, payload.description),
        },
      },
    };
  }
  case TYPES.ASSETS.CHANGE_DESCRIPTION.FAILED: {
    return {
      ...state,
      error,
      inProgress: {
        ...state.inProgress,
        description: false,
      },
    };
  }

  /** Change custom field */
  case TYPES.ASSETS.CHANGE_CUSTOM_FIELD.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        customField: true,
        customFields: [
          ...state.inProgress.customFields,
          payload.title,
        ],
      },
    };
  }
  case TYPES.ASSETS.CHANGE_CUSTOM_FIELD.COMPLETE: {
    const {
      ids, title, value, userId,
    } = payload;
    const { selectedAssetsAggregatedData } = state;
    return {
      ...state,
      items: helpers.changeCustomField(
        state.items,
        ids,
        title,
        value,
        userId,
      ),
      inProgress: {
        ...state.inProgress,
        customField: false,
        customFields: state.inProgress.customFields.filter(
          (item) => item !== title,
        ),
      },
      /** change aggregated data only if all changed assets selected */
      selectedAssetsAggregatedData: state.selectedItems.every((id) => ids.includes(id))
        ? {
          ...selectedAssetsAggregatedData,
          meta: {
            ...(selectedAssetsAggregatedData.meta || {}),
            [title]: value,
          },
          modifiedMetaFields: {
            ...selectedAssetsAggregatedData.modifiedMetaFields,
            [title]: helpers.setModifiedMetaField(title, userId, value),
          },
        }
        : selectedAssetsAggregatedData,
    };
  }
  case TYPES.ASSETS.CHANGE_CUSTOM_FIELD.FAILED: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        customField: false,
        customFields: state.inProgress.customFields.filter(
          (item) => item !== payload.title,
        ),
      },
      error,
    };
  }

  case TYPES.ASSETS.CHANGE_MULTIPLE_CUSTOM_FIELD.ATTACH: {
    const {
      ids, title, value, userId,
    } = payload;
    const { selectedAssetsAggregatedData } = state;
    const oldAggregatedValue = (selectedAssetsAggregatedData?.meta || {})[title] || '';
    return {
      ...state,
      items: helpers.changeMultipleCustomField(
        state.items,
        ids,
        title,
        value,
        userId,
        true,
      ),
      inProgress: {
        ...state.inProgress,
        customField: false,
        customFields: state.inProgress.customFields.filter(
          (item) => item !== title,
        ),
      },
      /** change aggregated data only if all changed assets selected */
      selectedAssetsAggregatedData: state.selectedItems.every((id) => ids.includes(id))
        ? {
          ...selectedAssetsAggregatedData,
          meta: {
            ...(selectedAssetsAggregatedData.meta || {}),
            [title]: oldAggregatedValue.length ? `${oldAggregatedValue},${value}` : value,
          },
        }
        : selectedAssetsAggregatedData,
    };
  }
  case TYPES.ASSETS.CHANGE_MULTIPLE_CUSTOM_FIELD.DETACH: {
    const {
      ids, title, value, userId,
    } = payload;
    const { selectedAssetsAggregatedData } = state;
    const oldAggregatedValue = (selectedAssetsAggregatedData?.meta || {})[title] || '';

    return {
      ...state,
      items: helpers.changeMultipleCustomField(
        state.items,
        ids,
        title,
        value,
        userId,
        false,
      ),
      inProgress: {
        ...state.inProgress,
        customField: false,
        customFields: state.inProgress.customFields.filter(
          (item) => item !== title,
        ),
      },
      /** change aggregated data only if all changed assets selected */
      selectedAssetsAggregatedData: state.selectedItems.every((id) => ids.includes(id))
        ? {
          ...selectedAssetsAggregatedData,
          meta: {
            ...(selectedAssetsAggregatedData.meta || {}),
            [title]: oldAggregatedValue.length
              ? oldAggregatedValue.split(',').filter((v) => v !== value).join(',')
              : selectedAssetsAggregatedData.meta,
          },
        }
        : selectedAssetsAggregatedData,
    };
  }

  /** Change trashed */
  case TYPES.ASSETS.CHANGE_TRASHED: {
    const { id, moveToTrash } = payload;
    return {
      ...state,
      items: state.items,
      selectedItems: moveToTrash
        ? state.selectedItems.filter((assetID) => assetID !== id)
        : state.selectedItems,
    };
  }

  case TYPES.ASSETS.SET_USER_ORIENTATION.START: {
    const { ids, value } = payload;
    const { rotation } = value;
    return {
      ...state,
      items: state.items.map((item) => {
        if (ids.includes(item._id)) {
          const editedItem = { ...item, userOrientation: value };
          if (
            rotation !== item.userOrientation.rotation
              && item.imageMediaMetadata
          ) {
            /** if rotation changed */
            editedItem.imageMediaMetadata = {
              rotation,
              height: item.imageMediaMetadata.width,
              width: item.imageMediaMetadata.height,
            };
          }
          return editedItem;
        }
        return item;
      }),
    };
  }

  case TYPES.ASSETS.SET_USER_ORIENTATION.COMPLETE: {
    return state;
  }

  case TYPES.ASSETS.SET_USER_ORIENTATION.FAILED: {
    return { ...state, error: payload.error };
  }

  /** set approvals data */
  case TYPES.ASSETS.UPDATE_APPROVE: {
    return {
      ...state,
      items: helpers.updateApprovals(
        state.items,
        payload.assetId,
        payload.data,
      ),
    };
  }

  /** Share/StopSharing asset */
  case TYPES.ASSETS.SHARE.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        share: true,
      },
    };
  }

  case TYPES.ASSETS.SHARE.COMPLETE: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        [payload.assetId],
        ['singleSharingSettings'],
        [payload.data],
      ),
      inProgress: {
        ...state.inProgress,
        share: false,
      },
    };
  }
  case TYPES.ASSETS.SHARE.FAILED: {
    return {
      ...state,
      error,
      inProgress: {
        ...state.inProgress,
        share: false,
      },
    };
  }

  /** Restrict assets */
  case TYPES.ASSETS.RESTRICT.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        restrict: true,
      },
    };
  }

  case TYPES.ASSETS.RESTRICT.COMPLETE: {
    const { ids, value } = payload;

    const restrictData = {};
    if ('isRestricted' in value) restrictData.isRestricted = value.isRestricted;
    if ('reason' in value) restrictData.restrictReason = value.reason;
    if ('startAt' in value) {
      restrictData.restrictStartAt = value.startAt;
      restrictData.restrictStartAtPlaceholder = value.startAt;
    }
    if ('expiresAt' in value) {
      restrictData.restrictExpiresAt = value.expiresAt;
      restrictData.restrictExpiresAtPlaceholder = value.expiresAt;
    }

    return {
      ...state,
      items: helpers.setField(
        state.items,
        ids,
        ['restrictSettings'],
        [value],
      ),
      inProgress: {
        ...state.inProgress,
        restrict: false,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        ...restrictData,
      },
    };
  }
  case TYPES.ASSETS.RESTRICT.FAILED: {
    return {
      ...state,
      error,
      inProgress: {
        ...state.inProgress,
        restrict: false,
      },
    };
  }

  /** Copy assets */
  case TYPES.ASSETS.COPY_ASSETS.START: {
    const { ids, fieldName = CONSTANTS.COPYING_STATUS_FIELD_NAME } = payload;
    return {
      ...state,
      selectedItems: state.selectedItems.filter((id) => !ids.includes(id)),
      items: helpers.setField(
        state.items,
        ids,
        [fieldName],
        [CONSTANTS.ASYNC_JOB_STATUS_WAITING],
      ),
    };
  }
  case TYPES.ASSETS.COPY_ASSETS.COMPLETE: {
    const { ids, queued } = payload;
    const countRemoved = state.items.filter((asset) => ids.includes(asset._id)).length;
    const total = !queued ? state.total - countRemoved : state.total;
    const items = !queued
      ? state.items.filter((asset) => !ids.includes(asset._id))
      : helpers.setField(
        state.items,
        ids,
        [CONSTANTS.COPYING_STATUS_FIELD_NAME],
        [CONSTANTS.ASYNC_JOB_STATUS_WAITING],
      );
    return {
      ...state,
      items,
      selectedItems: state.selectedItems.filter(
        (id) => !payload.ids.includes(id),
      ),
      geo: helpers.removeFromGeo(state.geo || [], ids),
      total,
      full: total <= items.length,
    };
  }
  case TYPES.ASSETS.COPY_ASSETS.FAILED: {
    const { ids } = payload;
    return {
      ...state,
      items: helpers.setField(
        state.items,
        ids,
        [CONSTANTS.COPYING_STATUS_FIELD_NAME],
        [CONSTANTS.ASYNC_JOB_STATUS_FAILED],
      ),
      error,
    };
  }

  /** Delete assets */
  case TYPES.ASSETS.DELETE_ASSETS.START: {
    const { ids, fieldName = CONSTANTS.TRASHING_STATUS_FIELD_NAME } = payload;
    return {
      ...state,
      selectedItems: state.selectedItems.filter((id) => !ids.includes(id)),
      items: helpers.setField(
        state.items,
        ids,
        [fieldName],
        [CONSTANTS.ASYNC_JOB_STATUS_WAITING],
      ),
    };
  }
  case TYPES.ASSETS.DELETE_ASSETS.COMPLETE: {
    const { ids, queued } = payload;
    const countRemoved = state.items.filter((asset) => ids.includes(asset._id)).length;
    const total = !queued ? state.total - countRemoved : state.total;
    const items = !queued
      ? state.items.filter((asset) => !ids.includes(asset._id))
      : helpers.setField(
        state.items,
        ids,
        [CONSTANTS.TRASHING_STATUS_FIELD_NAME],
        [CONSTANTS.ASYNC_JOB_STATUS_WAITING],
      );
    return {
      ...state,
      items,
      selectedItems: state.selectedItems.filter(
        (id) => !payload.ids.includes(id),
      ),
      geo: helpers.removeFromGeo(state.geo || [], ids),
      total,
      full: total <= items.length,
    };
  }
  case TYPES.ASSETS.DELETE_ASSETS.FAILED: {
    const { ids } = payload;
    return {
      ...state,
      items: helpers.setField(
        state.items,
        ids,
        [CONSTANTS.TRASHING_STATUS_FIELD_NAME],
        [CONSTANTS.ASYNC_JOB_STATUS_FAILED],
      ),
      error,
    };
  }

  /** Restore assets */
  case TYPES.ASSETS.RESTORE_ASSETS.START: {
    const { ids } = payload;
    return {
      ...state,
      selectedItems: state.selectedItems.filter((id) => !ids.includes(id)),
      items: helpers.setField(
        state.items,
        ids,
        [CONSTANTS.UNTRASHING_STATUS_FIELD_NAME],
        [CONSTANTS.ASYNC_JOB_STATUS_WAITING],
      ),
    };
  }
  case TYPES.ASSETS.RESTORE_ASSETS.COMPLETE: {
    const { ids } = payload;
    const items = state.items.filter((asset) => !ids.includes(asset._id));
    const total = state.total - ids.length;
    return {
      ...state,
      items,
      selectedItems: state.selectedItems.filter(
        (id) => !payload.ids.includes(id),
      ),
      geo: helpers.removeFromGeo(state.geo || [], ids),
      total,
      full: total <= items.length,
    };
  }
  case TYPES.ASSETS.RESTORE_ASSETS.FAILED: {
    const { ids } = payload;
    return {
      ...state,
      items: helpers.setField(
        state.items,
        ids,
        [CONSTANTS.UNTRASHING_STATUS_FIELD_NAME],
        [CONSTANTS.ASYNC_JOB_STATUS_FAILED],
      ),
      error,
    };
  }

  /** Moving assets */
  case TYPES.ASSETS.MOVING_ASSETS.START: {
    const { ids } = payload;
    return {
      ...state,
      items: helpers.setField(
        state.items,
        ids,
        ['moving'],
        [CONSTANTS.ASYNC_JOB_STATUS_WAITING],
      ),
    };
  }
  case TYPES.ASSETS.MOVING_ASSETS.COMPLETE: {
    const { ids } = payload;
    return {
      ...state,
      items: helpers.setField(
        state.items,
        ids,
        ['moving'],
        [CONSTANTS.ASYNC_JOB_STATUS_COMPLETE],
      ),
    };
  }
  case TYPES.ASSETS.MOVING_ASSETS.FAILED: {
    const { ids } = payload;
    return {
      ...state,
      items: helpers.setField(
        state.items,
        ids,
        ['moving'],
        [CONSTANTS.ASYNC_JOB_STATUS_FAILED],
      ),
    };
  }

  /** Add revision */
  case TYPES.ASSETS.ADD_REVISION.COMPLETE: {
    const {
      assetId, headRevisionId, thumbnailLink, imageMediaMetadata, userId,
    } = payload;
    return {
      ...state,
      items: helpers.addRevision(
        state.items,
        assetId,
        headRevisionId,
        imageMediaMetadata,
        thumbnailLink,
        userId,
      ),
    };
  }

  /** Change upload revision progress */
  case TYPES.ASSETS.CHANGE_UPLOAD_REVISION_PROGRESS: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        [payload.assetID],
        ['uploadRevisionProgress'],
        [payload.value],
      ),
    };
  }

  /** Update asset field */
  case TYPES.ASSETS.UPDATE_FIELDS_ONE_ASSET: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        [payload.assetID],
        payload.fields,
        payload.values,
      ),
    };
  }

  /** Update many assets fields */
  case TYPES.ASSETS.UPDATE_FIELDS_MANY_ASSETS: {
    return {
      ...state,
      items: helpers.setFieldsForManyAssets(state.items, payload.assets, payload.keywordsStrategy),
    };
  }

  case TYPES.ASSETS.REVERT_REVISION: {
    const {
      assetId, revisionIdToRevert, newRevisionId, userId,
    } = payload;
    return {
      ...state,
      items: helpers.revertRevision(
        state.items,
        assetId,
        revisionIdToRevert,
        newRevisionId,
        userId,
      ),
    };
  }

  /** Remove meta field modified by user from DB */
  case TYPES.ASSETS.REMOVE_MODIFIED_FIELD.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        modifiedFields: [
          ...state.inProgress.modifiedFields,
          payload.fieldName,
        ],
      },
    };
  }
  case TYPES.ASSETS.REMOVE_MODIFIED_FIELD.COMPLETE: {
    const nextModifiedMetaFields = { ...state.selectedAssetsAggregatedData.modifiedMetaFields };
    delete nextModifiedMetaFields[payload.fieldName];
    return {
      ...state,
      items: helpers.removeModifiedField(
        state.items,
        payload.ids,
        payload.fieldName,
      ),
      inProgress: {
        ...state.inProgress,
        modifiedFields: state.inProgress.modifiedFields.filter(
          (item) => item !== payload.fieldName,
        ),
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        modifiedMetaFields: nextModifiedMetaFields,
      },
    };
  }
  case TYPES.ASSETS.REMOVE_MODIFIED_FIELD.FAILED: {
    return {
      ...state,
      error,
      inProgress: {
        ...state.inProgress,
        modifiedFields: state.inProgress.modifiedFields.filter(
          (item) => item !== payload?.fieldName,
        ),
      },
    };
  }

  /** Get assets pages */
  case TYPES.ASSETS.FETCH_PAGES.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        pages: true,
      },
    };
  }
  case TYPES.ASSETS.FETCH_PAGES.COMPLETE: {
    let items = [...state.items];
    items = items.map((asset) => {
      if (payload.pages[asset._id]) {
        const updatedAsset = { ...asset };
        if (payload.revisionId) {
          updatedAsset.pages[payload.revisionId] = payload.pages[asset._id];
        } else {
          updatedAsset.pages.head = payload.pages[asset._id];
          updatedAsset.pages.expiresAt = payload.pages[asset._id][0].expiresAt;
        }

        return updatedAsset;
      }

      return asset;
    });
    return {
      ...state,
      items: [...helpers.extendAssets(items)],
      inProgress: {
        ...state.inProgress,
        pages: false,
      },
    };
  }
  case TYPES.ASSETS.FETCH_PAGES.FAILED: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        pages: false,
      },
      error,
    };
  }

  /** Get asset revisions thumbnails */
  case TYPES.ASSETS.FETCH_REVISIONS_THUMBNAILS.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        revisionsThumbnails: true,
      },
    };
  }
  case TYPES.ASSETS.FETCH_REVISIONS_THUMBNAILS.COMPLETE: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        [payload.id],
        ['revisionsThumbnails'],
        [payload.revisionsThumbnails],
      ),
      inProgress: {
        ...state.inProgress,
        revisionsThumbnails: false,
      },
    };
  }
  case TYPES.ASSETS.FETCH_REVISIONS_THUMBNAILS.FAILED: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        revisionsThumbnails: false,
      },
      error,
    };
  }

  case 'archive/archiveAssets/fulfilled': {
    const { ids, reason, hide } = payload;

    if (!hide) {
      return {
        ...state,
        items: state.items.filter(({ _id }) => !ids.includes(_id)),
        selectedItems: [],
      };
    }
    return {
      ...state,
      items: state.items.map((item) => {
        const { _id } = item;

        if (ids.includes(_id)) {
          return { ...item, archived: true, archivedByReason: reason };
        }
        return item;
      }),
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        isArchived: true,
      },
    };
  }

  case TYPES.COLLECTIONS.ARCHIVE.COMPLETE: {
    return {
      ...state,
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        isArchived: payload.isArchived,
      },
    };
  }

  case 'archive/unarchiveAssets/fulfilled': {
    const { ids, hide } = payload;

    if (hide) {
      return {
        ...state,
        items: state.items.filter(({ _id }) => !ids.includes(_id)),
        selectedItems: [],
      };
    }
    return {
      ...state,
      items: state.items.map((item) => {
        const { _id } = item;

        if (ids.includes(_id)) {
          return { ...item, archived: false };
        }
        return item;
      }),
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        isArchived: false,
      },
    };
  }

  /** Remove meta field modified by user from DB */
  case TYPES.ASSETS.RERUN_PARSING.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        processing: true,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        crawling: 'running',
      },
    };
  }
  case TYPES.ASSETS.RERUN_PARSING.COMPLETE: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        payload.ids,
        [payload.jobName],
        [CONSTANTS.ASYNC_JOB_STATUS_WAITING],
      ),
      inProgress: {
        ...state.inProgress,
        processing: false,
      },
    };
  }
  case TYPES.ASSETS.RERUN_PARSING.FAILED: {
    return {
      ...state,
      error,
      inProgress: {
        ...state.inProgress,
        processing: false,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        crawling: 'failed',
      },
    };
  }

  case TYPES.ASSETS.FETCH_WATERMARKS: {
    return {
      ...state,
      watermarks: payload.watermarks,
    };
  }

  case TYPES.ASSETS.DELETE_WATERMARKS: {
    return {
      ...state,
      watermarks: state.watermarks.filter((item) => item._id !== payload.watermarkId),
      items: helpers.setField(
        state.items,
        state.items.filter(
          (item) => item.watermarkId === payload.watermarkId,
        )
          .map((item) => item._id),
        ['watermarkId'],
        [null],
      ),
    };
  }

  case TYPES.ASSETS.CHANGE_WATERMARK: {
    return {
      ...state,
      watermarks: helpers.setField(
        state.watermarks,
        payload.watermarkId,
        payload.keys,
        payload.values,
      ),
      items: helpers.setField(
        state.items,
        payload.itemIds,
        ['watermarkChanged'],
        ['true'],
      ),
    };
  }

  case TYPES.ASSETS.SET_DEFAULT_WATERMARK: {
    return {
      ...state,
      watermarks: state.watermarks.map((watermark) => {
        const { _id } = watermark;
        if (payload.watermarkId === _id) {
          return { ...watermark, isDefault: true };
        }
        return { ...watermark, isDefault: false };
      }),
    };
  }

  case TYPES.ASSETS.ATTACH_WATERMARK.START: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        watermarking: true,
      },
    };
  }

  case TYPES.ASSETS.ATTACH_WATERMARK.COMPLETE: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        payload.ids,
        ['watermarkId'],
        [payload.watermarkId],
      ),
      inProgress: {
        ...state.inProgress,
        watermarking: false,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        watermarkId: payload.watermarkId,
      },
    };
  }

  case TYPES.ASSETS.ATTACH_WATERMARK.FAIL: {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        watermarking: false,
      },
      error,
    };
  }

  case TYPES.ASSETS.ATTACH_COMMENT: {
    return {
      ...state,
      items: state.items.map((item) => {
        const { _id } = item;

        if (_id === payload.assetId) {
          const comments = item.comments ? [...item.comments, payload.comment] : [payload.comment];
          return { ...item, comments };
        }
        return item;
      }),
    };
  }

  case TYPES.ASSETS.EDIT_COMMENT: {
    return {
      ...state,
      items: state.items.map((item) => {
        const { _id } = item;

        if (_id === payload.assetId) {
          const comments = item.comments.map((comment) => {
            if (comment._id === payload.commentId) {
              comment.mentions = payload.mentions;
              comment.text = payload.text;
              comment.updatedAt = new Date();
            }
            return comment;
          });
          return { ...item, comments };
        }
        return item;
      }),
    };
  }

  case 'asset/removeBoundingBox/pending': {
    return {
      ...state,
      // @TODO need add state for pending
    };
  }

  case 'asset/removeBoundingBox/fulfilled': {
    const { assetId, faceId, boundingBox } = payload;
    return {
      ...state,
      items: state.items.map((item) => {
        if (item._id === assetId) {
          return {
            ...item,
            faces: (item.faces || []).filter(
              (face) => (!(face._id === faceId && _isEqual(face.boundingBox, boundingBox))),
            ),
          };
        }
        return item;
      }),
    };
  }

  case 'asset/removeBoundingBox/rejected': {
    return {
      ...state,
      // @TODO need add state for rejected
    };
  }

  case 'asset/applyFace/pending': {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        assetFaceApplying: true,
      },
    };
  }

  case 'asset/applyFace/fulfilled': {
    const {
      name, boundingBox, assetId, revisionId, newFaceId, isNewFace, _id, unnamed,
    } = payload;
    if (isNewFace) {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item._id === assetId) {
            return {
              ...item,
              faces: (item.faces || []).map((face) => {
                if (_isEqual(face.boundingBox, boundingBox)) {
                  const newItem = {
                    ...face, name, revisionId, _id, unnamed,
                  };
                  return newItem;
                } return face;
              }),
            };
          }
          return item;
        }),
        inProgress: {
          ...state.inProgress,
          assetFaceApplying: false,
        },
      };
    }
    return {
      ...state,
      items: state.items.map((item) => {
        if (item._id === assetId) {
          return {
            ...item,
            faces: (item.faces || []).map((face) => {
              if (_isEqual(face.boundingBox, boundingBox)) {
                const newItem = {
                  ...face, name, revisionId, _id: newFaceId, unnamed,
                };
                return newItem;
              } return face;
            }),
          };
        }
        return item;
      }),
      inProgress: {
        ...state.inProgress,
        assetFaceApplying: false,
      },
    };
  }

  case 'asset/applyFace/rejected': {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        assetFaceApplying: false,
      },
    };
  }

  // in case when we are attaching existing person to new bb
  case 'asset/addCustomFace/pending': {
    const {
      name, boundingBox, assetId, faceId, revisionId, isNewFace, unnamed,
    } = action.meta.arg;
    if (!isNewFace) {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item._id === assetId) {
            if (item?.faces?.length > 0) {
              return {
                ...item,
                faces: [...item.faces, {
                  name, boundingBox, _id: faceId, revisionId, unnamed,
                }],
              };
            }
            return {
              ...item,
              faces: [{
                name, boundingBox, _id: faceId, revisionId, unnamed,
              }],
            };
          }
          return item;
        }),
        inProgress: {
          ...state.inProgress,
          assetFaceApplying: true,
        },
      };
    }
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        assetFaceApplying: true,
      },
    };
  }

  // in case when we are creating new person to new bb
  case 'asset/addCustomFace/fulfilled': {
    const {
      assetId, _id, name, revisionId, boundingBox, isNewFace, unnamed,
    } = payload;
    if (isNewFace) {
      return {
        ...state,
        items: state.items.map((item) => {
          if (item._id === assetId) {
            if (item?.faces?.length > 0) {
              return {
                ...item,
                faces: [...item.faces, {
                  name, boundingBox, _id, revisionId, unnamed,
                }],
              };
            }
            return {
              ...item,
              faces: [{
                name, boundingBox, _id, revisionId, unnamed,
              }],
            };
          }
          return item;
        }),
        inProgress: {
          ...state.inProgress,
          assetFaceApplying: false,
        },
      };
    }
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        assetFaceApplying: false,
      },
    };
  }

  case 'faces/addFaces/pending': {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        assetFaceApplying: true,
      },
    };
  }

  case 'faces/addFaces/fulfilled': {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        assetFaceApplying: false,
      },
    };
  }

  case 'faces/addFaces/rejected': {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        assetFaceApplying: false,
      },
    };
  }

  case 'asset/addCustomFace/rejected': {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        assetFaceApplying: false,
      },
    };
  }

  case 'faces/recognize/pending': {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        action.meta.arg,
        ['faceRecognizing'],
        ['running'],
      ),
      inProgress: {
        ...state.inProgress,
        faceRecognition: true,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        faceRecognizing: 'running',
      },
    };
  }

  case 'faces/recognize/fulfilled': {
    if (payload) {
      const { faces, assetId } = payload;
      return {
        ...state,
        inProgress: {
          ...state.inProgress,
          faceRecognition: false,
        },
        items: state.items.map((item) => {
          if (item._id === assetId) {
            return {
              ...item,
              faces: item?.faces?.length > 0
                ? [...item.faces, ...faces.facesToAttach]
                : faces.facesToAttach,
              faceRecognizing: 'complete',
              faceIndexing: 'complete',
            };
          }
          return item;
        }),
        selectedAssetsAggregatedData: {
          ...state.selectedAssetsAggregatedData,
          faces: (state.selectedAssetsAggregatedData.faces || []).concat(faces.facesToAttach),
          faceRecognizing: 'complete',
        },
      };
    } return {
      ...state,
      inProgress: {
        ...state.inProgress,
        faceRecognition: false,
      },
    };
  }

  case 'faces/recognize/rejected': {
    const { meta } = action;
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        faceRecognition: false,
      },
      items: state.items.map((item) => {
        let tempItem = { ...item };
        meta?.arg?.forEach((assetId) => {
          if (assetId === item._id) {
            tempItem = {
              ...tempItem,
              faces: item?.faces?.length > 0,
              faceRecognizing: 'failed',
              faceRecognizingReason: 'Face recongnizing failed',
              faceIndexing: 'failed',
            };
          }
        });
        return tempItem;
      }),
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        faceRecognizing: 'failed',
        faceRecognizingReason: 'Face recongnizing failed',
      },
    };
  }

  case 'asset/facesGenerated/fulfilled': {
    const { assets } = payload;
    return {
      ...state,
      items: state.items.map((item) => {
        for (let i = 0; i < assets.length; i++) {
          if (item._id === assets[i]._id) {
            return {
              ...item,
              faces: item?.faces?.length > 0 ? [...item.faces, ...assets[i].faces] : assets[i].faces,
              faceRecognizing: 'complete',
              faceIndexing: 'complete',
            };
          }
        }
        return item;
      }),
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        faceRecognizing: 'complete',
        faceIndexing: 'complete',
      },
    };
  }

  case TYPES.ASSETS.CRAWLING_EVENT: {
    const { assets } = payload;

    if (assets.length === 1) {
      const filledCrawledAsset = helpers.fillCrawledData(state.items, assets, payload.allKeywords);
      return {
        ...state,
        items: filledCrawledAsset,
        selectedAssetsAggregatedData: {
          ...state.selectedAssetsAggregatedData,
          keywords: filledCrawledAsset[0].keywords,
          title: filledCrawledAsset[0].title,
          description: filledCrawledAsset[0].description,
          crawling: 'complete',
        },
      };
    } return {
      ...state,
      items: helpers.fillCrawledData(state.items, payload.assets, payload.allKeywords),
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        crawling: 'complete',
      },
    };
  }

  case 'faces/rename/fulfilled': {
    const {
      id, name, description,
    } = payload;
    return {
      ...state,
      items: state.items.map((item) => {
        if (item?.faces?.length > 0) {
          return {
            ...item,
            faces: item.faces.map((face) => {
              if (face._id === id) {
                return {
                  ...face,
                  name,
                  description,
                  unnamed: false,
                };
              }
              return face;
            }),
          };
        }
        return item;
      }),
    };
  }

  case 'faces/remove/fulfilled': {
    const faceId = payload;
    return {
      ...state,
      items: state.items.map((item) => {
        if (item?.faces?.length > 0) {
          return {
            ...item,
            faces: item.faces.filter((face) => face._id !== faceId),
          };
        }
        return item;
      }),
    };
  }

  case 'faces/remove/rejected': {
    return {
      ...state,
    };
  }
  case mergeFaces.fulfilled: {
    const {
      id, name, description,
    } = payload;
    return {
      ...state,
      items: state.items.map((item) => {
        if (item?.faces?.length > 0) {
          return {
            ...item,
            faces: item.faces.map((face) => {
              if (face._id === id) {
                return {
                  ...face,
                  name,
                  description,
                  unnamed: false,
                };
              }
              return face;
            }),
          };
        }
        return item;
      }),
    };
  }

  case 'faces/deleteUnnamed/fulfilled': {
    const faces = payload;
    return {
      ...state,
      items: state.items.map((item) => {
        if (item?.faces?.length > 0) {
          return {
            ...item,
            faces: item.faces.filter((face) => !faces.includes(face._id)),
          };
        }
        return item;
      }),
    };
  }

  case 'asset/sendToTranscribe/pending': {
    const { assetIds } = action.meta.arg;

    return {
      ...state,
      items: state.items.map((item) => {
        if (assetIds.includes(item._id)) {
          return {
            ...item,
            transcribing: 'waiting',
          };
        }
        return item;
      }),
      inProgress: {
        ...state.inProgress,
        transcribing: true,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        transcribing: 'waiting',
      },
    };
  }

  case 'asset/sendToTranscribe/rejected': {
    const { assetIds } = action.meta.arg;

    return {
      ...state,
      items: state.items.map((item) => {
        if (assetIds.includes(item._id)) {
          return {
            ...item,
            transcribing: 'rejected',
          };
        }
        return item;
      }),
      inProgress: {
        ...state.inProgress,
        transcribing: false,
      },
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        transcribing: 'rejected',
      },
    };
  }

  case 'asset/editTranscript/fulfilled': {
    const {
      assetId, startTime, text, revisionId,
    } = payload;

    return {
      ...state,
      items: helpers.updateTranscriptLine(state.items, {
        assetId, startTime, text, revisionId,
      }),
    };
  }

  case 'asset/transcribingEvent/fulfilled': {
    const { data, initiator, type: eventType } = payload;
    let transcribing = 'waiting';
    const eventTypeNormalize = eventType.split('.').pop();

    if (eventTypeNormalize !== 'chunk_is_ready') {
      transcribing = eventTypeNormalize;
    }

    return {
      ...state,
      items: helpers.fillTranscribingData(state.items, data, eventType, initiator._id),
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        transcribing,
      },
    };
  }

  case 'asset/setTranscriptViewedByInitiator/fulfilled': {
    const { assetId, value } = payload;

    return {
      ...state,
      items: state.items.map((item) => {
        if (assetId === item._id) {
          return {
            ...item,
            transcriptViewedByInitiator: value,
          };
        }
        return item;
      }),
    };
  }

  case removeFromAllCollections.fulfilled.type: {
    const { assetId, isRootActive } = payload;

    /** if error or cancelled */
    if (!assetId) return state;

    return {
      ...state,
      items: isRootActive
        ? state.items.map((item) => {
          if (assetId === item._id) {
            return {
              ...item,
              tags: [],
            };
          }
          return item;
        })
        : state.items.filter(({ _id }) => _id !== assetId),
    };
  }

  case descriptionGenerationStarted.type: {
    const { assetsIds } = payload;
    return {
      ...state,
      items: helpers.setField(
        state.items,
        assetsIds,
        ['describing'],
        ['running'],
      ),
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        describing: 'running',
      },
    };
  }

  case descriptionGenerationFailed.type: {
    const { assetsIds } = payload;
    return {
      ...state,
      items: helpers.setField(
        state.items,
        assetsIds,
        ['describing'],
        ['failed'],
      ),
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        describing: _isEqual(assetsIds, state.selectedItems) ? 'failed' : state.selectedAssetsAggregatedData.describing,
      },
    };
  }

  case descriptionGenerated.fulfilled.type: {
    const { describedAssets } = payload;
    const { selectedItems } = state;
    const describedAssetsIds = describedAssets.map(({ _id }) => _id);
    let { description } = state.selectedAssetsAggregatedData;

    /** if selected only one asset */
    if (selectedItems.length === 1) {
      const asset = describedAssets.find(({ _id }) => _id === selectedItems[0]);
      if (asset) description = asset.description;
    }

    /** if selected only several assets and they have the same description */
    if (selectedItems.length > 1 && description) {
      /** if some asset has new description -> "Multiple selection" */
      if (selectedItems.some((id) => describedAssetsIds.includes(id))) description = '';
    }

    return {
      ...state,
      items: helpers.setDescription(state.items, describedAssets),
      selectedAssetsAggregatedData: {
        ...state.selectedAssetsAggregatedData,
        description,
        describing: _isEqual(describedAssetsIds, selectedItems) ? 'complete' : state.selectedAssetsAggregatedData.describing,
      },
    };
  }

  /** Retry importing from cloud storage */
  case retryImporting.pending.type: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        meta.arg.assetIds,
        [meta.arg.isMedia ? 'importingMedia' : 'importing'],
        [CONSTANTS.ASYNC_JOB_STATUS_WAITING],
      ),
      inProgress: {
        ...state.inProgress,
        processing: true,
      },
    };
  }

  case retryImporting.rejected.type: {
    return {
      ...state,
      items: helpers.setField(
        state.items,
        meta.arg.assetIds,
        [meta.arg.isMedia ? 'importingMedia' : 'importing'],
        [CONSTANTS.ASYNC_JOB_STATUS_FAILED],
      ),
      inProgress: {
        ...state.inProgress,
        processing: false,
      },
    };
  }

  default: {
    // Please note: we can migrate to slice gradually as below
    return state;
  }
  }
}
