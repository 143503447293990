/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const deleteOptionValue = createAsyncThunk(
  'products/deleteOptionValue',
  async ({ productId, optionId, valueId }, {
    extra: { Logger, Toast, sdk },
  }) => {
    try {
      const { data } = await sdk.products.deleteOptionValues(productId, optionId, [valueId]);
      return data;
    } catch (error) {
      Toast(
        'Option value is not deleted. Refresh and try again',
        { autoClose: false, type: 'error' },
      );
      Logger.error(new Error('Error option value deleting'), { error }, [
        'DeleteOptionValueFailed',
        'Option value is not deleted on the backend.',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder.addCase(
    deleteOptionValue.pending,
    (state, { meta }) => {
      state.items.forEach((product) => {
        if (product._id === meta.arg.productId) {
          product.isBusy = true;
        }
      });
    },
  ).addCase(
    deleteOptionValue.fulfilled,
    (state, { payload }) => {
      state.items = state.items.map((product) => {
        if (product._id === payload._id) return { ...product, isBusy: false, ...payload };
        return product;
      });
    },
  ).addCase(
    deleteOptionValue.rejected,
    (state, { meta, error }) => {
      state.items.forEach((product) => {
        if (product._id === meta.arg.productId) {
          product.isBusy = false;
        }
      });
      state.error = error;
    },
  );
};
