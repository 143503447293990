import React from 'react';
import ReactDOM from 'react-dom';

const Portal = ({
  children, isOpen, onClose, btnRef,
}) => {
  if (!isOpen) return null;

  const btn = btnRef.current.getBoundingClientRect();

  return ReactDOM.createPortal(
    <div
      className="modal-overlay"
      style={{
        position: 'absolute', top: `${btn.top}px`, left: `${btn.left}px`, width: `${btnRef.current.clientWidth}px`,
      }}
      onClick={onClose}
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>,
    document.getElementById('portal-root'),
  );
};

export default Portal;
