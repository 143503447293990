import TYPES from '../../action-types';
import { selectByMatch as selectByMatchHelper } from '../../helpers/assets';

const selectByMatch = ({
  matchData, value, isRange, isOnlyOne,
}) => (dispatch, getAll) => {
  const {
    assets,
  } = getAll();
  const { selectedItemsToMatch } = assets;

  let selectedAssetsData;

  if (isOnlyOne) {
    selectedAssetsData = [matchData];
  } else {
    selectedAssetsData = selectByMatchHelper(value, matchData, isRange, assets.lastClicked, assets.items, selectedItemsToMatch);
  }

  dispatch({
    type: TYPES.ASSETS.SELECT_BY_MATCH,
    payload: {
      selectedAssetsData,
      value,
      matchData,
    },
  });
  if (selectedItemsToMatch.length === 1 && !value) {
    window.dispatchEvent(new Event('selection:switched:off'));
  } else {
    window.dispatchEvent(new Event('selection:switched:on'));
  }
};

export default selectByMatch;
