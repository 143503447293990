/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const changeStatus = createAsyncThunk(
  'products/changeStatus',
  async ({ productId, status }, {
    extra: {
      sdk, Logger, showErrorDialog, utils,
    },
  }) => {
    try {
      await sdk.products.changeStatus(productId, status);
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not change product status';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error product change status'),
        { error },
        ['ChangeStatusProductFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      changeStatus.pending,
      (state, { meta }) => {
        const { productId, status } = meta.arg;
        state.items.forEach((product) => {
          if (productId === product._id) {
            product.oldStatus = product.status;
            product.status = status;
          }
        });
      },
    )
    .addCase(
      changeStatus.fulfilled,
      (state, { meta }) => {
        state.items.forEach((product) => {
          if (meta.arg.productId === product._id) {
            delete product.oldStatus;
          }
        });
      },
    )
    .addCase(
      changeStatus.rejected,
      (state, { meta, error }) => {
        state.items.forEach((product) => {
          if (meta.arg.productId === product._id) {
            product.status = product.oldStatus;
            delete product.oldStatus;
          }
        });
        state.error = error;
      },
    );
};
