import React, { useState, useRef, memo, useCallback } from 'react'; // eslint-disable-line
import pluralize from 'pluralize';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { Button, Icon as Icon2 } from '@picsio/ui';
import { Info } from '@picsio/icons';
import ErrorBoundary from '../ErrorBoundary';
import * as utils from '../../shared/utils';
import localization from '../../shared/strings';
import Logger from '../../services/Logger';
import Icon from '../Icon';
import Name from './Name';
import Description from './Description';
import MainInfo from './MainInfo';
import Colors from './Colors';
import Roles from './Roles';
import Website from './Website';
import downloadCollection from '../../helpers/fileDownloader/downloadCollection';
import moveCollection from '../../helpers/moveCollection';
import { selectAll } from '../../store/actions/assets';
import picsioConfig from '../../../../../config';
import showSelectFromTreeDialog from '../../helpers/showSelectFromTreeDialog';
import DialogRadios from '../dialogRadios';

import {
  changeCollectionColor,
  changeCollectionDescription,
  renameCollection,
  removeCollection,
  addToFavorites,
  getChildren,
  copyCollection,
  addCollection,
} from '../../store/actions/collections';
import CollectionMenu from './CollectionMenu';
import { isRouteSelectedAssets, navigate, isRouteProducts } from '../../helpers/history';
import { activeCollectionSelector } from '../../store/selectors/collections';
import ua from '../../ua';
import renderWebsitePresetsDialog from '../WebsitePresetsDialog/renderWebsitePresetsDialog';

const getCollectionPanelVisibility = () => {
  try {
    return JSON.parse(utils.LocalStorage.get('picsio.collectionPanelVisibility')) || {};
  } catch (err) {
    return {};
  }
};

const assetsSelector = (state) => state.assets;
const getTotal = createSelector([assetsSelector], (assets) => assets.total);

const CollectionInfo = () => {
  const dispatch = useDispatch();
  const nameRef = useRef(null);
  const { permissions: rolePermissions } = useSelector((state) => state.user.role);
  const { websites: websitesAllowed, csvImport } = useSelector((state) => state.user.subscriptionFeatures);
  const { items: teamRoles } = useSelector((state) => state.roles);
  const { items: teammates } = useSelector((state) => state.teammates);
  const websitePresets = useSelector((state) => state.websiteSettings.presets);
  const [collectionPanelVisibility, setSollectionPanelVisibility] = useState(
    getCollectionPanelVisibility(),
  );
  const collectionsStore = useSelector((state) => state.collections);
  const total = useSelector(getTotal);
  const activeCollections = useSelector((state) => activeCollectionSelector(state));
  const collection = activeCollections[0];

  const isEditCollectionAllowed = !collection?.archived
    ? collection?.permissions?.editCollections : false;

  const handleDownload = () => {
    const { _id: collectionId } = collection;
    Logger.log('User', 'CollectionInfoDownload', { collectionId });
    downloadCollection(collectionId, rolePermissions);
  };

  const handleCreateCollection = useCallback(
    (parent, name) => dispatch(addCollection(
      `${parent.path}${parent.name}/`,
      name,
      parent._id,
    )),
    [dispatch],
  );

  const handleCopyDialogClose = () => null;

  const handleCopyConfirmDialog = (selectedCollection) => {
    Logger.log('User', 'CopyCollectionDialogDuplicateClicked');
    const { _id } = selectedCollection[0];
    // eslint-disable-next-line no-new
    new DialogRadios({
      title: localization.DIALOGS.COPY_COLLECTION_RADIO_DIALOG.TITLE,
      items: [
        {
          label: localization.DIALOGS.COPY_COLLECTION_RADIO_DIALOG.COPY_ONE,
          value: 'ONE',
          checked: true,
          description: '',
        },
        {
          label: localization.DIALOGS.COPY_COLLECTION_RADIO_DIALOG.COPY_ALL,
          value: 'ALL',
          checked: false,
          description: '',
        },
      ],
      description: <span className="descriptionIcon"><Icon2 size="sm"><Info /></Icon2>{localization.DIALOGS.COPY_COLLECTION_RADIO_DIALOG.DESCRIPTION}</span>,
      cancelName: localization.DIALOGS.COPY_COLLECTION_RADIO_DIALOG.CANCEL,
      okName: localization.DIALOGS.COPY_COLLECTION_RADIO_DIALOG.OK,
      onOk: (value) => {
        dispatch(copyCollection(
          {
            copyingCollectionName: collection.name,
            copyChildren: value === 'ALL',
            parentID: _id,
            copyingCollectionId: collection._id,
          },
        ));
      },
    });
  };

  const handleCopy = () => {
    if (!picsioConfig.isMainApp) return;
    Logger.log('UI', 'CopyCollectionDialog');
    const { collections } = collectionsStore;

    showSelectFromTreeDialog({
      title: localization.DIALOGS.COPY_COLLECTIONS_TREE_DIALOG.TITLE,
      treeListItems: [collections.my] || [],
      createCollection: handleCreateCollection,
      onClick: () => {},
      onLoadChildren: async (item) => await dispatch(getChildren(item._id)),
      onClose: handleCopyDialogClose,
      textBtnCancel: localization.DIALOGS.COPY_COLLECTIONS_TREE_DIALOG.CANCEL_TEXT,
      onOk: (selectedCollections) => handleCopyConfirmDialog(selectedCollections),
      textBtnOk: localization.DIALOGS.COPY_COLLECTIONS_TREE_DIALOG.OK_TEXT,
      openedItems: [collections.my._id],
      type: 'duplicateCollection',
    });
  };

  const handleDelete = () => {
    dispatch(removeCollection(collection));
  };

  const handleRename = () => {
    nameRef.current.initCollectionRenaming();
  };

  const handleWebsite = () => {
    const { _id: collectionId, website } = collection;

    const hideWebsitePresetsDialog = utils.LocalStorage.get('picsio.hideWebsitePresetsDialog');
    const isNotDesktop = ua.browser.isNotDesktop();

    if (website || hideWebsitePresetsDialog || isNotDesktop) {
      Logger.log('User', 'CollectionInfoWebsiteSettings', { collectionId });
      navigate(`/websites/${collectionId}?tab=main`);
    } else {
      renderWebsitePresetsDialog({ collectionId, websitePresets });
    }
  };

  const handleFavorite = () => {
    const {
      _id, name, path, favorites,
    } = collection;
    dispatch(addToFavorites(_id, name, path, !favorites));
  };

  const handleMove = () => {
    Logger.log('UI', 'CollectionInfoMove');
    moveCollection(collection);
  };

  const toggleCollapseVisibility = (title) => {
    const newCollectionPanelVisibility = { ...collectionPanelVisibility };
    if (newCollectionPanelVisibility[title] === undefined) {
      newCollectionPanelVisibility[title] = false;
    } else {
      newCollectionPanelVisibility[title] = !newCollectionPanelVisibility[title];
    }
    utils.LocalStorage.set(
      'picsio.collectionPanelVisibility',
      JSON.stringify(newCollectionPanelVisibility),
    );
    setSollectionPanelVisibility(newCollectionPanelVisibility);
  };

  const setColor = (color) => {
    let newColor = color.hex;
    if (newColor === collection.color) {
      newColor = 'nocolor';
    }
    Logger.log('User', 'CollectionInfoCollectionColor', {
      collectionId: collection._id,
      color: newColor,
    });
    dispatch(changeCollectionColor(collection._id, newColor));
  };

  return (
    <Choose>
      <When condition={collection && activeCollections.length === 1}>
        <div className="InfoPanel">
          <div className="InfoPanelHeader" data-qa="InfoPanel-header">
            <header className="InfoPanelHeader__top">
              <Name
                ref={nameRef}
                collection={collection}
                renameCollection={renameCollection}
                isEditCollectionAllowed={isEditCollectionAllowed}
              />
              <If condition={!collection.archived && !collection.readOnly}>
                <div className="InfoPanelHeader__btns">
                  <CollectionMenu
                    collection={collection}
                    canUploadCsv={rolePermissions.importCSV && csvImport}
                    websitesAllowed={websitesAllowed}
                    handleCopy={handleCopy}
                    handleDownload={handleDownload}
                    handleDelete={handleDelete}
                    handleRename={handleRename}
                    handleWebsite={handleWebsite}
                    handleFavorite={handleFavorite}
                    handleMove={handleMove}
                  />
                </div>
              </If>
            </header>
            <div className="InfoPanelAssets">
              <Choose>
                <When condition={total && !isRouteSelectedAssets()}>
                  <div className="InfoPanelAssets__buttons">
                    <Button
                      id="button-selectAll"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        dispatch(selectAll());
                        Logger.log('User', 'CollectionInfoSelectAll');
                      }}
                      size="md"
                      fullWidth
                    >
                      {`${localization.DETAILS.textSelectedAllCollection}
                          ${utils.formatNumberWithSpaces(total)}
                          ${pluralize('asset', total, false)}`}
                    </Button>
                  </div>
                </When>
                <Otherwise>
                  <div className="InfoPanelAssets__placeholder">
                    {localization.DETAILS.textNoAssets}
                  </div>
                </Otherwise>
              </Choose>
            </div>
          </div>

          <div className="InfoPanelBody">
            <ErrorBoundary>
              <Description
                isOpen={collectionPanelVisibility.description}
                toggleCollapseVisibility={toggleCollapseVisibility}
                isEditCollectionAllowed={isEditCollectionAllowed}
                changeCollectionDescription={changeCollectionDescription}
                collection={collection}
              />
            </ErrorBoundary>
            <ErrorBoundary>
              <MainInfo
                isOpen={collectionPanelVisibility.maininfo}
                toggleCollapseVisibility={toggleCollapseVisibility}
                collection={collection}
                teammates={teammates}
              />
            </ErrorBoundary>
            <ErrorBoundary>
              <Roles
                isOpen={collectionPanelVisibility.roles}
                toggleCollapseVisibility={toggleCollapseVisibility}
                collectionId={collection._id}
                teammates={teammates}
                teamRoles={teamRoles}
              />
            </ErrorBoundary>
            <ErrorBoundary>
              <If condition={isEditCollectionAllowed}>
                <Colors
                  isOpen={collectionPanelVisibility.color}
                  toggleCollapseVisibility={toggleCollapseVisibility}
                  isBusy={collection.isBusy}
                  color={collection.color}
                  setColor={setColor}
                />
              </If>
            </ErrorBoundary>
            <ErrorBoundary>
              <If condition={collection.website}>
                <Website
                  isOpen={collectionPanelVisibility.website}
                  toggleCollapseVisibility={toggleCollapseVisibility}
                  website={collection.website}
                />
              </If>
            </ErrorBoundary>
          </div>
        </div>
      </When>
      {/* Inboxes, Lightboards */}
      <Otherwise>
        <div className="detailsPanel__empty">
          <Icon name="info" />
          <If condition={!isRouteProducts()}>
            <div className="txtNoImageSelected">
              {localization.DETAILS.textNoFiles} {`${total ? 'selected' : ''}`}
            </div>
          </If>
          <If condition={total && !isRouteSelectedAssets() && !isRouteProducts()}>
            <div className="btnsSelection">
              <Button
                id="button-selectAll"
                variant="contained"
                color="secondary"
                onClick={() => {
                  dispatch(selectAll());
                  Logger.log('User', 'InfoPanelSelectAll');
                }}
                size="md"
                fullWidth
              >
                {localization.DETAILS.textSelectedAll}
              </Button>
            </div>
          </If>
        </div>
      </Otherwise>
    </Choose>
  );
};

export default memo(CollectionInfo);
