/* eslint-disable no-param-reassign */
import CONSTANTS from '@picsio/db/src/constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { added } from '../keywords/actions';
import { add } from '../keywordsList/actions';

export const addKeywords = createAsyncThunk(
  'products/addKeywords',
  async ({ productId, keywordName }, {
    extra: {
      sdk, Logger, utils, showErrorDialog,
    },
    dispatch,
  }) => {
    try {
      const { data: keywords } = await sdk.products.addKeywords([productId], keywordName);

      keywords.forEach((keyword) => {
        const clonedKeyword = { ...keyword };

        if (clonedKeyword.isNew) {
          clonedKeyword.isFavorite = false;
          clonedKeyword.count = 0;

          /** call keywords action */
          dispatch(added([clonedKeyword]));
        } else {
          /** call keywords action */
          dispatch(add([{
            ...clonedKeyword,
            name: clonedKeyword.name || clonedKeyword.path.split(CONSTANTS.NEW_PATH_DELIMITER).pop(),
          }]));
        }
      });

      return keywords;
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not add keyword to product';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error add keyword to product'),
        { error },
        ['AddKeywordToProductFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      addKeywords.pending,
      () => {
        /** Do nothing */
      },
    )
    .addCase(
      addKeywords.fulfilled,
      (state, { payload: keywords, meta }) => {
        const { productId } = meta.arg;
        state.items.forEach((product) => {
          if (product._id === productId) {
            product.keywords = [...(product.keywords || []).filter((k) => !keywords.find(({ _id }) => _id === k._id)), ...keywords];
          }
        });
      },
    )
    .addCase(
      addKeywords.rejected,
      (state, { error }) => {
        state.error = error;
      },
    );
};
