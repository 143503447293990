/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const changeDescription = createAsyncThunk(
  'products/changeDescription',
  async ({ productId, description }, {
    extra: { Logger, Toast, sdk },
  }) => {
    try {
      const { data } = await sdk.products.changeDescription(productId, description);
      return data.descriptionHtml;
    } catch (error) {
      Toast(
        'Description for product is not changed. Refresh and try again',
        { autoClose: false, type: 'error' },
      );
      Logger.error(new Error('Error product description changing'), { error }, [
        'ChangeProductDescriptionFailed',
        'Description for product is not changed on the backend.',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder.addCase(
    changeDescription.pending,
    (state, { meta }) => {
      state.items.forEach((product) => {
        if (product._id === meta.arg.productId) {
          product.isBusy = true;
        }
      });
    },
  ).addCase(
    changeDescription.fulfilled,
    (state, { payload, meta }) => {
      state.items.forEach((product) => {
        if (product._id === meta.arg.productId) {
          product.isBusy = false;
          product.descriptionHtml = payload;
        }
      });
    },
  ).addCase(
    changeDescription.rejected,
    (state, { meta, error }) => {
      state.items.forEach((product) => {
        if (product._id === meta.arg.productId) {
          product.isBusy = false;
        }
      });
      state.error = error;
    },
  );
};
