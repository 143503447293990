import React, { memo, useCallback, useState } from 'react';
import { bool, func, string } from 'prop-types';
import { IconButton, Input } from '@picsio/ui';
import { CheckIcon, CloseIcon } from '@picsio/icons';
import s from './styles.module.scss';

const FormAdd = memo(({
  onSubmit,
  validate,
  onCancel,
  placeholder = 'Type something',
  placeholder2 = 'Type something',
  isOption = false,
}) => {
  const [value, setValue] = useState('');
  const [value2, setValue2] = useState('');
  const [hasError, setHasError] = useState(false);
  const [hasError2, setHasError2] = useState(false);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
    setHasError(!validate(e.target.value));
  }, [validate]);

  const handleChange2 = useCallback((e) => {
    setValue2(e.target.value);
    setHasError2(!e.target.value);
  }, [setValue2]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (validate(value) && (!isOption || (isOption && value2))) {
      onSubmit({ value, value2 });
      setValue('');
      if (isOption) setValue2('');
    }
    if (!validate(value)) setHasError(true);
    if (isOption && !value2) setHasError2(true);
  }, [validate, value, onSubmit, value2, isOption]);

  return (
    <form className={s.form} action="#" onSubmit={handleSubmit}>
      <Input
        value={value}
        error={hasError}
        onChange={handleChange}
        placeholder={placeholder}
        autoFocus
      />
      <If condition={isOption}>
        <Input
          value={value2}
          error={hasError2}
          onChange={handleChange2}
          placeholder={placeholder2}
        />
      </If>
      <div className={s.formButtons}>
        <IconButton type="submit" onClick={handleSubmit}>
          <CheckIcon />
        </IconButton>
        <IconButton type="reset" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
    </form>
  );
});

FormAdd.propTypes = {
  onSubmit: func.isRequired,
  validate: func.isRequired,
  onCancel: func.isRequired,
  placeholder: string,
  placeholder2: string,
  isOption: bool,
};

export default FormAdd;
