/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const duplicate = createAsyncThunk(
  'products/duplicate',
  async ({ productId, title, status }, {
    extra: {
      sdk, Logger, showErrorDialog, utils,
    },
  }) => {
    try {
      const { data: newProduct } = await sdk.products.duplicate(productId, title, status);
      return newProduct;
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not duplicate product';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error product duplicating'),
        { error },
        ['DuplicateProductFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      duplicate.pending,
      (state, { meta }) => {
        /** Do nothing */
      },
    )
    .addCase(
      duplicate.fulfilled,
      (state, { payload }) => {
        state.items.unshift(payload);
      },
    )
    .addCase(
      duplicate.rejected,
      (state, { error }) => {
        state.error = error;
      },
    );
};
