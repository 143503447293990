import globalHistory, { isRouteProducts, isRouteSearch } from '../history';
import { SINGLE_OPERATORS, NUMBER_OPERATORS } from '../../components/SearchFilters/config';

export default function prepareSearchQuery() {
  let query = window.location.search.slice(1);
  if (!isRouteSearch() && !isRouteProducts()) {
    const latestSearch = globalHistory.entries.find(
      ({ pathname, search }) => isRouteSearch(pathname + search),
    );
    if (latestSearch) query = latestSearch.search.slice(1);
  }

  const result = {
    collectionIds: [],
  };
  // query is empty for proofing main page
  if (query) {
    query.split('&').forEach((item) => {
      const [key, value] = item.split('=');
      const decodedValue = decodeURIComponent(value);
      if (key.endsWith('Operator')) {
        const id = key.replace(/Operator/ig, '');
        if (!result.operators) result.operators = {};
        result.operators[id] = value;
        if (SINGLE_OPERATORS.includes(value) && id in result) {
          result[id] = Boolean(result[id]);
        }
      } else {
        result[key] = key in result ? [].concat(result[key], decodedValue) : decodedValue;
      }
    });
  }

  // if only one faceId this is string
  if (typeof result.faces === 'string') {
    result.faces = [result.faces];
  }

  return result;
}
