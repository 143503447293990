import React, { useContext, useEffect, useRef } from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import PropTypes from 'prop-types';
import ThreeContext from '../../ThreeContext';

const FBXModel = ({ url, position }) => {
  const THREE = useContext(ThreeContext);
  const fbx = useLoader(FBXLoader, url);
  const mixer = useRef();
  const clock = useRef(new THREE.Clock());

  useEffect(() => {
    if (fbx.animations && fbx.animations.length > 0) {
      mixer.current = new THREE.AnimationMixer(fbx);
      const action = mixer.current.clipAction(fbx.animations[0]);
      action.play();
    }

    return () => {
      if (mixer.current) {
        mixer.current.stopAllAction();
        mixer.current.uncacheRoot(fbx);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbx]);

  useFrame(() => {
    if (mixer.current) {
      mixer.current.update(clock.current.getDelta());
    }
  });

  fbx.traverse((child) => {
    if (child.isMesh) {
      const material = new THREE.MeshStandardMaterial({
        transparent: child.material.transparent || false,
        metalness: 0.5,
        roughness: 0.4,
        color: child.material.color || 0xcccccc,
        depthTest: true,
        depthWrite: true,
        specular: 0x000000,
        shininess: 20,
        wireframe: false,
        opacity: child.material.opacity,
        side: THREE.DoubleSide,
      });
      Object.assign(child, {
        material,
        castShadow: true,
        receiveShadow: true,
      });
    }
  });

  return <primitive object={fbx} position={position} />;
};

FBXModel.propTypes = {
  url: PropTypes.string.isRequired,
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default FBXModel;
