/* eslint-disable camelcase */
import React, { memo, useMemo } from 'react';
import { TextField } from '@picsio/ui';
import s from '../styles.module.scss';

const DEFAULT_DATA = [];
const getNotAvailable = ({
  reserved, incoming, damaged, safety_stock, quality_control,
}) => reserved || 0 + incoming || 0 + damaged || 0 + safety_stock || 0 + quality_control || 0;

const InventoryLevels = ({ data = DEFAULT_DATA }) => {
  const total = useMemo(() => data.reduce((acc, { quantities }) => ({
    notAvailable: acc.notAvailable + getNotAvailable(quantities),
    committed: acc.committed + quantities.committed || 0,
    available: acc.available + quantities.available || 0,
    on_hand: acc.on_hand + quantities.on_hand || 0,
  }), {
    notAvailable: 0, committed: 0, available: 0, on_hand: 0,
  }), [data]);

  return (
    <>
      <div className={s.row}>
        Quantity
      </div>
      <div className={`${s.subRow} ${s.titleRow}`}>
        <div className={s.title}>Location</div>
        <div className={s.count}>
          Unavailable
        </div>
        <div className={s.count}>
          Committed
        </div>
        <div className={s.count}>
          Available
        </div>
        <div className={s.count}>
          On hand
        </div>
      </div>
      {data.map(({ location, quantities }) => (
        <div className={s.subRow} key={location.id}>
          <div className={s.title}>{location.name}</div>
          <div className={s.count}>
            <TextField
              value={getNotAvailable(quantities)}
              disabled
            />
          </div>
          <div className={s.count}>
            <TextField
              value={quantities.committed}
              disabled
            />
          </div>
          <div className={s.count}>
            <TextField
              value={quantities.available}
              disabled
            />
          </div>
          <div className={s.count}>
            <TextField
              value={quantities.on_hand}
              disabled
            />
          </div>
        </div>
      ))}
      <div className={`${s.subRow} ${s.titleRow}`}>
        <div className={s.title}>Total</div>
        <div className={`${s.count} ${s.number}`}>
          {total.notAvailable}
        </div>
        <div className={`${s.count} ${s.number}`}>
          {total.committed}
        </div>
        <div className={`${s.count} ${s.number}`}>
          {total.available}
        </div>
        <div className={`${s.count} ${s.number}`}>
          {total.on_hand}
        </div>
      </div>
    </>
  );
};

export default memo(InventoryLevels);
