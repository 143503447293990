import React from 'react';
import { useSelector } from 'react-redux';
import { ToriutLogo } from '@picsio/icons';
import Button from './Button';
import BrandedLogo from './BrandedLogo';
import config from '../../../../../config';

/**
 * Logo
 * @param {Object} props
 * @param {string} props.src
 * @param {string} props.username
 * @param {number} props.size
 * @param {boolean} props.avatarPicsio
 * @param {string} props.className
 * @returns {JSX}
 */
export default function Logo({ handleLogoClick, additionalClass }) {
  const { appBrandedLogoEnable, logoUrl, accentColor } = useSelector((state) => state.user.team);
  const { teamName } = useSelector((state) => state.user.team.policies);
  const { branding: brandingAllowed } = useSelector((state) => state.user.subscriptionFeatures);

  const brandedLogoStyle = {};
  if (accentColor) {
    brandedLogoStyle.backgroundColor = accentColor;
  }

  if (config.isPim) {
    brandedLogoStyle.fontSize = '50px';
  }

  return (
    <Choose>
      <When condition={brandingAllowed && appBrandedLogoEnable && logoUrl}>
        <Button
          id="button-logo"
          additionalClass={additionalClass}
          onClick={handleLogoClick}
          style={brandedLogoStyle}
        >
          <BrandedLogo src={logoUrl} alt={teamName} />
        </Button>
      </When>
      <When condition={config.isPim}>
        <Button
          id="button-logo"
          additionalClass={additionalClass}
          onClick={handleLogoClick}
          style={brandedLogoStyle}
          iconSize="xxl"
          icon={() => <ToriutLogo />}
        />
      </When>
      <Otherwise>
        <Button
          id="button-logo"
          icon="logoPicsio"
          additionalClass={additionalClass}
          onClick={handleLogoClick}
        />
      </Otherwise>
    </Choose>
  );
}
