import * as utils from '../../../shared/utils';
import getDetailsPanelEditable from './getDetailsPanelEditable';

const defaultPanelsState = {
  detailsProcessingVisibility: true,
  detailsDescriptionVisibility: true,
  detailsKeywordsVisibility: true,
  productKeywordsVisibility: true,
  detailsFacesVisibility: true,
  detailsTranscriptVisibility: true,
  detailsAssigneeVisibility: true,
  detailsLinkedAssetsVisibility: true,
  detailsCollectionsVisibility: true,
  detailsLightboardsVisibility: true,
  detailsMarksVisibility: true,
  detailsMapVisibility: true,
  detailsAssetShareVisibility: true,
  detailsAssetRestrictVisibility: true,
  detailsAssetArchiveVisibility: true,
  File: true,
  GPS: true,
};

export function getDetailsPanelVisibility() {
  try {
    return {
      ...defaultPanelsState,
      ...JSON.parse(utils.getCookie('picsio.detailsPanelVisibility')),
    };
  } catch (err) {
    return { ...defaultPanelsState };
  }
}
const getDefaultState = () => {
  const detailsPanelEditable = getDetailsPanelEditable();
  return {
    detailsPanelVisibility: getDetailsPanelVisibility(),
    detailsPanelEditable,
    currentLock: detailsPanelEditable.lockAlways || null,
    detailsPanelLocked: detailsPanelEditable.lockAlways || false,
    filename: '',
    isLocked: false,
  };
};

export default getDefaultState;
