/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import pullProducts from '../../helpers/products/pullProducts';
import * as utils from '../../shared/utils';

export const pullProduct = createAsyncThunk(
  'products/pullProduct',
  async ({ productId }, {
    extra: { Logger, Toast, sdk },
    getState, dispatch,
  }) => {
    try {
      const product = getState().products.items.find(({ _id }) => _id === productId);

      /** If last pull less than 1 min ago */
      if (product?.pulledAt && (Date.now() - product.pulledAt) < 60000) {
        return product;
      }

      const { data } = await sdk.products.pullOne(productId);
      return data;
    } catch (error) {
      const errorSubcode = utils.getDataFromResponceError(error, 'subcode');
      Logger.log('UI', 'ToastProductPullFailed');

      if (errorSubcode !== 'HasAttachingAssetsError') {
        Toast(
          'This product was deleted in your Shopify store. To keep your data up-to-date, please sync your entire product catalog.',
          {
            autoClose: false, type: 'error', onOk: () => pullProducts(dispatch), btnOkValue: 'Try again',
          },
        );
        Logger.error(new Error('Error pull product'), { error }, [
          'PullProductFailed',
          'Product pull failed on the backend.',
        ]);
      }

      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder.addCase(
    pullProduct.pending,
    (state) => {
      state.error = null;
    },
  ).addCase(
    pullProduct.fulfilled,
    (state, { payload: product }) => {
      state.items = state.items.map((item) => {
        if (item._id === product._id) {
          return {
            ...item,
            pulledAt: Date.now(), // if product is not pulled, pulledAt will be set below
            ...product,
          };
        }
        return item;
      });
    },
  ).addCase(
    pullProduct.rejected,
    (state, { error }) => {
      state.error = error;
    },
  );
};
