// eslint-disable-next-line import/prefer-default-export
export const getActiveProduct = (location) => {
  if ((location?.pathname || '').startsWith('/product/')) {
    const [, , productId, variantId] = location.pathname.split('?')[0].split('/');
    return {
      productId,
      variantId,
    };
  }
  return null;
};
