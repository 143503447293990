/* eslint-disable prefer-object-spread */
const script = document.getElementById('picsioConfig');
if (script) script.remove();

module.exports = Object.assign(
  {},
  window.Picsio,
  {
    eventsAppName: window.Picsio?.isPim ? 'toriut' : 'picsio',
  },
);
