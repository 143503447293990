/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

const LS_KEY = 'picsio.hideDetachAssetFromProductVariantWarning';

export const removeAssetFromVariant = createAsyncThunk(
  'products/removeAssetFromVariant',
  async ({ assetId, productId, variantId = 'default' }, {
    extra: {
      sdk, Logger, utils, showErrorDialog, showDialogAsync,
    },
  }) => {
    try {
      if (!utils.LocalStorage.get(LS_KEY)) {
        try {
          await showDialogAsync({
            title: 'Warning',
            text: 'Are you sure you want to remove asset from the product variant?',
            checkbox: {
              label: 'Don\'t show it again',
              value: false,
              onChange: (value) => {
                utils.LocalStorage.set(LS_KEY, value);
              },
            },
            textBtnOk: 'Remove',
          }).promise;
        } catch (error) {
          /** User pressed "Cancel" */
          return false;
        }
      }

      await sdk.products.removeAssetFromVariant(productId, variantId, assetId);
      return true;
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not remove asset from product variant';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error remove asset from product variant'),
        { error },
        ['RemoveAssetFromProductVariantFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      removeAssetFromVariant.pending,
      () => {
        /** Do nothing */
      },
    )
    .addCase(
      removeAssetFromVariant.fulfilled,
      (state, { meta, payload }) => {
        if (!payload) return;

        const { productId, variantId } = meta.arg;
        state.items.forEach((p) => {
          if (p._id === productId) {
            (p.variants || []).forEach((v) => {
              if (v._id === variantId) {
                v.assetId = null;
              }
            });
          }
        });
      },
    )
    .addCase(
      removeAssetFromVariant.rejected,
      (state, { error }) => {
        state.error = error;
      },
    );
};
