import React from 'react';
import { useLoader } from '@react-three/fiber';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import PropTypes from 'prop-types';

const DAEModel = ({ url, position }) => {
  const dae = useLoader(ColladaLoader, url);

  dae.scene.traverse((child) => {
    if (child.isMesh) {
      Object.assign(child, {
        castShadow: true,
        receiveShadow: true,
      });
    }
  });

  return <primitive object={dae.scene} position={position} />;
};

DAEModel.propTypes = {
  url: PropTypes.string.isRequired,
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default DAEModel;
