/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import { setField } from '../helpers/assets';

export const updateMediaFields = createAction('products/updateAssetFields');

export const reducer = (builder) => {
  builder.addCase(updateMediaFields.type, (state, { payload }) => {
    const { assetID, fields, values } = payload;
    state.items = state.items.map((item) => {
      if (item.assets && item.assets.length) {
        item.assets = setField(item.assets, assetID, fields, values);
      }
      return item;
    });
  });
};
