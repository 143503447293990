import React, {
  useState, useMemo, useEffect, useCallback, useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  string, number, object, array, arrayOf, shape, func, bool,
} from 'prop-types';
import cn from 'classnames';
import { Icon as IconContainer } from '@picsio/ui';
import {
  Crawler, Attention, Close, Transcript as TranscriptIcon, AiEngine, EmptyBackgroundLoginPage,
} from '@picsio/icons';
import picsioConfig from '../../../../../../config';
import * as utils from '../../../shared/utils';
import localization from '../../../shared/strings';
import Transcript from './Transcript';

/** Components */
import { checkUserAccess } from '../../../store/helpers/user';
import { getActiveProduct } from '../../../store/products/selectors';
import ErrorBoundary from '../../ErrorBoundary';
import UserAssign from './UserAssign';
import LinkedAssets from './LinkedAssets';
import DetailsBlock from '../../DetailsBlock';
import DetailsPanelHeader from './Header';
import Processing from './Processing';
import Description from './Description';
import Keywords from './Keywords';
import Faces from './Faces';
import CollectionsComponent from './CollectionsComponent';
import Lightboards from './Lightboards';
import Products from './Products';
import AssetMarks from './AssetMarks';
import Share from './Share';
import Restrict from './Restrict';
import RestrictedAsset from './RestrictedAsset';
import Map from './Map';
import CollectionInfo from '../../CollectionInfo';
import ProductInfo from '../../ProductInfo';
import ArchiveAssetTab from './ArchiveAssetTab';
import ua from '../../../ua';
import DetailsPanelConfig from './DetailsPanelEdit/DetailsPanelConfig';
import DetailsPanelEdit from './DetailsPanelEdit';
import Tooltip from '../../Tooltip';
import DetailsPanelToggleButton from '../../DetailsPanelToggleButton';
import WatermarkAssetsTab from './WatermarkAssetsTab';
import { getSearchProps } from '../../../helpers/history';
import getDetailsPanelDefaultState from '../helpers/getDetailsPanelDefaultState';
import DetailsPanelCustomFieldGroup from './CustomFields/CustomFieldGroup';
import DetailPanelEmpty from './DetailsPanelEdit/DetailPanelEmpty';
import Logger from '../../../services/Logger';
import sendEventToIntercom from '../../../services/IntercomEventService';

const { isMainApp, isPim } = picsioConfig;
const isMobile = ua.browser.isNotDesktop();

const Details = (props) => {
  const {
    selectedFace = {},
    onClickBoundingBox = null,
    addBoungingBox = null,
    tmpBoundingBoxes = [],
    removeTmpBoundginBox = null,
    revisionID = '',
    selectedAssetsAggregatedData,
    user,
    assets,
    updateInProgress,
    panelName,
    panelWidth,
    total,
    assetsIds,
    error,
    textareaHeightNameLS,
    inProgress,
    openedTree,
    watermarks,
    allAssetsSelected = false,
  } = props;

  /**
    STATE:
      detailsPanelVisibility: Boolean,
      detailsPanelEditable: {
        lockAlways: Boolean
        locked: Boolean
        unlockAlways: Boolean
        unlockLogout: Boolean
      }
      currentLock: detailsPanelEditable.lockAlways || null,
      detailsPanelLocked: detailsPanelEditable.lockAlways || false,
      filename: '',
      isLocked: false,
  */
  const location = useLocation();
  const [state, setState] = useState(getDetailsPanelDefaultState());
  const detailsPanelRef = useRef(null);
  const [detailsList, setDetailsList] = useState(null);

  const activeProduct = useMemo(() => getActiveProduct(location), [location]);

  const detailsListRef = useCallback((node) => {
    setDetailsList(node);
  }, []);
  const depthPercentage = useRef();

  const teamRestrictReason = useMemo(() => user.team && user.team.policies.restrictReason, [user]);
  const lightboardId = getSearchProps()?.lightboardId;
  const isLightboadrdTree = useMemo(() => !!lightboardId, [lightboardId]);
  const isAllowedManageWatermarksToAssets = useMemo(
    () => assets.every((asset) => asset?.permissions?.manageAssetWatermark),
    [assets],
  );
  const canEditWatermarks = useMemo(() => user?.role?.permissions?.manageWatermarks, [user]);
  const restrictReason = useMemo(
    () => selectedAssetsAggregatedData?.restrictReason
      || teamRestrictReason
      || window.websiteConfig?.user?.policies?.restrictReason
      || localization.RESTRICT.RESTRICTED_REASON,
    [selectedAssetsAggregatedData, teamRestrictReason],
  );
  const isMetadataEditable = useMemo(
    () => !selectedAssetsAggregatedData?.isRestricted
      || (selectedAssetsAggregatedData?.isRestricted && selectedAssetsAggregatedData?.permissions?.restrictedMetadataEditable),
    [selectedAssetsAggregatedData],
  );
  const isAllowedManageArchive = useMemo(
    () => checkUserAccess('subscriptions', 'archive', null, user)
      && checkUserAccess('permissions', 'manageArchive', null, user), [],
  );
  const isAllowedLightboards = useMemo(() => checkUserAccess('permissions', 'manageLightboards', null, user), []);

  const handleScroll = useCallback((event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const nextDepthPercentage = (Math.floor(((scrollTop + clientHeight) / scrollHeight) * 4) / 4) * 100;
    if (typeof depthPercentage.current !== 'number' || nextDepthPercentage !== depthPercentage.current) {
      depthPercentage.current = nextDepthPercentage;
      const data = { depth_percentage: `${nextDepthPercentage}%` };
      if (nextDepthPercentage === 100) data.timestamp = new Date().toISOString();
      if (nextDepthPercentage === 25) sendEventToIntercom('scroll within details panel');
      Logger.log('User', 'InfoPanelScroll', data);
    }
  }, []);

  const handleFirstClickOnDetailsPanel = useCallback(() => {
    sendEventToIntercom('click within details panel');
  }, []);

  useEffect(() => {
    if (detailsPanelRef.current) {
      detailsPanelRef.current.addEventListener('click', handleFirstClickOnDetailsPanel, { once: true });
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (detailsPanelRef.current) detailsPanelRef.current.removeEventListener('click', handleFirstClickOnDetailsPanel, { once: true });
    };
  }, [detailsPanelRef, handleFirstClickOnDetailsPanel]);

  useEffect(() => {
    if (detailsList) {
      detailsList.addEventListener('scroll', handleScroll);
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (detailsList) detailsList.removeEventListener('scroll', handleScroll);
    };
  }, [detailsList, handleScroll]);

  const toggleEditable = (parameter) => {
    const { detailsPanelEditable } = state;
    let { currentLock, filename } = state;

    switch (parameter) {
    case 'unlockAlways': {
      detailsPanelEditable.unlockAlways = true;
      detailsPanelEditable.unlockLogout = false;
      detailsPanelEditable.lockAlways = false;
      currentLock = false;
      break;
    }

    case 'unlockLogout': {
      detailsPanelEditable.unlockAlways = false;
      detailsPanelEditable.unlockLogout = true;
      detailsPanelEditable.lockAlways = false;
      currentLock = false;
      break;
    }

    case 'lockAlways': {
      detailsPanelEditable.unlockAlways = false;
      detailsPanelEditable.unlockLogout = false;
      detailsPanelEditable.lockAlways = true;
      currentLock = true;
      break;
    }

    case 'lockTemporary': {
      currentLock = true;
      break;
    }

    default: {
      detailsPanelEditable.locked = !detailsPanelEditable.locked;
      currentLock = !currentLock;
      break;
    }
    }

    if (assets.length < 2) {
      filename = assets[0]?.name;
    } else {
      filename = assets.length;
    }

    if (parameter !== 'lockTemporary') {
      utils.LocalStorage.set('picsio.detailsPanelEditable', detailsPanelEditable);
    }
    setState({
      ...state, detailsPanelEditable: { ...detailsPanelEditable }, currentLock, filename,
    });
  };

  useEffect(() => {
    if (state.isArchived || selectedAssetsAggregatedData?.isArchived) {
      toggleEditable('lockTemporary');
    }
  }, [selectedAssetsAggregatedData]);

  /**
   * Toggle sections visibility
   * @param {string} title
   */
  const toggleVisibility = (title) => {
    const detailsPanelVisibility = { ...state.detailsPanelVisibility };

    detailsPanelVisibility[title] = !detailsPanelVisibility[title];
    utils.setCookie('picsio.detailsPanelVisibility', JSON.stringify(detailsPanelVisibility));
    setState({ ...state, detailsPanelVisibility });
  };

  const highlightAnimationReset = (type) => {
    const asset = assets[0];
    if (asset) {
      props.actions.removeHighlight([asset._id], type);
      const { paramsForHighlight } = asset;
      if (paramsForHighlight.length === 0) {
        props.actions.resetHighlight(assetsIds);
      }
    }
  };

  const checkForFaceRecognitionStatus = useCallback(() => {
    if (selectedAssetsAggregatedData?.faceRecognizing === 'complete') return selectedAssetsAggregatedData?.faceIndexing;
    return selectedAssetsAggregatedData?.faceRecognizing;
  }, [selectedAssetsAggregatedData?.faceIndexing, selectedAssetsAggregatedData?.faceRecognizing]);

  const checkForFaceRecognitionReason = useCallback(() => {
    if (selectedAssetsAggregatedData?.faceRecognizing === 'complete') return selectedAssetsAggregatedData?.faceIndexingReason;
    return selectedAssetsAggregatedData?.faceRecognizingReason;
  }, [selectedAssetsAggregatedData?.faceRecognizingReason, selectedAssetsAggregatedData?.faceIndexingReason, selectedAssetsAggregatedData?.faceRecognizing]);

  const checkForFaceRecognitionErrorCode = useCallback(() => {
    if (selectedAssetsAggregatedData?.faceRecognizing === 'complete') return selectedAssetsAggregatedData?.faceIndexingErrorCode;
    return selectedAssetsAggregatedData?.faceRecognizingErrorCode;
  }, [selectedAssetsAggregatedData?.faceRecognizingErrorCode, selectedAssetsAggregatedData?.faceIndexingErrorCode, selectedAssetsAggregatedData?.faceRecognizing]);

  const checkForEmbeddingStatus = useCallback(() => {
    if (selectedAssetsAggregatedData?.embedding === 'complete') return selectedAssetsAggregatedData?.CLIPEmbedding;
    return selectedAssetsAggregatedData?.embedding;
  }, [selectedAssetsAggregatedData?.CLIPEmbedding, selectedAssetsAggregatedData?.embedding]);

  const checkForEmbeddingReason = useCallback(() => {
    if (selectedAssetsAggregatedData?.embedding === 'complete') return selectedAssetsAggregatedData?.CLIPEmbeddingReason;
    return selectedAssetsAggregatedData?.embeddingReason;
  }, [selectedAssetsAggregatedData?.embeddingReason, selectedAssetsAggregatedData?.CLIPEmbeddingReason, selectedAssetsAggregatedData?.embedding]);

  const checkForEmbeddingErrorCode = useCallback(() => {
    if (selectedAssetsAggregatedData?.embedding === 'complete') return selectedAssetsAggregatedData?.CLIPEmbeddingErrorCode;
    return selectedAssetsAggregatedData?.embeddingErrorCode;
  }, [selectedAssetsAggregatedData?.embeddingErrorCode, selectedAssetsAggregatedData?.CLIPEmbeddingErrorCode, selectedAssetsAggregatedData?.embedding]);

  const handleProcessingData = useCallback(() => {
    const processingData = [
      {
        name: 'keywording',
        status: selectedAssetsAggregatedData?.keywording,
        errors: selectedAssetsAggregatedData?.keywordingReason,
        errorCode: selectedAssetsAggregatedData?.keywordingErrorCode,
        icon: 'keyword',
      },
      {
        name: 'faceRecognizing',
        status: checkForFaceRecognitionStatus(),
        errors: checkForFaceRecognitionReason(),
        errorCode: checkForFaceRecognitionErrorCode(),
        icon: 'placeholderFace',
      },
      {
        name: 'transcribing',
        status: selectedAssetsAggregatedData?.transcribing,
        errors: selectedAssetsAggregatedData?.transcribingReason,
        errorCode: selectedAssetsAggregatedData?.transcribingErrorCode,
        icon: <IconContainer size="md"><TranscriptIcon /></IconContainer>,
      },
      {
        name: 'embedding',
        status: checkForEmbeddingStatus(),
        errors: checkForEmbeddingReason(),
        errorCode: checkForEmbeddingErrorCode(),
        icon: <IconContainer size="md"><AiEngine /></IconContainer>,
      },
      {
        name: 'productAttaching',
        status: selectedAssetsAggregatedData?.productAttaching,
        errors: selectedAssetsAggregatedData?.productAttachingReason,
        errorCode: selectedAssetsAggregatedData?.productAttachingErrorCode,
        icon: <IconContainer size="md"><EmptyBackgroundLoginPage /></IconContainer>, // @TODO Change icon
      },
      {
        name: 'metadating',
        status: selectedAssetsAggregatedData?.metadating,
        errors: selectedAssetsAggregatedData?.metadatingReason,
        errorCode: selectedAssetsAggregatedData?.metadatingErrorCode,
        icon: 'label',
      },
      {
        name: 'thumbnailing',
        status: selectedAssetsAggregatedData?.thumbnailing,
        errors: selectedAssetsAggregatedData?.thumbnailingReason,
        errorCode: selectedAssetsAggregatedData?.thumbnailingErrorCode,
        icon: 'previewTitle',
      },
      {
        name: 'replicating',
        status: selectedAssetsAggregatedData?.replicating,
        errors: selectedAssetsAggregatedData?.replicatingReason,
        errorCode: selectedAssetsAggregatedData?.replicatingErrorCode,
        icon: 'retry',
      },
      {
        name: 'contenting',
        status: selectedAssetsAggregatedData?.contenting,
        errors: selectedAssetsAggregatedData?.contentingReason,
        errorCode: selectedAssetsAggregatedData?.contentingErrorCode,
        icon: 'content',
      },
      {
        name: 'crawling',
        status: selectedAssetsAggregatedData?.crawling,
        errors: selectedAssetsAggregatedData?.crawlingReason,
        errorCode: selectedAssetsAggregatedData?.crawlingErrorCode,
        icon: <IconContainer size="md"><Crawler /></IconContainer>,
      },
      {
        name: 'converting',
        status: selectedAssetsAggregatedData?.converting,
        errors: selectedAssetsAggregatedData?.convertingReason,
        errorCode: selectedAssetsAggregatedData?.convertingErrorCode,
        icon: 'file',
      },
      {
        name: 'describing',
        status: selectedAssetsAggregatedData?.describing,
        errors: selectedAssetsAggregatedData?.describingReason,
        errorCode: selectedAssetsAggregatedData?.describingErrorCode,
        icon: 'content',
      },
    ];

    return processingData;
  }, [
    checkForFaceRecognitionStatus,
    checkForFaceRecognitionReason,
    checkForFaceRecognitionErrorCode,
    checkForEmbeddingStatus,
    checkForEmbeddingReason,
    checkForEmbeddingErrorCode,
    selectedAssetsAggregatedData.contenting,
    selectedAssetsAggregatedData?.contentingReason,
    selectedAssetsAggregatedData.contentingErrorCode,
    selectedAssetsAggregatedData.converting,
    selectedAssetsAggregatedData.convertingErrorCode,
    selectedAssetsAggregatedData.convertingReason,
    selectedAssetsAggregatedData.crawling,
    selectedAssetsAggregatedData.crawlingErrorCode,
    selectedAssetsAggregatedData.crawlingReason,
    selectedAssetsAggregatedData.transcribing,
    selectedAssetsAggregatedData.transcribingErrorCode,
    selectedAssetsAggregatedData.transcribingReason,
    selectedAssetsAggregatedData.productAttaching,
    selectedAssetsAggregatedData.productAttachingErrorCode,
    selectedAssetsAggregatedData.productAttachingReason,
    selectedAssetsAggregatedData.keywording,
    selectedAssetsAggregatedData.keywordingErrorCode,
    selectedAssetsAggregatedData.keywordingReason,
    selectedAssetsAggregatedData.metadating,
    selectedAssetsAggregatedData.metadatingErrorCode,
    selectedAssetsAggregatedData.metadatingReason,
    selectedAssetsAggregatedData.replicating,
    selectedAssetsAggregatedData.replicatingErrorCode,
    selectedAssetsAggregatedData.replicatingReason,
    selectedAssetsAggregatedData.thumbnailing,
    selectedAssetsAggregatedData.thumbnailingErrorCode,
    selectedAssetsAggregatedData.thumbnailingReason,
    selectedAssetsAggregatedData.describing,
    selectedAssetsAggregatedData.describingReason,
    selectedAssetsAggregatedData.describingErrorCode,
  ]);

  const isProcessingVisible = useMemo(() => {
    let isSomethingProcessing = false;
    const data = handleProcessingData();

    data.forEach((item) => {
      if (
        (item.name === 'crawling' && item.status === 'complete')
        || item.status === 'waiting'
        || item.status === 'running'
        || item.status === 'failed'
        || (['rejected', 'delayed'].includes(item.status) && item.errors && item.errors.length)
      ) {
        isSomethingProcessing = true;
      }
    });

    return isSomethingProcessing;
  }, [handleProcessingData]);

  const getProcessingIndicator = useMemo(() => {
    let indicator = null;
    let tooltipText = [];
    const data = handleProcessingData();
    const processingItems = [];
    const warningItems = [];
    const errorItems = [];

    data.forEach((item) => {
      if (item.status === 'running' || item.status === 'waiting') processingItems.push(item.name);
      if (item.status === 'rejected' || item.status === 'delayed') warningItems.push(item.name);
      if (item.status === 'failed') errorItems.push(item.name);
    });

    const processNames = {
      keywording: 'keywords',
      metadating: 'metadata',
      thumbnailing: 'thumbnail',
      contenting: 'content',
      replicating: 'updates',
      crawling: 'crawling',
      faceRecognizing: 'faceRecognition',
      transcribing: 'transcribe',
    };

    function tooltipTextCreator(items) {
      items.forEach((item, index, currentAr) => {
        if (currentAr.length - 1 === index) {
          tooltipText.push(`${processNames[item]};`);
        } else {
          tooltipText.push(`${processNames[item]}, `);
        }
      });
    }

    if (processingItems.length) {
      tooltipText = ['Processing: '];
      tooltipTextCreator(processingItems);
    }

    if (warningItems.length) {
      const text = tooltipText.length ? <><br /><br />Warnings: </> : 'Warnings: ';
      tooltipText.push(text);
      tooltipTextCreator(warningItems);
    }

    if (errorItems.length) {
      const text = tooltipText.length ? <><br /><br />Errors: </> : 'Errors: ';
      tooltipText.push(text);
      tooltipTextCreator(errorItems);
    }

    if (processingItems.length || warningItems.length || errorItems.length) {
      indicator = (
        <Tooltip content={tooltipText} placement="top">
          <span
            className={cn('processingIndicator', {
              indicator: processingItems.length && !warningItems.length && !errorItems.length,
            })}
          >
            {(() => {
              if (errorItems.length) return <IconContainer size="lg"><Close /></IconContainer>;
              if (warningItems.length && !errorItems.length) return <IconContainer size="sm"><Attention /></IconContainer>;
              return null;
            })()}
          </span>
        </Tooltip>
      );
    }

    return indicator;
  }, [handleProcessingData]);

  const handleResize = useCallback((event) => props.mainActions.resizePanel(event, panelName), [panelName, props.mainActions]);

  const filterGroupsByPermission = useCallback((groups) => groups.filter((group) => !(group[0].permission && !selectedAssetsAggregatedData?.permissions?.[group[0].permission])), [selectedAssetsAggregatedData?.permissions]);

  return (
    <DetailsPanelConfig userId={user._id}>
      {({
        isOpen, fields, groups, config, toggleEditPanel, setFields, updateConfig, resetToDefault,
      }) => (
        <div
          ref={detailsPanelRef}
          className={cn('detailsPanel', { disabled: updateInProgress })}
          style={{ width: panelWidth }}
        >
          <div
            className="resizer"
            onMouseDown={handleResize}
          />
          <Choose>
            <When condition={isMainApp && !assets.length && activeProduct}>
              <ProductInfo productId={activeProduct.productId} variantId={activeProduct.variantId} />
            </When>
            <When condition={isMainApp && assets.length < 1 && assetsIds.length === 0}>
              <CollectionInfo />
            </When>
            <Otherwise>
              <>
                <If condition={isMainApp}>
                  <DetailsPanelEdit
                    isOpen={isOpen}
                    fields={fields}
                    groups={groups}
                    config={config}
                    toggleEditPanel={toggleEditPanel}
                    setFields={setFields}
                    updateConfig={updateConfig}
                    resetToDefault={resetToDefault}
                    permissions={selectedAssetsAggregatedData?.permissions}
                  />
                </If>
                {/* Details Header */}
                <DetailsPanelHeader
                  actions={props.actions}
                  isMainApp={isMainApp}
                  permissions={selectedAssetsAggregatedData?.permissions}
                  collection={assets}
                  allAssetsSelected={allAssetsSelected}
                  total={total}
                  selectedAssetsIds={assetsIds}
                  detailsPanelEditable={state.detailsPanelEditable}
                  currentLock={state.currentLock}
                  toggleEditable={toggleEditable}
                  selectAll={props.actions.selectAll}
                  deselectAll={props.actions.deselectAll}
                  deleteAll={
                    selectedAssetsAggregatedData?.isRemoveForever ? props.actions.deleteAssets : props.actions.trashAssets
                  }
                  isDownloadable={selectedAssetsAggregatedData?.isDownloadable}
                  isRestricted={selectedAssetsAggregatedData?.isRestricted}
                  error={error}
                  isRemoveForever={Boolean(selectedAssetsAggregatedData?.isRemoveForever)}
                  lockMenuDisabled={selectedAssetsAggregatedData?.isArchived}
                  isArchived={selectedAssetsAggregatedData?.isArchived}
                />
                <div className="detailsPanel__content">
                  <div
                    className="detailsPanel__list"
                    ref={detailsListRef}
                  >
                    <If condition={isMainApp && filterGroupsByPermission(groups).length && !picsioConfig.isPim}>
                      <DetailsPanelToggleButton toggleEditPanel={toggleEditPanel} />
                    </If>
                    {/* Restricted asset */}
                    <If condition={selectedAssetsAggregatedData?.isRestricted}>
                      <RestrictedAsset
                        reason={
                          assets.length === 1
                            ? restrictReason
                            : localization.DETAILS.placeholderMultipleSelectionRestricted
                        }
                      />
                    </If>
                    {/* Processing */}
                    {isMainApp && isProcessingVisible && (
                      <DetailsBlock
                        dataQa="processing"
                        detailsPanelVisibility={state.detailsPanelVisibility}
                        toggleVisibility={toggleVisibility}
                        blockName="detailsProcessingVisibility"
                        blockTitle={localization.DETAILS.textProcessing}
                        indicator={getProcessingIndicator}
                        additionalClass="detailsProcessingSection"
                      >
                        <Processing
                          data={handleProcessingData()}
                          ids={assetsIds}
                          reRunParsing={props.actions.reRunParsing}
                        />
                      </DetailsBlock>
                    )}

                    <If condition={!filterGroupsByPermission(groups).length}>
                      <DetailPanelEmpty text={localization.DETAILS.emptyEditWidgetText} />
                    </If>

                    <If condition={filterGroupsByPermission(groups).length && !groups.find(([field]) => !config.hidden.includes(field.id || field.title))}>
                      <DetailPanelEmpty text={localization.DETAILS.emptyMetadataFieldsText()} />
                    </If>

                    {groups.map((group) => {
                      const { id, title, permission } = group[0];
                      const { hidden } = config;
                      const isHidden = hidden.includes(id) || hidden.includes(id.replace('meta.', '')) || (permission && !selectedAssetsAggregatedData?.permissions?.[permission]);

                      if (isHidden) return null;
                      const a = assets.find(({ _id }) => _id === assetsIds[0]);

                      switch (id) {
                      case 'titleAndDescription':
                        return (
                          <DetailsBlock
                            key={id}
                            dataQa="description"
                            detailsPanelVisibility={state.detailsPanelVisibility}
                            toggleVisibility={toggleVisibility}
                            blockName="detailsDescriptionVisibility"
                            blockTitle={localization.DETAILS.textTitleAndDescription}
                            showUpgradePlan={isMainApp && !props.user.subscriptionFeatures?.describing && !isPim}
                            showAI={isMainApp && !isPim}
                          >
                            <Description
                              eventPrefix="InfoPanel"
                              collection={assets}
                              selectedAssetsIds={assetsIds}
                              titleShow={selectedAssetsAggregatedData?.permissions?.titleShow}
                              titleEditable={
                                selectedAssetsAggregatedData?.permissions?.titleEditable && isMetadataEditable
                              }
                              title={selectedAssetsAggregatedData?.title}
                              description={selectedAssetsAggregatedData?.description}
                              descriptionShow={selectedAssetsAggregatedData?.permissions?.descriptionShow}
                              descriptionEditable={
                                selectedAssetsAggregatedData?.permissions?.descriptionEditable && isMetadataEditable
                              }
                              textareaHeightNameLS={textareaHeightNameLS}
                              changeTitle={props.actions.changeTitle}
                              changeDescription={props.actions.changeDescription}
                              generateDescription={props.actions.generateDescription}
                              modifiedTitle={selectedAssetsAggregatedData?.modifiedMetaFields?.Title}
                              modifiedDescription={selectedAssetsAggregatedData?.modifiedMetaFields?.Description}
                              disabled={
                                inProgress.title
                                  || inProgress.description
                                  || state.currentLock
                              }
                              order={group.slice(1).map(({ id }) => id).filter((item) => !hidden.includes(item))}
                              isMainApp={isMainApp}
                              describingIsAvailable={props.user.subscriptionFeatures?.describing && !isPim}
                              isDescribingAllowed={!!selectedAssetsAggregatedData?.permissions?.descriptionAutogeneration && !isPim}
                              inProgressDescription={['running', 'waiting'].includes(selectedAssetsAggregatedData?.describing)}
                            />
                          </DetailsBlock>
                        );
                      case 'keywords':
                        return (
                          <Keywords
                            key={id}
                            detailsPanelVisibility={state.detailsPanelVisibility}
                            toggleVisibility={toggleVisibility}
                            blockName="detailsKeywordsVisibility"
                            blockTitle={localization.DETAILS.textKeywords}
                            isMainApp={isMainApp}
                            attach={props.actions.attachKeyword}
                            detach={props.actions.detachKeyword}
                            detachMany={props.actions.detachKeywords}
                            generate={props.actions.generateKeywords}
                            keywords={selectedAssetsAggregatedData?.keywords}
                            permissions={selectedAssetsAggregatedData?.permissions}
                            selectedAssetsIds={assetsIds}
                            assets={props.assets}
                            disabled={
                              selectedAssetsAggregatedData?.permissions?.keywordsEditable !== true
                                || state.currentLock
                                || !isMetadataEditable
                            }
                            inProgress={inProgress.keywords}
                            highlight={selectedAssetsAggregatedData?.paramsForHighlight}
                            highlightAnimationReset={() => highlightAnimationReset()}
                            modifiedField={selectedAssetsAggregatedData?.modifiedMetaFields?.Keywords}
                            changeTree={props.mainActions.changeTree}
                            openedTree={openedTree}
                            team={user.team}
                            generationIsInProgress={['running', 'waiting'].includes(selectedAssetsAggregatedData?.keywording)}
                            keywordingnIsAvailable={
                              props.user.subscriptionFeatures.keywording
                            }
                          />
                        );
                      case 'faces':
                        return (
                          <If condition={isMainApp} key={id}>
                            <Faces
                              key={id}
                              selectedFace={selectedFace}
                              selectedAssetsIds={props.assetsIds}
                              sendFaces={props.facesActions.sendFacesRecognize}
                              team={user.team}
                              user={user}
                              detailsPanelVisibility={state.detailsPanelVisibility}
                              toggleVisibility={toggleVisibility}
                              blockTitle={localization.DETAILS.textFaces}
                              assets={assets}
                              permissions={user.role.permissions}
                              blockName="detailsFacesVisibility"
                              faceDropdownOnClick={onClickBoundingBox}
                              assetActions={props.actions}
                              applyFace={props.actions.applyFace}
                              addCustomFace={props.actions.addCustomFace}
                              createFace={props.facesActions.createFace}
                              addBoungingBox={addBoungingBox}
                              tmpBoundingBoxes={tmpBoundingBoxes}
                              removeTmpBoundginBox={removeTmpBoundginBox}
                              faceRecognitionIsAvailable={
                                props.user.subscriptionFeatures.faceRecognition
                              }
                              facesInAsset={selectedAssetsAggregatedData?.faces}
                              disabled={state.currentLock}
                              onClickOnFacesEye={props.onClickOnFacesEye}
                              facesAreShown={props.facesAreShown}
                              panelName={panelName}
                              imageRevisionID={revisionID}
                              changeTree={props.mainActions.changeTree}
                              openedTree={openedTree}
                              inProgress={['running', 'waiting'].includes(selectedAssetsAggregatedData.faceRecognizing)}
                            />
                          </If>
                        );
                      case 'transcript':
                        return (
                          <If key={id} condition={isMainApp && !isMobile}>
                            <Transcript
                              key={id}
                              assetIds={assetsIds}
                              assets={assets}
                              team={user.team}
                              detailsPanelVisibility={state.detailsPanelVisibility}
                              toggleVisibility={toggleVisibility}
                              blockTitle={localization.DETAILS.textTranscript}
                              blockName="detailsTranscriptVisibility"
                              isMainApp={isMainApp}
                              transcriptPermission={
                                selectedAssetsAggregatedData?.permissions?.autogenerateTranscript
                              }
                              transcribeAction={props.actions.sendAssetsToTranscribe}
                              transcribing={selectedAssetsAggregatedData?.transcribing}
                              subscriptionFeatures={props.user.subscriptionFeatures.transcribe}
                            />
                          </If>
                        );
                      case 'assignees':
                        return (
                          <DetailsBlock
                            key={id}
                            dataQa="assignees"
                            detailsPanelVisibility={state.detailsPanelVisibility}
                            toggleVisibility={toggleVisibility}
                            blockName="detailsAssigneeVisibility"
                            blockTitle={localization.DETAILS.textAssignees}
                          >
                            <UserAssign
                              selectedAssetsIds={assetsIds}
                              assignees={selectedAssetsAggregatedData?.assignees}
                              assign={props.actions.assignUser}
                              unAssign={props.actions.unAssignUser}
                              highlight={selectedAssetsAggregatedData?.paramsForHighlight}
                              highlightAnimationReset={() => highlightAnimationReset()}
                              readOnly={
                                selectedAssetsAggregatedData?.permissions?.assetAssigneesEditable !== true
                                  || state.currentLock
                              }
                              inProgress={inProgress.assignees}
                            />
                          </DetailsBlock>
                        );
                      case 'linkedAssets':
                        return (
                          <DetailsBlock
                            key={id}
                            dataQa="linkeAseets"
                            detailsPanelVisibility={state.detailsPanelVisibility}
                            toggleVisibility={toggleVisibility}
                            blockName="detailsLinkedAssetsVisibility"
                            blockTitle={localization.LinkedAssets.title}
                          >
                            <LinkedAssets
                              selectedAssets={assetsIds}
                              disabled={
                                selectedAssetsAggregatedData?.permissions?.linkedAssetsEditable !== true
                                  || state.currentLock
                              }
                            />
                          </DetailsBlock>
                        );
                      case 'collections':
                        return (
                          <CollectionsComponent
                            key={id}
                            detailsPanelVisibility={state.detailsPanelVisibility}
                            disabled={inProgress.collections || state.currentLock}
                            toggleVisibility={toggleVisibility}
                            blockName="detailsCollectionsVisibility"
                            blockTitle={localization.DETAILS.textCollections}
                            isMainApp={isMainApp}
                            collections={selectedAssetsAggregatedData?.tags}
                            selectedAssets={assetsIds}
                            remove={props.actions.removeFromCollection}
                            editingDisabled={
                              !selectedAssetsAggregatedData?.permissions?.collectionsEditable
                                || (selectedAssetsAggregatedData?.isRestricted
                                  && !selectedAssetsAggregatedData?.permissions?.restrictedIsAttachableOrRemovable)
                            }
                            highlight={selectedAssetsAggregatedData?.paramsForHighlight}
                            highlightAnimationReset={() => highlightAnimationReset()}
                            isArchived={selectedAssetsAggregatedData?.isArchived}
                            changeTree={props.mainActions.changeTree}
                            openedTree={openedTree}
                          />
                        );
                      case 'lightboards':
                        return (
                          <If key={id} condition={isAllowedLightboards}>
                            <DetailsBlock
                              key={id}
                              dataQa="lightboards"
                              detailsPanelVisibility={state.detailsPanelVisibility}
                              toggleVisibility={toggleVisibility}
                              blockName="detailsLightboardsVisibility"
                              blockTitle={localization.DETAILS.textLightboards}
                            >
                              <Lightboards
                                collection={assets}
                                selectedAssets={assetsIds}
                                lightboards={selectedAssetsAggregatedData?.lightboards}
                                remove={props.actions.removeFromLightboard}
                                lightboardsEditable={selectedAssetsAggregatedData?.permissions?.lightboardsEditable}
                                disabled={state.currentLock}
                                changeTree={props.mainActions.changeTree}
                                openedTree={openedTree}
                              />
                            </DetailsBlock>
                          </If>
                        );
                      case 'products':
                        return (
                          <If key={id} condition={isPim}>
                            <DetailsBlock
                              key={id}
                              dataQa="products"
                              detailsPanelVisibility={state.detailsPanelVisibility}
                              toggleVisibility={toggleVisibility}
                              blockName="detailsProductsVisibility"
                              blockTitle={localization.DETAILS.textProducts}
                            >
                              <Products
                                selectedAssets={assetsIds}
                                products={selectedAssetsAggregatedData?.products}
                                disabled={state.currentLock}
                                changeTree={props.mainActions.changeTree}
                                openedTree={openedTree}
                              />
                            </DetailsBlock>
                          </If>
                        );
                      case 'assetMark':
                        return (
                          <DetailsBlock
                            key={id}
                            dataQa="assetMarks"
                            detailsPanelVisibility={state.detailsPanelVisibility}
                            toggleVisibility={toggleVisibility}
                            blockName="detailsMarksVisibility"
                            blockTitle={localization.DETAILS.textAssetMarks}
                          >
                            <AssetMarks
                              eventPrefix="InfoPanel"
                              color={selectedAssetsAggregatedData?.color}
                              changeColor={props.actions.changeColor}
                              rating={selectedAssetsAggregatedData?.rating}
                              changeRating={props.actions.changeRating}
                              flag={selectedAssetsAggregatedData?.flag}
                              changeFlag={props.actions.changeFlag}
                              flagShow={selectedAssetsAggregatedData?.permissions?.flagShow}
                              flagEditable={
                                selectedAssetsAggregatedData?.permissions?.flagEditable
                                  && isMetadataEditable
                                  && !selectedAssetsAggregatedData?.isArchived
                              }
                              colorShow={selectedAssetsAggregatedData?.permissions?.colorShow}
                              colorEditable={
                                selectedAssetsAggregatedData?.permissions?.colorEditable
                                  && isMetadataEditable
                                  && !selectedAssetsAggregatedData?.isArchived
                              }
                              ratingShow={selectedAssetsAggregatedData?.permissions?.ratingShow}
                              ratingEditable={
                                selectedAssetsAggregatedData?.permissions?.ratingEditable
                                  && isMetadataEditable
                                  && !selectedAssetsAggregatedData?.isArchived
                              }
                              selectedAssets={assetsIds}
                              highlight={selectedAssetsAggregatedData?.paramsForHighlight}
                              highlightAnimationReset={() => highlightAnimationReset()}
                              disabled={state.currentLock}
                              modifiedFlag={selectedAssetsAggregatedData?.modifiedMetaFields?.Flag}
                              modifiedColor={selectedAssetsAggregatedData?.modifiedMetaFields?.Color}
                              modifiedRating={selectedAssetsAggregatedData?.modifiedMetaFields?.Rating}
                              order={group.slice(1).map(({ id }) => id).filter((item) => !hidden.includes(item))}
                            />
                          </DetailsBlock>
                        );
                      case 'share':
                        return (
                          <If
                            key={id}
                            condition={
                              props.assetsIds.length === 1
                                /** if asset isn't external -> External links can't be shared */
                                && !(a || {}).isExternal
                                && (!selectedAssetsAggregatedData?.isRestricted
                                  || (selectedAssetsAggregatedData?.isRestricted
                                    && selectedAssetsAggregatedData?.permissions?.restrictedIsDownloadableOrShareable))
                            }
                          >
                            <Share
                              key={id}
                              asset={a}
                              toggleVisibility={toggleVisibility}
                              isVisible={state.detailsPanelVisibility.detailsAssetShareVisibility}
                              disabled={state.currentLock}
                              assetSharingAllowed={user?.subscriptionFeatures?.assetSharing}
                              inProgress={inProgress.share}
                              onChange={props.actions.changeShare}
                            />
                          </If>
                        );
                      case 'restrict':
                        return (
                          <ErrorBoundary key={id}>
                            <Restrict
                              key={id}
                              selectedAssets={assetsIds}
                              isRestricted={selectedAssetsAggregatedData?.isRestricted}
                              reason={restrictReason}
                              startAt={selectedAssetsAggregatedData?.restrictStartAt}
                              expiresAt={selectedAssetsAggregatedData?.restrictExpiresAt}
                              toggleVisibility={toggleVisibility}
                              isVisible={
                                state.detailsPanelVisibility.detailsAssetRestrictVisibility
                              }
                              disabled={state.currentLock}
                              inProgress={inProgress.restrict}
                              onChange={props.actions.changeRestrict}
                              userActions={props.userActions}
                              restrictExpiresAtPlaceholder={selectedAssetsAggregatedData?.restrictExpiresAtPlaceholder}
                              restrictStartAtPlaceholder={selectedAssetsAggregatedData?.restrictStartAtPlaceholder}
                              teamRestrictReason={teamRestrictReason}
                            />
                          </ErrorBoundary>
                        );
                      case 'archive':
                        return (
                          <If
                            key={id}
                            condition={isAllowedManageArchive
                              && !isLightboadrdTree
                              && selectedAssetsAggregatedData?.tags}
                          >
                            <ArchiveAssetTab
                              key={id}
                              selectedAssetsIds={assetsIds}
                              isArchived={selectedAssetsAggregatedData?.isArchived}
                              reason={selectedAssetsAggregatedData?.archivedByReason}
                              isVisible={
                                state.detailsPanelVisibility.detailsAssetArchiveVisibility
                              }
                              toggleVisibility={toggleVisibility}
                              disabled={
                                state.currentLock && state.detailsPanelEditable.lockAlways
                              }
                              total={total}
                            />
                          </If>
                        );
                      case 'watermarks':
                        return (
                          <If key={id} condition={isAllowedManageWatermarksToAssets && isMainApp}>
                            <WatermarkAssetsTab
                              key={id}
                              selectedAssets={assets}
                              canEditWatermarks={canEditWatermarks}
                              selectedAssetsIds={assetsIds}
                              isVisible={
                                state.detailsPanelVisibility.detailsAssetWatermarkVisibility
                              }
                              toggleVisibility={toggleVisibility}
                              disabled={
                                state.currentLock || !props.user?.subscriptionFeatures?.watermarks
                              }
                              attach={props.actions.attachWatermark}
                              allWatermarks={watermarks}
                              watermarkId={props.user?.subscriptionFeatures?.watermarks ? selectedAssetsAggregatedData?.watermarkId : null}
                              inProgress={inProgress.watermarking}
                            />
                          </If>
                        );
                      case 'map':
                        return (
                          <DetailsBlock
                            key={id}
                            dataQa="map"
                            detailsPanelVisibility={state.detailsPanelVisibility}
                            toggleVisibility={toggleVisibility}
                            blockName="detailsMapVisibility"
                            blockTitle="Map"
                          >
                            <Map collection={assets} />
                          </DetailsBlock>
                        );
                      default: {
                        const isFieldsEditable = selectedAssetsAggregatedData?.permissions?.customFieldsEditable && isMetadataEditable;
                        const filteredGroup = group.filter((field) => !(hidden.includes(field.id) || hidden.includes(field.id?.replace('meta.', ''))));
                        if (filteredGroup.length <= 1) return null;

                        return (
                          <DetailsPanelCustomFieldGroup
                            key={title}
                            data={selectedAssetsAggregatedData?.meta || {}}
                            detailsPanelVisibility={state.detailsPanelVisibility}
                            selectedAssetsIds={assetsIds}
                            toggleVisibility={toggleVisibility}
                            onChange={props.actions.changeCustomField}
                            inProgressItems={inProgress.customFields}
                            disabled={!isMainApp || state.currentLock || !isFieldsEditable}
                            customFieldsAllowed={
                              /** Allow customFields on Proofing and SAS */
                              !isMainApp || user?.subscriptionFeatures?.customFields
                            }
                            modifiedFields={selectedAssetsAggregatedData?.modifiedMetaFields}
                            group={filteredGroup}
                          />
                        );
                      }
                      }
                    })}

                  </div>
                </div>
              </>
            </Otherwise>
          </Choose>
        </div>
      )}
    </DetailsPanelConfig>
  );
};

Details.propTypes = {
  assets: array,
  assetsIds: arrayOf(string),
  inProgress: object,
  total: number,
  actions: object,
  mainActions: object,
  facesActions: object,
  panelName: string,
  textareaHeightNameLS: string,
  error: object,
  user: object,
  selectedFace: shape({}),
  onClickBoundingBox: func,
  addBoungingBox: func,
  tmpBoundingBoxes: array,
  removeTmpBoundginBox: func,
  revisionID: string,
  selectedAssetsAggregatedData: shape({}),
  allAssetsSelected: bool,
};

export default Details;
