/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import { reducer as getProducts } from './getProducts';
import { reducer as create } from './create';
import { reducer as search } from './search';
import { reducer as rename } from './rename';
import { reducer as remove } from './remove';
import { reducer as addKeywords } from './addKeywords';
import { reducer as addAssets } from './addAssets';
import { reducer as removeKeywords } from './removeKeywords';
import { reducer as duplicate } from './duplicate';
import { reducer as changeStatus } from './changeStatus';
import { reducer as changeDescription } from './changeDescription';
import { reducer as removeAssets } from './removeAssets';
import { reducer as fetchAssets } from './fetchAssets';
import { reducer as getVariants } from './getVariants';
import { reducer as createOption } from './createOption';
import { reducer as deleteOption } from './deleteOption';
import { reducer as createOptionValue } from './createOptionValue';
import { reducer as deleteOptionValue } from './deleteOptionValue';
import { reducer as changePrice } from './changePrice';
import { reducer as changeCompareAtPrice } from './changeCompareAtPrice';
import { reducer as changeTaxable } from './changeTaxable';
import { reducer as changeTracked } from './changeTracked';
import { reducer as changeSku } from './changeSku';
import { reducer as changeBarcode } from './changeBarcode';
import { reducer as changeInventoryPolicy } from './changeInventoryPolicy';
import { reducer as changeWeight } from './changeWeight';
import { reducer as deleteVariant } from './deleteVariant';
import { reducer as changeRequiresShipping } from './changeRequiresShipping';
import { reducer as changeUnitCost } from './changeUnitCost';
import { reducer as addAssetToVariant } from './addAssetToVariant';
import { reducer as pullProduct } from './pullProduct';
import { reducer as removeAssetFromVariant } from './removeAssetFromVariant';
import { reducer as fetchThumbnails } from './fetchThumbnails';
import { reducer as updateAssetFields } from './updateMediaFields';
import { reducer as setMediaThumbnails } from './setMediaThumbnails';
import { reducer as changeHandle } from './changeHandle';

const initialState = {
  isLoaded: true,
  isLoading: false,
  items: [],
  filtered: null,
  isBusy: false,
  searchQuery: '',
  nameCreatingProduct: null,
  error: null,
  search: {
    items: [],
    total: 0,
    isLoading: false,
    isPageLoading: false,
    error: null,
  },
  // sortType: null,
  sortTypeUpdating: false,
};

const productsReducer = createReducer(initialState, (builder) => {
  getProducts(builder);
  create(builder);
  search(builder);
  rename(builder);
  remove(builder);
  addKeywords(builder);
  addAssets(builder);
  removeKeywords(builder);
  duplicate(builder);
  changeStatus(builder);
  removeAssets(builder);
  fetchAssets(builder);
  getVariants(builder);
  createOption(builder);
  deleteOption(builder);
  createOptionValue(builder);
  deleteOptionValue(builder);
  changeDescription(builder);
  changePrice(builder);
  changeCompareAtPrice(builder);
  changeTaxable(builder);
  changeTracked(builder);
  changeSku(builder);
  changeBarcode(builder);
  changeInventoryPolicy(builder);
  changeWeight(builder);
  deleteVariant(builder);
  changeRequiresShipping(builder);
  changeUnitCost(builder);
  addAssetToVariant(builder);
  pullProduct(builder);
  removeAssetFromVariant(builder);
  fetchThumbnails(builder);
  updateAssetFields(builder);
  setMediaThumbnails(builder);
  changeHandle(builder);
});

export default productsReducer;
