import React from 'react';
import { Icon } from '@picsio/ui';
import { Warning } from '@picsio/icons';
import PropTypes from 'prop-types';
import s from './ErrorsList.module.scss';

const ERROR_CODE_TEXT = {
  BY_FILE_SIZE: 'Exceeding file size:',
  BY_RESOLUTION_LIMIT: 'Exceeding photo resolution:',
  BY_FILE_TYPE: 'Unsupported format:',
  BY_DURATION_LIMIT: 'Exceeding video duration:',
  ALREADY_ATTACHED: 'Already added to this product:',
};

export default function ErrorsList({ codes = {} }) {
  const codeKeys = Object.keys(codes);
  return (
    <ul className={s.ErrorsList}>
      {codeKeys.map((code) => (
        <li key={code} className={s.item}>
          <div className={s.title}>
            <span className={s.icon}>
              <Icon size="md">
                <Warning />
              </Icon>
            </span>
            <span>{ERROR_CODE_TEXT[code]}</span>
          </div>
          <div className={s.text}>{codes[code].join(', ')}</div>
        </li>
      ))}
    </ul>
  );
}

ErrorsList.propTypes = {
  codes: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string),
  ).isRequired,
};
