import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { TextField, InputControlLabel, Select } from '@picsio/ui';
import { useDispatch } from 'react-redux';
import DetailsBlock from '../DetailsBlock';
import { changeWeight, changeRequiresShipping } from '../../store/products/actions';

import s from './styles.module.scss';

const WEIGHT_OPTIONS = [
  {
    id: 'GRAMS',
    value: 'g',
  },
  {
    id: 'KILOGRAMS',
    value: 'kg',
  },
  {
    id: 'OUNCES',
    value: 'oz',
  },
  {
    id: 'POUNDS',
    value: 'lb',
  },
];

const EMPTY_VALUE = {};

const Shipping = ({
  productId,
  variantId,
  requiresShipping,
  currentValue = EMPTY_VALUE,
  detailsPanelVisibility,
  toggleVisibility,
  blockName,
  blockTitle,
}) => {
  const dispatch = useDispatch();
  const [weight, setWeight] = useState(currentValue?.value || 0);

  useEffect(() => {
    setWeight(currentValue?.value || 0);
  }, [currentValue]);

  const handleChangeRequiresShipping = useCallback(() => {
    dispatch(changeRequiresShipping({ productId, variantId, value: !requiresShipping }));
  }, [dispatch, productId, requiresShipping, variantId]);

  const handleChangeWeight = useCallback((event) => {
    setWeight(event.target.value);
  }, []);

  const saveValue = useCallback(() => {
    dispatch(changeWeight({
      productId, variantId, value: Number(weight), unit: currentValue?.unit,
    }));
  }, [dispatch, productId, variantId, weight, currentValue]);

  const handleChangeUnit = useCallback((event) => {
    dispatch(changeWeight({
      productId, variantId, value: Number(weight), unit: event.target.value,
    }));
  }, [dispatch, productId, variantId, weight]);

  const renderUnitValue = useCallback((value) => {
    const item = WEIGHT_OPTIONS.find(({ id }) => id === value);
    return item?.value || '';
  }, []);

  return (
    <DetailsBlock
      dataQa="productShipping"
      detailsPanelVisibility={detailsPanelVisibility}
      toggleVisibility={toggleVisibility}
      blockName={blockName}
      blockTitle={blockTitle}
    >
      <div className={s.row}>
        <InputControlLabel
          control="checkbox"
          label="This is a physical product"
          value={requiresShipping}
          onChange={handleChangeRequiresShipping}
        />
      </div>
      <div className={s.row}>
        <If condition={requiresShipping}>
          <TextField
            value={weight}
            label="Weight"
            onChange={handleChangeWeight}
            onBlur={saveValue}
          />
          <Select
            style={{ width: 50, height: 30 }}
            value={currentValue?.unit}
            options={WEIGHT_OPTIONS}
            renderValue={renderUnitValue}
            dataId="id"
            dataValue="value"
            onChange={handleChangeUnit}
          />
        </If>
      </div>
    </DetailsBlock>
  );
};

export default memo(Shipping);
