import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import * as utils from '../../shared/utils';
import localization from '../../shared/strings';
import picsioConfig from '../../../../../config';

/**
 * Generates formatted html fragment for assets counter in top search field.
 * Possible morphemes:
 * 0 => 'No assets'
 * 1 => '1 asset'
 * many => '1 234 567 assets'
 *
 * @param {Object} props
 * @param {number} props.amount
 * @returns {JSX}
 */
function Counter({ amount, className = '', view = 'media' }) {
  return (
    <div className={`searchHeader-counter ${className}`}>
      <Choose>
        <When condition={amount === 0}>
          <If condition={view === 'products'}>
            <span>{localization.SEARCH.text.noProducts}</span>
          </If>
          <If condition={view === 'media'}>
            <span>{localization.SEARCH.text['No assets']}</span>
          </If>
        </When>
        <Otherwise>
          <>
            {`${utils.formatNumberWithSpaces(amount)}
                ${pluralize(`${view === 'products' ? 'product' : 'asset'}`, amount, false)}`}
          </>
        </Otherwise>
      </Choose>
    </div>
  );
}

Counter.propTypes = {
  amount: PropTypes.number.isRequired,
  className: PropTypes.string
};

export default Counter;
