import React from 'react';
import PropTypes from 'prop-types';
import _throttle from 'lodash/throttle';
import _orderBy from 'lodash/orderBy';
import _uniq from 'lodash/uniq';
import cn from 'classnames';
import { AutoSizer, List } from 'react-virtualized';
import styled from 'styled-components';
import { Popper, Button } from '@picsio/ui';
import localization from '../../shared/strings';
import Tag from '../Tag';
import Icon from '../Icon';
import * as utils from '../../shared/utils';
import ua from '../../ua';
import Logger from '../../services/Logger';
import Item from './view/item';
import Creator from './view/Creator';
import SkeletonItem from './view/SkeletonItem';
import ModifiedField from '../details/view/ModifiedField';
import 'react-virtualized/styles.css';
import Spinner from '../../UIComponents/Spinner';
import WithSkeletonTheme from '../WithSkeletonTheme';
import copyTextToClipboard from '../../helpers/copyTextToClipboard';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.lastFilter = null;
    this.creating = false;
    this.dropdownElement = React.createRef();
    this.dropdownBodyWrapper = React.createRef();
    this.dropdownInput = React.createRef();
    this.dropdownList = React.createRef();

    this.state = {
      focusedIndex: null,
      filterValue: '',
      filter: [],
      isDropdownOpened: false,
      isSearching: false,
      // createLinkVisible: false, // @TODO: temporary remove, because added 'Creator'. Will remove latter.
      filteredItems: props.items,
      newItems: [],
      rowHeight: 0,
      position: props.position,
    };

    this.throttledKeyDownHandler = _throttle(this.throttledKeyDownHandler, 160);
  }

  componentDidMount() {
    this.setState({ rowHeight: this.props.type !== 'user' ? 39 : 48 });
    if (this.props.autoFocus && this.dropdownInput && this.dropdownInput.current) {
      this.dropdownInput.current.focus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.checkedItems) !== JSON.stringify(this.props.checkedItems)) {
      this.updateView();
    }

    if (
      this.creating
      && this.lastFilter !== null
      && prevProps.items.length !== this.props.items.length
    ) {
      let filteredItems = this.lastFilter.split(',');
      filteredItems = filteredItems.map((item) => item.trim());
      const checkedItems = this.props.items.filter((item) => filteredItems.includes(item.title));
      if (checkedItems.length) {
        this.props.onCheckedHandler(checkedItems);
        this.lastFilter = null;
        this.creating = false;
      }
    }

    if (this.props.autoFocus && this.dropdownInput && this.dropdownInput.current) {
      this.dropdownInput.current.focus();
    }

    if (!ua.browser.isNotDesktop() && prevProps.normalizeDropdownPosition) {
      this.updatePosition(prevState.position);
    }
    /** update items in the state if new page loaded to the store */
    if (prevProps.items !== this.props.items && !this.state.filterValue) {
      this.setState({ filteredItems: this.props.items });
    }

    if (prevProps.disabled !== this.props.disabled) {
      this.hideDropdown();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleWindowClick);
    window.removeEventListener('touchstart', this.handleWindowClick);
  }

  handleWindowClick = (event) => {
    const dropdownElement = this.dropdownElement.current;
    const dropdownBodyWrapper = this.dropdownBodyWrapper.current;
    if (
      event.target !== dropdownElement
      && !event.target.classList.contains('btnRemove')
      && !dropdownBodyWrapper?.contains(event.target)
      && !dropdownElement.contains(event.target)
      && this.state.isDropdownOpened === true
      && !this.props.autoFocus
    ) {
      this.hideDropdown();
    }
  };

  openDropdown = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    document.body.classList.add('dropdownIsActive');
    if (this.props.singleChose) {
      this.props.onDropdownOpenClickHandler();
    }
    this.dropdownInput?.current?.focus();
    this.setState({ isDropdownOpened: true });

    window.addEventListener('click', this.handleWindowClick);
    window.addEventListener('touchstart', this.handleWindowClick);
  };

  hideDropdown = () => {
    document.body.classList.remove('dropdownIsActive');
    this.dropdownInput && this.dropdownInput.current && this.dropdownInput.current.blur();
    window.removeEventListener('click', this.handleWindowClick);
    window.removeEventListener('touchstart', this.handleWindowClick);
    if (this.props.onBlur) {
      this.props.onBlur();
    }
    this.setState({ focusedIndex: null, isDropdownOpened: false });
  };

  onChangeHandler = (event) => {
    const value = utils.sanitizeXSS(event.target.value, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] }, true);
    this.setState({ filterValue: value }, () => {
      this.setFilter(value);
    });
  };

  filterItem = (item, filterValues) => {
    if (filterValues.length === 0) return true;
    const name = item.title.toLowerCase();
    return filterValues.some((item) => name.includes(item.toLowerCase()));
  };

  setFilter = async (value, isPageLoading) => {
    let { focusedIndex } = this.state;
    const { filterFunc } = this.props;
    let newItems = [];

    const normalizedValue = value.replace(/,  +/g, ', ');
    let filter = normalizedValue.split(',').map((item) => item.trim());
    let words = normalizedValue.split(/[\s,]+/).map((item) => item.trim());
    filter = _uniq(filter);
    words = _uniq(words);
    words = words.filter(Boolean);

    // remove Whitespace-only Array Elements
    filter = filter.filter((str) => /\S/.test(str));

    let filteredItems = [];
    if (filterFunc) {
      if (!isPageLoading) this.setState({ isSearching: true });
      filteredItems = await filterFunc(normalizedValue);
      newItems = this.getItemsDifference(filter, [...this.props.items, ...filteredItems]);
    } else {
      filteredItems = (this.props.items || []).filter((item) => this.filterItem(item, words));
      newItems = this.getItemsDifference(filter, this.props.items);
      let relevanceItems = [];
      if (filteredItems.length && filter.length) {
        relevanceItems = filteredItems.map((entry) => {
          let points = 0;
          const titleNormalized = entry.title.toLowerCase();
          const searchValue = filter[0];
          const searchValueNormalized = searchValue.toLowerCase();

          if (titleNormalized.includes(searchValueNormalized)) {
            points += 1;
          }

          if (titleNormalized.startsWith(searchValueNormalized)) {
            points += 1;
          }

          if (titleNormalized === searchValueNormalized) {
            points += 1;
          }

          if (entry.title === searchValue) {
            points += 1;
          }

          if (words.every((item) => titleNormalized.includes(item.toLowerCase()))) {
            points += 1;
          }

          if (words.every((item) => entry.title.includes(item))) {
            points += 1;
          }

          return { ...entry, points };
        });

        relevanceItems = _orderBy(relevanceItems, ['points', 'title'], ['desc', 'asc']);

        filteredItems = relevanceItems;
      }
    }

    if (
      filteredItems.length === 0
      || focusedIndex >= filteredItems.length
      || focusedIndex === null
    ) {
      focusedIndex = 0;
    }

    if (this.props.createHandler && this.showCreateLink(this.state.filterValue, filteredItems)) {
      if (newItems.length) {
        const creator = {
          creator: true,
          type: this.props.type,
          onClick: this.props.createHandler ? this.createItems : this.attachItems,
          value: this.state.filterValue,
          filter,
          newItems,
        };
        filteredItems = [creator, ...filteredItems];
        this.dropdownList
          && this.dropdownList.current
          && this.dropdownList.current.forceUpdateGrid();
      }
    }

    this.setState({
      filter,
      newItems,
      filteredItems,
      focusedIndex,
      isSearching: false,
    });
  };

  showCreateLink = (value, _filteredItems) => {
    const filteredItems = _filteredItems || this.state.filteredItems;

    const isItemsContainValue = filteredItems.some(
      (item) => item.title && item.title.toLowerCase() === value.toLowerCase(),
    );
    return value !== '' && !isItemsContainValue;
  };

  resetFilter = () => {
    this.setState({
      newItems: [],
      filterValue: '',
      filter: [],
      filteredItems: [],
    });
  };

  toggleCheck = (item) => {
    const { checkedItems, onCheckedHandler, onUncheckedHandler } = this.props;
    const { filteredItems } = this.state;

    if (!item) {
      return;
    }
    const itemIndex = checkedItems.findIndex((checkedItem) => checkedItem._id === item._id);
    const itemIndexInList = filteredItems.findIndex((checkedItem) => checkedItem._id === item._id);

    this.setState({ focusedIndex: itemIndexInList }, () => {
      if (itemIndex === -1) onCheckedHandler(item);
      else onUncheckedHandler(item);
      this.resetFilter();
      this.dropdownInput.current.focus();
    });
  };

  focusHandler = (event) => {
    const { isDropdownOpened } = this.state;
    if (!isDropdownOpened) {
      this.openDropdown();
    }
    let value = '';
    if (event.target && event.target.value) {
      value = event.target.value;
    }
    if (this.state.filterValue !== value) {
      this.setFilter(value);
    }
  };

  throttledKeyDownHandler = (event) => {
    const {
      keyCode, shiftKey, altKey, metaKey, ctrlKey, target,
    } = event;
    const { checkedItems, showChips } = this.props;
    let { focusedIndex } = this.state;
    const {
      filter, filteredItems, newItems, isSearching,
    } = this.state;
    const {
      isItemsLoaded, isOnlyCreate, createHandler, singleChose,
    } = this.props;

    if (!isItemsLoaded || isSearching) return;

    // Up
    if (keyCode === 38) {
      event.stopPropagation();
      event.preventDefault();
      focusedIndex = focusedIndex !== null ? focusedIndex - 1 : 0;
      if (focusedIndex < 0) return;
    }

    // Down
    if (keyCode === 40) {
      event.stopPropagation();
      event.preventDefault();
      focusedIndex = focusedIndex !== null ? focusedIndex + 1 : 0;
      if (focusedIndex > filteredItems.length - 1) return;
    }

    // Enter
    if (keyCode === 13 && focusedIndex !== null) {
      event.stopPropagation();
      event.preventDefault();
      // Alt + Enter
      if (altKey && filter.length === 1) {
        // Toogle 100% identical from filtered values
        const ideticalValueIndex = filteredItems.findIndex(
          (item) => item.title?.toLowerCase() === filter[0].toLowerCase(),
        );
        if (ideticalValueIndex !== -1) {
          Logger.log('User', 'DropdownPressedAltEnter');
          return this.toggleCheck(filteredItems[ideticalValueIndex]);
        }
      }

      // Shift + Enter
      if (shiftKey && filter.length === 1) {
        // Handle creator if it exists
        const creatorIndex = filteredItems.findIndex((item) => item.creator);
        if (creatorIndex !== -1) {
          Logger.log('User', 'DropdownPressedShiftEnter');
          return filteredItems[creatorIndex].onClick();
        }
      }

      if (filter.length > 1) {
        if (isOnlyCreate) {
          if (filteredItems.length === 0 && newItems.length) {
            this.createItems();
          } else {
            this.attachItems();
          }
        } else if (createHandler) {
          this.createItems();
        } else {
          this.attachItems();
        }
        return;
      }

      if (filter.length === 1 && filteredItems.length === 0 && createHandler) {
        this.createItems();
      }

      // Handle creator by Enter
      if (filteredItems[focusedIndex] && filteredItems[focusedIndex].creator) {
        return filteredItems[focusedIndex].onClick();
      }

      return this.toggleCheck(filteredItems[focusedIndex]);
    }

    // Esc
    if (keyCode === 27) {
      if (this.state.filter.length === 0) {
        this.hideDropdown();
        return;
      }
      this.setFilter('');
      return;
    }

    // Backspace
    if (keyCode === 8 && !singleChose && !target?.value?.length && !metaKey && !ctrlKey && showChips) {
      this.removeLastItem();
    }

    // Tab
    if (keyCode === 9) {
      this.hideDropdown();
      return;
    }

    const isMAC = navigator.userAgent.toString().toLowerCase().includes('mac');
    /** Cmd/Ctrl + C -> copy all selected titles */
    if (checkedItems.length && keyCode === 67 && ((isMAC && metaKey) || (!isMAC && ctrlKey))) {
      event.stopPropagation();
      event.preventDefault();
      const text = checkedItems
        .map(({ _id }) => this.props.items.find((item) => item._id === _id))
        .filter(Boolean)
        .map(({ title }) => title)
        .join(', ');

      copyTextToClipboard(text, localization.DROPDOWN.tooltipItemsCopied);
      this.hideDropdown();
      return;
    }

    this.setState({ focusedIndex });
  };

  keyDownHandler = (event) => {
    event.persist();
    this.throttledKeyDownHandler(event);
  };

  getItemsDifference = (titles, items) => titles.map((title) => {
    if (!items.some((i) => i.title.toLowerCase() === title.toLowerCase())) return title;
    return null;
  }).filter(Boolean);

  createItems = () => {
    const filteredItems = this.state.filter;
    this.lastFilter = filteredItems.length > 1 ? filteredItems.join(',') : filteredItems[0];
    if (this.props.createHandler) {
      if (this.props.isOnlyCreate) {
        if (filteredItems.length > 1) {
          this.props.createHandler(this.lastFilter, undefined, true);
        } else {
          this.props.createHandler(this.lastFilter.trim());
        }
        this.creating = true;
      } else {
        this.props.onCheckedHandler({ title: filteredItems.join(',') });
      }
      this.resetFilter();
    }
  };

  attachItems = () => {
    const { items } = this.props;
    const { filter } = this.state;
    const checkedItems = items.filter((item) => filter.includes(item.title));
    if (checkedItems.length) {
      this.props.onCheckedHandler(checkedItems);
    }
    this.resetFilter();
  };

  removeLastItem = () => {
    const { filter } = this.state;
    const { checkedItems } = this.props;

    if (filter.length === 0 || filter[0] === '' || filter === undefined) {
      if (checkedItems.length > 0) {
        this.toggleCheck(checkedItems[checkedItems.length - 1]);
      }
    }
  };

  handleBlur = () => { };

  renderTags = () => {
    const {
      items, checkedItems, onItemClickHandler, singleChose,
      type, isRole, readOnly, isClose, showDeletedOptions,
    } = this.props;
    let selectedTags = [];

    if (showDeletedOptions) {
      selectedTags = checkedItems;
    } else {
      checkedItems.forEach((checkedItem) => {
        const item = items.find(({ _id }) => _id === checkedItem._id);
        if (item && selectedTags.findIndex(({ _id }) => _id === item._id) === -1) {
          selectedTags.push({
            _id: item._id,
            title: item.title,
            url: item.url,
          });
        }
      });
    }

    const handleTagOnClick = () => {
      singleChose && this.openDropdown();
    };

    return selectedTags.map((item) => (
      <div className="tagKeywordWrapper" onClick={handleTagOnClick} key={item._id}>
        <Tag
          singleChose={singleChose}
          type={type}
          text={item.title}
          avatar={item.url}
          isRole={isRole}
          onClick={() => onItemClickHandler(item._id)}
          onClose={!readOnly && !isClose && !singleChose ? (event) => this.removeItem(event, item) : null}
        />
      </div>
    ));
  };

  renderCreateLink = () => {
    const { props, state } = this;
    const createText = props.createText || localization.DROPDOWN.create;

    return (
      <div className="dropdown-create-link">
        <span
          className="dropdown-create"
          onClick={props.createHandler ? this.createItems : this.attachItems}
        >
          {props.createHandler && state.newItems.length > 0 && (
            <>
              <Icon name="addCollection" /> {createText}
              {state.newItems.length > 0 && 's'} "
              {state.newItems.length > 0 ? state.newItems.join(', ') : state.filter.join(', ')}"
              <br />
            </>
          )}

          {props.createHandler && state.filter.length > 1 && state.filteredItems.length > 0 && (
            <>
              {props.createHandler && state.newItems.length > 0 && <>and</>} attach{' '}
              {state.filter.length} selected keywords
            </>
          )}
        </span>
      </div>
    );
  };

  rowRenderer = ({ key, index, style }) => {
    const { hasNextPage, loadNextPage, inProgress } = this.props;
    const { filteredItems: items, filterValue } = this.state;
    const isSkeleton = !items[index];
    const isChecked = Boolean(
      this.props.checkedItems.find((checkedItem) => checkedItem._id === items[index]?._id),
    );

    if (isSkeleton && hasNextPage && loadNextPage && !inProgress) {
      setTimeout(() => {
        if (filterValue) this.setFilter(filterValue, true);
        else loadNextPage();
        /** to prevent scroll to top after page is loaded */
        this.setState({ focusedIndex: items.length });
      }, 200);
    }

    return (
      <Choose>
        <When condition={isSkeleton}>
          <div style={style} key={key}>
            <SkeletonItem />
          </div>
        </When>
        <When condition={items[index].creator}>
          <Creator
            key={key}
            item={items[index]}
            checked={isChecked}
            highlighted={index === this.state.focusedIndex}
            style={style}
            inProgress={this.props.inProgress}
          />
        </When>
        <Otherwise>
          <Item
            key={key}
            item={items[index]}
            checked={isChecked}
            highlighted={index === this.state.focusedIndex}
            toggleCheck={this.toggleCheck}
            singleChose={this.props.singleChose}
            style={style}
          />
        </Otherwise>
      </Choose>
    );
  };

  removeItem(event, item) {
    event.stopPropagation();
    if (!item) return;

    const { state, props, dropdownInput } = this;
    const { onUncheckedHandler } = props;
    const { isDropdownOpened } = state;

    onUncheckedHandler(item);

    if (isDropdownOpened) {
      dropdownInput.current.focus();
    }
  }

  updateView() {
    this.setFilter('');
  }

  updatePosition(prevPosition) {
    const dropdownElement = this.dropdownElement.current;
    const dropdownBodyWrapper = this.dropdownBodyWrapper.current;

    if (dropdownElement && dropdownBodyWrapper) {
      const dropdownEl = dropdownElement.getBoundingClientRect();
      const bodyWrapper = dropdownBodyWrapper.getBoundingClientRect();

      if (this.props.position === 'right' || this.props.position === 'left') {
        if (window.innerWidth < (bodyWrapper.width + dropdownEl.right)) {
          if (prevPosition !== 'left') { this.setState({ position: 'left' }); }
        } else if (prevPosition !== 'right') { this.setState({ position: 'right' }); }
        return;
      }

      if (window.innerHeight < (bodyWrapper.height + dropdownEl.bottom)) {
        if (prevPosition !== 'top') { this.setState({ position: 'top' }); }
      } else if (prevPosition !== 'bottom') this.setState({ position: 'bottom' });
    }
  }

  render() {
    const {
      filterText,
      items,
      additionalClass,
      disabled,
      icon,
      isItemsLoaded,
      placeholderIcon,
      placeholder,
      createHandler,
      createPlaceholderText,
      readOnly,
      error,
      inProgress,
      highlight,
      modifiedField,
      disablePortal,
      singleChose,
      changed,
      fromSearch,
      visibleItemsLength,
      showChips,
      showAddButton,
    } = this.props;
    const { props, state } = this;
    const {
      filteredItems, filterValue, filter, isDropdownOpened, position,
    } = state;
    const inputSize = filterText?.length || 10;
    let heightOfPopper = 0;

    if (filteredItems.length === 0) {
      heightOfPopper = 74;
    } else if (filteredItems.length > 10) {
      heightOfPopper = state.rowHeight * 10;
    } else {
      heightOfPopper = filteredItems.length * state.rowHeight;
    }

    const placeholderIconClassName = placeholderIcon && `placeholder-icon ${placeholderIcon}`;

    return (
      <div
        className={cn({
          dropdownMainWrapper: true,
          'dropdown-focused': isDropdownOpened,
          highlightBlink: highlight && highlight.includes(props.highlightAnimationResetName),
        })}
        onAnimationEnd={() => props.highlightAnimationReset(props.highlightAnimationResetName)}
        ref={this.dropdownElement}
      >
        <div
          className={cn({
            dropdown: true,
            disabled,
            readOnly,
            'dropdown-focused': isDropdownOpened,
            [additionalClass]: additionalClass,
            isError: error,
            inProgress,
          })}
          ref={(node) => {
            this.node = node;
          }}
        >
          <div
            className={cn('dropdown-tags', {
              'dropdown-tags-icon': typeof icon === 'function' ? !!icon() : !!icon,
              'dropdown-modifiedFeild': modifiedField,
              changed: changed && fromSearch,
            })}
          >
            <If condition={icon}>
              <If condition={typeof icon === 'string'}>
                <Icon name={inProgress ? 'sync' : props.icon} />
              </If>
              <If condition={typeof icon === 'function'}>
                <If condition={inProgress}>
                  <Icon name="sync" />
                </If>
                <If condition={!inProgress}>
                  {icon()}
                </If>
              </If>
            </If>
            <If condition={!readOnly}>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={null}
                aria-label="open"
                className={cn('dropdown-opener', { changed: changed && fromSearch })}
                onClick={this.openDropdown}
              />
            </If>
            <If condition={showChips}>
              {this.renderTags()}
            </If>
            <Choose>
              <When condition={!readOnly && !singleChose}>
                <div className="dropdown-filter">
                  <input
                    type="text"
                    ref={this.dropdownInput}
                    size={inputSize}
                    value={state.filterValue}
                    placeholder={filterText}
                    onFocus={this.focusHandler}
                    onKeyDown={this.keyDownHandler}
                    onBlur={this.handleBlur}
                    onChange={this.onChangeHandler}
                  />
                </div>
                <If condition={modifiedField}>
                  <ModifiedField field={modifiedField} />
                </If>
              </When>
              <When condition={!props.checkedItems.length}>
                <div className="dropdown-tags-placeholder">{filterText}</div>
              </When>
              <Otherwise>{null}</Otherwise>
            </Choose>
          </div>
          <If condition={isDropdownOpened}>
            <Popper
              target={this.dropdownElement}
              className="dropdownElementPopper"
              isOpen={isDropdownOpened}
              onClose={this.hideDropdown}
              placement={`${((!ua.browser.isNotDesktop() || ua.browser.isTablet()) && position) ? position : 'bottom'}-start`}
              disablePortal={disablePortal}
              offset={[0, 10]}
              hide={false}
              arrow={false}
              autoWidth
              outsideClickListener
            >
              <div
                className={cn('dropdown dropdown-body-wrapper')}
                ref={this.dropdownBodyWrapper}
              >
                <If condition={inProgress && singleChose}>
                  <Spinner />
                </If>
                <div className={cn({ inProgress: inProgress && singleChose })}>
                  <If condition={ua.browser.isNotDesktop()}>
                    <>
                      <header className="toolbar dropdown-header">
                        <div className="toolbarGroup">
                          <button
                            className="toolbarButton dropdown-close"
                            onClick={this.hideDropdown}
                            role="button"
                          >
                            <Icon name="regularPrevArrow" />
                          </button>
                        </div>
                        <div className="toolbarGroup">
                          <Choose>
                            <When condition={!readOnly}>
                              <div className="dropdown-filter">
                                <input
                                  type="text"
                                  size={inputSize}
                                  value={state.filterValue}
                                  placeholder={filterText}
                                  onFocus={this.focusHandler}
                                  onKeyDown={this.keyDownHandler}
                                  onBlur={this.handleBlur}
                                  onChange={this.onChangeHandler}
                                  autoFocus
                                />
                              </div>
                            </When>
                            <When condition={!props.checkedItems.length}>
                              <div className="dropdown-tags-placeholder">{filterText}</div>
                            </When>
                            <Otherwise>{null}</Otherwise>
                          </Choose>
                        </div>
                      </header>
                      <If condition={props.checkedItems.length}>
                        <div
                          className={cn('dropdown-tags', {
                            'dropdown-tags-icon': props.icon,
                            'dropdown-modifiedFeild': modifiedField,
                          })}
                        >
                          <If condition={icon}>
                            <Icon name={inProgress ? 'sync' : props.icon} />
                          </If>
                          {this.renderTags()}
                        </div>
                      </If>
                    </>
                  </If>
                  <Choose>
                    <When condition={items.length === 0 && !state.filter.length && !props.singleChose}>
                      <div className="dropdown-body">
                        <div className="placeholder">
                          <If condition={placeholderIconClassName}>
                            <div className={placeholderIconClassName}>
                              <Icon name={placeholderIcon} />
                            </div>
                          </If>
                          <If condition={placeholder}>
                            <div className="placeholder-text"> {placeholder} </div>
                          </If>
                          <If condition={createHandler}>
                            <div>{createPlaceholderText}</div>
                          </If>
                        </div>
                      </div>
                    </When>
                    <When condition={items.length > 0 || state.filter.length > 0 || props.singleChose}>
                      <WithSkeletonTheme>
                        <DropdownBody
                          className="dropdown-body"
                          ref={(node) => {
                            this.dropdownBody = node;
                          }}
                          itemsLength={filteredItems.length}
                          rowHeight={state.rowHeight}
                          position={position}
                          singleChose={singleChose}
                          visibleItemsLength={state.isSearching ? 3 : visibleItemsLength}
                        >
                          <If condition={singleChose}>
                            <input
                              type="text"
                              ref={this.dropdownInput}
                              size={inputSize}
                              value={state.filterValue}
                              placeholder={filteredItems.length > 0 ? 'Search for person' : 'Type person name'}
                              onFocus={this.focusHandler}
                              onKeyDown={this.keyDownHandler}
                              onBlur={this.handleBlur}
                              onChange={this.onChangeHandler}
                              readOnly={!isItemsLoaded}
                            />
                          </If>
                          <Choose>
                            <When condition={state.isSearching}>
                              <SkeletonItem width={100} />
                              <SkeletonItem width={150} />
                              <SkeletonItem width={200} />
                            </When>
                            <When condition={filteredItems.length !== 0}>
                              <AutoSizer>
                                {({ height, width }) => {
                                  const dropdownInputHeight = this.dropdownInput?.current?.offsetHeight;
                                  return (
                                    <List
                                      ref={this.dropdownList}
                                      height={singleChose ? height - dropdownInputHeight : height}
                                      rowCount={props.hasNextPage ? filteredItems.length + 1 : filteredItems.length}
                                      rowHeight={state.rowHeight}
                                      rowRenderer={this.rowRenderer}
                                      width={width}
                                      scrollToRow={0}
                                      scrollToIndex={state.focusedIndex || 0}
                                      data={props.checkedItems}
                                    />
                                  );
                                }}
                              </AutoSizer>
                            </When>
                            <Otherwise>
                              <div className="dropdown-noresults">
                                <Choose>
                                  <When condition={isItemsLoaded}>{`${localization.DROPDOWN.noResults
                                  } «${filter.join(', ')}»`}
                                  </When>
                                  <Otherwise>{localization.SPINNERS.LOADING}</Otherwise>
                                </Choose>
                              </div>
                            </Otherwise>
                          </Choose>
                        </DropdownBody>
                      </WithSkeletonTheme>
                    </When>
                    <Otherwise>{null}</Otherwise>
                  </Choose>
                </div>
              </div>
            </Popper>
          </If>
        </div>
        <If condition={showAddButton && this.showCreateLink(filterValue, filteredItems) && !state.isSearching}>
          <Button
            className="dropdown-add-button"
            color="primary"
            variant="contained"
            onClick={createHandler ? this.createItems : this.attachItems}
          >
            Add
          </Button>
        </If>
      </div>
    );
  }
}

Dropdown.defaultProps = {
  inProgress: false,
  disabled: false,
  isOnlyCreate: false,
  isItemsLoaded: true,
  readOnly: false,
  additionalClass: null,
  createText: null,
  filterText: null,
  createPlaceholderText: null,
  placeholderIcon: null,
  placeholder: null,
  icon: null,
  highlight: [],
  highlightAnimationReset: () => { },
  highlightAnimationResetName: null,
  createHandler: () => { },
  onBlur: () => { },
  modifiedField: null,
  onItemClickHandler: () => { },
  onUncheckedHandler: () => { },
  disablePortal: false,
  isClose: false,
  isRole: false,
  changed: false,
  fromSearch: false,
  position: 'bottom',
  singleChose: false,
  checkedItems: [],
  visibleItemsLength: null,
  filterFunc: null,
  loadNextPage: null,
  hasNextPage: false,
  showDeletedOptions: false,
  showChips: true,
  normalizeDropdownPosition: true,
  showAddButton: false,
};

Dropdown.propTypes = {
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
      descr: PropTypes.string,
    }),
  ).isRequired,
  isItemsLoaded: PropTypes.bool,
  checkedItems: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
  ),
  onItemClickHandler: PropTypes.func,
  onCheckedHandler: PropTypes.func.isRequired,
  onUncheckedHandler: PropTypes.func,
  createHandler: PropTypes.func,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderIcon: PropTypes.string,
  filterText: PropTypes.string,
  additionalClass: PropTypes.string,
  createText: PropTypes.string,
  createPlaceholderText: PropTypes.string,
  onBlur: PropTypes.func,
  highlight: PropTypes.arrayOf(PropTypes.string),
  highlightAnimationReset: PropTypes.func,
  highlightAnimationResetName: PropTypes.string,
  isOnlyCreate: PropTypes.bool,
  type: PropTypes.string.isRequired,
  inProgress: PropTypes.bool,
  modifiedField: PropTypes.shape({
    name: PropTypes.string,
  }),
  readOnly: PropTypes.bool,
  disablePortal: PropTypes.bool,
  isClose: PropTypes.bool,
  isRole: PropTypes.bool,
  changed: PropTypes.bool,
  fromSearch: PropTypes.bool,
  position: PropTypes.string,
  singleChose: PropTypes.bool,
  visibleItemsLength: PropTypes.number,
  filterFunc: PropTypes.func,
  loadNextPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  showDeletedOptions: PropTypes.bool,
  showChips: PropTypes.bool,
  normalizeDropdownPosition: PropTypes.bool,
  showAddButton: PropTypes.bool,
};

export default Dropdown;

const DropdownBody = styled.div`
  height: ${(props) => {
    if (props.itemsLength === 0) {
      return `${74}px`;
    } if (props.itemsLength > 6) {
      return `${props.rowHeight * 6}px`;
    } if (props.singleChose) {
      return `${(props.itemsLength + 1) * props.rowHeight}px`;
    }
    return `${props.itemsLength * props.rowHeight}px`;
  }};
`;
