import React, { useContext } from 'react';
import { useLoader } from '@react-three/fiber';
import { TDSLoader } from 'three/examples/jsm/loaders/TDSLoader';
import PropTypes from 'prop-types';
import ThreeContext from '../../ThreeContext';

const TDSModel = ({ url, position }) => {
  const THREE = useContext(ThreeContext);
  const tds = useLoader(TDSLoader, url);

  tds.traverse((child) => {
    const material = new THREE.MeshStandardMaterial({
      color: '#cccccc',
      metalness: 0.5,
      roughness: 0.4,
    });
    Object.assign(child, {
      material,
      castShadow: true,
      receiveShadow: true,
    });
  });

  return <primitive object={tds} position={position} />;
};

TDSModel.propTypes = {
  url: PropTypes.string.isRequired,
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default TDSModel;
