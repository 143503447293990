/* eslint-disable no-use-before-define */
import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Button, InfoBlock, Spinner } from '@picsio/ui';
import { Warning } from '@picsio/icons';
import { useSelector, useDispatch } from 'react-redux';
import Icon from '../Icon';
import Chooser from './Chooser';
import i18n from '../../shared/strings';
import Logger from '../../services/Logger';
import getChildren from '../../store/actions/collections/getChildren';
import showSelectFromTreeDialog from '../../helpers/showSelectFromTreeDialog';
import * as utils from '../../shared/utils';
import checkStorageLimit from './utils/checkStorageLimit';
import prepareSelectedFileFolder from './utils/prepareSelectedFileFolder';
import Toast from '../Toast';
import {
  navigate,
  isRouteFiltering,
  isRouteSelectedAssets,
} from '../../helpers/history';
import { showDialog } from '../dialog';
import getStorageType from '../../store/helpers/user/getStorageType';

const KEY_ENTER = 13;
const KEY_ESC = 27;

function DropboxChooserDialog({
  onOk = () => { },
  onClose = () => { },
  destroy,
  uploadCollectionId,
}) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showStorageLimitWarning, setShowStorageLimitWarning] = useState(false);
  const [excessStorageSize, setExcessStorageSize] = useState(0);
  const location = useSelector((state) => state.router.location);
  const user = useSelector((state) => state.user);
  const collectionsStore = useSelector((state) => state.collections);
  const dropboxAccountInfo = useSelector((state) => state.user.connectedCloud?.dropbox);
  const rootCollection = useSelector((state) => state.collections.collections?.my);

  const { storageType } = getStorageType(user);

  const isRootCollectionActive = useMemo(() => {
    const rootId = rootCollection?._id;
    const ids = location.query.collectionIds;

    if (!ids || !rootId) return false;

    const activeIds = typeof ids === 'string' ? [ids] : ids;
    return activeIds.includes(rootId);
  }, [rootCollection, location]);

  const isRootCollection = useCallback((collectionId) => {
    const rootId = rootCollection?._id;
    return collectionId === rootId;
  }, [rootCollection]);

  useEffect(() => {
    document.addEventListener('keydown', keyListener);
    window.addEventListener('window:dropbox:auth:closed', handleWindowDropboxAuthClosed);
    return () => {
      document.removeEventListener('keydown', keyListener);
      window.removeEventListener('window:dropbox:auth:closed', handleWindowDropboxAuthClosed);
    };
  }, [keyListener, handleWindowDropboxAuthClosed, selectedItems, dropboxAccountInfo]);

  const handleWindowDropboxAuthClosed = useCallback(() => {
    if (!dropboxAccountInfo) {
      destroy();
    }
  }, [destroy, dropboxAccountInfo]);

  const handleUploadFromDropbox = useCallback((files) => {
    const handleOk = (collectionIds) => {
      if (!files || !files.length) return;
      Logger.log('User', 'DropboxPickerItemsSelected', {
        assets_number: files.filter((item) => item['.tag'] === 'file').length,
        folders_number: files.filter((item) => item['.tag'] === 'folder').length,
      });
      window.dispatchEvent(
        new CustomEvent('importPanel:addFromCloud', {
          detail: {
            files,
            uploadCollectionId: collectionIds[0]._id,
          },
        }),
      );
    };

    if (!uploadCollectionId) {
      Logger.log('User', 'DropboxImportSelectCollection');
      showSelectFromTreeDialog({
        title: i18n.DIALOGS.SELECT_TO_IMPORT.TITLE,
        text: i18n.DIALOGS.SELECT_TO_IMPORT.TEXT,
        onOk: handleOk,
        treeListItems: [collectionsStore?.collections?.my],
        onLoadChildren: (item) => dispatch(getChildren(item._id)),
        textBtnOk: i18n.DIALOGS.SELECT_TO_IMPORT.OK_TEXT,
        textBtnCancel: i18n.DIALOGS.SELECT_TO_IMPORT.CANCEL_TEXT,
        openedItems: [collectionsStore?.collections?.my],
        canCreateCollection: true,
        multipleSelection: false,
        type: 'upload',
      });
    } else {
      const showUploadToRootDialog = uploadCollectionId ? isRootCollection(uploadCollectionId) : (isRouteFiltering() || isRouteSelectedAssets() || isRootCollectionActive);

      if (showUploadToRootDialog) {
        const params = {
          title: i18n.IMPORT.titleSelectCollection,
          text: [
            `<div>${i18n.IMPORT.textItBetterToUpload(rootCollection?.name)}</div>`,
          ].join(''),
          textBtnCancel: i18n.DIALOGS.btnCancel,
          textBtnOk: i18n.IMPORT.btnConfirm,
          onCancel: () => Logger.log('User', 'SelectCollectionDialogCancel'),
          onOk: async () => {
            Logger.log('User', 'SelectCollectionDialogContinue');
            window.dispatchEvent(
              new CustomEvent('importPanel:addFromCloud', {
                detail: {
                  files,
                  uploadCollectionId,
                },
              }),
            );
          },
          style: { width: 700 },
        };
        Logger.log('UI', 'SelectCollectionDialog');
        showDialog(params);
      } else {
        window.dispatchEvent(
          new CustomEvent('importPanel:addFromCloud', {
            detail: {
              files,
              uploadCollectionId,
            },
          }),
        );
      }
    }
  }, [dispatch, collectionsStore?.collections?.my, uploadCollectionId, isRootCollection, isRootCollectionActive, rootCollection?.name]);

  const handleClose = useCallback(() => {
    if (onClose) onClose();
    destroy();
    Logger.log('User', 'DropboxImportCancelled');
  }, [destroy, onClose]);

  const handleOk = useCallback(async () => {
    if (!selectedItems.length || isLoading || showStorageLimitWarning) return;
    setLoading(true);
    const { filesSize, selectedFolderPaths, preparedItems } = prepareSelectedFileFolder(selectedItems);

    try {
      const isEnoughSize = await checkStorageLimit({
        selectedFolderPaths, filesSize, setShowStorageLimitWarning, setExcessStorageSize,
      });
      if (!isEnoughSize) {
        Logger.log('User', 'DropboxImportError', { error_reason: 'insufficient space' });
        return;
      }
    } catch (err) {
      Logger.error(
        new Error('Error calculating the difference between available space on storage and downloaded files from dropbox '),
        { error: err },
        ['CalculatingDifferenceStorageFailed', (err && err.message) || 'NoMessage'],
      );
      Toast(i18n.DROPBOX_CHOOSER.toastCalculatingDifferenceStorageFailed, { autoClose: false, type: 'error' });
      return;
    } finally {
      setLoading(false);
    }

    handleUploadFromDropbox(preparedItems);
    if (onOk) onOk(preparedItems);
    destroy();
  }, [destroy, onOk, handleUploadFromDropbox, selectedItems, isLoading, showStorageLimitWarning]);

  const keyListener = useCallback((event) => {
    switch (event.keyCode) {
    case KEY_ENTER:
      return handleOk();
    case KEY_ESC:
      return handleClose();
    default:
      break;
    }
    return null;
  }, [handleClose, handleOk]);

  const handleGoToBilling = useCallback(() => {
    navigate('/billing?tab=overview');
    destroy();
  }, [destroy]);

  const handleSelectItem = useCallback((items) => {
    setSelectedItems(items);
    setShowStorageLimitWarning(false);
  }, []);

  return (
    <div className="simpleDialog">
      <div className="simpleDialogUnderlayer" />
      <div className="simpleDialogBox">
        <div className="simpleDialogHeader">
          <span className="simpleDialogTitle">
            {i18n.DROPBOX_CHOOSER.dialogTitle}
          </span>
          <span className="simpleDialogBtnCross" onClick={handleClose} role="presentation">
            <Icon name="close" />
          </span>
        </div>
        <div className="simpleDialogContent">
          <div className="simpleDialogDescription">
            <div className="simpleDialogDescription__text">
              {i18n.DROPBOX_CHOOSER.dialogDescription}
            </div>
            <If condition={showStorageLimitWarning}>
              <InfoBlock icon={() => <Warning />} iconColor="warning">
                {i18n.DROPBOX_CHOOSER.textWarningStorageLimit(handleGoToBilling, utils.bytesToSize(excessStorageSize), storageType)}
              </InfoBlock>
            </If>
          </div>

          <div className="simpleDialogContentInner">
            <Choose>
              <When condition={dropboxAccountInfo && !isLoading}>
                <Chooser
                  onLogout={handleClose}
                  onSelect={handleSelectItem}
                  selectedItems={selectedItems}
                />
              </When>
              <When condition={isLoading}>
                <div className="dropboxChooserPlaceholder">
                  <Spinner text="Storage limit checking..." style={{ background: 'none' }} />
                </div>
              </When>
              <Otherwise>
                <div className="dropboxChooserPlaceholder">
                  <Spinner text="Dropbox account connecting..." style={{ background: 'none' }} />
                </div>
              </Otherwise>
            </Choose>
          </div>
        </div>
        <div className="simpleDialogFooter">
          <Button
            color="secondary"
            size="md"
            variant="contained"
            onClick={handleClose}
          >
            {i18n.DROPBOX_CHOOSER.dialogBtnCancel}
          </Button>
          <Button
            color="primary"
            size="md"
            variant="contained"
            onClick={handleOk}
            disabled={!selectedItems.length || isLoading || showStorageLimitWarning}
          >
            {i18n.DROPBOX_CHOOSER.dialogBtnOk}
          </Button>
        </div>
      </div>
    </div>
  );
}

DropboxChooserDialog.propTypes = {
  destroy: PropTypes.func.isRequired,
  uploadCollectionId: PropTypes.string,
  onClose: PropTypes.func,
  onOk: PropTypes.func,
};

export default DropboxChooserDialog;
