/**
 * Make notification read
 * @param {Object[]} items
 * @param {Object} newComments - [key: string]: string[]
 * @param {Object} newRevisions - [key: string]: string[]
 * @param {string[]} ids
 * @returns {Object} items
 */
const setNotificationRead = (items, newComments, newRevisions, ids) => {
  const updatedNewComments = Object.keys(newComments).reduce((acc, key) => {
    const value = newComments[key];
    acc[key] = value.filter((notificationId) => !ids.includes(notificationId));
    return acc;
  }, {});

  const updatedNewRevisions = Object.keys(newRevisions).reduce((acc, key) => {
    const value = newRevisions[key];
    acc[key] = value.filter((notificationId) => !ids.includes(notificationId));
    return acc;
  }, {});

  const updatedItems = items.map((item) => {
    if (ids.includes(item._id)) return { ...item, read: true };
    return item;
  });

  return { updatedItems, updatedNewComments, updatedNewRevisions };
};

export default setNotificationRead;
