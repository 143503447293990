import TYPES from '../../action-types';

const SEPARATOR = '→';
const METADATER_USER_ID = '500000000000000000000002';

function parseFields(asset, keywordsStore) {
  const fields = ['metadating'];
  const values = ['complete'];

  if ('title' in asset) {
    fields.push('title');
    values.push(asset.title);
  }
  if ('description' in asset) {
    fields.push('description');
    values.push(asset.description);
  }
  if ('color' in asset) {
    fields.push('color');
    values.push(asset.color);
  }
  if ('rating' in asset) {
    fields.push('rating');
    values.push(asset.rating);
  }
  if ('flag' in asset) {
    fields.push('flag');
    values.push(asset.flag);
  }
  if ('keywords' in asset) {
    const keywordsNames = asset.keywords.map((name) => SEPARATOR + name);
    const keywords = keywordsStore.all.filter((keyword) => !!(keywordsNames.includes(keyword.path)
      || keywordsNames.some((keywordName) => keyword.path.endsWith(keywordName))));
    fields.push('keywords');
    values.push(keywords.map((k) => ({ ...k, attachedBy: METADATER_USER_ID })));
  }
  if ('meta' in asset) {
    fields.push('meta');
    values.push(asset.meta);
  }

  return { fields, values };
}

/**
 * @param {Array} receivedAssets
 */
function metadatingComplete(receivedAssets = []) {
  return (dispatch, getState) => {
    const { user, keywords: keywordsStore } = getState();
    const team = user?.team || {};
    const keywordsStrategy = team.settings?.metadater?.ignoreLockMetadataFields
      /** if ignoreLockMetadataFields is set to `true` - use strategy from settings (merge by default) */
      ? team.settings?.metadater?.keywordsStrategy || 'merge'
      /** default strategy -> merge */
      : 'merge';

    const assets = receivedAssets.map((asset) => {
      const { fields, values } = parseFields(asset, keywordsStore);
      return {
        _id: asset._id,
        fields,
        values,
      };
    });

    dispatch({
      type: TYPES.ASSETS.UPDATE_FIELDS_MANY_ASSETS,
      payload: { assets, keywordsStrategy },
    });
  };
}

export default metadatingComplete;
