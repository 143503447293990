import React, { memo, useCallback, useMemo } from 'react';
import { TextField, InputLabel, Icon } from '@picsio/ui';
import { OpenIn, BillInfo } from '@picsio/icons';
import { useSelector } from 'react-redux';
import DetailsBlock from '../DetailsBlock';
import Keywords from './Keywords';

import s from './styles.module.scss';
import Tooltip from '../Tooltip/Tooltip';

const DEFAULT_COLLECTIONS = [];

const ProductOrganization = ({
  productId,
  vendor,
  productType,
  keywords,
  category,
  collections = DEFAULT_COLLECTIONS,
  detailsPanelVisibility,
  toggleVisibility,
  blockName,
  blockTitle,
}) => {
  const myShopifyDomain = useSelector((state) => state.user.team.shopifyStore?.myshopify_domain || '');
  const product = useSelector((state) => state.products.items?.find(({ _id }) => productId === _id));
  const collectionsValue = useMemo(
    () => collections.map(({ title }) => title).join(', '),
    [collections],
  );

  const onOpenProduct = useCallback(() => {
    window.open(`https://admin.shopify.com/store/${myShopifyDomain.replace('.myshopify.com', '')}/products/${product.storeId.split('/').pop()}`, '_blank');
  }, [myShopifyDomain, product.storeId]);

  return (
    <DetailsBlock
      dataQa="productOrganization"
      detailsPanelVisibility={detailsPanelVisibility}
      toggleVisibility={toggleVisibility}
      blockName={blockName}
      blockTitle={blockTitle}
    >
      <div className={s.row}>
        <TextField
          value={productType}
          label="Product Type"
          disabled
        />
        <Tooltip content="Open in Shopify Admin" placement="top">
          <div onClick={onOpenProduct} role="presentation" style={{ cursor: 'pointer' }}>
            <Icon size="xl">
              <OpenIn />
            </Icon>
          </div>
        </Tooltip>
      </div>
      <div className={s.row}>
        <TextField
          value={vendor}
          label="Vendor"
          disabled
        />
        <Tooltip content="Open in Shopify Admin" placement="top">
          <div onClick={onOpenProduct} role="presentation" style={{ cursor: 'pointer' }}>
            <Icon size="xl">
              <OpenIn />
            </Icon>
          </div>
        </Tooltip>
      </div>
      <div className={s.row}>
        <TextField
          value={category?.name}
          label="Category"
          disabled
          helperText={category?.fullName}
        />
        <Tooltip content="Open in Shopify Admin" placement="top">
          <div onClick={onOpenProduct} role="presentation">
            <Icon size="xl" style={{ paddingBottom: '20px', cursor: 'pointer' }}>
              <OpenIn />
            </Icon>
          </div>
        </Tooltip>
      </div>
      <div className={s.row}>
        <TextField
          value={collectionsValue}
          label="Collections"
          disabled
        />
        <Tooltip content="Open in Shopify Admin" placement="top">
          <div onClick={onOpenProduct} role="presentation" style={{ cursor: 'pointer' }}>
            <Icon size="xl">
              <OpenIn />
            </Icon>
          </div>
        </Tooltip>
      </div>
      <InputLabel position="top" margin>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '5px' }}>Keywords</span>
          <Tooltip content="Toriut keywords are equivalent to Shopify tags" placement="top">
            <Icon size="xs">
              <BillInfo />
            </Icon>
          </Tooltip>
        </div>
      </InputLabel>
      <Keywords productId={productId} keywords={keywords} />
    </DetailsBlock>
  );
};

export default memo(ProductOrganization);
