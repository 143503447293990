import Toast from '../../components/Toast';
import { updateUser } from '../../store/actions/user';
import sdk from '../../sdk';
import * as utils from '../../shared/utils';
import { showDialog } from '../../components/dialog';
import l10n from '../../shared/strings';

const pullProducts = async (dispatch) => {
  try {
    dispatch(updateUser({ productsPulling: 'waiting' }));
    await sdk.products.pullAll();
    Toast('Sync in progress: fetching the latest products and attributes from Shopify. This may take a few moments');
  } catch (e) {
    const errorSubcode = utils.getDataFromResponceError(e, 'subcode');
    dispatch(updateUser({ productsPulling: 'rejected' }));

    if (errorSubcode === 'HasAttachingAssetsError') {
      showDialog({
        title: l10n.DIALOGS.SYNC_PRODUCTS_ERROR.TITLE,
        text: l10n.DIALOGS.SYNC_PRODUCTS_ERROR.TEXT,
        textBtnOk: l10n.DIALOGS.SYNC_PRODUCTS_ERROR.BTN_OK,
        textBtnCancel: null,
      });
    } else {
      Toast('Sync failed: unable to update products and attributes from Shopify. Please check your connection or try again', {
        type: 'error',
        autoClose: false,
        onOk: () => pullProducts(),
        btnOkValue: 'Try again',
      });
    }
  }
};

export default pullProducts;
