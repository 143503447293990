import React, {
  useState, memo, useEffect, useMemo,
  useCallback,
} from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import { useMount } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import CONSTANTS from '@picsio/db/src/constants';

import picsioConfig from '../../../../../config';

import { findCollection, getParent } from '../../store/helpers/collections';
import { getChildren as getCollectionChildren } from '../../store/actions/collections';
import { getByIds as getKeywordsByIds } from '../../store/keywordsList/actions';
import { findCollectionsPathToId } from '../../store/actions/helpers/archive';
import { setSearchRoute, navigate } from '../../helpers/history';

import WithSkeletonTheme from '../WithSkeletonTheme';
import BreadcrumbsItem from './BreadcrumbsItem';

const TYPES = {
  TAGS: 'collectionIds',
  LIGHTBOARDS: 'lightboardId',
  INBOXES: 'inboxId',
  KEYWORDS: 'keywords',
  ARCHIVE: 'archive',
  PRODUCTS: 'products',
};

const collectionsToReceive = [];

const Breadcrumbs = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const collectionsMainTree = useSelector((state) => state.collections.collections);
  const keywordsList = useSelector((state) => state.keywordsList.items);
  const treeKeywords = useSelector((state) => state.keywords.all);
  const lightboards = useSelector((state) => state.lightboards.lightboards);
  const products = useSelector((state) => state.products.items);
  const inboxes = useSelector((state) => state.inboxes.inboxes);
  const archive = useSelector((state) => state.archive.collections);

  const [items, setItems] = useState([]);

  const rootCollectionId = useMemo(
    () => collectionsMainTree?.my?._id,
    [collectionsMainTree?.my?._id],
  );

  const buildByProduct = useCallback((pathname) => {
    const [productId, variantId] = pathname.replace('/product/', '').split('/');
    const product = (products || []).find(({ _id }) => _id === productId);
    let variant;
    if (variantId) {
      variant = (product?.variants || []).find(({ _id }) => _id === variantId);
    }
    const newItems = [{
      type: TYPES.PRODUCTS,
      _id: TYPES.PRODUCTS,
      title: 'Products',
    }];

    if (product) {
      newItems.push({
        _id: product?._id,
        title: product?.title,
      });
    }

    if (variant) {
      newItems.push({
        _id: variant?._id,
        title: variant?.title,
      });
    }
    setItems(newItems);
  }, [products]);

  const buildByKeywords = useCallback(async (keywordId) => {
    const newItems = [{ type: TYPES.KEYWORDS, _id: null, title: 'Keywords' }];

    let keyword = keywordsList.find(({ _id }) => _id === keywordId) || treeKeywords.find(({ _id }) => _id === keywordId);
    if (!keyword) {
      const { payload } = await dispatch(getKeywordsByIds([keywordId]));
      [keyword] = (payload || [{}]);
    }
    if (keyword?.path) {
      newItems.push(...keyword.path
        .split(CONSTANTS.NEW_PATH_DELIMITER)
        .filter(Boolean)
        .map((name) => ({ _id: null, title: name })));
    }
    setItems(newItems);
  }, [dispatch, keywordsList, treeKeywords]);

  const buildByLightboards = useCallback((lightboardId) => {
    const lightboard = lightboards.find((lb) => lb._id === lightboardId);
    setItems([
      {
        type: TYPES.LIGHTBOARDS,
        _id: null,
        title: 'Lightboards',
      },
      {
        _id: lightboardId,
        title: lightboard.path.split(CONSTANTS.NEW_PATH_DELIMITER).pop(),
      },
    ]);
  }, [lightboards]);

  const buildByInboxes = useCallback((inboxId) => {
    const inbox = inboxes.find(({ _id }) => _id === inboxId);
    setItems([
      {
        type: TYPES.INBOXES,
        _id: null,
        title: 'Inboxes',
      },
      {
        _id: inboxId,
        title: inbox.name,
      },
    ]);
  }, [inboxes]);

  const buildByCollections = useCallback((currentCollectionId) => {
    const list = [];
    let collection = findCollection(collectionsMainTree, 'my', { _id: currentCollectionId });
    if (collectionsMainTree && collectionsMainTree.my && !collection) {
      if (!collectionsToReceive.includes(currentCollectionId)) {
        collectionsToReceive.push(currentCollectionId); // to prevent several requests for the same collection
        dispatch(getCollectionChildren(collectionsMainTree.my._id, { currentCollectionId }));
      }
      return;
    }
    while (collection) {
      list.unshift(collection);
      collection = getParent(collectionsMainTree, 'my', { _id: collection._id });
    }

    if (list.length > 0) {
      // name shouldn't be here, use path instead. API will stop pass name in future
      const newItems = list.map((item) => ({ title: item.name, _id: item._id, type: TYPES.TAGS }));
      setItems(newItems);
    }
  }, [collectionsMainTree, dispatch]);

  const buildByArchive = useCallback((collectionId) => {
    const { list } = findCollectionsPathToId(archive, collectionId);
    let newItems = list.map(({ _id, name }) => ({ _id, title: name }));

    if (!newItems.length) {
      newItems = [{
        type: TYPES.ARCHIVE,
        _id: archive[0]?._id,
        title: 'Archive',
      }];
    }
    setItems(newItems);
  }, [archive]);

  const buildByProofing = useCallback(() => {
    buildByCollections(rootCollectionId);
  }, [buildByCollections, rootCollectionId]);

  const buildItems = useCallback(() => {
    const { search, pathname = '' } = location;
    const searchParams = new URLSearchParams(search);

    const collectionIds = searchParams.get('collectionIds');
    const lightboardId = searchParams.get('lightboardId');
    const inboxId = searchParams.get('inboxId');
    const keywords = searchParams.getAll('keywords');
    const archived = searchParams.get('archived');
    const productId = pathname.startsWith('/product/') ? pathname.replace('/product/', '').split('/')[0] : null;

    if (productId || pathname === '/products') {
      buildByProduct(pathname, products);
      return;
    }

    if (collectionIds && collectionIds === rootCollectionId && keywords && Array.isArray(keywords) && keywords.length > 0) {
      buildByKeywords(keywords[0]);
      return;
    }

    if (lightboardId) {
      buildByLightboards(lightboardId);
      return;
    }
    if (inboxId) {
      buildByInboxes(inboxId);
      return;
    }

    if (collectionIds && !archived && typeof collectionIds === 'string') {
      buildByCollections(collectionIds);
      return;
    }
    if (collectionIds && archived) {
      buildByArchive(collectionIds);
      return;
    }

    if ((!collectionIds && !inboxId && !lightboardId) || (Array.isArray(collectionIds) && collectionIds.length > 1)) {
      buildByCollections(rootCollectionId);
      return;
    }

    if (picsioConfig.isProofing) buildByProofing();
  }, [
    rootCollectionId,
    buildByArchive,
    buildByCollections,
    buildByInboxes,
    buildByKeywords,
    buildByLightboards,
    buildByProduct,
    buildByProofing,
    location,
    products,
  ]);

  useMount(() => {
    buildItems();
  });

  /** Listen to change location */
  useEffect(() => {
    buildItems();
  }, [location, buildItems]);

  const handleItemClick = useCallback((id) => {
    if (!id) return;
    const type = items[0]?.type;

    switch (type) {
    case TYPES.TAGS:
    case TYPES.LIGHTBOARDS:
      setSearchRoute({ [type]: id });
      break;

    case TYPES.KEYWORDS:
      setSearchRoute({ collectionIds: rootCollectionId, [type]: id });
      break;

    case TYPES.ARCHIVE:
      setSearchRoute({ collectionIds: id, archived: true });
      break;

    case TYPES.PRODUCTS:
      if (id === TYPES.PRODUCTS) navigate('/products');
      else navigate(`/product/${id}`);
      break;

    default:
      break;
    }
  }, [items, rootCollectionId]);

  return (
    <div className="breadCrumbs" data-testid="breadcrumbs">
      <Choose>
        <When condition={items.length > 0}>
          <ul>
            {items.map((item) => (
              <BreadcrumbsItem key={item._id || item.title} onClick={handleItemClick} {...item} />
            ))}
          </ul>
        </When>
        <Otherwise>
          <WithSkeletonTheme>
            <ul>
              <li><span><Skeleton width={98} /></span></li>
              <li><span><Skeleton width={70} /></span></li>
            </ul>
          </WithSkeletonTheme>
        </Otherwise>
      </Choose>
    </div>
  );
};

export default memo(Breadcrumbs);
