import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { TextField, InputControlLabel } from '@picsio/ui';
import { useDispatch } from 'react-redux';
import {
  changeSku, changeBarcode, changeTracked, changeInventoryPolicy,
} from '../../../store/products/actions';
import DetailsBlock from '../../DetailsBlock';
import Levels from './Levels';

import s from '../styles.module.scss';

const Inventory = ({
  productId,
  variantId,
  tracked,
  sku,
  barcode,
  continueSelling,
  inventoryLevels,
  detailsPanelVisibility,
  toggleVisibility,
  blockName,
  blockTitle,
}) => {
  const dispatch = useDispatch();
  const [skuValue, setSkuValue] = useState(sku || '');
  const [barcodeValue, setBarcodeValue] = useState(barcode || '');

  useEffect(() => {
    setSkuValue(sku || '');
  }, [sku]);

  useEffect(() => {
    setBarcodeValue(barcode || '');
  }, [barcode]);

  const handleChangeSku = useCallback((e) => {
    setSkuValue(e.target.value);
  }, [setSkuValue]);

  const saveSku = useCallback(() => {
    if (skuValue === sku) return;
    dispatch(changeSku({ productId, variantId, sku: skuValue }));
  }, [dispatch, productId, sku, skuValue, variantId]);

  const handleChangeBarcode = useCallback((e) => {
    setBarcodeValue(e.target.value);
  }, [setBarcodeValue]);

  const saveBarcode = useCallback(() => {
    if (barcodeValue === barcode) return;
    dispatch(changeBarcode({ productId, variantId, barcode: barcodeValue }));
  }, [barcodeValue, barcode, dispatch, productId, variantId]);

  const saveTracked = useCallback(() => {
    dispatch(changeTracked({ productId, variantId, tracked: !tracked }));
  }, [dispatch, productId, tracked, variantId]);

  const saveInventoryPolicy = useCallback(() => {
    dispatch(changeInventoryPolicy({ productId, variantId, inventoryPolicy: continueSelling ? 'DENY' : 'CONTINUE' }));
  }, [dispatch, productId, continueSelling, variantId]);

  return (
    <DetailsBlock
      dataQa="productInventory"
      detailsPanelVisibility={detailsPanelVisibility}
      toggleVisibility={toggleVisibility}
      blockName={blockName}
      blockTitle={blockTitle}
    >
      <div className={s.row}>
        <TextField
          value={skuValue}
          onChange={handleChangeSku}
          onBlur={saveSku}
          label="SKU (Stock Keeping Unit)"
        />
        <TextField
          value={barcodeValue}
          onChange={handleChangeBarcode}
          onBlur={saveBarcode}
          label="Barcode (ISBN, UPC, GTIN, etc.)"
        />
      </div>
      <hr className={s.hr} />
      <div className={s.row}>
        <InputControlLabel
          control="checkbox"
          label="Track quantity"
          value={tracked}
          onChange={saveTracked}
        />
      </div>
      <If condition={tracked}>
        <div className={s.row}>
          <InputControlLabel
            control="checkbox"
            label="Continue selling when out of stock"
            value={continueSelling}
            onChange={saveInventoryPolicy}
          />
        </div>
      </If>
      <If condition={tracked && inventoryLevels}>
        <Levels data={inventoryLevels} />
      </If>
    </DetailsBlock>
  );
};

export default memo(Inventory);
