import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import {
  IconButton, Icon, Button,
} from '@picsio/ui';

import {
  Rhomb, RhombFilled, Trash, AddCircleFilled,
} from '@picsio/icons';
import {
  createOption, deleteOption, createOptionValue, deleteOptionValue,
} from '../../../store/products/actions';
import DetailsBlock from '../../DetailsBlock';
import Tooltip from '../../Tooltip';
import FormAdd from './FormAdd';

import s from './styles.module.scss';
import Placeholder from '../../DetailsBlock/Placeholder';
import { showDialog } from '../../dialog';

const Options = ({
  productId,
  currentValue,
  isSimpleProduct = false,
  detailsPanelVisibility,
  toggleVisibility,
  blockName,
  blockTitle,
}) => {
  const dispatch = useDispatch();
  const [showNewOptionForm, setShowNewOptionForm] = useState(false);
  const [newOptionValue, setNewOptionValue] = useState(null);

  const validateOption = useCallback((name) => {
    if (!name) return false;
    return currentValue.every((option) => option.name.toLowerCase() !== name.toLowerCase());
  }, [currentValue]);

  const handleAddOption = useCallback(({ value, value2 }) => {
    if (validateOption(value) && value2) {
      dispatch(createOption({ productId, name: value, values: [value2] }));
      setShowNewOptionForm(false);
    }
  }, [dispatch, productId, validateOption]);

  const handleDeleteOption = useCallback((optionId) => () => {
    showDialog({
      title: 'Delete option',
      text: 'Are you sure you want to delete this option?',
      onOk: () => dispatch(deleteOption({ productId, optionId })),
      textBtnOk: 'Delete',
    });
  }, [dispatch, productId]);

  const handleDeleteOptionValue = useCallback((optionId, valueId) => () => {
    showDialog({
      title: 'Delete option value',
      text: 'Are you sure you want to delete this option value?',
      onOk: () => dispatch(deleteOptionValue({ productId, optionId, valueId })),
      textBtnOk: 'Delete',
    });
  }, [dispatch, productId]);

  const validateOptionValue = useCallback((name) => {
    if (!name) return false;
    const option = currentValue.find((item) => item.id === newOptionValue);
    if (!option) return false;
    return option.values.every((value) => value.name.toLowerCase() !== name.toLowerCase());
  }, [currentValue, newOptionValue]);

  const handleAddOptionValue = useCallback(({ value }) => {
    if (validateOptionValue(value)) {
      dispatch(createOptionValue({ productId, optionId: newOptionValue, name: value }));
      setNewOptionValue(null);
    }
  }, [validateOptionValue, dispatch, productId, newOptionValue]);

  const hideNewOptionForm = useCallback(() => {
    setShowNewOptionForm(false);
  },
  [setShowNewOptionForm]);

  const hideNewOptionValueForm = useCallback(() => {
    setNewOptionValue(null);
  },
  [setNewOptionValue]);

  const actions = useMemo(() => {
    const isOpened = !!detailsPanelVisibility[blockName];
    if (!isOpened) return [];
    return [
      <Tooltip key="Add option" content={currentValue.length > 2 ? 'You have reached the maximum number of options' : null} placement="top">
        <Button color="primary" disabled={currentValue.length > 2} onClick={() => setShowNewOptionForm(true)}>
          + Add option
        </Button>
      </Tooltip>,
    ];
  }, [blockName, currentValue.length, detailsPanelVisibility]);

  return (
    <DetailsBlock
      dataQa="productOptions"
      detailsPanelVisibility={detailsPanelVisibility}
      toggleVisibility={toggleVisibility}
      blockName={blockName}
      blockTitle={blockTitle}
      actions={actions}
    >
      <If condition={showNewOptionForm}>
        <FormAdd
          onSubmit={handleAddOption}
          validate={validateOption}
          onCancel={hideNewOptionForm}
          placeholder="Type option name"
          placeholder2="Type option value"
          isOption
        />
      </If>
      <Choose>
        <When condition={isSimpleProduct}>
          <Placeholder>
            No options available.
          </Placeholder>
        </When>
        <Otherwise>
          <ul className={s.options}>
            {currentValue.map((option) => (
              <li className={s.optionItem} key={option.id}>
                <div className={s.optionName}>
                  <div className={s.optionNameTitle}>
                    <Icon>
                      <RhombFilled />
                    </Icon>
                    {option.name}
                  </div>
                  <div className={s.actions}>
                    <Tooltip content="Add option value" placement="top">
                      <IconButton onClick={() => setNewOptionValue(option.id)}>
                        <AddCircleFilled />
                      </IconButton>
                    </Tooltip>
                    <Tooltip content="Delete option" placement="top">
                      <IconButton onClick={handleDeleteOption(option.id)}>
                        <Trash />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                <ul className={s.optionValues}>
                  <If condition={newOptionValue === option.id}>
                    <FormAdd
                      onSubmit={handleAddOptionValue}
                      validate={validateOptionValue}
                      onCancel={hideNewOptionValueForm}
                      placeholder="Type option value"
                    />
                  </If>
                  {option.values.map((value) => (
                    <li key={value.id} className={cn(s.optionValue, { [s.optionValueWithoutVariants]: value.hasVariants === false })}>
                      <div className={s.optionNameTitle}>
                        <Icon>
                          <Rhomb />
                        </Icon>
                        {value.name}
                        <If condition={value.hasVariants === false}>
                          <sup>(has no variants)</sup>
                        </If>
                      </div>
                      <div className={s.actions}>
                        <If condition={(option.values.length > 1 && option.values.filter((v) => v.hasVariants || v.hasVariants === undefined).length > 1) || value.hasVariants === false}>
                          <Tooltip content="Delete option value" placement="top">
                            <IconButton onClick={handleDeleteOptionValue(option.id, value.id)}>
                              <Trash />
                            </IconButton>
                          </Tooltip>
                        </If>
                      </div>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </Otherwise>
      </Choose>

    </DetailsBlock>
  );
};

const optionValueShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

const optionShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(optionValueShape).isRequired,
});

Options.propTypes = {
  productId: PropTypes.string,
  currentValue: PropTypes.arrayOf(optionShape).isRequired,
  isSimpleProduct: PropTypes.bool,
  detailsPanelVisibility: PropTypes.object.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
  blockName: PropTypes.string.isRequired,
  blockTitle: PropTypes.string.isRequired,
};

export default memo(Options);
