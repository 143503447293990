const helpersLink = {
  inboxesLimit: 'https://help.pics.io/en/articles/4066282-inbox',
  inboxes: 'https://help.pics.io/en/articles/4066282-inbox',
  maxGB: 'https://help.pics.io/en/articles/5140154-pics-io-storage',
  websitesLimit: 'https://help.pics.io/en/articles/6422126-sharing-assets-via-shared-collections-websites-in-pics-io',
  websites: 'https://help.pics.io/en/articles/6422126-sharing-assets-via-shared-collections-websites-in-pics-io',
  api: 'https://help.pics.io/en/articles/5246748-pics-io-api',
  branding: 'https://help.pics.io/en/articles/3738906-branding-for-teams',
  twoFactorAuth: 'https://help.pics.io/en/articles/5406508-two-factor-authentication-2fa',
  watermarksLimit: 'https://help.pics.io/en/articles/5695407-watermarking',
  watermarks: 'https://help.pics.io/en/articles/5695407-watermarking',
  csvImport: 'https://help.pics.io/en/articles/6151893-export-and-import-metadata-in-pics-io',
  okta: 'https://help.pics.io/en/articles/6545884-okta-single-sign-on-configuration-custom-integration',
  adobeAddons: 'https://help.pics.io/en/articles/2902055-pics-io-integration-with-adobe-creative-cloud',
  comments: 'https://help.pics.io/en/articles/1278976-commenting-and-marking-an-asset',
  customFields: 'https://help.pics.io/en/articles/1269146-settings-custom-fields',
  revisions: 'https://help.pics.io/en/articles/1269332-uploading-and-comparing-revisions-in-pics-io',
  wordpressAddons: 'https://help.pics.io/en/articles/6443987-pics-io-integration-with-wordpress-cms',
  analytics: 'https://help.pics.io/en/articles/4097770-analytics-and-reporting',
  websitesAnalytics: 'https://help.pics.io/en/articles/4097770-analytics-and-reporting',
  libraryAnalytics: 'https://help.pics.io/en/articles/4097770-analytics-and-reporting',
  assetAnalytics: 'https://help.pics.io/en/articles/4097770-analytics-and-reporting',
  teamAnalytics: 'https://help.pics.io/en/articles/4097770-analytics-and-reporting',
  roles: 'https://help.pics.io/en/articles/1938618-assigning-roles-to-teammates',
  faceRecognition: 'https://help.pics.io/en/articles/6770873-face-recognition',
  transcribe: 'https://help.pics.io/en/articles/7200004-video-and-audio-transcription-speech-to-text',
  googleAddons: 'https://help.pics.io/en/articles/2732164-pics-io-add-ons-for-google-workspace-tools',
  keywording: 'https://help.pics.io/en/articles/1269309-autokeywording-function',
  replicating: 'https://help.pics.io/en/articles/1305701-processing-xmp-iptc-exif-metadata-in-pics-io#:~:text=How%20does%20Pics.io%20save%20changes%20that%20I%20make%20in%20the%20metadata%3F',
  aiKit: 'https://help.pics.io/en/articles/9692774-ai-kit-add-on-and-tiers-guide',
  searchFilters: 'https://help.pics.io/en/articles/10205208-using-syntax-search-in-pics-io-a-comprehensive-guide',
};

export default helpersLink;
