import React, {
  useCallback, memo,
  useState,
} from 'react';
import {
  arrayOf, string, shape,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@picsio/ui';
import { ControlledVocabulary, Label } from '@picsio/icons';
import KeywordsDropdown from '../keywordsDropdown';
import l10n from '../../shared/strings';
import { addKeywords, removeKeywords } from '../../store/products/actions';
import { changeTree } from '../../store/actions/main';
import { setSearchRoute } from '../../helpers/history';
import Logger from '../../services/Logger';
import * as UtilsCollections from '../../store/utils/collections';

function Keywords({
  productId,
  keywords = [],
}) {
  const dispatch = useDispatch();
  const isCreatableKeywords = useSelector((state) => {
    const isAddKeywordsOutsideVocabulary = state.user.role.permissions.addKeywordsOutsideVocabulary;
    const { isKeywordsActionsAllowed } = state.user;
    return isAddKeywordsOutsideVocabulary || isKeywordsActionsAllowed;
  });
  const openedTree = useSelector((state) => state.main?.openedTree);
  const isCVEnabled = useSelector((state) => state.user?.team?.policies?.useKeywordsControlledVocabulary);
  const [isBusy, setBusy] = useState(false);

  const handleAttachKeyword = useCallback(async (keyword, logEvent = true) => {
    /** keyword can be array, when user attach it with comma (,) */
    const isArray = Array.isArray(keyword);
    const keywordName = isArray ? keyword.map(({ title }) => title).join(',') : keyword.title;

    if (logEvent) {
      Logger.log('User', 'ProductKeywordsAdd', {
        keyword: isArray ? keyword.map(({ _id, title }) => `${_id || '<NEW>'} | ${title}`).join(' ; ') : `${keyword._id || '<NEW>'} | ${keyword.title}`,
        product: productId,
        keywordCount: isArray ? keyword.length : 1,
      });
    }
    setBusy(true);
    await dispatch(addKeywords({ productId, keywordName }));
    setBusy(false);
  }, [dispatch, productId, setBusy]);

  const handleDetachKeyword = useCallback(async (item) => {
    setBusy(true);
    await dispatch(removeKeywords({
      keywordIds: [item._id],
      productId,
    }));
    setBusy(false);
  }, [productId, dispatch, setBusy]);

  const handleClickKeyword = useCallback((keywordId) => {
    /** Navigate to keyword */
    if (openedTree !== 'keywords') dispatch(changeTree('keywords'));

    setSearchRoute({
      collectionIds: UtilsCollections.getRootId(),
      keywords: keywordId,
    });
  }, [dispatch, openedTree]);

  const dropdownIcon = useCallback(() => (
    <Icon>
      <Choose>
        <When condition={isCVEnabled}>
          <ControlledVocabulary />
        </When>
        <Otherwise>
          <Label />
        </Otherwise>
      </Choose>
    </Icon>
  ), [isCVEnabled]);

  return (
    <KeywordsDropdown
      placeholder={l10n.DROPDOWN.placeholderKeywords}
      placeholderIcon="emptyKeywords"
      icon={dropdownIcon}
      onItemClickHandler={handleClickKeyword}
      filterText={isCVEnabled ? l10n.DROPDOWN.chooseKeywordsCV : l10n.DROPDOWN.chooseKeywords}
      createText={l10n.DROPDOWN.createKeyword}
      createPlaceholderText={l10n.DROPDOWN.placeholderKeywordsCreate}
      checkedItems={keywords}
      onCheckedHandler={handleAttachKeyword}
      onUncheckedHandler={handleDetachKeyword}
      canCreate={isCreatableKeywords}
      showAddButton={false}
      disabled={isBusy}
    />
  );
}

Keywords.defaultProps = {
  keywords: [],
};

Keywords.propTypes = {
  productId: string.isRequired,
  keywords: arrayOf(shape({
    _id: string,
    title: string,
  })),
};

export default memo(Keywords);
