/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux';
import * as mainActions from '../actions/main';
import { getSearchProps, setSearchRoute, navigate } from '../../helpers/history';
import { showDialog } from '../../components/dialog';
import { isHaveTeammatePermission } from '../helpers/user';

const handleGoToBilling = () => {
  navigate('/billing?tab=overview');
  ReactDOM.unmountComponentAtNode(document.querySelector('.wrapperDialog'));
};

export const sendFacesForCollectionRecognize = createAsyncThunk(
  'faces/recognize/collection',
  async (
    collectionId,
    {
      extra: {
        sdk, Logger, Toast, utils, localization,
      },
      getState,
      dispatch,
    }) => {
    const { user, roles, teammates } = getState();
    const billingDenied = !isHaveTeammatePermission('manageBilling', user);
    const keywordingDenied = !isHaveTeammatePermission('manageKeywords', user);
    const teamManagingDenied = !isHaveTeammatePermission('manageTeam', user);

    const canNotBuyApiCalls = billingDenied || keywordingDenied || teamManagingDenied;
    const usersWithPermissionsToBuyApiCalls = utils.getUsersWithPermissionToBuyKeywords(user, teammates.items, roles.items);

    let data;
    try {
      ({ data } = await sdk.faces.sendFacesForCollectionRecognize(collectionId));

      if (data.assetsToProcessCount) {
        Toast(localization.TOAST.FACE_RECOGNIZING_COLLECTION_COMPLETE(data.assetsToProcessCount));
      }

      Logger.log('Face', 'FaceRecogRecognizeLaunched');
    } catch (error) {
      const errorSubcode = utils.getDataFromResponceError(error, 'subcode');
      if (errorSubcode === 'NotEnoughPaidApiCallsError') {
        showDialog({
          title: localization.DETAILS.titleNotEnoughCredits,
          text: localization.DETAILS.textReachedAiCreditLimit(canNotBuyApiCalls, usersWithPermissionsToBuyApiCalls),
          textBtnCancel: null,
          textBtnOk: canNotBuyApiCalls ? localization.DIALOGS.btnOk : localization.DIALOGS.btnSubscribe,
          onOk: canNotBuyApiCalls ? Function.prototype : handleGoToBilling,
        });
      }
      if (errorSubcode === 'AssetsNotFoundApiError') {
        showDialog({
          title: localization.DIALOGS.FACE_RECOGNITION_ASSETS_NOT_FOUND.TITLE,
          text: localization.DIALOGS.FACE_RECOGNITION_ASSETS_NOT_FOUND.TEXT(),
          textBtnCancel: null,
          textBtnOk: localization.DIALOGS.FACE_RECOGNITION_ASSETS_NOT_FOUND.BTN_OK,
        });
      }

      throw error;
    }
    try {
      if (collectionId.length === 1) {
        const mainAction = bindActionCreators(mainActions, dispatch);
        const treeOpened = utils.LocalStorage.get('picsio.treeOpened');
        const { collections } = getState();
        const { _id: rootCollectionId } = collections.collections.my;
        const makeSearchRoute = () => {
          const { trashed, archived } = getSearchProps();
          const result = { trashed, archived };
          result.collectionIds = rootCollectionId;
          result.faces = data.createdPersons.map((face) => face._id);
          return result;
        };
        const countFaces = data.createdPersons.length;

        Toast(localization.TOAST.FACE_RECOGNIZING_COMPLETE(data.assetsToProcessCount, countFaces), {
          autoClose: false,
          closeButton: false,
          btnOkValue: 'See faces',
          onOk: () => {
            setSearchRoute(makeSearchRoute());
            if (treeOpened !== 'face') mainAction.changeTree('face');
          },
        });
        return { faces: data, assetId: collectionId[0] };
      }
      return null;
    } catch (error) {
      Logger.error(new Error('Error faces recognize'), { error }, [
        'FacesRecognizeFailed',
        (error && error.message) || 'NoMessage',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder.addCase(
    sendFacesForCollectionRecognize.pending,
    (state, { meta }) => {
      state.all = state.all.map((face) => {
        if (face._id === meta.arg) {
          return { ...face, isBusy: true };
        } return face;
      });
      state.isBusy = true;
    },
  ).addCase(
    sendFacesForCollectionRecognize.fulfilled,
    (state, { payload }) => {
      if (payload) {
        state.isBusy = false;
        const allFaces = state.all;
        const newFaces = [];
        payload.faces.createdPersons.forEach((item) => {
          const isFacedExist = allFaces.find((storeFaced) => storeFaced._id === item._id);
          if (!isFacedExist) {
            newFaces.push({ ...item, avatar: null });
          }
        });
        state.all = [...state.all, ...newFaces];
        state.tree = {
          ...state.tree,
          unnamed: {
            ...state.tree.unnamed,
            nodes: [
              ...state.tree.unnamed.nodes, ...newFaces,
            ],
          },
        };
      }
    },
  ).addCase(
    sendFacesForCollectionRecognize.rejected,
    (state, { meta, error }) => {
      state.all.forEach((face) => {
        if (face._id === meta.arg) delete face.isBusy;
      });
      state.isBusy = false;
      state.error = error;
    },
  );
};
