import React, { useCallback, useRef, useState } from 'react'; // eslint-disable-line
import { useSelector } from 'react-redux';

import Dropdown from '../dropdown';

const normalizeItems = ({ _id, title }) => ({ _id, title });

const ProductsDropdown = (props) => {
  const isLoaded = useSelector((state) => state.products.isLoaded);
  const items = useSelector((state) => state.products.items.map(normalizeItems));

  return (
    <Dropdown
      {...props}
      visibleItemsLength={8}
      type="keyword"
      items={items}
      isItemsLoaded={isLoaded}
    />
  );
};

export default ProductsDropdown;
