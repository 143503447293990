import Q from 'q';

import * as utils from '../../../shared/utils';
import picsioConfig from '../../../../../../config';

import { isArchiveAllowed } from '../../helpers/user';
import TYPES from '../../action-types';
import fetchArchivedCollections from '../archive/fetchArchivedCollections';
import sdk from '../../../sdk';

import setTeamName from './setTeamName';
import getChildren from './getChildren';

/**
 * Get collections
 */
function getCollections() {
  return async (dispatch, getState) => {
    dispatch({ type: TYPES.COLLECTIONS.FETCH.START });

    try {
      let promises;
      if (picsioConfig.isMainApp) {
        promises = [
          sdk.collections.getFavorites(),
          sdk.collections.getWebsites(),
          sdk.collections.getRoot(),
        ];
      } else {
        promises = [
          { data: {} },
          { data: {} },
          { data: { ...picsioConfig.access.tag, name: picsioConfig.access.overridedTagname } },
        ];
      }

      const [{ data: favorites }, { data: websites }, { data: my }] = await Q.all(promises);

      const nameSharedCollection = my.path.split('/').pop();

      favorites.path = 'favorites';
      websites.path = 'websites';
      my.path = 'root';
      my.hasChild = true;
      my.name = picsioConfig.isMainApp ? `${picsioConfig.isPim ? 'Media' : 'My team'} library` : nameSharedCollection;

      if (!my.storageId) my.storageId = my.googleId;

      const isFavoritesOpened = utils.LocalStorage.get('picsio.tagsTree.favorites.open');
      const isWebsitesOpened = utils.LocalStorage.get('picsio.tagsTree.websites.open');
      favorites.isOpen = isFavoritesOpened === null ? true : isFavoritesOpened;
      websites.isOpen = isWebsitesOpened === null ? true : isWebsitesOpened;
      my.isOpen = picsioConfig.isMainApp ? utils.LocalStorage.get('picsio.tagsTree.my.open') : true;

      dispatch({
        type: TYPES.COLLECTIONS.FETCH.COMPLETE,
        payload: {
          tree: { favorites, websites, my },
        },
      });

      if (picsioConfig.isMainApp) {
        setTeamName()(dispatch, getState);
      }

      // we forgot why my._id might be undefined, 13 june 2018
      if (my._id) {
        window.rootCollectionId = my._id;
        const { collectionIds: currentCollectionId, archived } = getState().router.location.query;
        const isRoot = my._id === currentCollectionId;

        if (!isRoot && !archived) {
          getChildren(my._id, { currentCollectionId })(dispatch);
        } else {
          getChildren(my._id)(dispatch);
        }
        if (isArchiveAllowed(getState().user)) {
          if (archived) {
            if (isRoot) {
              dispatch(fetchArchivedCollections({ collectionId: my._id }));
            } else {
              dispatch(
                fetchArchivedCollections({
                  collectionId: my._id,
                  currentCollectionId,
                }),
              );
            }
          }
        }
        return { rootCollectionId: my._id, currentCollectionId, archived };
      }
      console.error('By some  reasons my._id is undefined. Please report to @pokie.');
      return null;
    } catch (error) {
      console.error(error);
      dispatch({
        type: TYPES.COLLECTIONS.FETCH.FAILED,
        error,
      });
      throw error;
    }
  };
}

export default getCollections;
