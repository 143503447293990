import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button } from '@picsio/ui';
import { MediaMatching } from '@picsio/icons';
import s from './styles.module.scss';
import { navigate } from '../../helpers/history';
import { getRootId } from '../../store/utils/collections';
import l18n from '../../shared/strings';
import Logger from '../../services/Logger';

const Tabs = memo(({
  list, active, onChange, showBulkMediaMatch = false,
}) => {
  const handleClickButton = useCallback((tabId) => () => {
    onChange(tabId);
  }, [onChange]);

  const onOpenMediaMatch = useCallback(() => {
    Logger.log('User', 'OpenBulkMediaMatch');
    const rootId = getRootId();
    navigate(`/search?collectionIds=${rootId}&isMediaMatch=true`);
  }, []);

  return (
    <div className={s.tabs}>
      {list.map((tab) => (
        <button
          key={tab.id}
          className={cn(s.tab, { [s.active]: active === tab.id })}
          type="button"
          onClick={handleClickButton(tab.id)}
        >
          {tab.text}
        </button>
      ))}
      <If condition={showBulkMediaMatch}>
        <div className={s.buttonBulkMedia}>
          <Button
            onClick={onOpenMediaMatch}
            color="primary"
            componentProps={{
              'data-testid': 'btnBulkMediaMatch',
            }}
            startIcon={<MediaMatching />}
            size="xl"
          >
            {l18n.BULK_MEDIA_MATCH.BTN_OPEN_PLUGIN}
          </Button>
        </div>
      </If>
    </div>
  );
});

Tabs.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  active: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  showBulkMediaMatch: PropTypes.bool,
};

export default Tabs;
