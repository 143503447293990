import React from 'react';
import { useLoader } from '@react-three/fiber';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import PropTypes from 'prop-types';

const STLModel = ({ url, position }) => {
  const geometry = useLoader(STLLoader, url);

  return (
    <mesh geometry={geometry} castShadow receiveShadow position={position}>
      <meshStandardMaterial
        color="#cccccc"
        metalness={0.5}
        roughness={0.4}
      />
    </mesh>
  );
};

STLModel.propTypes = {
  url: PropTypes.string.isRequired,
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default STLModel;
