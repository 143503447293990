import _uniqBy from 'lodash/uniqBy';
import * as utils from '../../../shared/utils';

/**
 * Updating fields(meta, etc.) for many assets
 * @param {Array} items - current state assets,
 * @param {Array} assets - assets with new fields(meta, etc.),
 * @returns {Array}
 */
export default function setFieldsForManyAssets(items = [], assets = [], keywordsStrategy = 'merge') {
  const assetsLUT = assets.reduce((acc, asset) => {
    acc[asset._id] = asset;
    return acc;
  }, {});

  return items.map((item) => {
    const asset = assetsLUT[item._id];
    if (!asset) return item;

    const newItem = { ...item };

    asset.fields.forEach((key, index) => {
      if (utils.isObject(asset.values[index]) && utils.isObject(newItem[key])) {
        /** if field is object */
        newItem[key] = { ...newItem[key], ...asset.values[index] };
      } else if (key === 'keywords' && keywordsStrategy === 'merge') {
        /** if field is keywords and need to merge keywords */
        newItem[key] = _uniqBy([...(newItem[key] || []), ...(asset.values[index] || [])], '_id');
      } else {
        /** just add new value or replace old value */
        newItem[key] = asset.values[index];
      }
    });

    return newItem;
  });
}
