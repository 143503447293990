import React from 'react';
import { bool, func, string } from 'prop-types';
import { Button } from '@picsio/ui';
import l18n from '../../shared/strings';

const PlaceholderOtherFilters = ({ isRootCollection = false, handleNewSearch = null, searchText = null }) => (
  <div className="placeholderEmptyTitle">
    {l18n.SEARCH.noAssetsFound()}

    <If condition={!isRootCollection && searchText}>
      <div className="searchInRoot">
        {l18n.SEARCH.searchInRoot}
      </div>
      <Button
        variant="contained"
        color="primary"
        size="md"
        onClick={handleNewSearch}
        style={{ display: 'inline' }}
      >
        {l18n.SEARCH.searchButtonText(searchText)}
      </Button>
    </If>
  </div>
);

PlaceholderOtherFilters.propTypes = {
  isRootCollection: bool,
  handleNewSearch: func,
  searchText: string,
};

export default PlaceholderOtherFilters;
