import React, {
  memo, useMemo, useState, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  DownloadCsv,
  DotsVertical,
  RemoveFolder,
  Delete,
  Edit,
  Download,
  Dublicate,
} from '@picsio/icons';
import cn from 'classnames';
import {
  Menu, IconButton, MenuItem, MenuItemIcon, MenuItemText, Icon,
} from '@picsio/ui';
import showRemoveAssetFromCollectionDialog from '../../../helpers/showRemoveAssetFromCollectionDialog';
import Logger from '../../../services/Logger';
import localization from '../../../shared/strings';
import showSelectFromTreeDialog from '../../../helpers/showSelectFromTreeDialog';
import { checkUserAccess } from '../../../store/helpers/user';
import * as collectionsActions from '../../../store/actions/collections';
import picsioConfig from '../../../../../../config';

const AssetsMenu = (props) => {
  const {
    permissions,
    isRestricted,
    isDownloadable,
    isRemoveForever,
    numberOfAssets,
    downloadSelectedImages,
    exportToCSV,
    removeSelectedImages,
    initAssetRenaming,
    isArchived,
    duplicateAssets,
    isLightboardsView,
    isInbox,
    assetId,
    hasCollections = false,
  } = props;
  const user = useSelector((state) => state.user);
  const ref = useRef();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = useCallback(() => setMenuOpen((prevValue) => !prevValue), []);
  const isDownloadArchiveAllowed = isArchived ? (checkUserAccess('subscriptions', 'archive', null, user) && checkUserAccess('permissions', 'downloadArchive', null, user)) : true;
  const isDeleteArchiveAllowed = isArchived ? (checkUserAccess('subscriptions', 'archive', null, user) && checkUserAccess('permissions', 'deleteArchive', null, user)) : true;
  const isDuplicatable = numberOfAssets < 2 && permissions.upload === true && (!isLightboardsView && !isInbox);
  const dispatch = useDispatch();
  const myCollection = useSelector((state) => state.collections.collections.my);

  const handleDuplicate = useCallback(() => {
    Logger.log('User', 'ThumbnailCopyAsset');
    const { _id } = myCollection;
    const onLoadChildren = async (item) => dispatch(collectionsActions.getChildren(item._id));

    showSelectFromTreeDialog({
      title: localization.DIALOGS.DUPLICATE_ASSET_TO.TITLE,
      treeListItems: [myCollection],
      onLoadChildren,
      onOk: async (selectedCollections) => {
        duplicateAssets([assetId], selectedCollections[0]._id, true, true);
      },
      textBtnOk: localization.DIALOGS.DUPLICATE_ASSET_TO.OK,
      textBtnCancel: localization.DIALOGS.DUPLICATE_ASSET_TO.CANCEL,
      type: 'duplicate',
      openedItems: [_id],
    });
  }, [assetId, dispatch, duplicateAssets, myCollection]);

  const memoizedControls = useMemo(() => {
    const hasDownloadPermission = permissions?.assetsIsDownloadable === true
      && (!isRestricted || (isRestricted && permissions?.restrictedIsDownloadableOrShareable === true))
      && (isArchived ? isDownloadArchiveAllowed : true);
    const controls = [];

    /** Download */
    if (hasDownloadPermission && isDownloadable) {
      controls.push({
        id: 'menuDownload',
        text: localization.DETAILS.textDownload,
        onClick: downloadSelectedImages,
        icon: () => <Download />,
      });
    }
    /** Rename */
    if (numberOfAssets < 2 && permissions.fileNameEditable === true && !isArchived) {
      controls.push({
        id: 'menuRename',
        text: localization.DETAILS.textRename,
        onClick: initAssetRenaming,
        icon: () => <Edit />,
      });
    }
    /** Duplicate */
    if (hasDownloadPermission && isDownloadable && isDuplicatable) {
      controls.push({
        id: 'menuDuplicate',
        text: localization.DETAILS.textDuplicate,
        onClick: handleDuplicate,
        icon: () => <Dublicate />,
      });
    }
    /** Remove from collection */
    if (permissions.collectionsEditable && hasCollections && numberOfAssets === 1) {
      controls.push({
        id: 'menuRemoveFromCollections',
        text: localization.DETAILS.textRemoveFromCollections,
        onClick: () => showRemoveAssetFromCollectionDialog({ assetId }),
        icon: () => <RemoveFolder />,
      });
    }
    /** Move to trash */
    if (
      permissions?.assetsIsRemovable === true
        && (!isRestricted || (isRestricted && permissions?.restrictedIsAttachableOrRemovable === true))
        && (isArchived ? isDeleteArchiveAllowed : true)
    ) {
      controls.push({
        id: 'menuRemoveForever',
        text: isRemoveForever
          ? localization.DETAILS.textDeleteForever
          : localization.DETAILS.textDelete,
        onClick: removeSelectedImages,
        icon: () => <Delete />,
      });
    }
    /** Export CSV */
    if (hasDownloadPermission && !picsioConfig.isPim) {
      controls.push({
        id: 'menuExportToCSV',
        text: localization.DETAILS.textExportToCSV,
        onClick: exportToCSV,
        icon: () => <Icon size="lg"><DownloadCsv /></Icon>,
      });
    }

    return controls;
  }, [permissions, isRestricted, isArchived, isDownloadArchiveAllowed, hasCollections,
    numberOfAssets, isDeleteArchiveAllowed, exportToCSV, isDownloadable, downloadSelectedImages,
    isDuplicatable, handleDuplicate, assetId, isRemoveForever, removeSelectedImages,
    initAssetRenaming,
  ]);

  return (
    <If condition={memoizedControls.length}>
      <IconButton
        ref={ref}
        buttonSize="default"
        className={cn({ isActive: isMenuOpen })}
        color="default"
        component="button"
        disabled={false}
        id="assetMenuOpener"
        onClick={toggleMenu}
        size="lg"
      >
        <DotsVertical />
      </IconButton>
      <Menu
        target={ref}
        arrow
        padding="s"
        placement="bottom-end"
        isOpen={isMenuOpen}
        onClose={toggleMenu}
        outsideClickListener
      >
        {memoizedControls.map((control) => {
          const {
            id, text, onClick, icon: ControlIcon,
          } = control;

          return (
            <MenuItem
              key={id}
              id={id}
              onClick={() => {
                onClick();
                toggleMenu();
              }}
              className="menuItemDefault"
            >
              <MenuItemIcon size="md">
                <ControlIcon />
              </MenuItemIcon>
              <MenuItemText primary={text} />
            </MenuItem>
          );
        })}
      </Menu>
    </If>
  );
};

AssetsMenu.propTypes = {
  isDownloadable: PropTypes.bool.isRequired,
  isRestricted: PropTypes.bool.isRequired,
  isRemoveForever: PropTypes.bool.isRequired,
  permissions: PropTypes.shape({
    assetsIsDownloadable: PropTypes.bool,
    restrictedIsAttachableOrRemovable: PropTypes.bool,
    restrictedIsDownloadableOrShareable: PropTypes.bool,
    assetsIsRemovable: PropTypes.bool,
    fileNameEditable: PropTypes.bool,
    upload: PropTypes.bool,
    collectionsEditable: PropTypes.bool,
  }).isRequired,
  downloadSelectedImages: PropTypes.func.isRequired,
  exportToCSV: PropTypes.func.isRequired,
  removeSelectedImages: PropTypes.func.isRequired,
  initAssetRenaming: PropTypes.func.isRequired,
  numberOfAssets: PropTypes.number.isRequired,
  isArchived: PropTypes.bool.isRequired,
  duplicateAssets: PropTypes.func.isRequired,
  isInbox: PropTypes.bool.isRequired,
  assetId: PropTypes.string.isRequired,
  isLightboardsView: PropTypes.bool.isRequired,
  hasCollections: PropTypes.bool,
};

export default memo(AssetsMenu);
