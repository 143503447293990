import React, { forwardRef } from 'react';
import cn from 'classnames';
import { CirclePlusIcon } from '@picsio/icons';
import { IconButton } from '@picsio/ui';
import l10n from '../../shared/strings';

const AddFiltersBtn = forwardRef(({ title = l10n.SEARCH.addFiltersBtn, onAddFilters }) => {
  return (
    <div
      className={cn('searchFilterItem addFiltersBtn')}
      onClick={onAddFilters}
    >
      <div
        className={cn('searchFilterItem__title')}
      >
        { title }
      </div>
      <div className="searchFilterItem__section">
        <IconButton size="md" className="searchFilterItem__iconBtn">
          <CirclePlusIcon />
        </IconButton>
      </div>
    </div>
  );
});

export default AddFiltersBtn;
