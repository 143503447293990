/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const rename = createAsyncThunk(
  'products/rename',
  async ({ _id, title }, {
    extra: {
      sdk, Logger, showErrorDialog, utils,
    },
  }) => {
    try {
      await sdk.products.rename(_id, title);
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not rename product';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error product renaming'),
        { error },
        ['RenameProductFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      rename.pending,
      (state, { meta }) => {
        const { _id } = meta.arg;
        state.items.forEach((product) => {
          if (product._id === _id) product.isBusy = true;
        });
      },
    )
    .addCase(
      rename.fulfilled,
      (state, { meta }) => {
        state.items.forEach((product) => {
          if (product._id === meta.arg._id) {
            product.title = meta.arg.title;
            delete product.isBusy;
          }
        });
      },
    )
    .addCase(
      rename.rejected,
      (state, { meta, error }) => {
        state.items.forEach((item) => {
          if (item._id === meta.arg._id) {
            delete item.isBusy;
          }
        });
        state.error = error;
      },
    );
};
