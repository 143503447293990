import React from 'react';
import {
  array, element, oneOfType, string,
} from 'prop-types';
import { useSelector } from 'react-redux';
import ErrorBoundary from '../ErrorBoundary';
import picsioConfig from '../../../../../config';
import ToolbarCatalogProofing from '../toolbars/ToolbarCatalogProofing';
import CollectionsTree from '../CollectionsTree';
import CatalogView from '../CatalogView';
import DownloadListComponent from '../DownloadList';
import SearchFilters from '../SearchFilters';
import { PROOFING_FILTERS_ORDER } from '../SearchFilters/config';

const { newSearchFilters } = window.websiteConfig;

export default function AppProofing({ children = null }) {
  const isTreeOpened = useSelector((state) => state.main?.openedTree === 'collections');
  const { access } = picsioConfig;

  const searchFilters = Object.keys(PROOFING_FILTERS_ORDER).reduce((acc, setting) => {
    if (access[setting]) {
      acc.push(PROOFING_FILTERS_ORDER[setting]);
    }
    return acc;
  }, ['transcript', 'type', 'isRestricted']);

  return (
    <ErrorBoundary>
      <div className="appMain">
        <div className="content">
          <DownloadListComponent />
          <If condition={picsioConfig.access.tagsTreeShow && isTreeOpened}>
            <CollectionsTree />
          </If>
          <div className="topleftPicsioToolbar" />
          <div className="toprightPicsioToolbar" />
          <div className="leftPicsioToolbar" />
          <div className="bottomleftPicsioToolbar" />

          <div className="appCatalog">
            <ErrorBoundary>
              <If condition={newSearchFilters && access.searchShow && !picsioConfig.isPim && searchFilters}>
                <SearchFilters searchFilters={searchFilters} />
              </If>
            </ErrorBoundary>
            <CatalogView />
            <ToolbarCatalogProofing />
          </div>
        </div>
        {children}
      </div>
    </ErrorBoundary>
  );
}

AppProofing.propTypes = {
  children: oneOfType([string, element, array]),
};
