import React, { useEffect, useRef, useContext } from 'react';
import { useThree } from '@react-three/fiber';
import PropTypes from 'prop-types';
import STLModel from '../models/STLModel';
import ObjModel from '../models/OBJModel';
import FBXModel from '../models/FBXModel';
import TDSModel from '../models/TDSModel';
import DAEModel from '../models/DAEModel';
import PLYModel from '../models/PLYModel';
import GLTFModel from '../models/GLTFModel';
import ThreeContext from '../ThreeContext';

const AdjustCamera = ({ url = null, asset, position }) => {
  const { camera } = useThree();
  const objectRef = useRef();
  const THREE = useContext(ThreeContext);

  useEffect(() => {
    if (objectRef.current && THREE) {
      const box = new THREE.Box3().setFromObject(objectRef.current);
      const size = new THREE.Vector3();
      const center = new THREE.Vector3();

      box.getSize(size);
      box.getCenter(center);

      const maxSize = Math.max(size.x, size.y, size.z);
      const distance = maxSize / (2 * Math.tan((camera.fov * Math.PI) / 360));

      camera.position.set(center.x, center.y, center.z + distance * 2);
      camera.lookAt(center);
      camera.updateProjectionMatrix();

      return () => {
        box.makeEmpty();
        size.set(0, 0, 0);
        center.set(0, 0, 0);
      };
    }
    return undefined;
  }, [url, asset, camera, THREE]);

  const ModelComponent = url && (
    <Choose>
      <When condition={asset.name.endsWith('.stl')}>
        <STLModel url={url} position={position} />
      </When>
      <When condition={asset.name.endsWith('.obj')}>
        <ObjModel url={url} position={position} />
      </When>
      <When condition={asset.name.endsWith('.fbx')}>
        <FBXModel url={url} position={position} />
      </When>
      <When condition={asset.name.endsWith('.3ds')}>
        <TDSModel url={url} position={position} />
      </When>
      <When condition={asset.name.endsWith('.dae')}>
        <DAEModel url={url} position={position} />
      </When>
      <When condition={asset.name.endsWith('.ply')}>
        <PLYModel url={url} position={position} />
      </When>
      <When condition={asset.name.endsWith('.glb')}>
        <GLTFModel url={url} position={position} />
      </When>
    </Choose>
  );

  return (
    <>
      <group ref={objectRef}>
        {ModelComponent}
      </group>
    </>
  );
};

AdjustCamera.propTypes = {
  // eslint-disable-next-line react/require-default-props
  url: PropTypes.string,
  asset: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default AdjustCamera;
