import React, {
  useState, useRef, useCallback, useMemo, useEffect,
} from 'react';
import pluralize from 'pluralize';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {
  ArrowPrev,
  Download,
  Info,
  Forum,
  Close,
  Statistics,
} from '@picsio/ui/dist/icons';
import { IconColorful, Checkbox, Icon } from '@picsio/ui';
import { GoogleDrive, OpenIn, Transcript } from '@picsio/icons';
import Logger from '../../services/Logger';
import isValidAssetName from '../../helpers/assets/isValidAssetName';

import * as actions from '../../store/actions/assets';
import { Input } from '../../UIComponents';

import Button from './Button';
import Group from './Group';
import Logo from './Logo';
import ua from '../../ua';

import localization from '../../shared/strings';
import picsioConfig from '../../../../../config';

export default function ToolbarPreviewTop(props) {
  const {
    assetName,
    pages,
    activePageNumber,
    analytics,
    download,
    historyPanel,
    details,
    onClose,
    assetId,
    permissions,
    storageId,
    storageType,
    source = null,
    selectedAssetsIds = [],
    rootCollectionId = '',
    renameInProgress = false,
    isAssetSelected = false,
    selectAsset = () => {},
    isExternalAsset = false,
    assetsActions = {},
    collectionsActions = {},
    transcriptions,
    isSelectable,
  } = props;
  const userId = useSelector((state) => state.user._id);

  const isMainApp = useMemo(() => picsioConfig.isMainApp, []);
  const [ext, setAssetExt] = useState('');
  const [newName, setAssetNewName] = useState(assetName);
  const inputRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    setAssetNewName(assetName);
  }, [assetName]);

  const initAssetRenaming = () => {
    const assetNameFull = assetName.split('.');
    const assetExt = assetNameFull.pop();
    const assetNewName = assetNameFull.join('.');
    setAssetExt(assetExt);
    setAssetNewName(assetNewName);
  };

  useEffect(() => {
    if (transcriptions?.transcribingParams?.initiatorId === userId
      && transcriptions?.isActive
      && typeof transcriptions?.transcriptViewedByInitiator !== 'undefined'
      && !transcriptions?.transcriptViewedByInitiator
    ) {
      dispatch(actions.setTranscriptViewedByInitiator({ assetId, value: true }));
    }
  }, [userId, assetId, dispatch, transcriptions]);

  const handleEsc = useCallback((event) => {
    const asset = assetName.split('.');
    asset.pop();
    const assetWithoutExt = asset.join('.');
    setAssetNewName(assetWithoutExt);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }, [assetName, setAssetNewName]);

  const handleInputRenameBlur = () => {
    const name = `${newName}.${ext}`;
    if (assetName !== name && newName !== '' && isValidAssetName(name, { showToast: true })) {
      dispatch(actions.rename(assetId, name));
      setAssetNewName(name);
      return;
    }
    handleEsc();
  };

  const handleInputRenameKeyDown = (event) => {
    if (event.keyCode === 13) {
      inputRef.current && inputRef.current.blur();
    }

    if (event.keyCode === 27) {
      handleEsc(event);
    }
  };

  const handleInputRenameChange = () => {
    const assetNewName = inputRef.current.value;
    setAssetNewName(assetNewName);
  };

  const openInLink = useMemo(() => {
    if (isMainApp && (source || storageType === 'gd')) {
      return source || `https://docs.google.com/file/d/${storageId}/edit`;
    }
    return null;
  }, [storageType, storageId, source, isMainApp]);

  const handleClickOpenIn = useCallback(() => {
    Logger.log('User', 'PreviewOpenInNewTab', openInLink);
  }, [openInLink]);

  const handleClickTranscript = () => {
    transcriptions.handler();
  };

  const handleCheckboxSelect = (event) => {
    if (isAssetSelected) {
      Logger.log('User', 'ClipboardPreviewCheckboxUnselect', { assetId });
    } else {
      Logger.log('User', 'ClipboardPreviewCheckboxSelect', { assetId });
    }
    selectAsset({
      id: assetId, value: !isAssetSelected, isRange: event.shiftKey, isOnlyValue: event.altKey,
    });
  };

  const showAllSelectedAssets = useCallback(() => {
    Logger.log('User', 'ClipboardPreviewCounterClick', { selectedAssetsCount: selectedAssetsIds.length });
    assetsActions.getSelectedAssets(selectedAssetsIds);
    collectionsActions.setActiveCollection(rootCollectionId);
  }, [assetsActions, collectionsActions, rootCollectionId, selectedAssetsIds]);

  return (
    <div className="toolbar toolbarPreviewTop">
      <Group>
        <If condition={onClose}>
          <Choose>
            <When condition={ua.isMobileApp() || ua.browser.isNotDesktop()}>
              <Button
                id="button-backToCatalog"
                icon={() => <ArrowPrev />}
                iconSize="xl"
                onClick={onClose}
              />
            </When>
            <When condition={isMainApp}>
              <Logo handleLogoClick={onClose} />
            </When>
            <Otherwise>
              <Button
                id="button-backToCatalog"
                icon={() => <ArrowPrev />}
                iconSize="xl"
                additionalClass="color"
                onClick={onClose}
              />
            </Otherwise>
          </Choose>
        </If>
      </Group>

      <If condition={!ua.isMobileApp() && !ua.browser.isNotDesktop() && isMainApp && isSelectable}>
        <Group additionalClass="selectedAssetsWrapper">
          <div className="selectedAssets">
            <Checkbox
              checked={isAssetSelected}
              onChange={handleCheckboxSelect}
            />
            <If condition={selectedAssetsIds.length > 0}>
              <div className="selectedAssetsText" data-testid="previewToolbarAssetsCount" onClick={showAllSelectedAssets}>
                {`${pluralize('asset', selectedAssetsIds.length, true)} selected`}
              </div>
            </If>
          </div>
        </Group>
      </If>
      <Group additionalClass="assetNameWrapper previewAssetNameWrapper">
        <If condition={isMainApp || picsioConfig.access.fileNameShow}>
          <div className={cn('assetName', { isExternalAsset })}>
            <Input
              type="text"
              error={!isValidAssetName(newName)}
              className="assetRenaming"
              value={newName}
              onChange={handleInputRenameChange}
              onKeyDown={handleInputRenameKeyDown}
              onBlur={handleInputRenameBlur}
              disabled={!isMainApp || (isMainApp && !permissions.editAssetFilename) || renameInProgress}
              customRef={inputRef}
              size={assetName.length}
              onFocus={initAssetRenaming}
            />
          </div>
        </If>
        <If condition={openInLink}>
          <a
            className="button-openIn toolbarButton"
            href={openInLink}
            onClick={handleClickOpenIn}
            rel="noreferrer"
            target="_blank"
          >
            <If condition={isExternalAsset}>
              <Icon size="lg">
                <OpenIn />
              </Icon>
            </If>
            <If condition={!isExternalAsset}>
              <IconColorful size="lg">
                <GoogleDrive />
              </IconColorful>
            </If>
          </a>
        </If>
        <If condition={pages || activePageNumber}>
          <span className="multipagePaging">
            <b>{activePageNumber}</b> / {pages}
          </span>
        </If>
      </Group>
      <Group>
        <If condition={download}>
          <Button
            id="button-previewDownloadTop"
            icon={() => <Download />}
            onClick={download.handler}
            additionalClass={download.additionalClass}
          />
        </If>
        <If condition={isMainApp && transcriptions?.showButton && !picsioConfig.isPim}>
          <Button
            id="button-previewTranscripts"
            icon={() => <Transcript />}
            onClick={handleClickTranscript}
            isActive={transcriptions?.isActive}
            tooltip={localization.TOOLBARS.titleTranscriptions}
            tooltipPosition="bottom"
            additionalClass={(
              transcriptions?.transcribingParams?.initiatorId === userId
              && typeof transcriptions?.transcriptViewedByInitiator !== 'undefined'
              && !transcriptions?.transcriptViewedByInitiator)
              ? 'viewed' : ''}
          />
        </If>
        <If condition={isMainApp && !picsioConfig.isPim}>
          <Button
            id="button-previewAnalytics"
            icon={() => <Statistics />}
            onClick={analytics.handler}
            isActive={analytics.isActive}
            tooltip={localization.TOOLBARS.titleAnalytics}
            tooltipPosition="bottom"
          />
        </If>

        <If condition={historyPanel}>
          <Button
            id="button-previewActivity"
            icon={() => <Forum />}
            onClick={historyPanel.handler}
            isActive={historyPanel.isActive}
            tooltip={localization.TOOLBARS.titleActivity}
            tooltipPosition="bottom"
          />
        </If>
        <If condition={details}>
          <Button
            id="button-previewDetails"
            icon={() => <Info />}
            onClick={details.handler}
            isActive={details.isActive}
            tooltip={localization.TOOLBARS.titleDetails}
            tooltipPosition="bottom"
          />
        </If>
        <If condition={onClose}>
          <Button
            id="button-previewClose"
            icon={() => <Close />}
            iconSize="xxl"
            additionalClass="mobileHidden"
            onClick={onClose}
            tooltip={localization.TOOLBARS.titleClosePreview}
            tooltipPosition="bottom"
          />
        </If>
      </Group>
    </div>
  );
}

ToolbarPreviewTop.propTypes = {
  assetName: PropTypes.string,
  pages: PropTypes.string,
  activePageNumber: PropTypes.number,
  analytics: PropTypes.object,
  download: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  historyPanel: PropTypes.object,
  details: PropTypes.object,
  onClose: PropTypes.func,
  historyItems: PropTypes.array,
  isSupportedForDiff: PropTypes.bool,
  setActive: PropTypes.func,
  isDownloadable: PropTypes.bool,
  activeRevisionID: PropTypes.func,
  lastRevisionNumber: PropTypes.number,
  addRevision: PropTypes.func,
  subscriptionFeatures: PropTypes.object,
  isAllowedUploadingRevision: PropTypes.bool,
  isRevisionUploading: PropTypes.bool,
  showRevisions: PropTypes.bool,
  assetId: PropTypes.string,
  permissions: PropTypes.object,
  storageId: PropTypes.string,
  storageType: PropTypes.string,
  source: PropTypes.string,
  transcriptions: PropTypes.object,
  selectedAssetsIds: PropTypes.array,
  isAssetSelected: PropTypes.bool,
  renameInProgress: PropTypes.bool,
  selectAsset: PropTypes.func,
  isExternalAsset: PropTypes.bool,
  assetsActions: PropTypes.shape({
    getSelectedAssets: PropTypes.func,
  }),
  collectionsActions: PropTypes.shape({
    setActiveCollection: PropTypes.func,
  }),
  rootCollectionId: PropTypes.string,
  isSelectable: PropTypes.bool,
};
