import {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import picsioConfig from '../../../../../../../config';
import { LocalStorage, capitalizeFirstLetter } from '../../../../shared/utils';

import { sortFieldsByConfigOrder, sortGroups, getWebsiteCustomFields } from './utils';
import { createGroups } from '../../../../store/helpers/customFields';

const { isMainApp, isPim } = picsioConfig;
const websiteCustomFields = window.websiteConfig?.customFields;

const mainFields = [
  {
    id: 'share',
    title: 'Share',
    type: 'separator',
    permission: 'isAllowedSAS',
    visibility: true,
  }, {
    title: 'Title & Description',
    id: 'titleAndDescription',
    type: 'separator',
    visibility: true,
    permission: 'titleOrDescriptionShow',
  }, {
    title: 'Title',
    id: 'title',
    type: 'field',
    visibility: true,
    permission: 'titleShow',
  }, {
    title: 'Description',
    id: 'description',
    type: 'field',
    visibility: true,
    permission: 'descriptionShow',
  }, {
    title: 'Keywords',
    id: 'keywords',
    type: 'separator',
    visibility: true,
  }, {
    id: 'faces',
    title: 'Faces',
    type: 'separator',
    visibility: true,
  }, {
    id: 'transcript',
    title: 'Transcript',
    type: 'separator',
    visibility: true,
  }, {
    id: 'watermarks',
    title: 'Watermarks',
    type: 'separator',
    visibility: true,
  }, {
    title: 'Assignees',
    id: 'assignees',
    type: 'separator',
    visibility: true,
  }, {
    id: 'linkedAssets',
    title: 'Linked Assets',
    type: 'separator',
    visibility: true,
  }, {
    id: 'collections',
    title: 'Collections',
    permission: 'collectionsShow',
    type: 'separator',
    visibility: true,
  }, {
    id: 'lightboards',
    title: 'Lightboards',
    permission: 'lightboardsShow',
    type: 'separator',
    visibility: true,
  }, {
    id: 'assetMark',
    title: 'Asset marks',
    type: 'separator',
    visibility: true,
    permission: 'flagOrColorOrRatingShow',
  }, {
    title: 'Flag',
    id: 'flag',
    type: 'field',
    visibility: true,
    permission: 'flagShow',
  }, {
    title: 'Color',
    id: 'color',
    type: 'field',
    visibility: true,
    permission: 'colorShow',
  }, {
    title: 'Rating',
    id: 'rating',
    type: 'field',
    visibility: true,
    permission: 'ratingShow',
  }, {
    id: 'restrict',
    title: 'Restrict',
    permission: 'isRestrictEditable',
    type: 'separator',
    visibility: true,
  }, {
    id: 'archive',
    title: 'Archive',
    type: 'separator',
    visibility: true,
  }, {
    id: 'map',
    title: 'Map',
    type: 'separator',
    permission: 'customFieldsShow',
    visibility: true,
  },
];

if (isPim) {
  mainFields.unshift({
    id: 'products',
    title: 'Products',
    type: 'separator',
    visibility: true,
  });
}

const websiteFieldKeys = ['titleAndDescription', 'title', 'description', 'assetMark', 'flag', 'color', 'rating', 'map'];
const websiteFields = mainFields.filter((f) => websiteFieldKeys.includes(f.id));
const pimFieldKeys = ['products', 'titleAndDescription', 'title', 'description', 'keywords', 'assignees', 'linkedAssets', 'collections', 'assetMark', 'flag', 'color', 'rating', 'restrict', 'map'];
const pimFields = mainFields.filter((f) => pimFieldKeys.includes(f.id));

const defaultConfig = {
  order: [],
  hidden: [],
};

const getStorageConfig = (userId) => {
  const oldStorageConfig = LocalStorage.get('picsio.detailsPanelConfig');
  const storageConfig = LocalStorage.get('picsio.editWidgetsConfig') || {};

  if (oldStorageConfig && (!storageConfig || !storageConfig[userId])) {
    LocalStorage.set('picsio.editWidgetsConfig', { [userId]: oldStorageConfig });
    LocalStorage.remove('picsio.detailsPanelConfig');
  }

  return storageConfig[userId];
};

const updateStorageConfig = (userId, updatedConfig) => {
  const storageConfig = LocalStorage.get('picsio.editWidgetsConfig');

  LocalStorage.set('picsio.editWidgetsConfig', {
    ...storageConfig,
    [userId]: updatedConfig,
  });
};

const buildMetaFields = (fields) => fields.map((item, idx) => ({
  ...item,
  order: idx,
}));

const DetailsPanelConfig = (props) => {
  const { userId, children } = props;
  const [isOpen, setOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [config, setConfig] = useState(defaultConfig);
  const [isResetToDefault, setResetToDefault] = useState(false);

  const user = useSelector((state) => state.user);
  const roles = useSelector((state) => state.roles.items);
  const customFields = useSelector((state) => state.customFields);

  const teammateRoleId = useMemo(() => user.parent && user.parent.teammateRoleId, [user.parent]);
  const isOwner = useMemo(() => !teammateRoleId, [teammateRoleId]);
  const userRole = useMemo(() => roles.find((role) => role._id === teammateRoleId), [roles, teammateRoleId]);
  const defaultFields = useMemo(() => (isMainApp ? (isPim ? pimFields : mainFields) : websiteFields), [isMainApp]);
  // Set 'map' field in the end of the custom fields list
  const customFieldsItems = useMemo(() => ((customFields.items && customFields.items[0]?.type !== 'separator') ? [{ title: 'Custom fields', type: 'separator', visibility: 'visible' }, ...customFields.items] : (customFields.items || []))
    .map((item) => ({ ...item, permission: 'customFieldsShow', id: `meta.${item.title}` }))
    .concat(defaultFields.slice(-1)),
  [customFields.items, defaultFields]);
  const filteredCustomFieldsItems = useMemo(() => ((!isMainApp && websiteCustomFields) ? getWebsiteCustomFields(customFieldsItems, websiteCustomFields) : customFieldsItems), [customFieldsItems]);
  const initFields = useMemo(() => defaultFields.slice(0, defaultFields.length - 1).concat(filteredCustomFieldsItems), [filteredCustomFieldsItems, defaultFields]);
  const hiddenFields = useMemo(() => userRole?.hiddenFields || (isOwner && []) || (websiteCustomFields && []) || initFields.filter(({ visibility, type }) => visibility === 'hidden' && type !== 'separator').map(({ id, title }) => id || title), [initFields, isOwner, userRole?.hiddenFields]);
  const filteredRoleFields = useMemo(() => ((hiddenFields && hiddenFields.length) ? initFields.filter(({ id }) => !(hiddenFields.includes(id) || hiddenFields.includes(id.replace('meta.', '')))) : initFields), [initFields, hiddenFields]);
  const fields = useMemo(() => buildMetaFields(filteredRoleFields), [filteredRoleFields]);
  const initGroups = useMemo(() => createGroups(fields), [fields]);
  const filteredInitGroups = useMemo(() => ((userRole?.hiddenFields || isOwner || websiteCustomFields) ? initGroups : initGroups.filter((group) => group[0].visibility !== 'hidden')), [initGroups, isOwner, userRole?.hiddenFields]);
  const metadataOrder = useMemo(() => (userRole?.metadataOrder
    ? userRole.metadataOrder.filter((item) => capitalizeFirstLetter(item) !== 'Comment').map((item) => ((mainFields.some(({ id }) => id === item) || item.startsWith('meta.')) ? item : (mainFields.find(({ title }) => title === item)?.id || `meta.${item}`)))
    : filteredInitGroups.map(([field]) => field.id)), [filteredInitGroups, userRole?.metadataOrder]);
  const customFieldsTitles = useMemo(() => customFields.groups.map((group) => group[0] && group[0].title), [customFields]);
  const groupedFields = useMemo(() => sortGroups(filteredInitGroups, metadataOrder), [filteredInitGroups, metadataOrder]);

  useEffect(() => {
    if (isMainApp) {
      const storageConfig = getStorageConfig(userId);

      if (storageConfig) {
        // Update storage config if user has config with old naming
        if (storageConfig.order.includes('description') || storageConfig.order.includes('customFields')) {
          storageConfig.order = storageConfig.order.reduce((acc, id) => {
            if (id === 'description') return [...acc, 'titleAndDescription'];
            if (id === 'customFields') return [...acc, ...customFieldsTitles];
            return [...acc, id];
          }, []);
        }
        if (storageConfig.hidden.includes('description') || storageConfig.hidden.includes('customFields')) {
          storageConfig.hidden = storageConfig.hidden.reduce((acc, id) => {
            if (id === 'description') return [...acc, 'titleAndDescription'];
            if (id === 'customFields') return [...acc, ...customFieldsTitles];
            return [...acc, id];
          }, []);
        }

        const sortedFields = sortFieldsByConfigOrder(groupedFields, storageConfig);

        setGroups(sortedFields);
        setConfig(storageConfig);
      } else {
        setGroups(groupedFields);
        if (isResetToDefault) setResetToDefault(false);
      }
    } else {
      setGroups(groupedFields);
    }
  }, [customFieldsTitles, groupedFields, userId, isResetToDefault]);

  const toggleEditPanel = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  const updateConfig = useCallback(
    (updatedFields, hidden) => {
      const order = updatedFields.map(([field]) => field.id || field.title);
      const updatedConfig = { order, hidden };

      setGroups(updatedFields);
      setConfig(updatedConfig);

      updateStorageConfig(userId, updatedConfig);
    },
    [userId, setConfig, setGroups],
  );

  return children({
    isOpen, fields, groups, config, toggleEditPanel, setFields: setGroups, updateConfig, resetToDefault: setResetToDefault,
  });
};

DetailsPanelConfig.defaultProps = {
  userId: '',
};

DetailsPanelConfig.propTypes = {
  userId: PropTypes.string,
  children: PropTypes.func.isRequired,
};

export default DetailsPanelConfig;
