import React, { useCallback, useRef } from 'react';
import useHover from '@react-hook/hover';
import PropTypes from 'prop-types';
import Logger from '../../services/Logger';
import picsioConfig from '../../../../../config';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

const NameGD = ({
  fileNameShow, storageId, name, isListViewMode, fileExtension, itemWidth, isImportingFailed, isImportingInProcess, isMatchingView,
}) => {
  const handleClickOpenIn = useCallback((event) => {
    event.stopPropagation();
    Logger.log('User', 'ThumbnailOpenInGD');
  }, []);

  const catalogItemName = useRef();
  const isHovering = useHover(catalogItemName, { enterDelay: 0, leaveDelay: 0 });

  return (
    <div className="catalogItem__name" ref={catalogItemName}>
      <If condition={fileNameShow}>
        <Tooltip
          content={`${name}${isListViewMode ? `.${fileExtension}` : ''}`}
          placement="top"
          interactive
          delayHide={500}
        >
          <div className="catalogItem__name-holder" style={{ width: isHovering && itemWidth }}>
            <span className="catalogItem__name-name">
              <If condition={picsioConfig.isMainApp && !isImportingFailed && !isImportingInProcess}><Icon name="logoGD" /></If>
              <span>{name}.
                {isListViewMode ? `${fileExtension}` : ''}
              </span>
            </span>
            <span className="catalogItem__name-ext">
              <If condition={!isListViewMode}><span>{fileExtension}</span></If>
              <If condition={!isImportingFailed && !isImportingInProcess && !isMatchingView}>
                <Choose>
                  <When condition={picsioConfig.isMainApp}>
                    <a
                      className="catalogItem__name-openIn"
                      href={`https://docs.google.com/file/d/${storageId}/edit`}
                      onClick={handleClickOpenIn}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Icon name="openIn" />
                    </a>
                  </When>
                  <Otherwise>
                    <Icon name="logoGD" />
                  </Otherwise>
                </Choose>
              </If>
            </span>
          </div>
        </Tooltip>
      </If>
    </div>
  );
};

const NameS3 = ({
  fileNameShow, name, isListViewMode, fileExtension, itemWidth, isImportingFailed, isImportingInProcess, isMatchingView,
}) => {
  const catalogItemName = useRef();
  const isHovering = useHover(catalogItemName, { enterDelay: 0, leaveDelay: 0 });

  return (
    <div className="catalogItem__name" ref={catalogItemName}>
      <If condition={fileNameShow}>
        <Tooltip
          content={`${name}${isListViewMode ? `.${fileExtension}` : ''}`}
          placement="top"
          interactive
          delayHide={500}
        >
          <div className="catalogItem__name-holder" style={{ width: isHovering && itemWidth }}>
            <span className="catalogItem__name-name">
              <span>
                {name}.
                {isListViewMode ? `${fileExtension}` : ''}
              </span>
            </span>
            <span className="catalogItem__name-ext">
              <If condition={!isListViewMode}><span>{fileExtension}</span></If>
              <If condition={!isImportingFailed && !isImportingInProcess && !isMatchingView}>
                <Icon name="amazonS3" />
              </If>
            </span>
          </div>
        </Tooltip>
      </If>
    </div>
  );
};

const Name = (props) => {
  const {
    storageType,
    fileNameShow,
    storageId,
    shortName,
    isListViewMode,
    fileExtension,
    itemWidth,
    isImportingFailed,
    isImportingInProcess,
    isMatchingView = false,
  } = props;

  return (
    <>
      <Choose>
        <When condition={storageType === 's3'}>
          <NameS3
            fileNameShow={fileNameShow}
            name={shortName}
            isListViewMode={isListViewMode}
            fileExtension={fileExtension}
            itemWidth={itemWidth}
            isImportingFailed={isImportingFailed}
            isImportingInProcess={isImportingInProcess}
            isMatchingView={isMatchingView}
          />
        </When>
        <Otherwise>
          <NameGD
            fileNameShow={fileNameShow}
            storageId={storageId}
            name={shortName}
            isListViewMode={isListViewMode}
            fileExtension={fileExtension}
            itemWidth={itemWidth}
            isImportingFailed={isImportingFailed}
            isImportingInProcess={isImportingInProcess}
            isMatchingView={isMatchingView}
          />
        </Otherwise>
      </Choose>
    </>
  );
};

NameGD.propTypes = {
  fileExtension: PropTypes.string.isRequired,
  fileNameShow: PropTypes.bool.isRequired,
  isListViewMode: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  storageId: PropTypes.string,
  itemWidth: PropTypes.number.isRequired,
  isImportingFailed: PropTypes.bool.isRequired,
  isImportingInProcess: PropTypes.bool.isRequired,
  isMatchingView: PropTypes.bool,
};

NameS3.propTypes = {
  fileExtension: PropTypes.string.isRequired,
  fileNameShow: PropTypes.bool.isRequired,
  isListViewMode: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  itemWidth: PropTypes.number.isRequired,
  isImportingFailed: PropTypes.bool.isRequired,
  isImportingInProcess: PropTypes.bool.isRequired,
  isMatchingView: PropTypes.bool,
};

Name.propTypes = {
  fileExtension: PropTypes.string.isRequired,
  fileNameShow: PropTypes.bool.isRequired,
  isListViewMode: PropTypes.bool.isRequired,
  shortName: PropTypes.string.isRequired,
  storageId: PropTypes.string,
  storageType: PropTypes.string.isRequired,
  itemWidth: PropTypes.number.isRequired,
  isImportingFailed: PropTypes.bool.isRequired,
  isImportingInProcess: PropTypes.bool.isRequired,
  isMatchingView: PropTypes.bool,
};

export default Name;
