import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@picsio/ui';
import {
  Dublicate,
  AddRevision,
  CommentAdd,
  Edit,
  Download,
  Delete,
  DeleteFrom,
  Close,
  RestoreFrom,
  Web,
  DateEmpty,
  DateUpload,
  RemoveFolder,
  Sync,
} from '@picsio/icons';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import {
  ASYNC_JOB_STATUS_FAILED,
  ASYNC_JOB_STATUS_REJECTED,
} from '@picsio/db/src/constants';
import showSelectFromTreeDialog from '../../helpers/showSelectFromTreeDialog';
import picsioConfig from '../../../../../config';
import Store from '../../store';
import * as utils from '../../shared/utils';
import localization from '../../shared/strings';
import Logger from '../../services/Logger';
import * as collectionsActions from '../../store/actions/collections';
import Tooltip from '../Tooltip';
import Name from './Name';
import Colors from './Colors';
import Button from './ControlButton';
import { navigate } from '../../helpers/history';
import { showErrorDialog } from '../dialog';
import sendEventToIntercom from '../../services/IntercomEventService';
import showRemoveAssetFromCollectionDialog from '../../helpers/showRemoveAssetFromCollectionDialog';
import DialogRadios from '../dialogRadios';

export default function Controls(props) {
  const {
    allowAssetSharing,
    allowRemoveTags,
    assetId,
    assetSharing,
    color,
    colorChangeable,
    colorShow,
    commentsEdit,
    commentsEnable,
    deleteForever,
    remove,
    downloadFiles,
    enableEditor,
    fileExtension = '',
    fileNameShow,
    handleDownload,
    handleRemoveLightboard,
    handleUploadClick,
    isAssetFromInbox,
    isEditableInPicsioEditor,
    isGoogleDriveDocument,
    isLightboardsView,
    isListViewMode,
    isMobileView,
    isShared,
    isTrashed,
    itemWidth,
    itemHeight,
    mimeType,
    removeFiles,
    restoreAssets,
    shortName,
    storageId,
    storageType,
    title = '',
    trashAssets,
    uploadFiles,
    duplicateAssets,
    allowDuplicateAsset,
    isImportingFailed,
    isImportingMediaFailed,
    isImportingInProcess,
    retryImporting,
    generatedDate = '',
    createdDate = '',
    numberOfCollections = 0,
    isProductView = false,
    isMatchingView = false,
  } = props;

  const dispatch = useDispatch();
  const assets = useSelector((state) => state.assets.items);

  const handleShare = () => {
    sendEventToIntercom('share from thumbnail');
    if (allowAssetSharing) {
      Logger.log('User', 'ThumbnailShareAsset');
      navigate(`/singlesharing/${assetId}`);
    } else {
      navigate('/billing?tab=overview');
    }
  };
  const handleDuplicate = () => {
    if (!picsioConfig.isMainApp) {
      /** Duplicate is only allowed in the main application */
      return;
    }

    Logger.log('User', 'ThumbnailCopyAsset');
    const rootCollection = Store.getState().collections.collections.my;
    const { _id } = rootCollection;
    const onLoadChildren = async (item) => dispatch(collectionsActions.getChildren(item._id));

    showSelectFromTreeDialog({
      title: localization.DIALOGS.DUPLICATE_ASSET_TO.TITLE,
      treeListItems: [rootCollection],
      onLoadChildren,
      onOk: async (selectedCollections) => {
        duplicateAssets([assetId], selectedCollections[0]._id, true, true);
      },
      textBtnOk: localization.DIALOGS.DUPLICATE_ASSET_TO.OK,
      textBtnCancel: localization.DIALOGS.DUPLICATE_ASSET_TO.CANCEL,
      type: 'duplicate',
      openedItems: [_id],
    });
  };

  const handleClickEdit = () => {
    if (isGoogleDriveDocument) {
      utils.openDocument(storageId, mimeType);
    } else if (isEditableInPicsioEditor) {
      navigate(`/develop/${assetId}`);
    } else {
      showErrorDialog(localization.DIALOGS.UNSUPPORTED_FILE.TEXT, localization.DIALOGS.UNSUPPORTED_FILE.TITLE);
    }
  };

  const handleAddCommentClick = () => {
    Logger.log('User', 'ThumbnailCommentAddClick');
    navigate(`preview/${assetId}#comments?focus=true`);
  };

  const handleRemove = () => {
    Logger.log('User', 'ThumbnailTrash');
    trashAssets([assetId]);
  };

  const handleRemoveFromCollections = useCallback(() => {
    Logger.log('User', 'ThumbnailRemoveFromCollections', { assetId });
    showRemoveAssetFromCollectionDialog({ assetId });
  }, [assetId]);

  const handleRestore = () => {
    Logger.log('User', 'ThumbnailRestoreFromTrash');
    restoreAssets([assetId]);
  };

  const handleRetryImporting = () => {
    DialogRadios({
      title: localization.DIALOGS.RETRY_IMPORT_ASSETS.TITLE,
      text: localization.DIALOGS.RETRY_IMPORT_ASSETS.TEXT,
      items: [
        {
          label: localization.DIALOGS.RETRY_IMPORT_ASSETS.RADIO_LABEL_ONE,
          value: 'one',
          checked: true,
          dataTestId: 'option-retry-importing-one-asset',
        },
        {
          label: localization.DIALOGS.RETRY_IMPORT_ASSETS.RADIO_LABEL_ALL,
          value: 'all',
          dataTestId: 'retry-importing-all-assets',
        },
      ],
      onOk: (value) => {
        Logger.log('User', 'RetryImportAssetDialogConfirm');
        if (value === 'one') {
          retryImporting({ assetIds: [assetId], type: value, isMedia: isImportingMediaFailed });
        } else {
          const { importFromCloud, importFromStore } = assets.reduce((acc, asset) => {
            if ([ASYNC_JOB_STATUS_FAILED, ASYNC_JOB_STATUS_REJECTED].includes(asset.importing)) {
              acc.importFromCloud.push(asset._id);
            }
            if ([ASYNC_JOB_STATUS_FAILED, ASYNC_JOB_STATUS_REJECTED].includes(asset.importingMedia)) {
              acc.importFromStore.push(asset._id);
            }
            return acc;
          }, { importFromCloud: [], importFromStore: [] });

          if (importFromCloud.length) {
            retryImporting({ assetIds: importFromCloud, type: value, isMedia: false });
          }
          if (importFromStore.length) {
            retryImporting({ assetIds: importFromStore, type: value, isMedia: isImportingMediaFailed });
          }
        }
      },
      onCancel: () => {
        Logger.log('User', 'RetryImportAssetDialogCancel');
      },
      cancelName: localization.DIALOGS.btnCancel,
      okName: localization.DIALOGS.btnConfirm,
    });
  };

  const tooltipPlacement = isListViewMode ? 'top' : 'left';

  return (
    <div className={cn('catalogItem__controls', { hidden: isImportingInProcess && !isListViewMode })}>
      <div className="catalogItem__controls-top">
        <Name
          storageType={storageType}
          fileNameShow={fileNameShow}
          isListViewMode={isListViewMode}
          storageId={storageId}
          shortName={shortName}
          fileExtension={fileExtension}
          title={title}
          itemWidth={itemWidth}
          isImportingFailed={isImportingFailed || isImportingMediaFailed}
          isImportingInProcess={isImportingInProcess}
          isMatchingView={isMatchingView}
        />
        <If condition={isListViewMode && (generatedDate || createdDate) && !isImportingFailed && !isImportingInProcess && !isImportingMediaFailed}>
          <div className="catalogItem__date">
            <If condition={generatedDate}>
              <Tooltip
                placement="left"
                content={localization.DETAILS.uploadDateTooltipText}
              >
                <div className="catalogItem__date-item" data-testid="metadata-UploadDate">
                  <DateUpload />
                  <span>
                    {generatedDate}
                  </span>
                </div>
              </Tooltip>
            </If>
            <If condition={createdDate}>
              <Tooltip
                placement="left"
                content={localization.DETAILS.creationDateTooltipText}
              >
                <div className="catalogItem__date-item" data-testid="metadata-CreateDate">
                  <DateEmpty />
                  <span>
                    {createdDate}
                  </span>
                </div>
              </Tooltip>
            </If>
          </div>
        </If>
        <If condition={colorShow && !isImportingFailed && !isImportingInProcess && !isImportingMediaFailed}>
          <Colors assetId={assetId} color={color} disabled={!colorChangeable} />
        </If>
      </div>
      <If condition={!isMobileView}>
        <div className="catalogItem__controls-bottom">
          <If condition={picsioConfig.isMainApp && !isProductView && !isMatchingView && allowAssetSharing && !isImportingFailed && !isImportingInProcess && !isImportingMediaFailed && !picsioConfig.isPim}>
            <Tooltip
              content={
                !assetSharing
                  ? `${localization.CATALOG_ITEM.tooltipShareAsset}.<br>
                    ${localization.UPGRADE_PLAN.tooltip}`
                  : isShared
                    ? localization.CATALOG_ITEM.tooltipShareAssetSettings
                    : localization.CATALOG_ITEM.tooltipShareAsset
              }
              placement={tooltipPlacement}
            >
              <IconButton
                size="md"
                className="catalogItem__button"
                color="inherit"
                isActive={isShared}
                onClick={handleShare}
                componentProps={{
                  'data-testid': 'catalogItemShare',
                }}
              >
                <Web />
              </IconButton>
            </Tooltip>
          </If>
          <If condition={picsioConfig.isMainApp && !isProductView && !isMatchingView && allowDuplicateAsset && !isLightboardsView && !isImportingFailed && !isImportingInProcess && !isImportingMediaFailed}>
            <Tooltip
              content={localization.CATALOG_ITEM.tooltipDuplicate}
              placement={tooltipPlacement}
            >
              <IconButton
                size="md"
                className="catalogItem__button"
                color="inherit"
                onClick={handleDuplicate}
                componentProps={{
                  'data-testid': 'catalogItemDuplicate',
                }}
              >
                <Dublicate />
                {/* <Copy /> */}
              </IconButton>
            </Tooltip>
          </If>
          <If condition={picsioConfig.isMainApp && !isProductView && !isMatchingView && itemHeight > 240 && !isImportingFailed && !isImportingInProcess && !isImportingMediaFailed}>
            <Choose>
              <When condition={uploadFiles}>
                <Tooltip
                  content={localization.CATALOG_ITEM.tooltipAddRevision}
                  placement={tooltipPlacement}
                >
                  <IconButton
                    size="md"
                    className="catalogItem__button"
                    color="inherit"
                    onClick={handleUploadClick}
                    componentProps={{
                      'data-testid': 'catalogItemUpload',
                    }}
                  >
                    <AddRevision />
                  </IconButton>
                </Tooltip>
              </When>
              <When condition={!isTrashed && uploadFiles && !isImportingFailed && !isImportingInProcess && !isImportingMediaFailed}>
                <Tooltip
                  content={localization.UPGRADE_PLAN.tooltipForButtons}
                  placement={tooltipPlacement}
                  delayHide={500}
                  interactive
                >
                  <IconButton
                    size="md"
                    className="catalogItem__button disabled"
                    color="inherit"
                    onClick={() => { }}
                    componentProps={{
                      'data-testid': 'catalogItemAddRevision',
                    }}
                  >
                    <AddRevision />
                  </IconButton>
                </Tooltip>
              </When>
              <Otherwise>
                {null}
              </Otherwise>
            </Choose>
          </If>
          <If condition={!isProductView && !isMatchingView && commentsEnable && commentsEdit && isListViewMode && !isImportingFailed && !isImportingInProcess && !isImportingMediaFailed}>
            <Tooltip
              content={localization.CATALOG_ITEM.tooltipAddComment}
              placement={tooltipPlacement}
            >
              <IconButton
                size="md"
                className="catalogItem__button"
                color="inherit"
                onClick={handleAddCommentClick}
                componentProps={{
                  'data-testid': 'catalogItemComment',
                }}
              >
                <CommentAdd />
              </IconButton>
            </Tooltip>
          </If>
          <If condition={!isProductView && !isMatchingView && downloadFiles && !isImportingFailed && !isImportingInProcess && !isImportingMediaFailed}>
            <Tooltip
              content={localization.CATALOG_ITEM.tooltipDownload}
              placement={tooltipPlacement}
            >
              <IconButton
                size="md"
                className="catalogItem__button"
                color="inherit"
                onClick={handleDownload}
                componentProps={{
                  'data-testid': 'catalogItemDownload',
                }}
              >
                <Download />
              </IconButton>
            </Tooltip>
          </If>
          <If condition={picsioConfig.isMainApp && !isProductView && !isMatchingView && enableEditor && !isImportingFailed && !isImportingInProcess && !isImportingMediaFailed}>
            <Tooltip
              content={localization.CATALOG_ITEM.tooltipEdit}
              placement={tooltipPlacement}
            >
              <IconButton
                size="md"
                className="catalogItem__button"
                color="inherit"
                onClick={handleClickEdit}
                componentProps={{
                  'data-testid': 'catalogItemEdit',
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </If>
          <If condition={picsioConfig.isMainApp && isLightboardsView && !isImportingFailed && !isImportingInProcess && !isImportingMediaFailed}>
            <Button
              icon="catalogItemUnlight"
              onClick={handleRemoveLightboard}
              placement={tooltipPlacement}
              tooltip={localization.CATALOG_ITEM.tooltipRemoveFromLightboard}
              testid="catalogItemRemoveFromLB"
            />
          </If>
          <If condition={picsioConfig.isMainApp && !isProductView && !isMatchingView && numberOfCollections > 0 && !isTrashed && allowRemoveTags && !isImportingFailed && !isImportingMediaFailed}>
            <Tooltip
              content={localization.CATALOG_ITEM.tooltipRemoveFromCollections}
              placement={tooltipPlacement}
            >
              <IconButton
                size="md"
                className="catalogItem__button"
                color="inherit"
                onClick={handleRemoveFromCollections}
                componentProps={{ 'data-testid': 'catalogItemRemoveFromCollection' }}
              >
                <RemoveFolder />
              </IconButton>
            </Tooltip>
          </If>
          <If condition={picsioConfig.isMainApp && !isProductView && !isMatchingView && (isImportingFailed || isImportingMediaFailed) && !isImportingInProcess}>
            <Tooltip
              content="Retry import"
              placement={tooltipPlacement}
            >
              <IconButton
                size="md"
                className="catalogItem__button"
                color="inherit"
                onClick={handleRetryImporting}
                componentProps={{ 'data-testid': 'catalogItemRetryImport' }}
              >
                <Sync />
              </IconButton>
            </Tooltip>
          </If>
          <If condition={picsioConfig.isMainApp && !isProductView && !isMatchingView && removeFiles && !isImportingFailed && !isImportingInProcess && !isImportingMediaFailed}>
            <Tooltip
              content={
                isAssetFromInbox
                  ? localization.CATALOG_ITEM.tooltipDeleteForever
                  : localization.CATALOG_ITEM.tooltipDelete
              }
              placement={tooltipPlacement}
            >
              <IconButton
                size="md"
                className="catalogItem__button"
                color="inherit"
                onClick={isAssetFromInbox ? deleteForever : handleRemove}
                componentProps={{ 'data-testid': 'catalogItemDelete' }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </If>
          <If condition={picsioConfig.isMainApp && !isProductView && !isMatchingView && isTrashed && !isImportingFailed && !isImportingInProcess && !isImportingMediaFailed}>
            <Tooltip
              content={localization.CATALOG_ITEM.tooltipRestore}
              placement={tooltipPlacement}
            >
              <IconButton
                size="md"
                className="catalogItem__button"
                color="inherit"
                onClick={handleRestore}
                componentProps={{
                  'data-testid': 'catalogItemRestore',
                }}
              >
                <RestoreFrom />
              </IconButton>
            </Tooltip>
          </If>
          <If condition={picsioConfig.isMainApp && !isProductView && !isMatchingView && (isTrashed || (isImportingFailed && !isImportingInProcess && !isImportingMediaFailed))}>
            <Tooltip
              content={localization.CATALOG_ITEM.tooltipDeleteForever}
              placement={tooltipPlacement}
            >
              <IconButton
                size="md"
                className="catalogItem__button"
                color="inherit"
                onClick={deleteForever}
                componentProps={{
                  'data-testid': 'catalogItemDelete',
                }}
              >
                <DeleteFrom />
              </IconButton>
            </Tooltip>
          </If>
          <If condition={picsioConfig.isMainApp && isProductView && !isMatchingView}>
            <Tooltip
              content="Remove"
              placement={tooltipPlacement}
            >
              <IconButton
                size="md"
                className="catalogItem__button"
                color="inherit"
                onClick={remove}
                componentProps={{
                  'data-testid': 'catalogItemRemove',
                }}
              >
                <Close />
              </IconButton>
            </Tooltip>
          </If>
        </div>
      </If>
    </div>
  );
}

Controls.propTypes = {
  allowAssetSharing: PropTypes.bool.isRequired,
  allowRemoveTags: PropTypes.bool.isRequired,
  numberOfCollections: PropTypes.number,
  assetId: PropTypes.string.isRequired,
  assetSharing: PropTypes.bool.isRequired,
  color: PropTypes.string,
  colorChangeable: PropTypes.bool.isRequired,
  colorShow: PropTypes.bool.isRequired,
  commentsEdit: PropTypes.bool.isRequired,
  commentsEnable: PropTypes.bool.isRequired,
  deleteForever: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  downloadFiles: PropTypes.bool.isRequired,
  enableEditor: PropTypes.bool.isRequired,
  fileExtension: PropTypes.string,
  fileNameShow: PropTypes.bool.isRequired,
  handleDownload: PropTypes.func.isRequired,
  handleRemoveLightboard: PropTypes.func.isRequired,
  handleUploadClick: PropTypes.func.isRequired,
  isAssetFromInbox: PropTypes.bool.isRequired,
  isEditableInPicsioEditor: PropTypes.bool.isRequired,
  isGoogleDriveDocument: PropTypes.bool.isRequired,
  isLightboardsView: PropTypes.bool.isRequired,
  isListViewMode: PropTypes.bool.isRequired,
  isMobileView: PropTypes.bool.isRequired,
  isShared: PropTypes.bool.isRequired,
  isTrashed: PropTypes.bool.isRequired,
  itemWidth: PropTypes.number.isRequired,
  itemHeight: PropTypes.number.isRequired,
  mimeType: PropTypes.string.isRequired,
  removeFiles: PropTypes.bool.isRequired,
  restoreAssets: PropTypes.func.isRequired,
  shortName: PropTypes.string.isRequired,
  storageId: PropTypes.string,
  storageType: PropTypes.string.isRequired,
  title: PropTypes.string,
  trashAssets: PropTypes.func.isRequired,
  uploadFiles: PropTypes.bool.isRequired,
  duplicateAssets: PropTypes.func.isRequired,
  allowDuplicateAsset: PropTypes.bool.isRequired,
  generatedDate: PropTypes.string,
  createdDate: PropTypes.string,
  isImportingFailed: PropTypes.bool,
  isImportingMediaFailed: PropTypes.bool,
  isImportingInProcess: PropTypes.bool,
  retryImporting: PropTypes.func.isRequired,
  isProductView: PropTypes.bool,
  isMatchingView: PropTypes.bool,
};
