/* eslint-disable no-param-reassign */
import { createAction } from '@reduxjs/toolkit';
import { setCustomThumbnails } from '../helpers/assets';

export const setMediaThumbnails = createAction('products/setMediaThumbnails');

export const reducer = (builder) => {
  builder.addCase(setMediaThumbnails.type, (state, { payload: assets }) => {
    state.items = state.items.map((item) => {
      if (item.assets && item.assets.length) {
        item.assets = setCustomThumbnails(item.assets, assets);
      }
      return item;
    });
  });
};
