/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const removeKeywords = createAsyncThunk(
  'products/removeKeywords',
  async ({ productId, keywordIds }, {
    extra: {
      sdk, Logger, utils, showErrorDialog,
    },
  }) => {
    try {
      await sdk.products.removeKeywords([productId], keywordIds);
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not remove keywords from product';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error remove keywords from product'),
        { error },
        ['RemoveKeywordFromProductFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      removeKeywords.pending,
      () => {
        /** Do nothing */
      },
    )
    .addCase(
      removeKeywords.fulfilled,
      (state, { meta }) => {
        const { productId, keywordIds } = meta.arg;
        state.items.forEach((product) => {
          if (product._id === productId) {
            product.keywords = (product.keywords || []).filter((k) => !keywordIds.find((_id) => _id === k._id));
          }
        });
      },
    )
    .addCase(
      removeKeywords.rejected,
      (state, { error }) => {
        state.error = error;
      },
    );
};
