/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const deleteVariant = createAsyncThunk(
  'products/deleteVariant',
  async ({
    productId, variantId,
  }, {
    extra: {
      sdk, Logger, showErrorDialog, utils,
    },
    getState,
  }) => {
    try {
      const { items } = getState().products;
      const isLastVariant = items.find((product) => product._id === productId)?.variants?.length === 1;

      await sdk.products.deleteVariant(variantId);
      if (isLastVariant) {
        const { data } = await sdk.products.pullOne(productId);
        return data;
      }
      return null;
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not delete product variant';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error delete product variant'),
        { error },
        ['DeleteProductVariantFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      deleteVariant.pending,
      (state, { meta }) => {
        const { productId } = meta.arg;
        state.items.forEach((product) => {
          if (product._id === productId) {
            product.isBusy = true;
          }
        });
      },
    )
    .addCase(
      deleteVariant.fulfilled,
      (state, { meta, payload }) => {
        const { productId, variantId } = meta.arg;
        if (payload) {
          state.items = state.items.map((product) => {
            if (product._id === productId) {
              return {
                ...payload,
                isBusy: false,
              };
            }
            return product;
          });
        } else {
          state.items.forEach((product) => {
            if (product._id === productId) {
              /** { optionName: optionValue, Size: "S" } */
              let variantOptions;

              product.isBusy = false;
              product.variants = product.variants.filter((variant) => {
                const needToKeep = variant._id !== variantId;
                if (!needToKeep) variantOptions = variant.options;
                return needToKeep;
              });

              if (variantOptions) {
                const withoutVariants = {};

                /** Check variants */
                Object.keys(variantOptions).forEach((optionName) => {
                  const optionValue = variantOptions[optionName];
                  const variantWithOption = product.variants.find(({ options }) => options[optionName] === optionValue);
                  if (!variantWithOption) withoutVariants[optionName] = optionValue;
                });

                /** Update availableOptions */
                Object.keys(withoutVariants).forEach((optionName) => {
                  const optionValue = withoutVariants[optionName];
                  product.availableOptions.forEach((option) => {
                    if (option.name === optionName) {
                      option.values.forEach((value) => {
                        if (value.name === optionValue) {
                          value.hasVariants = false;
                        }
                      });
                    }
                  });
                });
              }
            }
          });
        }
        state.search.items = state.search.items.filter(({ _id }) => _id !== variantId);
      },
    )
    .addCase(
      deleteVariant.rejected,
      (state, { meta, error }) => {
        state.items.forEach((product) => {
          if (product._id === meta.arg.productId) {
            product.isBusy = false;
          }
        });
        state.error = error;
      },
    );
};
