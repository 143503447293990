/* eslint-disable react/require-default-props */
import { string } from 'prop-types';
import React, {
  memo, useMemo, useCallback, useState,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, IconButton, TextField } from '@picsio/ui';
import { Pen } from '@picsio/icons';
import { changeStatus, rename } from '../../store/products/actions';

import { setCookie } from '../../shared/utils';
import Logger from '../../services/Logger';

import { getDetailsPanelVisibility } from '../details/helpers/getDetailsPanelDefaultState';
import isSimpleProductHelper from '../../helpers/products/isSimpleProduct';

import s from './styles.module.scss';
import Description from './Description';
import Pricing from './Pricing';
import Inventory from './Inventory';
import Shipping from './Shipping';
import SearchListing from './SearchListing';
import ProductOrganization from './ProductOrganization';
import Options from './Options';
import ProductMeta from './ProductMeta';
import VariantOptions from './VariantOptions';
import Tooltip from '../Tooltip';

const statuses = [
  {
    id: 'DRAFT',
    value: 'Draft',
  },
  {
    id: 'ACTIVE',
    value: 'Active',
  },
  {
    id: 'ARCHIVED',
    value: 'Archived',
  },
];

const ProductInfo = ({ productId, variantId }) => {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.products.items?.find(({ _id }) => productId === _id));

  const isSimpleProduct = useMemo(
    () => isSimpleProductHelper(product),
    [product],
  );

  const $renameRef = useRef(null);

  const variant = useMemo(
    () => {
      if (isSimpleProduct) return product?.defaultVariant;

      return product?.variants?.find(({ _id }) => variantId === _id);
    },
    [product, variantId, isSimpleProduct],
  );

  const [panelsVisibility, setPanelsVisibility] = useState(getDetailsPanelVisibility());
  const [newProductTitle, setNewProductTitle] = useState(null);

  const toggleVisibility = useCallback((title) => {
    const detailsPanelVisibility = { ...panelsVisibility };

    detailsPanelVisibility[title] = !detailsPanelVisibility[title];
    setCookie('picsio.detailsPanelVisibility', JSON.stringify(detailsPanelVisibility));
    setPanelsVisibility(detailsPanelVisibility);
  }, [panelsVisibility, setPanelsVisibility]);

  const status = useMemo(() => {
    const value = product.status || '';
    return value.charAt(0).toUpperCase() + value.slice(1);
  }, [product]);

  const handleChangeStatus = useCallback((event) => {
    /** @type {string} */
    const { value } = event.target;
    dispatch(changeStatus({
      productId,
      status: value,
    }));
  }, [dispatch, productId]);

  const renderStatusValue = useCallback((value) => {
    const [firstLetter, ...rest] = value;
    return `${firstLetter.toUpperCase()}${rest.join('').toLowerCase()}`;
  }, []);

  const initRenaming = useCallback(() => {
    if (!!variant && !isSimpleProduct) return;

    setNewProductTitle(product.title);
    setTimeout(() => {
      $renameRef.current?.select();
    }, 100);
  }, [isSimpleProduct, product.title, variant]);

  const handleChangeTitle = useCallback((event) => {
    setNewProductTitle(event.target.value);
  }, []);

  const saveNewTitle = useCallback(async () => {
    const newValue = newProductTitle?.trim();
    if (!newValue || newValue === product.title) {
      setNewProductTitle(null);
      return;
    }
    Logger.log('User', 'ProductInfoRenameProduct');
    await dispatch(rename({ _id: product._id, title: newValue }));
    setNewProductTitle(null);
  }, [dispatch, newProductTitle, product._id, product.title]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      saveNewTitle();
    }
    if (event.key === 'Escape') {
      setNewProductTitle(null);
    }
  }, [saveNewTitle, setNewProductTitle]);

  return (
    <div className={`InfoPanel${product.isBusy ? ` ${s.disabled}` : ' '} `}>
      <div data-qa="details-header" className="detailsPanel__header  detailsPanel__btns">
        <div className={`detailsPanel__statistics ${s.header}`}>
          {/* filename */}
          <Choose>
            <When condition={newProductTitle !== null}>
              <TextField
                value={newProductTitle}
                onChange={handleChangeTitle}
                onBlur={saveNewTitle}
                onKeyDown={handleKeyDown}
                disabled={product.isBusy}
                inputProps={{
                  autoFocus: true,
                  ref: $renameRef,
                }}
              />
            </When>
            <Otherwise>
              <Tooltip content={product.title} placement="left">
                <div
                  className="filename"
                  onDoubleClick={initRenaming}
                >
                  <If condition={!variant || isSimpleProduct}>
                    <IconButton size="xs" onClick={initRenaming}>
                      <Pen />
                    </IconButton>
                  </If>
                  {(variant?.title && !isSimpleProduct) && `[ ${variant.title} ] `}{product.title}
                </div>
              </Tooltip>
            </Otherwise>
          </Choose>
          {/* Status */}
          <Select
            value={status}
            options={statuses}
            dataId="id"
            dataValue="value"
            disabled={!!variant && !isSimpleProduct}
            renderValue={renderStatusValue}
            onChange={handleChangeStatus}
          />
        </div>
      </div>

      <div className="InfoPanelBody">
        <If condition={!variant || isSimpleProduct}>
          <Description
            productId={productId}
            detailsPanelVisibility={panelsVisibility}
            toggleVisibility={toggleVisibility}
            blockName="productDescriptionVisibility"
            blockTitle="Description"
            currentValue={product.descriptionHtml}
          />
        </If>
        <If condition={!variant || isSimpleProduct}>
          <br />
          <ProductOrganization
            productId={productId}
            detailsPanelVisibility={panelsVisibility}
            toggleVisibility={toggleVisibility}
            blockName="productOrganizationVisibility"
            blockTitle="Product organization"
            vendor={product.vendor}
            productType={product.productType}
            keywords={product.keywords}
            category={product.category}
            collections={product.collections}
          />
        </If>
        <If condition={!variant || isSimpleProduct}>
          <br />
          <Options
            productId={productId}
            detailsPanelVisibility={panelsVisibility}
            toggleVisibility={toggleVisibility}
            blockName="productOptionsVisibility"
            blockTitle="Options"
            currentValue={product.availableOptions}
            isSimpleProduct={isSimpleProduct}
            variantOptions={variant?.options}
          />
        </If>
        <If condition={variant}>
          <If condition={!isSimpleProduct}>
            <br />
            <VariantOptions
              detailsPanelVisibility={panelsVisibility}
              toggleVisibility={toggleVisibility}
              blockName="variantOptionsVisibility"
              blockTitle="Options"
              options={variant?.options}
            />
          </If>
          <br />
          <Pricing
            productId={productId}
            variantId={variant._id}
            detailsPanelVisibility={panelsVisibility}
            toggleVisibility={toggleVisibility}
            blockName="productPricingVisibility"
            blockTitle="Pricing"
            price={variant.price}
            compareAtPrice={variant.compareAtPrice}
            taxable={variant.taxable}
            unitCost={variant.unitCost}
          />
          <br />
          <Inventory
            productId={productId}
            variantId={variant._id}
            tracked={variant.tracked}
            sku={variant.sku}
            barcode={variant.barcode}
            continueSelling={variant.inventoryPolicy === 'CONTINUE'}
            inventoryLevels={variant.inventoryLevels}
            detailsPanelVisibility={panelsVisibility}
            toggleVisibility={toggleVisibility}
            blockName="productInventoryVisibility"
            blockTitle="Inventory"
          />
          <br />
          <Shipping
            productId={productId}
            variantId={variant._id}
            detailsPanelVisibility={panelsVisibility}
            toggleVisibility={toggleVisibility}
            blockName="productShippingVisibility"
            blockTitle="Shipping"
            currentValue={variant.weight}
            requiresShipping={variant.requiresShipping}
          />
        </If>
        <If condition={!variant || isSimpleProduct}>
          <br />
          <ProductMeta
            detailsPanelVisibility={panelsVisibility}
            toggleVisibility={toggleVisibility}
            blockName="productMetaVisibility"
            blockTitle="Metafields"
            meta={product.meta}
          />
          <br />
          <SearchListing
            productId={productId}
            detailsPanelVisibility={panelsVisibility}
            toggleVisibility={toggleVisibility}
            blockName="productSearchListingVisibility"
            blockTitle="Search engine listing"
            handle={product.handle}
            meta={product.meta}
          />
        </If>
      </div>
    </div>
  );
};

ProductInfo.propTypes = {
  productId: string.isRequired,
  variantId: string,
};

export default memo(ProductInfo);
