import React, { useContext } from 'react';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { useLoader } from '@react-three/fiber';
import PropTypes from 'prop-types';
import ThreeContext from '../../ThreeContext';

const ObjModel = ({ url, position }) => {
  const THREE = useContext(ThreeContext);
  const obj = useLoader(OBJLoader, url);

  obj.traverse((child) => {
    if (child.isMesh) {
      const material = new THREE.MeshStandardMaterial({
        color: '#cccccc',
        metalness: 0.5,
        roughness: 0.4,
      });
      Object.assign(child, {
        material,
        castShadow: true,
        receiveShadow: true,
      });
    }
  });

  return (
    <primitive
      object={obj}
      position={position}
    />
  );
};

ObjModel.propTypes = {
  url: PropTypes.string.isRequired,
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ObjModel;
