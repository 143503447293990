/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import renderUnsupportedMediaDialog from '../../components/UnsupportedMediaDialog/renderUnsupportedMediaDialog';

export const addAssets = createAsyncThunk(
  'products/addAssets',
  async ({ assetIds, product }, {
    extra: {
      sdk, Logger, utils, showErrorDialog, localization: l10n, Toast,
    },
    getState,
  }) => {
    try {
      const ids = assetIds || getState().assets?.selectedAssetsIds;

      const { data } = await sdk.assets.attachToProduct(product._id, ids);

      let assetsWithReason = { failedIds: [], codes: {} };
      if (data.assetsWithReason) {
        assetsWithReason = data.assetsWithReason.reduce((acc, { _id, reason, name }) => {
          acc.failedIds.push(_id);
          acc.codes[reason] = acc.codes[reason] ? [...acc.codes[reason], name] : [name];
          return acc;
        }, { failedIds: [], codes: {} });
      }

      if (Object.keys(assetsWithReason.codes).length !== 0) {
        renderUnsupportedMediaDialog({ codes: assetsWithReason.codes, allowedAssetCount: data.assetsToProcessCount, allAssetCount: ids.length });
      }

      if (data.assetsToProcessCount > 0) {
        Toast(l10n.TOAST.ATTACHING_ASSETS_TO_PRODUCT_SENT);
      }

      if (assetsWithReason.failedIds.length) {
        return { assetIds: ids.filter((id) => !assetsWithReason.failedIds.includes(id)), product };
      }
      return { assetIds: ids, product };
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not add assets to product';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error add assets to product'),
        { error },
        ['AddAssetsToProductFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      addAssets.pending,
      () => {
        /** Do nothing */
      },
    )
    .addCase(
      addAssets.fulfilled,
      () => {
        /** Do nothing */
      },
    )
    .addCase(
      addAssets.rejected,
      (state, { error }) => {
        state.error = error;
      },
    );
};
