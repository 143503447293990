/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import getThumbnailUrls from '../../helpers/getThumbnailUrls';

export const fetchThumbnails = createAsyncThunk(
  'products/fetchThumbnails',
  async ({ assetIds }, {
    extra: { Logger, Toast },
  }) => {
    try {
      return await getThumbnailUrls(assetIds);
    } catch (error) {
      Logger.log('UI', 'ToastProductsNotLoaded');
      Toast(
        'Products not loaded. Refresh and try again',
        { autoClose: false, type: 'error' },
      );
      Logger.error(new Error('Error products fetching'), { error }, [
        'FetchProductsFailed',
        'Products can not be fetched from backend.',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder.addCase(
    fetchThumbnails.pending,
    () => {
      // do nothing
    },
  ).addCase(
    fetchThumbnails.fulfilled,
    (state, { payload: thumbs }) => {
      state.search.items.forEach((item) => {
        const thumb = thumbs.find((t) => t._id === item.assetId);
        if (thumb) item.thumbnail = thumb.thumbnail;
      });
    },
  ).addCase(
    fetchThumbnails.rejected,
    (state, { error }) => {
      state.error = error;
    },
  );
};
