/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import getThumbnailUrls from '../../helpers/getThumbnailUrls';
import { setThumbnailUrls, extendAssets } from '../helpers/assets';

export const fetchAssets = createAsyncThunk(
  'products/fetchAssets',
  async ({ productId }, {
    extra: { Logger, Toast, sdk },
    getState,
  }) => {
    try {
      const { team } = getState().user;
      const rootCollectionId = getState().collections?.collections?.my?._id;

      const { data } = await sdk.assets.search({
        tagId: rootCollectionId,
        from: 0,
        size: 250,
        products: productId,
      });

      if (!data.images?.length) return [];

      const assets = extendAssets(data.images);

      const thumbs = await getThumbnailUrls(assets.map(({ _id }) => _id));

      const assetsWithThumbs = setThumbnailUrls(assets, thumbs, team?.storageType === 's3');

      return assetsWithThumbs.map((asset) => ({
        ...asset,
        permissions: Object
          .keys(asset.permissions || {})
          .reduce((negativePermissions, key) => ({ ...negativePermissions, [key]: false }), {}),
      }));
    } catch (error) {
      Logger.log('UI', 'ToastProductsNotLoaded');
      Toast(
        'Products not loaded. Refresh and try again',
        { autoClose: false, type: 'error' },
      );
      Logger.error(new Error('Error products fetching'), { error }, [
        'FetchProductsFailed',
        'Products can not be fetched from backend.',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder.addCase(
    fetchAssets.pending,
    (state, { meta }) => {
      state.items.forEach((product) => {
        if (product._id === meta.arg.productId) product.fetchingAssets = true;
      });
    },
  ).addCase(
    fetchAssets.fulfilled,
    (state, { payload: assets, meta }) => {
      state.items.forEach((product) => {
        if (product._id === meta.arg.productId) {
          product.fetchingAssets = false;
          product.assets = assets;
        }
      });
    },
  ).addCase(
    fetchAssets.rejected,
    (state, { error, meta }) => {
      state.items.forEach((product) => {
        if (product._id === meta.arg.productId) delete product.fetchingAssets;
      });
      state.error = error;
    },
  );
};
