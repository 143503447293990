import React, { useEffect, useRef } from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';
import PropTypes from 'prop-types';

const GLTFModel = ({ url, position }) => {
  const gltf = useLoader(GLTFLoader, url);
  const { scene, animations } = gltf;
  const mixerRef = useRef();

  useEffect(() => {
    if (animations && animations.length > 0) {
      mixerRef.current = new THREE.AnimationMixer(scene);
      animations.forEach((clip) => {
        const action = mixerRef.current.clipAction(clip);
        action.play();
      });
    }

    return () => {
      if (mixerRef.current) {
        mixerRef.current.stopAllAction();
      }
    };
  }, [scene, animations]);

  useFrame((state, delta) => {
    if (mixerRef.current) {
      mixerRef.current.update(delta);
    }
  });

  scene.traverse((child) => {
    if (child.isMesh) {
      Object.assign(child, {
        castShadow: true,
        receiveShadow: true,
      });
    }
  });

  return (
    <primitive object={scene} position={position} />
  );
};

GLTFModel.propTypes = {
  url: PropTypes.string.isRequired,
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default GLTFModel;
