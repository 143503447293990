import React, { memo, useCallback, useMemo } from 'react';
import { InputControlLabel } from '@picsio/ui';
import {
  bool, func, shape, string,
} from 'prop-types';
import cn from 'classnames';

const Field = ({
  field, value, onChange,
}) => {
  const title = useMemo(
    () => (field.title.startsWith('meta.') ? field.title.replace('meta.', '') : field.title),
    [field.title],
  );
  const handleChange = useCallback(() => onChange(field, !value), [onChange, field, value]);

  return (
    <li key={field?.id} className={cn('', { checked: value })}>
      <InputControlLabel
        control="checkbox"
        label={title}
        value={value}
        onChange={handleChange}
      />
    </li>
  );
};

Field.propTypes = {
  field: shape({
    title: string,
    id: string,
  }).isRequired,
  value: bool,
  onChange: func,
};

Field.defaultProps = {
  value: false,
  onChange: () => {},
};

export default memo(Field);
