import React from 'react';
import { createRoot } from 'react-dom/client';
import SearchFiltersDialog from '../components/SearchFiltersDialog';

/** Show search filters dialog */
export default function showExportCsvDialog(props) {
  const $wrapper = document.createElement('div');
  $wrapper.classList.add('wrapperDialog');
  $wrapper.classList.add('exportDialogWrapper');

  document.body.appendChild($wrapper);
  const root = createRoot($wrapper);

  function destroy() {
    root.unmount();
    document.body.removeChild($wrapper);
  }

  root.render(<SearchFiltersDialog destroy={destroy} {...props} />);
}
