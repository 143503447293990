import React, { useCallback } from 'react'; // eslint-disable-line
import { string, func, oneOfType } from 'prop-types';
import * as utils from '../../shared/utils';

const BreadcrumbsItem = ({ _id, title, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(_id);
  }, [_id, onClick]);

  return (
    <li className={_id ? null : 'disabled'}>
      <span
        onClick={handleClick}
        role="button"
        onKeyDown={handleClick}
        tabIndex={0}
      >
        {utils.decodeSlash(title)}
      </span>
    </li>
  );
};

BreadcrumbsItem.propTypes = {
  _id: oneOfType([string, null]).isRequired,
  title: string.isRequired,
  onClick: func.isRequired,
};

export default BreadcrumbsItem;
