import TYPES from '../../action-types';
import picsioConfig from '../../../../../../config';

/** Set teamName */
function setTeamName(name) {
  return (dispatch, getState) => {
    const { teamName } = getState().user.team.policies;
    dispatch({
      type: TYPES.COLLECTIONS.UPDATE_ROOT_COLLECTION_NAME,
      payload: {
        teamName: picsioConfig.isPim ? 'Media' : (name || teamName),
      },
    });
  };
}

export default setTeamName;
