/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';
import _unique from 'lodash/uniq';
import searchWithQuery from '../../helpers/products/search';
import { fetchThumbnails } from './fetchThumbnails';

export const search = createAsyncThunk(
  'products/search',
  async (payload, {
    extra: { Logger, Toast },
    dispatch,
  }) => {
    /** @TODO: sort */
    const page = payload?.page || 1;
    try {
      const { data } = await searchWithQuery(page);

      /** get thumbnails for products/variants */
      const assetIds = data.products.map((p) => p.assetId).filter(Boolean);
      if (assetIds.length) dispatch(fetchThumbnails({ assetIds: _unique(assetIds) }));

      return data;
    } catch (error) {
      Toast(
        'Products search failed. Refresh and try again',
        { autoClose: false, type: 'error' },
      );
      Logger.error(new Error('Error products searching'), { error }, [
        'ProductsSearchFailed',
        'Products search failed.',
      ]);
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder.addCase(
    search.pending,
    (state, { meta }) => {
      const { page = 1 } = meta.arg;
      if (page === 1) {
        state.search.items = [];
        state.search.total = 0;
        state.search.isLoading = true;
      } else {
        state.search.isPageLoading = true;
      }
      state.search.error = null;
    },
  ).addCase(
    search.fulfilled,
    (state, { payload, meta }) => {
      const { page = 1 } = meta.arg;
      if (page === 1) {
        state.search.isLoading = false;
        state.search.items = payload.products;
      } else {
        state.search.isPageLoading = false;
        state.search.items.push(...payload.products);
      }
      state.search.total = payload.total;
    },
  ).addCase(
    search.rejected,
    (state, { error }) => {
      state.search.isLoading = false;
      state.search.isPageLoading = false;
      state.search.error = error;
    },
  );
};
