/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getVariants = createAsyncThunk(
  'products/getVariants',
  async ({ _id }, {
    extra: {
      sdk, Logger, showErrorDialog, utils,
    },
  }) => {
    try {
      const { data: variants } = await sdk.products.getVariants(_id);
      return variants;
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not get product variants';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error getting product variants'),
        { error },
        ['GetProductVariantsFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      getVariants.pending,
      (state, { meta }) => {
        const { _id } = meta.arg;
        state.items.forEach((product) => {
          if (product._id === _id) product.isBusy = true;
        });
      },
    )
    .addCase(
      getVariants.fulfilled,
      (state, { payload, meta }) => {
        state.items.forEach((product) => {
          if (product._id === meta.arg._id) {
            product.variants = payload || [];
            product.hasChildren = !!payload.length;
            delete product.isBusy;
          }
        });
      },
    )
    .addCase(
      getVariants.rejected,
      (state, { meta, error }) => {
        state.items.forEach((item) => {
          if (item._id === meta.arg._id) {
            delete item.isBusy;
          }
        });
        state.error = error;
      },
    );
};
