import React, { memo, useMemo } from 'react';
import { TextField } from '@picsio/ui';
import DetailsBlock from '../DetailsBlock';
import Placeholder from '../DetailsBlock/Placeholder';

import s from './styles.module.scss';

const DEFAULT_META = [];
const EXCLUDE_KEYS = ['title_tag', 'description_tag'];

const ProductMeta = ({
  meta = DEFAULT_META,
  detailsPanelVisibility,
  toggleVisibility,
  blockName,
  blockTitle,
}) => {
  const dataToDisplay = useMemo(() => meta.filter((item) => !EXCLUDE_KEYS.includes(item.key)), [meta]);

  return (
    <DetailsBlock
      dataQa="productMeta"
      detailsPanelVisibility={detailsPanelVisibility}
      toggleVisibility={toggleVisibility}
      blockName={blockName}
      blockTitle={blockTitle}
    >
      <If condition={dataToDisplay.length === 0}>
        <Placeholder>
          No meta fields
        </Placeholder>
      </If>
      {dataToDisplay.map((item) => {
        const { key } = item;
        let { value } = item;

        try {
          value = JSON.parse(item.value);
          if (Array.isArray(value)) value = value.join(' • ');
          if (typeof value.value !== 'undefined') {
            /** @TODO: can be with units, e.g. { value: 12, unit: 'METERS' } */
            value = value.value;
          }
        } catch (e) {
        // do nothing
        }
        return (
          <div className={s.row} key={key}>
            <TextField
              value={value}
              label={key}
              disabled
            />
          </div>
        );
      })}
    </DetailsBlock>
  );
};

export default memo(ProductMeta);
