/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useCallback, useMemo, useState,
} from 'react';
import { Icon, Spinner } from '@picsio/ui';
import { Info } from '@picsio/icons';
import { permissions as dbPermissions } from '@picsio/db/src/constants';
import { useDispatch, useSelector } from 'react-redux';
import picsioConfig from '../../../../../config';
import Item from './Item';
import { setSearchRoute } from '../../helpers/history';
import Logger from '../../services/Logger';
import l10n from '../../shared/strings';
import ua from '../../ua';
import {
  getChildren as getChildrenAction,
  moveCollection as moveCollectionAction,
  copyCollection as copyCollectionAction,
  setActiveCollection as setActiveCollectionAction,
  sortCollections as sortCollectionsAction,
} from '../../store/actions/collections';
import { addToCollection } from '../../store/actions/assets';
import { setMobileMainScreenPanel } from '../../store/actions/main';
import { clearChangedTagsIds } from '../../store/actions/notifications';
import showSelectFromTreeDialog from '../../helpers/showSelectFromTreeDialog';
import TreeResizer from '../TreeResizer';
import DialogRadios from '../dialogRadios';

import FavoritesTree from './FavoritesTree';
import WebsitesTree from './WebsitesTree';
import MainTree from './MainTree';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Search from './Search';
import Trash from './Trash';
import RecursiveSearchControl from './RecursiveSearchControl';
import { getActionForDroppedAssets, checkRestrictedAssets, findLevel } from './helpers';
import { showDialog } from '../dialog';

/** fix for websites */
import './styles.scss';

const { isMainApp } = picsioConfig;

export default function CollectionsTree() {
  const dispatch = useDispatch();

  const my = useSelector((state) => state.collections.collections?.my || {});
  const selectedIds = useSelector((state) => (state.collections.activeCollections || []).map(({ _id }) => _id));
  const isLoaded = useSelector((state) => state.collections.isLoaded);
  const sortInProgress = useSelector((state) => state.collections.sortInProgress);
  const isSearching = useSelector((state) => state.collections.search?.isSearching);
  const isSearchApplied = useSelector((state) => state.collections.search?.collections?.my);
  const isTrashActive = useSelector((state) => state.router?.location?.query?.trashed === 'true');
  const isArchiveActive = useSelector((state) => state.router?.location?.query?.archived === 'true');
  const isInboxActive = useSelector((state) => Boolean(state.router?.location?.query?.inboxId));
  const permissionForCustomSort = useSelector((state) => Boolean(state.user?.role?.permissions[dbPermissions.manageCollectionsCustomSort]));

  const selected = useMemo(() => {
    if (isArchiveActive) return []; // if selected archive -> don't highlight collections in the tree
    return selectedIds;
  }, [selectedIds, isArchiveActive]);

  const [dragData, setDragData] = useState({});

  const attachAssets = useCallback(async (event, collection, data) => {
    if (collection.path === 'root') return;

    const { selectedAssetIds, hasRestrictedAndNoPermission } = checkRestrictedAssets();
    if (hasRestrictedAndNoPermission) {
      showDialog({
        title: l10n.RESTRICT.TITLE,
        text: selectedAssetIds.length === 1 ? l10n.RESTRICT.attachAssetToCollection : l10n.RESTRICT.attachAssetsToCollection,
        textBtnOk: l10n.RESTRICT.BTN_OK,
        textBtnCancel: null,
      });
      return;
    }
    const numberOfAssets = (data || '').split('\n').length;
    const action = await getActionForDroppedAssets(collection.name, isInboxActive, event.altKey, numberOfAssets);
    if (typeof action !== 'string') return; // User just closed the dialog

    const isMove = action === 'MOVE';

    dispatch(addToCollection({
      collectionID: collection._id,
      collectionPath: (collection.path + collection.name).substring(1),
      assetIDs: undefined,
      isMove,
    }));

    if (isMove) {
      Logger.log('User', 'ThumbnailMoveToCollection');
    } else {
      Logger.log('User', 'ThumbnailAddToCollection', {
        collectionId: collection._id,
        assetIDs: selectedAssetIds,
      });
    }
  }, [dispatch, isInboxActive]);

  const getCurrentCollectionNeighbors = useCallback((_id) => {
    let result = [];
    function loop(collection, _, nodes) {
      if (collection._id === _id) {
        result = nodes.filter((node) => node._id !== _id);
        return false;
      }
      if (collection.nodes) collection.nodes.every(loop);
      return true;
    }

    if (my.nodes) my.nodes.every(loop);

    return result;
  }, [my]);

  const moveCollection = useCallback((collection) => {
    Logger.log('UI', 'MoveCollectionDialog');

    showSelectFromTreeDialog({
      title: l10n.DIALOGS.MOVE_COLLECTION_DIALOG.TITLE(collection.name),
      treeListItems: my,
      onLoadChildren: ({ _id }) => dispatch(getChildrenAction(_id)),
      textBtnCancel: l10n.DIALOGS.MOVE_COLLECTION_DIALOG.CANCEL_TEXT,
      onOk: async (selectedCollections) => {
        if (!selectedCollections?.length) {
          return;
        }

        const targetCollection = selectedCollections[0];

        dispatch(moveCollectionAction(
          collection._id,
          targetCollection._id,
          targetCollection.path === 'root' ? '/' : `${targetCollection.path}${targetCollection.name}/`,
          collection.path,
          collection.name,
        ));
      },
      textBtnOk: l10n.DIALOGS.MOVE_COLLECTION_DIALOG.OK_TEXT,
      openedItems: [my?._id],
      collectionToMove: collection,
      type: 'move',
    });
  }, [my, dispatch]);

  const duplicateCollection = useCallback((collectionToDuplicate) => {
    Logger.log('UI', 'CopyCollectionDialog');

    showSelectFromTreeDialog({
      title: l10n.DIALOGS.COPY_COLLECTIONS_TREE_DIALOG.TITLE,
      treeListItems: my,
      // onClick: this.handleToggleCollection,
      onLoadChildren: ({ _id }) => dispatch(getChildrenAction(_id)),
      textBtnCancel: l10n.DIALOGS.COPY_COLLECTIONS_TREE_DIALOG.CANCEL_TEXT,
      onOk: (selectedCollection) => {
        Logger.log('User', 'CopyCollectionDialogDuplicateClicked');
        const { _id } = selectedCollection[0];

        // eslint-disable-next-line no-new
        new DialogRadios({
          title: l10n.DIALOGS.COPY_COLLECTION_RADIO_DIALOG.TITLE,
          items: [
            {
              label: l10n.DIALOGS.COPY_COLLECTION_RADIO_DIALOG.COPY_ONE,
              value: 'ONE',
              checked: true,
              description: '',
            },
            {
              label: l10n.DIALOGS.COPY_COLLECTION_RADIO_DIALOG.COPY_ALL,
              value: 'ALL',
              checked: false,
              description: '',
            },
          ],
          description: (
            <span className="descriptionIcon">
              <Icon size="sm">
                <Info />
              </Icon>
              {l10n.DIALOGS.COPY_COLLECTION_RADIO_DIALOG.DESCRIPTION}
            </span>
          ),
          cancelName: l10n.DIALOGS.COPY_COLLECTION_RADIO_DIALOG.CANCEL,
          okName: l10n.DIALOGS.COPY_COLLECTION_RADIO_DIALOG.OK,
          onOk: (value) => {
            dispatch(copyCollectionAction({
              copyingCollectionName: collectionToDuplicate.name,
              copyChildren: value === 'ALL',
              parentID: _id,
              copyingCollectionId: collectionToDuplicate._id,
            }));
          },
        });
      },
      textBtnOk: l10n.DIALOGS.COPY_COLLECTIONS_TREE_DIALOG.OK_TEXT,
      openedItems: [my?._id],
      type: 'duplicateCollection',
    });
  }, [dispatch, my]);

  const handleClickChild = useCallback((e, { nodeId }) => {
    /** Change route */
    if (isTrashActive || isArchiveActive || selected.length > 1 || !selected.includes(nodeId)) {
      setSearchRoute({ collectionIds: [nodeId] });
      Logger.log('User', 'CollectionPanelSelectCollection', nodeId);
      if (ua.isMobileApp() || (ua.browser.isNotDesktop() && window.innerWidth < 1024)) {
        /** Hide tree on mobile */
        dispatch(setMobileMainScreenPanel('catalog'));
      }
    }
  }, [selected, isTrashActive, isArchiveActive, dispatch]);

  const handleClickTrash = useCallback(() => {
    if (isTrashActive) return;

    dispatch(clearChangedTagsIds());
    setSearchRoute({ collectionIds: my?._id, trashed: true });
    setActiveCollectionAction(null);
    Logger.log('User', 'CollectionPanelSelectCollection', 'Trash');
    if (ua.isMobileApp() || (ua.browser.isNotDesktop() && window.innerWidth < 1024)) {
      /** Hide tree on mobile */
      dispatch(setMobileMainScreenPanel('catalog'));
    }
  }, [dispatch, my?._id, isTrashActive]);

  const handleDragStart = useCallback((_, id, name, path) => {
    const lvl = findLevel(my.nodes, id);
    setDragData({
      id, lvl, name, path,
    });

    function onMouseUp() {
      setDragData({});
      document.body.removeEventListener('dragend', onMouseUp);
    }

    document.body.addEventListener('dragend', onMouseUp);
    Logger.log('User', 'CollectionsPanelDragCollectionStart', {
      collectionId: id,
      position: lvl.indexOf(id) + 1,
    });
  }, [setDragData, my?.nodes]);

  const sortCollections = useCallback((receiverItemId, position, nodeParentIds) => {
    /** if dropped outside the level */
    if (!dragData.lvl?.includes(receiverItemId)) return;

    dispatch(sortCollectionsAction({
      moveId: dragData.id,
      receiveId: receiverItemId,
      position,
      parentIds: nodeParentIds,
    }));
    Logger.log('User', 'CollectionsPanelDragCollectionFinish', {
      collectionId: receiverItemId,
      position: dragData.lvl.indexOf(receiverItemId) + 1,
    });
  }, [dispatch, dragData]);

  const renderNode = useCallback((props) => (
    <Item
      key={props.nodeId}
      getNeighbors={getCurrentCollectionNeighbors}
      moveCollection={moveCollection}
      duplicateCollection={duplicateCollection}
      attachAssets={attachAssets}
      increaseIndentation
      isSortable={false}
      isDraggable={false}
      {...props}
    />
  ), [duplicateCollection, getCurrentCollectionNeighbors, moveCollection, attachAssets]);

  const renderNodeForMainTree = useCallback((props) => (
    <Item
      key={props.nodeId}
      getNeighbors={getCurrentCollectionNeighbors}
      moveCollection={moveCollection}
      duplicateCollection={duplicateCollection}
      attachAssets={attachAssets}
      onDragStart={handleDragStart}
      isSortable={permissionForCustomSort && !isSearchApplied}
      isDraggable={isMainApp}
      dragData={dragData}
      sortCollections={sortCollections}
      {...props}
    />
  ), [
    attachAssets,
    dragData,
    duplicateCollection,
    getCurrentCollectionNeighbors,
    handleDragStart,
    moveCollection,
    permissionForCustomSort,
    isSearchApplied,
    sortCollections,
  ]);

  return (
    <div className="_wrapperTree tree">
      <TreeResizer>
        <If condition={isMainApp}>
          <ErrorBoundary className="errorBoundaryComponent">
            <Search />
          </ErrorBoundary>
        </If>
        <div className="listFolderTree">
          <If condition={!isLoaded || isSearching || sortInProgress}>
            <Spinner />
          </If>
          <ErrorBoundary className="errorBoundaryComponent">
            <FavoritesTree
              renderNode={renderNode}
              selected={selected}
              onNodeSelect={handleClickChild}
            />
          </ErrorBoundary>
          <If condition={!picsioConfig.isPim}>
            <ErrorBoundary className="errorBoundaryComponent">
              <WebsitesTree
                renderNode={renderNode}
                selected={selected}
                onNodeSelect={handleClickChild}
              />
            </ErrorBoundary>
          </If>
          <ErrorBoundary className="errorBoundaryComponent">
            <MainTree
              data={my}
              renderNode={renderNodeForMainTree}
              selected={isTrashActive ? [] : selected}
              onNodeSelect={handleClickChild}
            />
          </ErrorBoundary>
          <If condition={isMainApp}>
            <Trash isActive={isTrashActive} onClick={handleClickTrash} />
          </If>
        </div>
        <If condition={isMainApp}>
          <RecursiveSearchControl />
        </If>
      </TreeResizer>
    </div>
  );
}
