import React, { memo } from 'react';
import { TextField } from '@picsio/ui';
import DetailsBlock from '../DetailsBlock';

import s from './styles.module.scss';

const EMPTY_VALUE = {};

const VariantOptions = ({
  options = EMPTY_VALUE, blockName, blockTitle, toggleVisibility, detailsPanelVisibility,
}) => (

  <DetailsBlock
    dataQa="variantOptions"
    detailsPanelVisibility={detailsPanelVisibility}
    toggleVisibility={toggleVisibility}
    blockName={blockName}
    blockTitle={blockTitle}
  >
    {Object.keys(options).map((key) => (
      <div key={key} className={s.row}>
        <TextField
          label={key}
          value={options[key]}
          disabled
        />
      </div>
    ))}
  </DetailsBlock>
);

export default memo(VariantOptions);
