import React from 'react';
import {
  object, oneOfType, string, func, bool,
} from 'prop-types';
import dayjs from 'dayjs';
import { Icon as IconUI } from '@picsio/ui';
import { Warning, Cloud, Shopify } from '@picsio/icons';
import picsioConfig from '../../../../../../config';
import ToolbarPreviewLeft from '../../toolbars/ToolbarPreviewLeft';
import Icon from '../../Icon';
import * as utils from '../../../shared/utils';

const CONFIG_ERRORS = {
  204: {
    icon: 'broken',
    text: <span>file is empty and can't be used</span>,
    iconColor: '#474747',
  },
  205: {
    icon: 'notSupportedVideo',
    text: <span>This video format is not supported yet</span>,
    iconColor: '#474747',
    selectable: true,
  },
  401: {
    icon: 'noAccessCatalogItem',
    text: <span>insufficient rights <br />to access the preview</span>,
    iconColor: '#474747',
  },
  404: {
    icon: 'notFoundCatalogItem',
    text: <span>file not found <br />in Google Drive</span>,
    iconColor: '#474747',
  },
  noPermissions: {
    icon: 'error',
    text: <span>No permissions to access</span>,
    iconColor: '#474747',
  },
  // this code is not returned by google drive but we can use it to handle situation when file is trashed, but exists in our database
  456: {
    icon: 'notFoundCatalogItem',
    text: <span>file is trashed <br />in Google Drive</span>,
    iconColor: '#474747',
  },
  500: {
    icon: 'notGeneratedCatalogItem',
    text: <span>preview could not be <br />generated</span>,
    iconColor: '#474747',
  },

  thumbnailing: {
    icon: 'generationThumbnailCatalogItem',
    text: <span>Generating thumbnail<br />please check back later</span>,
    iconColor: '#474747',
  },

  converting: {
    icon: 'file',
    text: <span>Preparing and optimizing for preview<br />please check back later</span>,
    iconColor: '#474747',
  },

  transcoding: {
    icon: 'generationThumbnailCatalogItem',
    text: <span>Converting video<br />please check back later</span>,
    iconColor: '#474747',
  },

  locked: {
    icon: 'lockedCatalogItem',
    text: <span>picture <br />is locked</span>,
    iconColor: '#474747',
  },
  importingInProcess: {
    text: 'Importing from external storage...',
    icon: () => <Cloud width={50} height={50} />,
    iconColor: '#474747',
  },
  importingMediaInProcess: {
    text: 'Importing from Shopify store...',
    icon: () => <Shopify width={50} height={50} />,
    iconColor: '#474747',
  },
  importingFailed: {
    text: 'Import failed',
    icon: () => <Warning width={50} height={50} />,
    iconColor: '#FF6B00',
  },
};

const CONFIG_FORMATS = {
  ai: {
    icon: 'aiCatalogItem',
    text: 'AI',
  },
  eps: {
    icon: 'epsCatalogItem',
    text: 'EPS',
  },
  mp3: {
    icon: 'mp3CatalogItem',
    text: 'MP3',
  },
  wav: {
    icon: 'wavCatalogItem',
    text: 'WAV',
  },
  aiff: {
    icon: 'mp3CatalogItem',
    text: 'AIFF',
  },
  sketch: {
    icon: 'sketchCatalogItem',
    text: <span>generating <br />thumbnail</span>,
    iconColor: '#FFCC00',
  },
  obj: {
    icon: 'file3d',
    text: 'OBJ',
  },
  stl: {
    icon: 'file3d',
    text: 'STL',
  },
  fbx: {
    icon: 'file3d',
    text: 'FBX',
  },
  ply: {
    icon: 'file3d',
    text: 'PLY',
  },
  dae: {
    icon: 'file3d',
    text: 'DAE',
  },
  '3ds': {
    icon: 'file3d',
    text: '3DS',
  },
  glb: {
    icon: 'file3d',
    text: 'GLB',
  },
};

const DEFAULT_PLACEHOLDER = {
  icon: 'noPreviewCatalogItem',
  text: <span>no preview <br />available</span>,
  iconColor: '#474747',
};

class Placeholder extends React.Component {
  render() {
    const { props } = this;
    const extension = props.model.fileExtension;
    const fileName = props.model.name;
    const code = props.thumbnailingError && props.thumbnailingError.code ? props.thumbnailingError.code : null;
    let config = null;

    if (!props.model.hasAccess) {
      config = CONFIG_ERRORS.noPermissions;
    } else if (props.isSkipped) {
      config = DEFAULT_PLACEHOLDER;
    } else if (code !== null && code !== undefined) {
      config = CONFIG_ERRORS[code] || DEFAULT_PLACEHOLDER;
      // } else if (props.isThumbnailing || Date.create(props.model.updatedAt).hoursAgo() === 0) {
    } else if (props.isImportingInProcess) {
      config = CONFIG_ERRORS.importingInProcess;
    } else if (props.isImportingFailed) {
      config = CONFIG_ERRORS.importingFailed;
    } else if (props.isImportingMediaInProcess) {
      config = CONFIG_ERRORS.importingMediaInProcess;
    } else if (props.isTranscoding) {
      config = CONFIG_ERRORS.transcoding;
    } else if (props.isConverting) {
      config = CONFIG_ERRORS.converting;
    } else if (props.isThumbnailing || dayjs(props.model.updatedAt).diff(dayjs(), 'hour') === 0) {
      config = CONFIG_ERRORS.thumbnailing;
    } else {
      config = DEFAULT_PLACEHOLDER;
    }

    // config = Object.clone(config);
    config = { ...config };

    const formatConfig = CONFIG_FORMATS[extension];
    if (formatConfig && !props.isSkipped) {
      config.icon = formatConfig.icon || config.icon;
      config.iconColor = formatConfig.iconColor || config.iconColor;
      config.text = formatConfig.text || config.text;
    }

    const { iconColor } = config;
    const text = props.text || config.text;
    const icon = props.icon || config.icon;
    /** @type {boolean} */
    const showButtons = !props.model.trashed && props.model.hasAccess;
    const isRestricted = utils.isAssetRestricted(props.model.restrictSettings);

    return (
      <div className="placeholderMediaFile" draggable>
        {picsioConfig.isMainApp && (
          <ToolbarPreviewLeft
            assetId={props.model._id}
            addRevision={showButtons && props.addRevision}
            openEditor={showButtons && props.openEditor}
            download={showButtons && props.handleDownload}
            moveToTrash={showButtons && props.moveToTrash}
            permissions={props.model.permissions}
            isRestricted={isRestricted}
            isRemoveForever={props.isRemoveForever}
            isPlaceholder
            isThumbnailingRejected={props.isThumbnailingRejected}
            isImportingInProcess={props.isImportingInProcess || props.isImportingMediaInProcess}
            isImportingFailed={props.isImportingFailed}
          />
        )}
        <div className="innerPlaceholderMediaFile">
          <Choose>
            <When condition={typeof icon === 'function'}>
              <IconUI size="xxxl" color="inherit" className="icon" style={{ color: iconColor }}>
                {icon()}
              </IconUI>
            </When>
            <Otherwise>
              <Icon
                name={icon}
                style={{ color: iconColor }}
              />
            </Otherwise>
          </Choose>
          <div className="text">
            {text}
          </div>
          <div className="fileName">{fileName}</div>
          {props.isImportingFailed && (
            <span className="picsioDefBtn" onClick={props.retryImporting}>
              Retry Import
            </span>
          )}
          {(code === 404 || code === 456) && (
            <span className="picsioDefBtn" onClick={props.deleteNotFoundFile}>
              Delete
            </span>
          )}
          {code === 204 && (
            <span className="picsioDefBtn" onClick={props.moveToTrash}>
              Delete
            </span>
          )}
          {!props.model.hasAccess && (
            <span className="picsioDefBtn" onClick={props.removeFromLightboard}>
              Remove from lightboard
            </span>
          )}
        </div>
      </div>
    );
  }
}

Placeholder.propTypes = {
  model: object.isRequired,
  isThumbnailing: bool,
  isTranscoding: bool,
  isConverting: bool,
  openEditor: func,
  addRevision: oneOfType([func, bool]),
  handleDownload: oneOfType([func, bool]),
  moveToTrash: oneOfType([func, bool]),
  thumbnailingError: oneOfType([string, object]),
  deleteNotFoundFile: func,
  text: string,
  icon: string,
};

export default Placeholder;
