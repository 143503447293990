import React, { useEffect } from 'react';
import { useLoader } from '@react-three/fiber';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader';
import PropTypes from 'prop-types';

const PLYModel = ({ url, position }) => {
  const geometry = useLoader(PLYLoader, url);

  useEffect(() => {
    geometry.computeVertexNormals();
  }, [geometry]);

  return geometry && (
    <mesh geometry={geometry} rotation={[-Math.PI / 2, 0, 0]} castShadow receiveShadow position={position}>
      <meshPhysicalMaterial
        color={0xcccccc}
        metalness={0.5}
        roughness={0}
      />
    </mesh>
  );
};

PLYModel.propTypes = {
  url: PropTypes.string.isRequired,
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default PLYModel;
