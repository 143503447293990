/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit';

const LS_KEY = 'picsio.hideDetachAssetsFromProductWarning';

export const removeAssets = createAsyncThunk(
  'products/removeAssets',
  async ({ assetIds, product }, {
    extra: {
      sdk, Logger, utils, showErrorDialog, showDialogAsync,
    },
    getState,
  }) => {
    try {
      const ids = assetIds || getState().assets?.selectedAssetsIds;

      if (!utils.LocalStorage.get(LS_KEY)) {
        try {
          await showDialogAsync({
            title: 'Warning',
            text: 'Are you sure you want to remove selected assets from the product?',
            checkbox: {
              label: 'Don\'t show it again',
              value: false,
              onChange: (value) => {
                utils.LocalStorage.set(LS_KEY, value);
              },
            },
            textBtnOk: 'Remove',
          }).promise;
        } catch (error) {
          /** User pressed "Cancel" */
          return [];
        }
      }

      await sdk.assets.detachFromProduct(product._id, ids);
      return ids;
    } catch (error) {
      const errorMessage = utils.getDataFromResponceError(error, 'msg') || 'Can not remove assets from product';
      showErrorDialog(errorMessage);
      Logger.error(
        new Error('Error remove assets from product'),
        { error },
        ['RemoveAssetsFromProductFailed', errorMessage || 'NoMessage'],
      );
      throw error;
    }
  },
);

export const reducer = (builder) => {
  builder
    .addCase(
      removeAssets.pending,
      (state, { meta }) => {
        const { product } = meta.arg;
        state.items.forEach((p) => {
          if (p._id === product._id) {
            p.isBusy = true;
          }
        });
      },
    )
    .addCase(
      removeAssets.fulfilled,
      (state, { payload: ids, meta }) => {
        if (!ids.length) return;

        const { product } = meta.arg;
        state.items.forEach((p) => {
          if (p._id === product._id) {
            if (p.assets) p.assets = p.assets.filter((a) => !ids.includes(a._id));
            p.isBusy = false;
          }
        });
      },
    )
    .addCase(
      removeAssets.rejected,
      (state, { error }) => {
        state.error = error;
      },
    );
};
