import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import { IconButton, Icon } from '@picsio/ui';
import {
  CheckIcon, CloseIcon, SearchPlusIcon, SearchIcon, Close, AddMoreFilters,
} from '@picsio/icons';
import { usePrevious } from 'react-use';
import SearchFilterItem from './SearchFilterItem';
import Logger from '../../services/Logger';
import picsioConfig from '../../../../../config';
import {
  BOOL_OPTIONS, PROOFING_FILTERS, SINGLE_OPERATORS, FILTER_NAMES_ASSETS, FILTER_NAMES_PRODUCTS, OPERATORS_ORDER,
} from './config';
import AddFiltersBtn from './AddFiltersBtn';
import CatalogViewMode from '../CatalogViewMode';
import ToolbarSort from '../Sort';
import showAddNewFiltersDialog from '../../helpers/showSearchFiltersDialog';
import { showDialog, showErrorDialog } from '../dialog';
import l10n from '../../shared/strings';
import Tooltip from '../Tooltip';
import Autocomplete from '../Search/Autocomplete';
import * as utils from '../../shared/utils';

import {
  getSearchProps, isRouteSearch, reloadRoute, setSearchRoute,
  navigate,
  isRouteProducts,
  isRouteSelectedAssets,
} from '../../helpers/history';
import ua from '../../ua';
import { changeTree, setMobileAdditionalScreenPanel, setMobileMainScreenPanel } from '../../store/actions/main';
import { getByIds } from '../../store/keywordsList/actions';
import { setSortType as setInboxSortType } from '../../store/inboxes/actions';
import * as savedSearchActions from '../../store/actions/savedSearches';
import { activeCollectionSelector } from '../../store/selectors/collections';
import updateUser from '../../store/actions/user/updateUser';
import * as UtilsCollections from '../../store/utils/collections';
import checkForCollectionsSortingsEquality from '../../helpers/checkForCollectionsSortingsEquality';
import { activeSortTypeSelector } from '../../store/inboxes/selectors';
import { updateSortType } from '../../store/actions/lightboards';
import { getAssets } from '../../store/actions/assets';
import Counter from '../Search/Counter';

import './styles.scss';
import TabsCatalog from '../TabsCatalog';

const defaultSort = { type: 'uploadTime', order: 'desc' };
const booleanSearchOperators = [' OR ', ' AND ', ' NOT '];
const tabsConfig = [
  { id: 'media', text: 'Media library' },
  { id: 'products', text: 'Products' },
];

const SearchFilters = ({ searchFilters = [], onlyTextSearch = false }) => {
  const dispatch = useDispatch();
  const assets = useSelector((state) => state.assets);
  const products = useSelector((state) => state.products);
  const user = useSelector((state) => state.user);
  const teammates = useSelector((state) => state.teammates);
  const location = useSelector((state) => state.router.location);
  const openedTree = useSelector((state) => state.main.openedTree);
  const rootCollection = useSelector((state) => state.collections.collections?.my);
  const { activeLightboard } = useSelector((state) => state.lightboards);
  const [view, setView] = useState('');
  const FILTERS = useMemo(() => {
    if (!isRouteSearch(location.pathname) && !isRouteProducts(location.pathname) && !isRouteSelectedAssets(location.pathname)) return {};
    if (isRouteProducts(location.pathname)) {
      setView('products');
      return FILTER_NAMES_PRODUCTS;
    }
    setView('media');
    return FILTER_NAMES_ASSETS;
  }, [location.pathname]);

  const activeTab = useMemo(() => {
    if (location.pathname.startsWith('/products')) return tabsConfig[1].id;
    return tabsConfig[0].id;
  }, [location]);

  const keywordsList = useSelector((state) => state.keywordsList.items);
  const treeKeywords = useSelector((state) => state.keywords.all);
  const savedSearches = useSelector((state) => state.savedSearches.all);
  const allFaces = useSelector((state) => state.faces.all);
  const activeCollections = useSelector(activeCollectionSelector);
  const collectionsSortType = useSelector((state) => state.collections.activeCollections.map((activeCollection) => activeCollection?.sortType));
  const lightboardsSortType = useSelector((state) => state.lightboards.activeLightboard?.sortType);
  const { inboxes, activeInboxID } = useSelector((state) => state.inboxes);
  const customFields = useSelector((state) => state.customFields.items);
  const activeInboxSortType = useSelector(activeSortTypeSelector);
  const prevLocation = usePrevious(location);
  const prevCollectionsSortType = usePrevious(collectionsSortType[0]);
  const prevCollectionsSortTypeLength = usePrevious(collectionsSortType.length);
  const prevLightboardsSortType = usePrevious(lightboardsSortType);
  const prevInboxesSortType = usePrevious(activeInboxSortType);
  const [sortType, setSortType] = useState(defaultSort);
  const watermarks = useSelector((state) => state.assets.watermarks);

  const availableSearchFilters = useMemo(() => {
    if (picsioConfig.isProofing) {
      return PROOFING_FILTERS;
    }
    if (isRouteProducts(location.pathname)) {
      return user.availableSearchFilters?.products || {};
    }
    return user.availableSearchFilters?.assets || {};
  }, [location.pathname, user.availableSearchFilters?.assets, user.availableSearchFilters?.products]);

  const searchFiltersOrder = useMemo(() => {
    if (picsioConfig.isProofing) {
      return searchFilters;
    } if (isRouteProducts(location.pathname)) {
      return user?.settings?.favoriteSearchFilters?.products || [];
    }
    return user?.settings?.favoriteSearchFilters?.assets || [];
  }, [location.pathname, user?.settings?.favoriteSearchFilters?.products, user?.settings?.favoriteSearchFilters?.assets, searchFilters]);

  const [customizingMode, setCustomizingMode] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [confirmedFilters, setConfirmedFilters] = useState({});
  const [searchValue, setSearchValue] = useState('');

  const [open, setOpen] = useState('');

  const normalizeFieldOptions = useCallback((id, options) => {
    if (FILTERS[id]?.options) {
      return options.map((option) => ({ label: FILTERS[id].options[option], value: option }));
    }
    return options.map((option) => ({ label: option, value: option }));
  }, [FILTERS]);

  const generateOperatorsOrder = useCallback((operators, field, id) => {
    if (OPERATORS_ORDER[id]) return _uniq([...OPERATORS_ORDER[id], ...operators]);
    if (field.type === 'date') {
      return _uniq([...OPERATORS_ORDER.date, ...operators]);
    }
    if (field.type === 'number') {
      return _uniq([...OPERATORS_ORDER.number, ...operators]);
    }
    if (field.type === 'enum' && field.multiple) {
      return _uniq([...OPERATORS_ORDER.enumMultiple, ...operators]);
    }
    if (field.type === 'enum' && !field.multiple) {
      return _uniq([...OPERATORS_ORDER.enumSingle, ...operators]);
    }
    if (id === 'comment' && field.type === 'string') {
      return _uniq([...OPERATORS_ORDER.comment, ...operators]);
    }
    if (field.type === 'string' || field.type === 'text') {
      return _uniq([...OPERATORS_ORDER.text, ...operators]);
    }
    return operators;
  }, []);

  const normalizeDefaultSearchFields = useCallback((fields) => fields.filter(([, { hidden }]) => !hidden).map(([id, filed]) => {
    const fieldData = {
      ...filed,
      operators: generateOperatorsOrder(filed.operators, filed, id),
      id,
      title: FILTERS[id]?.title || id,
    };
    if (filed.options) {
      fieldData.options = normalizeFieldOptions(id, filed.options);
    }

    if (filed.type === 'boolean') {
      fieldData.options = BOOL_OPTIONS;
    }

    if (FILTERS[id]?.booleanOptions) {
      fieldData.options = FILTERS[id].booleanOptions;
    }

    return fieldData;
  }), [FILTERS, normalizeFieldOptions, location.pathname, location.key]);

  const initFilters = useMemo(
    () => normalizeDefaultSearchFields(Object.entries(availableSearchFilters)),
    [availableSearchFilters, normalizeDefaultSearchFields, location.pathname, location.key],
  );

  const [activeFilters, setActiveFilters] = useState(initFilters);
  const [order, setOrder] = useState(searchFiltersOrder || activeFilters.map(({ id }) => id));
  const [prevFilters, setPrevFilters] = useState(activeFilters);
  const inactiveFilters = useMemo(() => activeFilters.filter(({ id }) => !order?.find((item) => item === id)), [order, activeFilters]);

  const [prevOrder, setPrevOrder] = useState(order);

  const filterIsHidden = useCallback((entity) => {
    if (entity === 'lightboardId' || entity === 'inboxId') return true;
    if (entity === 'collectionIds' && activeCollections.length === 1 && activeCollections[0]?._id === rootCollection?._id) return true;
    return false;
  }, [activeCollections, rootCollection?._id]);

  const resetIsHidden = useMemo(() => {
    const filters = { ...selectedFilters };
    delete filters.text;

    if (!Object.keys(filters).length) return true;

    if (Object.keys(filters).length === 1) {
      if (filters.collectionIds && filters.collectionIds?.value?.length === 1 && filters.collectionIds.value[0]._id === rootCollection?._id) return true;
      if (filters.lightboardId) return true;
      if (filters.inboxId) return true;
      return false;
    }
    return false;
  }, [rootCollection?._id, selectedFilters]);

  const sortFilters = useCallback((withNew = false) => {
    const sorted = activeFilters.reduce((acc, filter) => {
      const index = order.findIndex((item) => item === filter.id);
      if (index > -1) {
        acc.byOrder[index] = filter;
      } else if (withNew) {
        acc.new.push(filter);
      }
      return acc;
    }, { byOrder: [], new: [] });

    const result = [...sorted.byOrder.filter(Boolean), ...sorted.new];

    const newOrder = result.map((filter) => filter.id);
    if (!_isEqual(newOrder, order)) {
      setOrder(newOrder);
    }

    return result;
  }, [activeFilters, order]);

  const renderItems = useMemo(() => sortFilters(), [sortFilters, activeFilters]);

  const internalSubmit = useRef(false);
  const searchSource = useRef('searchbar');
  const searchTagIds = useRef(null);
  const searchInboxId = useRef(null);
  const searchLightboardId = useRef(null);

  const originalEl = useRef();
  const draggableElement = useRef();
  const tmpItem = useRef();
  const tmpPosition = useRef(null);
  const itemToMove = useRef('');
  const itemToMoveAfter = useRef('');

  const templateTmpItem = useRef(document.createElement('div'));
  templateTmpItem.current.classList.add('temporarySearchFilterItem');

  const onRouteSearch = () => {
    const searchProps = getSearchProps();
    searchTagIds.current = searchProps.collectionIds;
    searchLightboardId.current = searchProps.lightboardId;
    searchInboxId.current = searchProps.inboxId;
    let searchOrder = searchFiltersOrder;

    const nonExistingFilters = Object.keys(searchProps).filter((entity) => !(searchFiltersOrder || [])?.includes(entity) && !filterIsHidden(entity));
    if (nonExistingFilters.length > 0) {
      searchOrder = [...order, ...nonExistingFilters];
    }

    if (internalSubmit.current) {
      internalSubmit.current = false;
      return;
    }

    const result = { text: '' };
    Object.entries(searchProps).forEach(([key, value]) => {
      if (key.endsWith('Operator')) {
        const id = key.replace(/Operator/ig, '');
        if (result[id]) result[id].operator = value;
        else result[id] = { operator: value };
      } else if (result[key]) result[key].value = value;
      else result[key] = { value };
    });

    const metaFields = Object.keys(searchProps).filter((searchFilter) => searchFilter.startsWith('meta.') && !searchFilter.includes('Operator'));
    if (metaFields) {
      customFields.forEach((customField) => {
        const customFieldTitle = `meta.${customField.title}`;
        if (metaFields.includes(customFieldTitle) && customField.type === 'enum') {
          if (Array.isArray(searchProps[customFieldTitle])) {
            result[customFieldTitle].value = searchProps[customFieldTitle].map((option) => ({ title: option, value: option, _id: option }));
          } else {
            result[customFieldTitle].value = [{ title: searchProps[customFieldTitle], value: searchProps[customFieldTitle], _id: searchProps[customFieldTitle] }];
          }
        }
      });
    }

    if (searchProps.commentedBy) {
      const confirmedUsers = teammates.items.filter((teammate) => teammate.parent === undefined || teammate.parent.confirmed === true);
      if (Array.isArray(searchProps.commentedBy)) {
        result.commentedBy.value = confirmedUsers.filter((teammate) => searchProps.commentedBy.includes(teammate._id));
      } else {
        result.commentedBy.value = [confirmedUsers.find((teammate) => teammate._id === searchProps.commentedBy)];
      }
    }
    if (searchProps.createdBy) {
      const confirmedUsers = teammates.items.filter((teammate) => teammate.parent === undefined || teammate.parent.confirmed === true);
      if (Array.isArray(searchProps.createdBy)) {
        result.createdBy.value = confirmedUsers.filter((teammate) => searchProps.createdBy.includes(teammate._id));
      } else {
        result.createdBy.value = [confirmedUsers.find((teammate) => teammate._id === searchProps.createdBy)];
      }
    }

    if (searchProps.keywords && !SINGLE_OPERATORS.includes(result?.keywords?.operator)) {
      result.keywords.value = [];
      const notFoundInTheStore = [];
      searchProps.keywords.forEach((id) => {
        const keyword = keywordsList.find((kw) => kw._id === id) || treeKeywords.find((kw) => kw._id === id);
        if (keyword) {
          const item = {
            _id: keyword._id,
            title: keyword.path.split('→').pop().toLowerCase(),
          };
          result.keywords.value.push(item);
        } else {
          notFoundInTheStore.push(id);
        }
      });

      if (notFoundInTheStore.length) {
        (async () => {
          const { payload } = await dispatch(getByIds(notFoundInTheStore));
          if (Array.isArray(payload)) {
            const items = payload.map(({ _id, name }) => ({ _id, title: name }));
            result.keywords.value = [...result.keywords.value, ...items];
          }
        })();
      }
    }

    if (searchProps.inboxId) {
      result.inboxId.value = [inboxes.find((inbox) => inbox._id === activeInboxID)];
    }

    if (searchProps.collectionIds) {
      result.collectionIds.value = activeCollections;
    }

    if (searchProps.watermarkId) {
      if (Array.isArray(searchProps.watermarkId)) {
        result.watermarkId.value = watermarks.filter((watermark) => searchProps.watermarkId.includes(watermark._id));
      } else {
        result.watermarkId.value = [watermarks.find((watermark) => watermark._id === searchProps.watermarkId)];
      }
    }

    if (searchProps.faces) {
      result.faces.value = [];
      searchProps.faces.forEach((id) => {
        const face = allFaces.find((face) => face._id === id);
        if (face) {
          const item = {
            _id: face._id,
            title: face.name.split('→').pop().toLowerCase(),
          };
          if (!result.faces.value) result.faces.value = [];
          result.faces.value.push(item);
        }
      });
    }

    if (searchProps.searchSource) {
      result.searchSource = searchProps.searchSource;
    }

    if (searchProps.lightboardId) {
      result.lightboardId.value = [activeLightboard];
    }

    if (searchProps.text) {
      result.text = searchProps.text;
    }
    Object.entries(result).forEach(([id, { operator, value }]) => {
      if (!operator) {
        if (id === 'collectionIds' || id === 'keywords' || id === 'faces') {
          result[id].operator = 'oneOf';
        } else if (id === 'lightboardId' || id === 'inboxId' || id === 'trashed') {
          result[id].operator = 'equals';
        }
      }
    });
    setSearchValue(result.text);
    setSelectedFilters(result);
    setConfirmedFilters(result);
    setActiveFilters(initFilters);
    setOrder(searchOrder);
  };

  const makeSearchRoute = useCallback((filters) => {
    const { trashed, archived } = getSearchProps();
    const result = {};
    if (trashed && searchSource.current) {
      result.trashed = trashed;
    }
    if (archived && searchSource.current) {
      result.archived = archived;
    }
    const rootCollectionId = rootCollection?._id;

    Object.entries(filters).forEach(([id, { operator, value }]) => {
      switch (true) {
      case ['faces', 'collectionIds', 'lightboardId', 'inboxId', 'keywords', 'assignees', 'commentedBy', 'createdBy', 'watermarkId'].includes(id) || (id.startsWith('meta.')):
        if (value?.length > 0 && Array.isArray(value)) {
          result[id] = value.map((item) => item?._id || item);
        } else {
          result[id] = value;
        }
        result[`${id}Operator`] = operator;
        break;
      default:
        result[id] = value;
        result[`${id}Operator`] = operator;
      }
    });

    if (filters.text) result.text = filters.text;

    // eslint-disable-next-line no-param-reassign
    delete filters.searchSource;
    if (searchSource.current && !_isEmpty(filters)) result.searchSource = searchSource.current;

    if (location?.query?.archived && filters.collection?.value && filters.collection?.value.length > 0) {
      delete result.archived;
    }
    if (location?.query?.trashed && filters.collection?.value && (!filters.collection?.value.includes(rootCollectionId) || filters.collection?.value.length > 1)) {
      delete result.trashed;
    }
    if (openedTree && openedTree !== 'collections' && filters.collection?.value && filters.collection?.value.length > 0) {
      dispatch(changeTree('collections'));
    }
    if (Object.keys(filters).length === 0 && Object.keys(result).length === 0 && view === 'media') {
      result.collectionIds = rootCollectionId;
    }

    return result;
  }, [dispatch, location?.query?.archived, location?.query?.trashed, openedTree, rootCollection?._id, searchInboxId, searchLightboardId, searchSource]);

  const setRoute = useCallback((filters) => {
    const isMobile = ua.browser.isNotDesktop() || ua.isMobileApp();
    internalSubmit.current = true;

    // for submit or reset, when route not changed
    setTimeout(() => {
      internalSubmit.current = false;
    }, 200);
    setSearchRoute(makeSearchRoute(filters), { isProducts: view === 'products' });

    if (isMobile) {
      dispatch(setMobileAdditionalScreenPanel());
      dispatch(setMobileMainScreenPanel('catalog'));
    }
  }, [dispatch, makeSearchRoute, view]);

  const submit = useCallback((filters, isReset = false) => {
    searchSource.current = isReset ? null : 'searchbar';
    setRoute(filters);
  }, [setRoute]);

  const handleSelectFilter = useCallback((id, data) => {
    setSelectedFilters((prev) => ({ ...prev, [id]: data }));
  }, []);

  const isEntity = useCallback((id) => {
    if (id === 'collectionIds' || id === 'inboxId' || id === 'lightboardId') {
      return true;
    } return false;
  }, []);

  const getEntity = useCallback((filters) => Object.keys(filters).find((filter) => isEntity(filter)), [isEntity]);

  const handleConfirmFilter = useCallback((id, data) => {
    setConfirmedFilters((prev) => {
      const oldFilters = { ...prev };
      if (!oldFilters[id] && isEntity(id)) {
        const oldSearchEntity = getEntity(oldFilters);
        if (oldSearchEntity === 'collectionIds') {
          searchTagIds.current = null;
        } else if (oldSearchEntity === 'inboxId') {
          searchInboxId.current = null;
        } else if (oldSearchEntity === 'lightboardId') {
          searchLightboardId.current = null;
        }
        delete oldFilters[oldSearchEntity];
      }
      const nextFilters = {
        ...oldFilters,
        [id]: data,
      };
      if (searchValue) {
        nextFilters.text = searchValue;
      }
      Logger.log('User', 'FilterApplied', {
        filter_name: id,
        filter_operator: data.operator,
        filter_value: data.value,
        active_filters_list: oldFilters,
        active_filters_count: Object.keys(nextFilters).length,
        broad_search_value: searchValue,
      });
      submit(nextFilters);
      return nextFilters;
    });
    setOpen('');
  }, [getEntity, isEntity, searchValue, submit]);

  const handleResetAllFilters = useCallback(() => {
    Logger.log('User', 'ResetAllFiltersClicked');
    setSearchValue('');
    if (searchInboxId.current) {
      setConfirmedFilters({ inboxId: confirmedFilters.inboxId });
      setSelectedFilters({ inboxId: selectedFilters.inboxId });
      submit({}, true);
      return;
    }
    if (searchLightboardId.current) {
      setConfirmedFilters({ lightboardId: confirmedFilters.lightboardId });
      setSelectedFilters({ lightboardId: selectedFilters.lightboardId });
      submit({}, true);
      return;
    }
    if (searchTagIds.current) {
      setConfirmedFilters({
        collectionIds: {
          operator: 'oneOf',
          value: [rootCollection],
        },
      });
      setSelectedFilters({
        collectionIds: {
          operator: 'oneOf',
          value: [rootCollection],
        },
      });
      submit({}, true);
      return;
    }
    setSelectedFilters({});
    setConfirmedFilters({});
    setOpen('');
    submit({}, true);
  }, [submit]);

  const handleResetFilter = useCallback((id) => {
    setSelectedFilters((prev) => {
      const nextFilters = { ...prev };
      delete nextFilters[id];
      return nextFilters;
    });
    setConfirmedFilters((prev) => {
      const nextFilters = { ...prev };
      Logger.log('User', 'ResetFilterClicked', {
        filter_name: id,
        filter_operator: nextFilters[id]?.operator || 'none',
        filter_value: nextFilters[id]?.value || 'none',
        remaining_filters_list: Object.keys(nextFilters).filter((key) => key !== id),
        remaining_filters_count: Object.keys(nextFilters).length - 1,
      });
      delete nextFilters[id];
      submit(nextFilters);
      return nextFilters;
    });
    setOpen('');
  }, [submit]);

  const handleCustomizingMode = useCallback(() => {
    Logger.log('User', 'ManageFiltersClicked');
    setCustomizingMode((prev) => !prev);
    setPrevFilters(activeFilters);
    setPrevOrder(order);
  }, [activeFilters, order]);

  const handleSavedSearchSave = useCallback(() => {
    const { manageTeamSavedSearches } = user.role.permissions;

    const dialogConfig = {
      title: l10n.SEARCH.dialogTitleCreateSearch,
      input: {
        label: l10n.SEARCH.dialogCreateSearchLabel,
        value: '',
      },
      onOk: (data) => {
        const savedSearchDuplicate = savedSearches.find(
          (ss) => ss.name.toLowerCase().trim() === data.input.toLowerCase().trim(),
        );

        if (savedSearchDuplicate) {
          showDialog({
            title: l10n.SEARCH.dialogTitleSearchHaveAlready,
            text: l10n.SEARCH.dialogTextSearchHaveAlready,
            textBtnCancel: null,
          });
          return;
        }

        if (!data.input) {
          showErrorDialog(l10n.SEARCH.dialogErrorNameEmpty);
          return;
        }
        Logger.log('User', 'SaveSearchClicked', {
          active_filters_list: confirmedFilters,
          search_name: data.input,
          shared_with_team: data.checkbox,
          broad_search_value: searchValue,
        });
        dispatch(savedSearchActions.add(data.input, makeSearchRoute(confirmedFilters), data.checkbox));
      },
    };
    if (manageTeamSavedSearches) {
      dialogConfig.checkbox = {
        value: true,
        label: l10n.SEARCH.dialogCreateSearchLabelShare,
      };
    }
    showDialog(dialogConfig);
  }, [confirmedFilters, dispatch, makeSearchRoute, savedSearches, user?.role?.permissions]);

  const handleCancelCustomizingMode = useCallback(() => {
    Logger.log('User', 'CancelFilterConfigurationClicked');
    setCustomizingMode(false);
    setActiveFilters(prevFilters);
    setOrder(prevOrder);
  }, [prevFilters, prevOrder]);

  const handleSaveFilters = useCallback(() => {
    Logger.log('User', 'SaveFilterConfigurationClicked');
    const isFiltersOrderChanged = !_isEqual(order, prevOrder);
    if (isFiltersOrderChanged) {
      Logger.log('User', 'ChangeFilterDisplayOrder', {
        changed_filter_position: order,
      });
    }
    setCustomizingMode(false);
    setPrevFilters(activeFilters);
    setPrevOrder(order);
    if (view === 'media') {
      dispatch(updateUser({ settings: { favoriteSearchFilters: { assets: order } } }, false));
    }
    if (view === 'products') {
      dispatch(updateUser({ settings: { favoriteSearchFilters: { products: order } } }, false));
    }
  }, [activeFilters, dispatch, order, view]);

  const checkActive = useCallback((id) => confirmedFilters[id], [confirmedFilters]);

  const checkOpen = useCallback((id) => id === open, [open]);

  const handleOpen = useCallback(
    (id) => {
      if (customizingMode) return;
      setOpen(id);
    }, [customizingMode],
  );

  const handleClose = useCallback(
    (e) => {
      e.stopPropagation();
      if (customizingMode) return;
      setOpen('');
    }, [customizingMode],
  );

  const handleRemoveFilter = useCallback((id) => {
    Logger.log('User', 'RemoveFilterClicked', {
      removed_filter: id,
    });
    setOrder(order.filter((or) => or !== id));
  }, [order]);

  const handleAddFilters = useCallback((filters) => {
    const filtersIds = filters.map(({ id }) => id);
    Logger.log('User', 'AddFiltersClicked', {
      selected_filter_name: filtersIds,
    });
    setOrder([...order, ...filtersIds]);
  }, [order]);

  const handleShowAddFiltersDialog = useCallback(() => {
    showAddNewFiltersDialog({ filters: inactiveFilters, onOk: handleAddFilters });
  }, [handleAddFilters, inactiveFilters]);

  const preventDefault = (event) => {
    event.preventDefault();
  };

  const dragstart = (event) => {
    document.body.addEventListener('dragover', preventDefault);
    document.body.addEventListener('drop', preventDefault);

    event.stopPropagation();

    const el = (originalEl.current = event.currentTarget);
    draggableElement.current = event.currentTarget;
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/plain', 'Data to Drag');
    tmpItem.current = templateTmpItem.current;

    tmpPosition.current = itemToMove.current = itemToMoveAfter.current = event.currentTarget.dataset.id;

    setTimeout(() => {
      el.parentNode.insertBefore(tmpItem.current, el);
      el.style.display = 'none';
    }, 0);
  };

  const dragenter = (event) => {
    event.stopPropagation();
    const { currentTarget } = event;
    const { id } = currentTarget.dataset;
    const el = currentTarget;
    el.after(tmpItem.current);

    tmpPosition.current = itemToMoveAfter.current = id;
  };

  const dragend = () => {
    document.body.removeEventListener('dragover', preventDefault);
    document.body.removeEventListener('drop', preventDefault);
    tmpItem.current.remove();
    originalEl.current.style.display = 'inline-block';
    if (itemToMoveAfter.current === 'first') {
      itemToMoveAfter.current = '';
    }

    const fieldToMove = activeFilters.find((item) => item.id === itemToMove.current);
    const fieldToMoveAfter = activeFilters.find(
      (item) => item.id === itemToMoveAfter.current,
    );

    if (!fieldToMove || fieldToMove === fieldToMoveAfter) return;

    const fieldsToMove = [fieldToMove.id];
    const currentFieldToMoveAfter = itemToMoveAfter.current;

    const newItems = [...order];
    const indexToRemove = newItems.findIndex((item) => item === fieldsToMove[0]);
    const itemsToMove = newItems.splice(indexToRemove, fieldsToMove.length);
    const indexToPut = currentFieldToMoveAfter ? newItems.findIndex((item) => item === currentFieldToMoveAfter) : -1;
    newItems.splice(indexToPut + 1, 0, ...itemsToMove);

    setOrder(newItems);
  };

  const getCurrentSortType = () => {
    const { collectionIds, lightboardId, inboxId } = location.query;
    let sortTypeNew = sortType;

    if (collectionIds && collectionsSortType.length) {
      if (collectionsSortType.length === 1) {
        sortTypeNew = collectionsSortType[0];
      } else {
        const equalSortings = checkForCollectionsSortingsEquality(collectionsSortType);
        if (equalSortings) {
          sortTypeNew = collectionsSortType[0];
        } else {
          sortTypeNew = defaultSort;
        }
      }
    } else if (lightboardId && lightboardsSortType) {
      sortTypeNew = lightboardsSortType;
    } else if (inboxId) {
      if (activeInboxSortType) {
        sortTypeNew = activeInboxSortType;
      }
    }

    return sortTypeNew;
  };

  const changeSortType = () => {
    const sortTypeNew = getCurrentSortType();
    if (sortTypeNew !== sortType) {
      setSortType(sortTypeNew);
    }
  };

  useEffect(() => {
    if ((isRouteSearch() && prevLocation !== location) || (
      prevCollectionsSortType !== collectionsSortType[0]
      || prevCollectionsSortTypeLength !== collectionsSortType.length
      || prevLightboardsSortType !== lightboardsSortType
      || prevInboxesSortType !== activeInboxSortType
    )) {
      changeSortType();
    }
  });

  const handleChangeSort = useCallback(async (name, order) => {
    if (picsioConfig.isMainApp) {
      const { collectionIds, lightboardId, inboxId } = location.query;
      if (collectionIds) {
        UtilsCollections.setSortType({
          type: name,
          order,
        });
      }

      if (inboxId) {
        await dispatch(setInboxSortType({ _id: inboxId, sortType: { type: name, order } }));
        reloadRoute();
      }

      if (lightboardId) {
        dispatch(updateSortType(lightboardId, {
          type: name,
          order,
        }));
      }
    } else {
      const newSortType = { type: name, order };
      setSortType(newSortType);
      window.websiteConfig.sortType = newSortType;
      dispatch(getAssets(true));
    }
  }, [dispatch, location.query]);

  useEffect(() => {
    onRouteSearch();
  }, [activeCollections, allFaces, location.key, activeLightboard, activeInboxID]);

  const getSearchPlaceholder = useMemo(() => {
    let placeholder = 'Search all collections';

    if (picsioConfig.isPim) {
      placeholder = 'Type to search';
      return placeholder;
    }
    const {
      lightboardId, inboxId, archived, collectionIds, trashed,
    } = getSearchProps();
    const rootCollectionId = rootCollection?._id;

    // archive
    if (collectionIds && archived) {
      if (activeCollections?.name && activeCollections?.name !== rootCollection.name) {
        placeholder = `Search in '${utils.decodeSlash(activeCollections.name)}'`;
      }
    } else if (trashed && activeCollections.every(({ _id }) => _id === rootCollectionId)) {
      placeholder = 'Search in trashed assets';
    } else if (collectionIds) {
      if (activeCollections.length === 1 && activeCollections[0]?.name && activeCollections[0]?.name !== rootCollection.name) {
        placeholder = `Search in '${utils.decodeSlash(activeCollections[0].name)}'`;
      } else if ((activeCollections.length === 1 && activeCollections.find(({ _id }) => _id !== rootCollectionId))) {
        placeholder = `Search in '${utils.decodeSlash(activeCollections[0].name)}'`;
      } else if (activeCollections.length > 1) {
        placeholder = 'Search in multiple collections';
      }
    } else if (lightboardId) {
      if (activeLightboard?.path) {
        const name = activeLightboard.path.split('→').pop();
        placeholder = `Search in '${decodeURIComponent(name)}'`;
      }
    } else if (inboxId) {
      const inbox = inboxes.find((item) => item._id === inboxId) || {};
      if (inbox?.name) {
        const { name } = inbox;
        placeholder = `Search in '${decodeURIComponent(name)}'`;
      }
    }
    return placeholder;
  }, [activeCollections, activeLightboard, inboxes, rootCollection?._id, rootCollection?.name, location.key]);

  const handleSearchValueChange = useCallback((searchText) => {
    setSearchValue(searchText);
  }, []);

  const submitSearchValue = (text) => {
    setSearchRoute(makeSearchRoute({ ...confirmedFilters, text }), { isProducts: view === 'products' });
    Logger.log('User', 'Search', {
      text: searchValue,
      ...location.query,
    });

    const booleanSearchOperatorsInUse = new Set();
    booleanSearchOperators.forEach((operator) => {
      if (searchValue.includes(operator)) {
        booleanSearchOperatorsInUse.add(operator);
      }
    });
    if (booleanSearchOperatorsInUse.size) {
      Logger.log('User', 'SearchWithBooleanOperators', {
        operators: Array.from(booleanSearchOperatorsInUse),
      });
    }
  };

  const resetSearchValue = useCallback(() => {
    setSearchValue('');

    setSearchRoute(makeSearchRoute({ ...confirmedFilters, text: '' }), { isProducts: view === 'products' });
  }, [confirmedFilters, makeSearchRoute, view]);

  const handleChangeTab = useCallback((tabId) => {
    if (tabId === 'media') {
      navigate('/search');
    } else {
      navigate('/products');
    }
  }, []);

  return (
    <>
      <If condition={!onlyTextSearch && picsioConfig.isPim}>
        <TabsCatalog
          list={tabsConfig}
          active={activeTab}
          onChange={handleChangeTab}
          showBulkMediaMatch
        />
      </If>
      <div className={cn('searchFilters__wrapper', { onlyTextSearch })} style={{ justifyContent: picsioConfig.isProofing ? 'unset' : 'space-between' }}>
        <div className="wrapperSearch searchFilters">

          <div id="searchInput">
            <div className="searchHeader">
              <Choose>
                <When condition={searchValue.length}>
                  <span
                    role="button"
                    tabIndex={-1}
                    onKeyPress={resetSearchValue}
                    className="btnReset"
                    onClick={resetSearchValue}
                  >
                    <Icon size="md" color="inherit">
                      <Close />
                    </Icon>
                  </span>
                </When>
                <Otherwise>
                  <span className="btnSearch">
                    <Icon size="md" color="inherit">
                      <SearchIcon />
                    </Icon>
                  </span>
                </Otherwise>
              </Choose>
              <Autocomplete
                placeholder={getSearchPlaceholder}
                searchValue={searchValue}
                handleChange={handleSearchValueChange}
                handleSubmit={submitSearchValue}
              />
              <Counter view={view} amount={view === 'products' ? products.search.total : assets.total} className="filters" />
              {
                searchValue.length > 0 && (
                  <div
                    className="picsioDefBtn btnSearch btnCallToAction btnSubmitText"
                    onClick={() => {
                      Logger.log('User', 'AdvancedSearchPanelConfirm', { ...location.query });
                      submit({ ...confirmedFilters, text: searchValue });
                    }}
                  >
                    {l10n.SEARCH.text.Search}
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <If condition={!onlyTextSearch}>
          <div className="searchFilters__container" style={{ display: customizingMode ? 'flex' : 'inline-block' }}>

            <If condition={!customizingMode}>
              <div className="searchFilters__controls" style={{ float: 'right' }}>
                <If condition={picsioConfig.isMainApp}>
                  <Tooltip
                    content={l10n.SEARCH.addMoreFilters}
                    placement="top"
                  >
                    <IconButton
                      size="md"
                      className="searchFilters__iconBtn addMore"
                      onClick={handleCustomizingMode}
                    >
                      <AddMoreFilters />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    content={l10n.SEARCH.saveSearch}
                    placement="top"
                  >
                    <IconButton
                      size="md"
                      className="searchFilters__iconBtn"
                      onClick={handleSavedSearchSave}
                      disabled={!Object.keys(selectedFilters).length}
                    >
                      <SearchPlusIcon />
                    </IconButton>
                  </Tooltip>
                </If>
                <If condition={isRouteSearch(location.path)}>
                  <Tooltip
                    content={l10n.SEARCH.sort}
                    placement="top"
                  >
                    <div>
                      <ToolbarSort
                        sortType={sortType}
                        changeSort={handleChangeSort}
                        openOnClick
                      />
                    </div>
                  </Tooltip>
                  <Tooltip
                    content={l10n.SEARCH.view}
                    placement="top"
                  >
                    <div>
                      <CatalogViewMode openOnClick leftFilter />
                    </div>
                  </Tooltip>
                </If>
              </div>
            </If>
            <div
              className="firstSearchItem"
              data-id="first"
              onDragEnter={dragenter}
            />
            {
              renderItems.map((item) => (
                <SearchFilterItem
                  key={item.id}
                  id={item.id}
                  data={item}
                  filterData={selectedFilters[item.id]}
                  confirmedData={confirmedFilters[item.id]}
                  customizingMode={customizingMode}
                  onSelectFilter={handleSelectFilter}
                  onConfirmFilter={handleConfirmFilter}
                  onResetFilter={handleResetFilter}
                  isSelected={checkActive(item.id)}
                  onOpen={handleOpen}
                  onClose={handleClose}
                  isOpen={checkOpen(item.id)}
                  onRemove={handleRemoveFilter}
                  onDragStart={dragstart}
                  onDragEnter={dragenter}
                  onDragEnd={dragend}
                  filters={FILTERS}
                  rootCollectionId={rootCollection?._id}
                />
              ))
            }
            <If condition={picsioConfig.isMainApp}>
              <If condition={customizingMode}>
                <AddFiltersBtn onAddFilters={handleShowAddFiltersDialog} />
              </If>
            </If>
            <If condition={!customizingMode && Object.keys(selectedFilters).length && !resetIsHidden}>
              <div
                data-id="resetFilters"
                style={{
                  position: 'relative', display: 'inline-block',
                }}
              >
                <span
                  className="picsioLink"
                  onClick={handleResetAllFilters}
                >
                  {l10n.SEARCH.resetFiltersBtn}
                </span>
              </div>
            </If>
          </div>
        </If>

        <If condition={customizingMode}>
          <div className="searchFilters__controls" style={{ float: 'right', padding: '10px 12px' }}>
            <IconButton
              size="md"
              className="searchFilters__iconBtn searchFilters__customizeIconBtn"
              onClick={handleCancelCustomizingMode}
            >
              <CloseIcon />
            </IconButton>
            <Tooltip
              content={l10n.SEARCH.saveFiltersTooltip}
              placement="top"
            >
              <IconButton
                size="md"
                className="searchFilters__iconBtn searchFilters__customizeIconBtn"
                onClick={handleSaveFilters}
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
          </div>
        </If>
      </div>
    </>
  );
};

export default SearchFilters;
