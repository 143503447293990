import { useThree } from '@react-three/fiber';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ThreeContext from '../ThreeContext';

const ScreenshotHandler = ({ takeScreenshot }) => {
  const theme = useSelector((state) => state?.user?.settings?.picsioTheme);
  const THREE = useContext(ThreeContext);
  const {
    gl, scene, camera, size,
  } = useThree();

  // eslint-disable-next-line no-param-reassign
  takeScreenshot.current = () => {
    const dpr = window.devicePixelRatio;
    const width = Math.floor(size.width * dpr);
    const height = Math.floor(size.height * dpr);

    const originalBackground = scene.background;

    scene.background = new THREE.Color(theme === 'light' ? '#FFFFFF' : '#000000');

    const renderTarget = new THREE.WebGLRenderTarget(width, height);
    renderTarget.texture.encoding = THREE.sRGBEncoding;

    gl.setRenderTarget(renderTarget);
    gl.render(scene, camera);
    gl.setRenderTarget(null);

    scene.background = originalBackground;

    const buffer = new Uint8Array(width * height * 4);
    gl.readRenderTargetPixels(renderTarget, 0, 0, width, height, buffer);

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    const imageData = ctx.createImageData(width, height);

    for (let y = 0; y < height; y += 1) {
      const rowStart = y * width * 4;
      const flippedRowStart = (height - y - 1) * width * 4;
      for (let x = 0; x < width * 4; x += 4) {
        imageData.data[flippedRowStart + x] = (buffer[rowStart + x] / 255) ** (1 / 2.2) * 255;
        imageData.data[flippedRowStart + x + 1] = (buffer[rowStart + x + 1] / 255) ** (1 / 2.2) * 255;
        imageData.data[flippedRowStart + x + 2] = (buffer[rowStart + x + 2] / 255) ** (1 / 2.2) * 255;
        imageData.data[flippedRowStart + x + 3] = buffer[rowStart + x + 3];
      }
    }

    ctx.putImageData(imageData, 0, 0);

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          const $image = new Image();
          $image.addEventListener('load', () => resolve(blob));
          $image.src = window.URL.createObjectURL(blob);
        },
        'image/jpeg',
        0.92,
      );
    });
  };

  return null;
};

ScreenshotHandler.propTypes = {
  takeScreenshot: PropTypes.shape({
    current: PropTypes.any,
  }).isRequired,
};

export default ScreenshotHandler;
